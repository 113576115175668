import { useGLTF } from '@react-three/drei'

export function Glasses(props) {
  const { nodes, materials } = useGLTF('./assets/inventory/glasses.glb')
  return (
    <group {...props} dispose={null} scale={0.004}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.frame.geometry}
        material={materials['Chrome 001']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.neck.geometry}
        material={materials['Chrome 001']}
      />
      <mesh castShadow receiveShadow geometry={nodes.Handle.geometry} material={materials.black} />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.glass.geometry}
        material={materials['Glass w/o']}
      />
    </group>
  )
}

useGLTF.preload('./assets/inventory/glasses.glb')