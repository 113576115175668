// DemoCredentials.js
import React from 'react';
import styled from 'styled-components';

const CredentialsContainer = styled.div`
  position: fixed;
  top: 50px; /* Positioned slightly further down from the top */
  left: 50%;
  transform: translateX(-50%);
  background-color: #222;
  color: #ffcc00; /* Color for the general text */
  padding: 25px 35px; /* Increased padding for a larger box */
  border-radius: 12px; /* Slightly larger border radius */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
  font-size: 20px; /* Increased font size for better visibility */
  font-family: Arial, sans-serif;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  text-align: center; /* Center text */
`;

const Key = styled.span`
  color: #1e90ff; /* Blue color for the key */
  font-weight: bold;
`;

const Value = styled.code`
  color: #ffffff; /* Color for the values */
  background-color: #444; /* Background color for readability */
  padding: 2px 6px;
  border-radius: 4px;
`;

const DemoCredentials = () => {
  return (
    <CredentialsContainer>
      <Key>Demo Credentials:</Key>
      <div>
        <Key>Username:</Key> <Value>gamejam01</Value>
      </div>
      <div>
        <Key>Password:</Key> <Value>gamejamdemo</Value>
      </div>
    </CredentialsContainer>
  );
};

export default DemoCredentials;
