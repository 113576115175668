import { useEffect } from "react";
import useEventStore from '../../stores/useEventStore';
import useGame from '../../stores/useGame';
import { eventToEventStore } from "../../services/eventToEventStore";

export default function useEventObserver() {
    const { event, setEvent } = useEventStore((state) => state);
    const { UID } = useGame((state) => state);
    useEffect(() => {
        console.log('xvf', 'observerd', event);
        if (event) {
            eventToEventStore(event, UID).then(() => {
                setEvent(null);
            })
        }
    }, [event, UID, setEvent]);
}