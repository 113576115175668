import styled from 'styled-components';
import useGoalStore from '../stores/useGoalStore';

const GoalsContainer = styled.div`
  position: fixed;
  top: 5rem;
  right: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  backdrop-filter: blur(4px);
  background: #00000080; /* Semi-transparent black background */
  color: white;
  font-weight: bold;
  font-size: 1.5rem;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 14rem;
  max-height: 20rem; /* Limit the height */
  overflow-y: auto; /* Enable vertical overflow */

  &::-webkit-scrollbar {
    width: 5px;
    /* Adjust the width to make the scrollbar thinner */
  }

  &::-webkit-scrollbar-thumb {
    background-color: white;
    /* Set the color of the scrollbar thumb */
    border-radius: 10px;
    /* Optional: Add rounded corners to the scrollbar thumb */
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    /* Optional: Set the track background to transparent */
  }
`;

const GoalContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ latest, completed }) => 
    completed ? '#333' : (latest ? '#555' : '#444')}; /* Darker background for latest goal and completed goals */
  border: ${({ latest, completed }) => 
    !completed && latest ? '2px solid #ff6347' : 'none'}; /* Red border for selected goal */
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 8px;
  width: 100%;
  justify-content: flex-start;
  cursor: ${({ completed }) => (completed ? 'default' : 'pointer')}; /* No pointer cursor for completed goals */
  transition: background-color 0.3s, border 0.3s; /* Smooth transition for hover effects */
  
  &:hover {
    background-color: ${({ latest, completed }) => 
      completed ? '#333' : (latest ? '#444' : '#555')}; /* Lighter background on hover */
  }
`;

const Icon = styled.i`
  font-size: 20px;
  color: #ff6347; /* Red color for the icon */
  margin-right: 8px;
`;

const GoalText = styled.span`
  font-size: 1rem;
  color: ${({ completed }) => (completed ? '#888' : '#fff')}; /* Grey color if completed */
  font-family: 'Arial', sans-serif; /* Matching font */
  text-decoration: ${({ completed }) => (completed ? 'line-through' : 'none')}; /* Strikethrough if completed */
`;

const GoalStyledComponent = ({ goal, setLatestGoal, isLatest }) => (
  <GoalContainer
    latest={isLatest}
    completed={goal.status}
    onClick={() => !goal.status && setLatestGoal(goal.id)} // Prevent selection if completed
  >
    <Icon className='bx bx-bullseye'></Icon>
    <GoalText completed={goal.status}>{goal.goal}</GoalText>
  </GoalContainer>
);

const GoalsWidget = () => {
  const goals = useGoalStore((state) => state.goals);
  const latestGoalId = useGoalStore((state) => state.latestGoalId);
  const setLatestGoal = useGoalStore((state) => state.setLatestGoalId);

  return (
    <GoalsContainer>
      <h2 style={{ marginBottom: '10px', fontSize: '1.5rem' }}>User Goals</h2>
      <div>
        {goals.map((goal) => (
          <GoalStyledComponent
            key={goal.id}
            goal={goal}
            setLatestGoal={setLatestGoal}
            isLatest={goal.id === latestGoalId}
          />
        ))}
      </div>
    </GoalsContainer>
  );
};

export default GoalsWidget;
