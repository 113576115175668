function formatString(input) {
      // Replace '_' with spaces and split the string
      let words = input.replace(/_/g, " ").split("[")[0].split(" ");

      // Capitalize each word
      let formattedWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1)
      );

      // Join the words with spaces and add remaining characters
      let result = formattedWords.join(" ");

      return result;
}
    
export default formatString;