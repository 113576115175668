import World from './World.jsx'
import Lights from '../Lights.jsx'
import Player from './Player.jsx'
import Effects from './Effects.jsx'
import { useEffect, useRef } from 'react'
import { createExecute, createNarration } from './config/Narrations.jsx';
import useDialogue from '../stores/useDialogue.jsx';
import useAssistant from '../stores/useAssistant.jsx';
import useGame from '../stores/useGame.jsx'
import { StoryGenerationAPI } from '../services/storyGeneration.js'

// const narrationListMapper = (situation) => {
//     const mappedNarrationList = situation.map(scene => {
//         scene.source = 'system'
//         scene.type = 'narration'
//         scene.text = scene.dialogue
//         scene.trigger= "start"
//         scene.action= null
//         scene.key= "onStart"
//         scene.timeout= 1000
//         delete scene.next;
//         let startCode = 48
//         if(scene.choices.length > 0) {
//             // scene.source = 'system'
//             scene.type = 'dialogue'
//             scene.trigger = 'choices'
//             scene.action= null
//             scene.key= scene.id
//             scene.timeout= 1
//             scene.choices = scene.choices.map(choice => {
//                 const newChoice = {
//                     label: choice.action.type,
//                     keyCode: startCode++,
//                     next: {
//                         action: null,
//                         source: 'system',
//                         key: scene.id,
//                         timeout: 1,
//                         text: choice.action.url,
//                         type: 'execute',
//                         trigger: 'browser',
//                         url: choice.action.url,
//                         next: null,
//                         nextId: scene.nextId
//                     }
//                 }
//                 return newChoice
//             })
//         }
//         return scene
//     })
//     return mappedNarrationList
// }


// Run setInterval of 30 seconds

export default function Experience({ downgradedPerformance = false })
{
    const ref = useRef();
    const setNarration = useDialogue(state => state.setNarration);
    const { setIframePosition } = useGame((state) => state);
    const { plot, gameSession } = useAssistant((state) => state);

    useEffect(() => {   
        // console.log('xvf', gameSession)
        const DEBUG = localStorage.getItem('DEBUG') || 'FALSE';
        if (plot && DEBUG === 'FALSE') {
            // execute for video
            setIframePosition('center');
            const createTutorialExecute = createExecute(
                'Tutorial Execute',
                'https://app.supademo.com/embed/clyvz8lcw1b61z9kdfh8nh1v9'
            );
            setNarration(createNarration('plot', ` Edna:-${plot} `, createTutorialExecute));
        }
    }, [])

    // useEffect(() => {
    //     if (currentNarrationList !== null) {
    //         setNarration(currentNarrationList);
    //     }
    // }, [])

    // useEffect(() => {
    //     // console.log('xvf narrations', getNarration('start'));
    //     // console.log('getGeneration', getNarration('start', null, 'onStart'))
    //     // true means it will use `mock` data and else it will use openai to fetch content.
    //     StoryGenerationAPI.sceneGeneration(false, null, topic, uid).then(response => {
    //         // const narrationSceneList = narrationListMapper(response)
    //         // console.log('narrationSceneList[0]',narrationSceneList[0])
    //         // setSceneList(narrationSceneList) // we setting enitre scene
    //         // setNarration(narrationSceneList[0]) // and then we're taking first element from it by defaul
    //         // console.log(narrationSceneList[0])
    //         const { currentNarrationList, gameSessionId
    //         } = response; 
    //         setNarration(currentNarrationList) // and then we're taking first element from it by default
    //         setGameSessionId(gameSessionId)
    //     })
    // }, []);

    return(
    <>
        <Player ref={ ref } />
        <World char={ ref } />
        <Lights 
            char={ ref }
            downgradedPerformance={ downgradedPerformance } 
        />
        { !downgradedPerformance && (
            <Effects />
        )}
    </>
    )
}