import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function MainGatewayPortal(props) {
  const { nodes, materials } = useGLTF("./assets/island/Main-Gateway-Portal.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane010.geometry}
        material={materials["Material.021"]}
        position={[-2.349, 4.83, 45.8]}
        scale={34.502}
      />
    </group>
  );
}

useGLTF.preload("./assets/island/Main-Gateway-Portal.glb");