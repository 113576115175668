import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";

export default function Walls(props) {
  const { nodes, materials } = useGLTF("./assets/suncity/walls.glb");

  return (
    <RigidBody type="fixed" colliders="trimesh">
      <group {...props} dispose={null}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube157.geometry}
          material={nodes.Cube157.material}
          position={[-72.178, 2.828, 31.458]}
          scale={[0.192, 4.132, 99.024]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube158.geometry}
          material={nodes.Cube158.material}
          position={[80.164, 2.828, 31.458]}
          scale={[0.192, 4.132, 99.024]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube180.geometry}
          material={nodes.Cube180.material}
          position={[4.662, 2.828, 130.744]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.193, 4.167, 77.247]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube181.geometry}
          material={nodes.Cube181.material}
          position={[4.662, 2.828, -67.474]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.193, 4.167, 77.247]}
        />
      </group>
    </RigidBody>
  );
}

useGLTF.preload("./assets/suncity/walls.glb")