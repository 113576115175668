import React from "react";
import { useGLTF } from "@react-three/drei";

export default function BareTree(props) {
  const { nodes, materials } = useGLTF("./assets/island/Bare-Tree.glb");

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Tree_average_bare.geometry}
        material={materials["wood 2"]}
        position={[-20.849, 1.869, 11.721]}
        scale={24.675}
      />
    </group>
  );
}

useGLTF.preload("./assets/island/Bare-Tree.glb");
