import React, { useEffect, useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";
import { useFrame } from "@react-three/fiber";

export default function Cars(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF("./assets/suncity/cars.glb");
  const { actions } = useAnimations(animations, group)
  // console.log('xvf', actions, animations)

  useEffect(() => {
    actions["car.006Action"].play();
    actions["car.007Action"].play();
    // actions["car.010Action"].play();
  }, [])

  useFrame(() => {
    actions["car.012Action"].play();
    actions["wheel fr.011Action.003"].play();
    actions["wheel fr.014Action"].play();
  })
  return (
    <RigidBody type="fixed" colliders="trimesh">
      <group ref={group} {...props} dispose={null}>
        <group name="Scene">
          <group name="car" position={[-14.653, -0.556, -32.422]} scale={1.161}>
            <mesh
              name="Cube091"
              castShadow
              receiveShadow
              geometry={nodes.Cube091.geometry}
              material={materials["steel-blue"]}
            />
            <mesh
              name="Cube091_1"
              castShadow
              receiveShadow
              geometry={nodes.Cube091_1.geometry}
              material={materials["steel-black"]}
            />
            <mesh
              name="Cube091_2"
              castShadow
              receiveShadow
              geometry={nodes.Cube091_2.geometry}
              material={materials.mirror}
            />
            <mesh
              name="Cube091_3"
              castShadow
              receiveShadow
              geometry={nodes.Cube091_3.geometry}
              material={materials.wheel}
            />
            <mesh
              name="Cube091_4"
              castShadow
              receiveShadow
              geometry={nodes.Cube091_4.geometry}
              material={materials.light}
            />
            <mesh
              name="Cube091_5"
              castShadow
              receiveShadow
              geometry={nodes.Cube091_5.geometry}
              material={materials["red light"]}
            />
            <mesh
              name="Cube091_6"
              castShadow
              receiveShadow
              geometry={nodes.Cube091_6.geometry}
              material={materials["plastic-white"]}
            />
            <mesh
              name="Cube091_7"
              castShadow
              receiveShadow
              geometry={nodes.Cube091_7.geometry}
              material={materials["leather-black"]}
            />
            <mesh
              name="Cube091_8"
              castShadow
              receiveShadow
              geometry={nodes.Cube091_8.geometry}
              material={materials.glass}
            />
            <mesh
              name="Cube091_9"
              castShadow
              receiveShadow
              geometry={nodes.Cube091_9.geometry}
              material={materials["leather-brown"]}
            />
            <mesh
              name="Cube091_10"
              castShadow
              receiveShadow
              geometry={nodes.Cube091_10.geometry}
              material={materials.tire}
            />
            <mesh
              name="Cube091_11"
              castShadow
              receiveShadow
              geometry={nodes.Cube091_11.geometry}
              material={materials["Material.075"]}
            />
          </group>
          <group
            name="car001"
            position={[-14.653, -0.556, -1.424]}
            rotation={[Math.PI, 0, Math.PI]}
            scale={1.161}
          >
            <mesh
              name="Cube092"
              castShadow
              receiveShadow
              geometry={nodes.Cube092.geometry}
              material={materials["steel-blue"]}
            />
            <mesh
              name="Cube092_1"
              castShadow
              receiveShadow
              geometry={nodes.Cube092_1.geometry}
              material={materials["steel-black"]}
            />
            <mesh
              name="Cube092_2"
              castShadow
              receiveShadow
              geometry={nodes.Cube092_2.geometry}
              material={materials.mirror}
            />
            <mesh
              name="Cube092_3"
              castShadow
              receiveShadow
              geometry={nodes.Cube092_3.geometry}
              material={materials.wheel}
            />
            <mesh
              name="Cube092_4"
              castShadow
              receiveShadow
              geometry={nodes.Cube092_4.geometry}
              material={materials.light}
            />
            <mesh
              name="Cube092_5"
              castShadow
              receiveShadow
              geometry={nodes.Cube092_5.geometry}
              material={materials["red light"]}
            />
            <mesh
              name="Cube092_6"
              castShadow
              receiveShadow
              geometry={nodes.Cube092_6.geometry}
              material={materials["plastic-white"]}
            />
            <mesh
              name="Cube092_7"
              castShadow
              receiveShadow
              geometry={nodes.Cube092_7.geometry}
              material={materials["leather-black"]}
            />
            <mesh
              name="Cube092_8"
              castShadow
              receiveShadow
              geometry={nodes.Cube092_8.geometry}
              material={materials.glass}
            />
            <mesh
              name="Cube092_9"
              castShadow
              receiveShadow
              geometry={nodes.Cube092_9.geometry}
              material={materials["leather-brown"]}
            />
            <mesh
              name="Cube092_10"
              castShadow
              receiveShadow
              geometry={nodes.Cube092_10.geometry}
              material={materials.tire}
            />
          </group>
          <group
            name="car002"
            position={[-24.584, -0.556, -32.465]}
            scale={1.161}
          >
            <mesh
              name="Cube093"
              castShadow
              receiveShadow
              geometry={nodes.Cube093.geometry}
              material={materials["steel-blue"]}
            />
            <mesh
              name="Cube093_1"
              castShadow
              receiveShadow
              geometry={nodes.Cube093_1.geometry}
              material={materials["steel-black"]}
            />
            <mesh
              name="Cube093_2"
              castShadow
              receiveShadow
              geometry={nodes.Cube093_2.geometry}
              material={materials.mirror}
            />
            <mesh
              name="Cube093_3"
              castShadow
              receiveShadow
              geometry={nodes.Cube093_3.geometry}
              material={materials.wheel}
            />
            <mesh
              name="Cube093_4"
              castShadow
              receiveShadow
              geometry={nodes.Cube093_4.geometry}
              material={materials.light}
            />
            <mesh
              name="Cube093_5"
              castShadow
              receiveShadow
              geometry={nodes.Cube093_5.geometry}
              material={materials["red light"]}
            />
            <mesh
              name="Cube093_6"
              castShadow
              receiveShadow
              geometry={nodes.Cube093_6.geometry}
              material={materials["plastic-white"]}
            />
            <mesh
              name="Cube093_7"
              castShadow
              receiveShadow
              geometry={nodes.Cube093_7.geometry}
              material={materials["leather-black"]}
            />
            <mesh
              name="Cube093_8"
              castShadow
              receiveShadow
              geometry={nodes.Cube093_8.geometry}
              material={materials.glass}
            />
            <mesh
              name="Cube093_9"
              castShadow
              receiveShadow
              geometry={nodes.Cube093_9.geometry}
              material={materials["leather-brown"]}
            />
            <mesh
              name="Cube093_10"
              castShadow
              receiveShadow
              geometry={nodes.Cube093_10.geometry}
              material={materials.tire}
            />
            <mesh
              name="Cube093_11"
              castShadow
              receiveShadow
              geometry={nodes.Cube093_11.geometry}
              material={materials["Material.076"]}
            />
          </group>
          <group
            name="car003"
            position={[-14.604, -0.556, -44.189]}
            scale={1.161}
          >
            <mesh
              name="Cube094"
              castShadow
              receiveShadow
              geometry={nodes.Cube094.geometry}
              material={materials["steel-blue"]}
            />
            <mesh
              name="Cube094_1"
              castShadow
              receiveShadow
              geometry={nodes.Cube094_1.geometry}
              material={materials["steel-black"]}
            />
            <mesh
              name="Cube094_2"
              castShadow
              receiveShadow
              geometry={nodes.Cube094_2.geometry}
              material={materials.mirror}
            />
            <mesh
              name="Cube094_3"
              castShadow
              receiveShadow
              geometry={nodes.Cube094_3.geometry}
              material={materials.wheel}
            />
            <mesh
              name="Cube094_4"
              castShadow
              receiveShadow
              geometry={nodes.Cube094_4.geometry}
              material={materials.light}
            />
            <mesh
              name="Cube094_5"
              castShadow
              receiveShadow
              geometry={nodes.Cube094_5.geometry}
              material={materials["red light"]}
            />
            <mesh
              name="Cube094_6"
              castShadow
              receiveShadow
              geometry={nodes.Cube094_6.geometry}
              material={materials["plastic-white"]}
            />
            <mesh
              name="Cube094_7"
              castShadow
              receiveShadow
              geometry={nodes.Cube094_7.geometry}
              material={materials["leather-black"]}
            />
            <mesh
              name="Cube094_8"
              castShadow
              receiveShadow
              geometry={nodes.Cube094_8.geometry}
              material={materials.glass}
            />
            <mesh
              name="Cube094_9"
              castShadow
              receiveShadow
              geometry={nodes.Cube094_9.geometry}
              material={materials["leather-brown"]}
            />
            <mesh
              name="Cube094_10"
              castShadow
              receiveShadow
              geometry={nodes.Cube094_10.geometry}
              material={materials.tire}
            />
            <mesh
              name="Cube094_11"
              castShadow
              receiveShadow
              geometry={nodes.Cube094_11.geometry}
              material={materials["Material.078"]}
            />
          </group>
          <group
            name="car004"
            position={[-13.919, -0.556, -63.473]}
            rotation={[Math.PI, 0, Math.PI]}
            scale={1.161}
          >
            <mesh
              name="Cube095"
              castShadow
              receiveShadow
              geometry={nodes.Cube095.geometry}
              material={materials["steel-blue"]}
            />
            <mesh
              name="Cube095_1"
              castShadow
              receiveShadow
              geometry={nodes.Cube095_1.geometry}
              material={materials["steel-black"]}
            />
            <mesh
              name="Cube095_2"
              castShadow
              receiveShadow
              geometry={nodes.Cube095_2.geometry}
              material={materials.mirror}
            />
            <mesh
              name="Cube095_3"
              castShadow
              receiveShadow
              geometry={nodes.Cube095_3.geometry}
              material={materials.wheel}
            />
            <mesh
              name="Cube095_4"
              castShadow
              receiveShadow
              geometry={nodes.Cube095_4.geometry}
              material={materials.light}
            />
            <mesh
              name="Cube095_5"
              castShadow
              receiveShadow
              geometry={nodes.Cube095_5.geometry}
              material={materials["red light"]}
            />
            <mesh
              name="Cube095_6"
              castShadow
              receiveShadow
              geometry={nodes.Cube095_6.geometry}
              material={materials["plastic-white"]}
            />
            <mesh
              name="Cube095_7"
              castShadow
              receiveShadow
              geometry={nodes.Cube095_7.geometry}
              material={materials["leather-black"]}
            />
            <mesh
              name="Cube095_8"
              castShadow
              receiveShadow
              geometry={nodes.Cube095_8.geometry}
              material={materials.glass}
            />
            <mesh
              name="Cube095_9"
              castShadow
              receiveShadow
              geometry={nodes.Cube095_9.geometry}
              material={materials["leather-brown"]}
            />
            <mesh
              name="Cube095_10"
              castShadow
              receiveShadow
              geometry={nodes.Cube095_10.geometry}
              material={materials.tire}
            />
            <mesh
              name="Cube095_11"
              castShadow
              receiveShadow
              geometry={nodes.Cube095_11.geometry}
              material={materials["Material.079"]}
            />
          </group>
          <group
            name="car005"
            position={[-14.653, -0.556, -13.666]}
            rotation={[Math.PI, 0, Math.PI]}
            scale={1.161}
          >
            <mesh
              name="Cube101"
              castShadow
              receiveShadow
              geometry={nodes.Cube101.geometry}
              material={materials["steel-blue"]}
            />
            <mesh
              name="Cube101_1"
              castShadow
              receiveShadow
              geometry={nodes.Cube101_1.geometry}
              material={materials["steel-black"]}
            />
            <mesh
              name="Cube101_2"
              castShadow
              receiveShadow
              geometry={nodes.Cube101_2.geometry}
              material={materials.mirror}
            />
            <mesh
              name="Cube101_3"
              castShadow
              receiveShadow
              geometry={nodes.Cube101_3.geometry}
              material={materials.wheel}
            />
            <mesh
              name="Cube101_4"
              castShadow
              receiveShadow
              geometry={nodes.Cube101_4.geometry}
              material={materials.light}
            />
            <mesh
              name="Cube101_5"
              castShadow
              receiveShadow
              geometry={nodes.Cube101_5.geometry}
              material={materials["red light"]}
            />
            <mesh
              name="Cube101_6"
              castShadow
              receiveShadow
              geometry={nodes.Cube101_6.geometry}
              material={materials["plastic-white"]}
            />
            <mesh
              name="Cube101_7"
              castShadow
              receiveShadow
              geometry={nodes.Cube101_7.geometry}
              material={materials["leather-black"]}
            />
            <mesh
              name="Cube101_8"
              castShadow
              receiveShadow
              geometry={nodes.Cube101_8.geometry}
              material={materials.glass}
            />
            <mesh
              name="Cube101_9"
              castShadow
              receiveShadow
              geometry={nodes.Cube101_9.geometry}
              material={materials["leather-brown"]}
            />
            <mesh
              name="Cube101_10"
              castShadow
              receiveShadow
              geometry={nodes.Cube101_10.geometry}
              material={materials.tire}
            />
          </group>
          <group
            name="car006"
            position={[13.453, -0.556, 77.45]}
            rotation={[Math.PI, 0, Math.PI]}
            scale={1.161}
          >
            <mesh
              name="Cube119"
              castShadow
              receiveShadow
              geometry={nodes.Cube119.geometry}
              material={materials["steel-blue"]}
            />
            <mesh
              name="Cube119_1"
              castShadow
              receiveShadow
              geometry={nodes.Cube119_1.geometry}
              material={materials["steel-black"]}
            />
            <mesh
              name="Cube119_2"
              castShadow
              receiveShadow
              geometry={nodes.Cube119_2.geometry}
              material={materials.mirror}
            />
            <mesh
              name="Cube119_3"
              castShadow
              receiveShadow
              geometry={nodes.Cube119_3.geometry}
              material={materials.wheel}
            />
            <mesh
              name="Cube119_4"
              castShadow
              receiveShadow
              geometry={nodes.Cube119_4.geometry}
              material={materials.light}
            />
            <mesh
              name="Cube119_5"
              castShadow
              receiveShadow
              geometry={nodes.Cube119_5.geometry}
              material={materials["red light"]}
            />
            <mesh
              name="Cube119_6"
              castShadow
              receiveShadow
              geometry={nodes.Cube119_6.geometry}
              material={materials["plastic-white"]}
            />
            <mesh
              name="Cube119_7"
              castShadow
              receiveShadow
              geometry={nodes.Cube119_7.geometry}
              material={materials["leather-black"]}
            />
            <mesh
              name="Cube119_8"
              castShadow
              receiveShadow
              geometry={nodes.Cube119_8.geometry}
              material={materials.glass}
            />
            <mesh
              name="Cube119_9"
              castShadow
              receiveShadow
              geometry={nodes.Cube119_9.geometry}
              material={materials["leather-brown"]}
            />
            <mesh
              name="Cube119_10"
              castShadow
              receiveShadow
              geometry={nodes.Cube119_10.geometry}
              material={materials.tire}
            />
            <mesh
              name="Cube119_11"
              castShadow
              receiveShadow
              geometry={nodes.Cube119_11.geometry}
              material={materials["Material.079"]}
            />
          </group>
          <group
            name="car007"
            position={[-16.566, -0.556, 45.886]}
            rotation={[Math.PI, 0, Math.PI]}
            scale={1.161}
          >
            <mesh
              name="Cube129"
              castShadow
              receiveShadow
              geometry={nodes.Cube129.geometry}
              material={materials["steel-blue"]}
            />
            <mesh
              name="Cube129_1"
              castShadow
              receiveShadow
              geometry={nodes.Cube129_1.geometry}
              material={materials["steel-black"]}
            />
            <mesh
              name="Cube129_2"
              castShadow
              receiveShadow
              geometry={nodes.Cube129_2.geometry}
              material={materials.mirror}
            />
            <mesh
              name="Cube129_3"
              castShadow
              receiveShadow
              geometry={nodes.Cube129_3.geometry}
              material={materials.wheel}
            />
            <mesh
              name="Cube129_4"
              castShadow
              receiveShadow
              geometry={nodes.Cube129_4.geometry}
              material={materials.light}
            />
            <mesh
              name="Cube129_5"
              castShadow
              receiveShadow
              geometry={nodes.Cube129_5.geometry}
              material={materials["red light"]}
            />
            <mesh
              name="Cube129_6"
              castShadow
              receiveShadow
              geometry={nodes.Cube129_6.geometry}
              material={materials["plastic-white"]}
            />
            <mesh
              name="Cube129_7"
              castShadow
              receiveShadow
              geometry={nodes.Cube129_7.geometry}
              material={materials["leather-black"]}
            />
            <mesh
              name="Cube129_8"
              castShadow
              receiveShadow
              geometry={nodes.Cube129_8.geometry}
              material={materials.glass}
            />
            <mesh
              name="Cube129_9"
              castShadow
              receiveShadow
              geometry={nodes.Cube129_9.geometry}
              material={materials["leather-brown"]}
            />
            <mesh
              name="Cube129_10"
              castShadow
              receiveShadow
              geometry={nodes.Cube129_10.geometry}
              material={materials.tire}
            />
          </group>
          <group
            name="car008"
            position={[-13.244, -0.556, 87.134]}
            scale={1.161}
          >
            <mesh
              name="Cube675"
              castShadow
              receiveShadow
              geometry={nodes.Cube675.geometry}
              material={materials["steel-blue"]}
            />
            <mesh
              name="Cube675_1"
              castShadow
              receiveShadow
              geometry={nodes.Cube675_1.geometry}
              material={materials["steel-black"]}
            />
            <mesh
              name="Cube675_2"
              castShadow
              receiveShadow
              geometry={nodes.Cube675_2.geometry}
              material={materials.mirror}
            />
            <mesh
              name="Cube675_3"
              castShadow
              receiveShadow
              geometry={nodes.Cube675_3.geometry}
              material={materials.wheel}
            />
            <mesh
              name="Cube675_4"
              castShadow
              receiveShadow
              geometry={nodes.Cube675_4.geometry}
              material={materials.light}
            />
            <mesh
              name="Cube675_5"
              castShadow
              receiveShadow
              geometry={nodes.Cube675_5.geometry}
              material={materials["red light"]}
            />
            <mesh
              name="Cube675_6"
              castShadow
              receiveShadow
              geometry={nodes.Cube675_6.geometry}
              material={materials["plastic-white"]}
            />
            <mesh
              name="Cube675_7"
              castShadow
              receiveShadow
              geometry={nodes.Cube675_7.geometry}
              material={materials["leather-black"]}
            />
            <mesh
              name="Cube675_8"
              castShadow
              receiveShadow
              geometry={nodes.Cube675_8.geometry}
              material={materials.glass}
            />
            <mesh
              name="Cube675_9"
              castShadow
              receiveShadow
              geometry={nodes.Cube675_9.geometry}
              material={materials["leather-brown"]}
            />
            <mesh
              name="Cube675_10"
              castShadow
              receiveShadow
              geometry={nodes.Cube675_10.geometry}
              material={materials.tire}
            />
            <mesh
              name="Cube675_11"
              castShadow
              receiveShadow
              geometry={nodes.Cube675_11.geometry}
              material={materials["Material.078"]}
            />
          </group>
          <group
            name="car010"
            position={[17.049, -0.556, 87.134]}
            rotation={[Math.PI, 0, Math.PI]}
            scale={1.161}
          >
            <mesh
              name="Cube677"
              castShadow
              receiveShadow
              geometry={nodes.Cube677.geometry}
              material={materials["steel-blue"]}
            />
            <mesh
              name="Cube677_1"
              castShadow
              receiveShadow
              geometry={nodes.Cube677_1.geometry}
              material={materials["steel-black"]}
            />
            <mesh
              name="Cube677_2"
              castShadow
              receiveShadow
              geometry={nodes.Cube677_2.geometry}
              material={materials.mirror}
            />
            <mesh
              name="Cube677_3"
              castShadow
              receiveShadow
              geometry={nodes.Cube677_3.geometry}
              material={materials.wheel}
            />
            <mesh
              name="Cube677_4"
              castShadow
              receiveShadow
              geometry={nodes.Cube677_4.geometry}
              material={materials.light}
            />
            <mesh
              name="Cube677_5"
              castShadow
              receiveShadow
              geometry={nodes.Cube677_5.geometry}
              material={materials["red light"]}
            />
            <mesh
              name="Cube677_6"
              castShadow
              receiveShadow
              geometry={nodes.Cube677_6.geometry}
              material={materials["plastic-white"]}
            />
            <mesh
              name="Cube677_7"
              castShadow
              receiveShadow
              geometry={nodes.Cube677_7.geometry}
              material={materials["leather-black"]}
            />
            <mesh
              name="Cube677_8"
              castShadow
              receiveShadow
              geometry={nodes.Cube677_8.geometry}
              material={materials.glass}
            />
            <mesh
              name="Cube677_9"
              castShadow
              receiveShadow
              geometry={nodes.Cube677_9.geometry}
              material={materials["leather-brown"]}
            />
            <mesh
              name="Cube677_10"
              castShadow
              receiveShadow
              geometry={nodes.Cube677_10.geometry}
              material={materials.tire}
            />
            <mesh
              name="Cube677_11"
              castShadow
              receiveShadow
              geometry={nodes.Cube677_11.geometry}
              material={materials["Material.250"]}
            />
          </group>
          <group
            name="car011"
            position={[52.571, -0.556, 87.134]}
            rotation={[Math.PI, 0, Math.PI]}
            scale={1.161}
          >
            <mesh
              name="Cube678"
              castShadow
              receiveShadow
              geometry={nodes.Cube678.geometry}
              material={materials["steel-blue"]}
            />
            <mesh
              name="Cube678_1"
              castShadow
              receiveShadow
              geometry={nodes.Cube678_1.geometry}
              material={materials["steel-black"]}
            />
            <mesh
              name="Cube678_2"
              castShadow
              receiveShadow
              geometry={nodes.Cube678_2.geometry}
              material={materials.mirror}
            />
            <mesh
              name="Cube678_3"
              castShadow
              receiveShadow
              geometry={nodes.Cube678_3.geometry}
              material={materials.wheel}
            />
            <mesh
              name="Cube678_4"
              castShadow
              receiveShadow
              geometry={nodes.Cube678_4.geometry}
              material={materials.light}
            />
            <mesh
              name="Cube678_5"
              castShadow
              receiveShadow
              geometry={nodes.Cube678_5.geometry}
              material={materials["red light"]}
            />
            <mesh
              name="Cube678_6"
              castShadow
              receiveShadow
              geometry={nodes.Cube678_6.geometry}
              material={materials["plastic-white"]}
            />
            <mesh
              name="Cube678_7"
              castShadow
              receiveShadow
              geometry={nodes.Cube678_7.geometry}
              material={materials["leather-black"]}
            />
            <mesh
              name="Cube678_8"
              castShadow
              receiveShadow
              geometry={nodes.Cube678_8.geometry}
              material={materials.glass}
            />
            <mesh
              name="Cube678_9"
              castShadow
              receiveShadow
              geometry={nodes.Cube678_9.geometry}
              material={materials["leather-brown"]}
            />
            <mesh
              name="Cube678_10"
              castShadow
              receiveShadow
              geometry={nodes.Cube678_10.geometry}
              material={materials.tire}
            />
            <mesh
              name="Cube678_11"
              castShadow
              receiveShadow
              geometry={nodes.Cube678_11.geometry}
              material={materials["Material.078"]}
            />
          </group>
          <group
            name="car012"
            position={[76.464, -0.556, 50.859]}
            rotation={[0, -Math.PI / 2, 0]}
            scale={1.161}
          >
            <mesh
              name="Cube679"
              castShadow
              receiveShadow
              geometry={nodes.Cube679.geometry}
              material={materials["steel-blue"]}
            />
            <mesh
              name="Cube679_1"
              castShadow
              receiveShadow
              geometry={nodes.Cube679_1.geometry}
              material={materials["steel-black"]}
            />
            <mesh
              name="Cube679_2"
              castShadow
              receiveShadow
              geometry={nodes.Cube679_2.geometry}
              material={materials.mirror}
            />
            <mesh
              name="Cube679_3"
              castShadow
              receiveShadow
              geometry={nodes.Cube679_3.geometry}
              material={materials.wheel}
            />
            <mesh
              name="Cube679_4"
              castShadow
              receiveShadow
              geometry={nodes.Cube679_4.geometry}
              material={materials.light}
            />
            <mesh
              name="Cube679_5"
              castShadow
              receiveShadow
              geometry={nodes.Cube679_5.geometry}
              material={materials["red light"]}
            />
            <mesh
              name="Cube679_6"
              castShadow
              receiveShadow
              geometry={nodes.Cube679_6.geometry}
              material={materials["plastic-white"]}
            />
            <mesh
              name="Cube679_7"
              castShadow
              receiveShadow
              geometry={nodes.Cube679_7.geometry}
              material={materials["leather-black"]}
            />
            <mesh
              name="Cube679_8"
              castShadow
              receiveShadow
              geometry={nodes.Cube679_8.geometry}
              material={materials.glass}
            />
            <mesh
              name="Cube679_9"
              castShadow
              receiveShadow
              geometry={nodes.Cube679_9.geometry}
              material={materials["leather-brown"]}
            />
            <mesh
              name="Cube679_10"
              castShadow
              receiveShadow
              geometry={nodes.Cube679_10.geometry}
              material={materials.tire}
            />
            <mesh
              name="Cube679_11"
              castShadow
              receiveShadow
              geometry={nodes.Cube679_11.geometry}
              material={materials["Material.238"]}
            />
          </group>
          <group
            name="car013"
            position={[52.571, -0.556, 111.461]}
            rotation={[Math.PI, 0, Math.PI]}
            scale={1.161}
          >
            <mesh
              name="Cube680"
              castShadow
              receiveShadow
              geometry={nodes.Cube680.geometry}
              material={materials["steel-blue"]}
            />
            <mesh
              name="Cube680_1"
              castShadow
              receiveShadow
              geometry={nodes.Cube680_1.geometry}
              material={materials["steel-black"]}
            />
            <mesh
              name="Cube680_2"
              castShadow
              receiveShadow
              geometry={nodes.Cube680_2.geometry}
              material={materials.mirror}
            />
            <mesh
              name="Cube680_3"
              castShadow
              receiveShadow
              geometry={nodes.Cube680_3.geometry}
              material={materials.wheel}
            />
            <mesh
              name="Cube680_4"
              castShadow
              receiveShadow
              geometry={nodes.Cube680_4.geometry}
              material={materials.light}
            />
            <mesh
              name="Cube680_5"
              castShadow
              receiveShadow
              geometry={nodes.Cube680_5.geometry}
              material={materials["red light"]}
            />
            <mesh
              name="Cube680_6"
              castShadow
              receiveShadow
              geometry={nodes.Cube680_6.geometry}
              material={materials["plastic-white"]}
            />
            <mesh
              name="Cube680_7"
              castShadow
              receiveShadow
              geometry={nodes.Cube680_7.geometry}
              material={materials["leather-black"]}
            />
            <mesh
              name="Cube680_8"
              castShadow
              receiveShadow
              geometry={nodes.Cube680_8.geometry}
              material={materials.glass}
            />
            <mesh
              name="Cube680_9"
              castShadow
              receiveShadow
              geometry={nodes.Cube680_9.geometry}
              material={materials["leather-brown"]}
            />
            <mesh
              name="Cube680_10"
              castShadow
              receiveShadow
              geometry={nodes.Cube680_10.geometry}
              material={materials.tire}
            />
            <mesh
              name="Cube680_11"
              castShadow
              receiveShadow
              geometry={nodes.Cube680_11.geometry}
              material={materials["Material.251"]}
            />
          </group>
          <group
            name="car014"
            position={[-48.702, -0.53, 103.438]}
            rotation={[Math.PI, 0, Math.PI]}
            scale={1.161}
          >
            <mesh
              name="Cube639"
              castShadow
              receiveShadow
              geometry={nodes.Cube639.geometry}
              material={materials["steel-blue"]}
            />
            <mesh
              name="Cube639_1"
              castShadow
              receiveShadow
              geometry={nodes.Cube639_1.geometry}
              material={materials["steel-black"]}
            />
            <mesh
              name="Cube639_2"
              castShadow
              receiveShadow
              geometry={nodes.Cube639_2.geometry}
              material={materials.mirror}
            />
            <mesh
              name="Cube639_3"
              castShadow
              receiveShadow
              geometry={nodes.Cube639_3.geometry}
              material={materials.wheel}
            />
            <mesh
              name="Cube639_4"
              castShadow
              receiveShadow
              geometry={nodes.Cube639_4.geometry}
              material={materials.light}
            />
            <mesh
              name="Cube639_5"
              castShadow
              receiveShadow
              geometry={nodes.Cube639_5.geometry}
              material={materials["red light"]}
            />
            <mesh
              name="Cube639_6"
              castShadow
              receiveShadow
              geometry={nodes.Cube639_6.geometry}
              material={materials["plastic-white"]}
            />
            <mesh
              name="Cube639_7"
              castShadow
              receiveShadow
              geometry={nodes.Cube639_7.geometry}
              material={materials["leather-black"]}
            />
            <mesh
              name="Cube639_8"
              castShadow
              receiveShadow
              geometry={nodes.Cube639_8.geometry}
              material={materials.glass}
            />
            <mesh
              name="Cube639_9"
              castShadow
              receiveShadow
              geometry={nodes.Cube639_9.geometry}
              material={materials["leather-brown"]}
            />
            <mesh
              name="Cube639_10"
              castShadow
              receiveShadow
              geometry={nodes.Cube639_10.geometry}
              material={materials.tire}
            />
            <mesh
              name="Cube639_11"
              castShadow
              receiveShadow
              geometry={nodes.Cube639_11.geometry}
              material={materials["Material.079"]}
            />
          </group>
          <group
            name="car015"
            position={[-48.702, -0.53, 77.718]}
            rotation={[Math.PI, 0, Math.PI]}
            scale={1.161}
          >
            <mesh
              name="Cube640"
              castShadow
              receiveShadow
              geometry={nodes.Cube640.geometry}
              material={materials["steel-blue"]}
            />
            <mesh
              name="Cube640_1"
              castShadow
              receiveShadow
              geometry={nodes.Cube640_1.geometry}
              material={materials["steel-black"]}
            />
            <mesh
              name="Cube640_2"
              castShadow
              receiveShadow
              geometry={nodes.Cube640_2.geometry}
              material={materials.mirror}
            />
            <mesh
              name="Cube640_3"
              castShadow
              receiveShadow
              geometry={nodes.Cube640_3.geometry}
              material={materials.wheel}
            />
            <mesh
              name="Cube640_4"
              castShadow
              receiveShadow
              geometry={nodes.Cube640_4.geometry}
              material={materials.light}
            />
            <mesh
              name="Cube640_5"
              castShadow
              receiveShadow
              geometry={nodes.Cube640_5.geometry}
              material={materials["red light"]}
            />
            <mesh
              name="Cube640_6"
              castShadow
              receiveShadow
              geometry={nodes.Cube640_6.geometry}
              material={materials["plastic-white"]}
            />
            <mesh
              name="Cube640_7"
              castShadow
              receiveShadow
              geometry={nodes.Cube640_7.geometry}
              material={materials["leather-black"]}
            />
            <mesh
              name="Cube640_8"
              castShadow
              receiveShadow
              geometry={nodes.Cube640_8.geometry}
              material={materials.glass}
            />
            <mesh
              name="Cube640_9"
              castShadow
              receiveShadow
              geometry={nodes.Cube640_9.geometry}
              material={materials["leather-brown"]}
            />
            <mesh
              name="Cube640_10"
              castShadow
              receiveShadow
              geometry={nodes.Cube640_10.geometry}
              material={materials.tire}
            />
            <mesh
              name="Cube640_11"
              castShadow
              receiveShadow
              geometry={nodes.Cube640_11.geometry}
              material={materials["Material.079"]}
            />
          </group>
          <group
            name="car016"
            position={[-38.81, -0.53, 120.43]}
            rotation={[0, 1.571, 0]}
            scale={1.161}
          >
            <mesh
              name="Cube641"
              castShadow
              receiveShadow
              geometry={nodes.Cube641.geometry}
              material={materials["steel-blue"]}
            />
            <mesh
              name="Cube641_1"
              castShadow
              receiveShadow
              geometry={nodes.Cube641_1.geometry}
              material={materials["steel-black"]}
            />
            <mesh
              name="Cube641_2"
              castShadow
              receiveShadow
              geometry={nodes.Cube641_2.geometry}
              material={materials.mirror}
            />
            <mesh
              name="Cube641_3"
              castShadow
              receiveShadow
              geometry={nodes.Cube641_3.geometry}
              material={materials.wheel}
            />
            <mesh
              name="Cube641_4"
              castShadow
              receiveShadow
              geometry={nodes.Cube641_4.geometry}
              material={materials.light}
            />
            <mesh
              name="Cube641_5"
              castShadow
              receiveShadow
              geometry={nodes.Cube641_5.geometry}
              material={materials["red light"]}
            />
            <mesh
              name="Cube641_6"
              castShadow
              receiveShadow
              geometry={nodes.Cube641_6.geometry}
              material={materials["plastic-white"]}
            />
            <mesh
              name="Cube641_7"
              castShadow
              receiveShadow
              geometry={nodes.Cube641_7.geometry}
              material={materials["leather-black"]}
            />
            <mesh
              name="Cube641_8"
              castShadow
              receiveShadow
              geometry={nodes.Cube641_8.geometry}
              material={materials.glass}
            />
            <mesh
              name="Cube641_9"
              castShadow
              receiveShadow
              geometry={nodes.Cube641_9.geometry}
              material={materials["leather-brown"]}
            />
            <mesh
              name="Cube641_10"
              castShadow
              receiveShadow
              geometry={nodes.Cube641_10.geometry}
              material={materials.tire}
            />
            <mesh
              name="Cube641_11"
              castShadow
              receiveShadow
              geometry={nodes.Cube641_11.geometry}
              material={materials["Material.079"]}
            />
          </group>
          <group
            name="car017"
            position={[-38.554, -0.556, 80.539]}
            rotation={[0, 1.571, 0]}
            scale={1.161}
          >
            <mesh
              name="Cube642"
              castShadow
              receiveShadow
              geometry={nodes.Cube642.geometry}
              material={materials["steel-blue"]}
            />
            <mesh
              name="Cube642_1"
              castShadow
              receiveShadow
              geometry={nodes.Cube642_1.geometry}
              material={materials["steel-black"]}
            />
            <mesh
              name="Cube642_2"
              castShadow
              receiveShadow
              geometry={nodes.Cube642_2.geometry}
              material={materials.mirror}
            />
            <mesh
              name="Cube642_3"
              castShadow
              receiveShadow
              geometry={nodes.Cube642_3.geometry}
              material={materials.wheel}
            />
            <mesh
              name="Cube642_4"
              castShadow
              receiveShadow
              geometry={nodes.Cube642_4.geometry}
              material={materials.light}
            />
            <mesh
              name="Cube642_5"
              castShadow
              receiveShadow
              geometry={nodes.Cube642_5.geometry}
              material={materials["red light"]}
            />
            <mesh
              name="Cube642_6"
              castShadow
              receiveShadow
              geometry={nodes.Cube642_6.geometry}
              material={materials["plastic-white"]}
            />
            <mesh
              name="Cube642_7"
              castShadow
              receiveShadow
              geometry={nodes.Cube642_7.geometry}
              material={materials["leather-black"]}
            />
            <mesh
              name="Cube642_8"
              castShadow
              receiveShadow
              geometry={nodes.Cube642_8.geometry}
              material={materials.glass}
            />
            <mesh
              name="Cube642_9"
              castShadow
              receiveShadow
              geometry={nodes.Cube642_9.geometry}
              material={materials["leather-brown"]}
            />
            <mesh
              name="Cube642_10"
              castShadow
              receiveShadow
              geometry={nodes.Cube642_10.geometry}
              material={materials.tire}
            />
            <mesh
              name="Cube642_11"
              castShadow
              receiveShadow
              geometry={nodes.Cube642_11.geometry}
              material={materials["Material.076"]}
            />
          </group>
          <group
            name="car018"
            position={[-48.624, -0.556, 60.786]}
            rotation={[0, -1.571, 0]}
            scale={1.161}
          >
            <mesh
              name="Cube643"
              castShadow
              receiveShadow
              geometry={nodes.Cube643.geometry}
              material={materials["steel-blue"]}
            />
            <mesh
              name="Cube643_1"
              castShadow
              receiveShadow
              geometry={nodes.Cube643_1.geometry}
              material={materials["steel-black"]}
            />
            <mesh
              name="Cube643_2"
              castShadow
              receiveShadow
              geometry={nodes.Cube643_2.geometry}
              material={materials.mirror}
            />
            <mesh
              name="Cube643_3"
              castShadow
              receiveShadow
              geometry={nodes.Cube643_3.geometry}
              material={materials.wheel}
            />
            <mesh
              name="Cube643_4"
              castShadow
              receiveShadow
              geometry={nodes.Cube643_4.geometry}
              material={materials.light}
            />
            <mesh
              name="Cube643_5"
              castShadow
              receiveShadow
              geometry={nodes.Cube643_5.geometry}
              material={materials["red light"]}
            />
            <mesh
              name="Cube643_6"
              castShadow
              receiveShadow
              geometry={nodes.Cube643_6.geometry}
              material={materials["plastic-white"]}
            />
            <mesh
              name="Cube643_7"
              castShadow
              receiveShadow
              geometry={nodes.Cube643_7.geometry}
              material={materials["leather-black"]}
            />
            <mesh
              name="Cube643_8"
              castShadow
              receiveShadow
              geometry={nodes.Cube643_8.geometry}
              material={materials.glass}
            />
            <mesh
              name="Cube643_9"
              castShadow
              receiveShadow
              geometry={nodes.Cube643_9.geometry}
              material={materials["leather-brown"]}
            />
            <mesh
              name="Cube643_10"
              castShadow
              receiveShadow
              geometry={nodes.Cube643_10.geometry}
              material={materials.tire}
            />
            <mesh
              name="Cube643_11"
              castShadow
              receiveShadow
              geometry={nodes.Cube643_11.geometry}
              material={materials["Material.076"]}
            />
          </group>
          <group
            name="car019"
            position={[-32.806, -0.556, 24.12]}
            rotation={[0, 1.571, 0]}
            scale={1.161}
          >
            <mesh
              name="Cube644"
              castShadow
              receiveShadow
              geometry={nodes.Cube644.geometry}
              material={materials["steel-blue"]}
            />
            <mesh
              name="Cube644_1"
              castShadow
              receiveShadow
              geometry={nodes.Cube644_1.geometry}
              material={materials["steel-black"]}
            />
            <mesh
              name="Cube644_2"
              castShadow
              receiveShadow
              geometry={nodes.Cube644_2.geometry}
              material={materials.mirror}
            />
            <mesh
              name="Cube644_3"
              castShadow
              receiveShadow
              geometry={nodes.Cube644_3.geometry}
              material={materials.wheel}
            />
            <mesh
              name="Cube644_4"
              castShadow
              receiveShadow
              geometry={nodes.Cube644_4.geometry}
              material={materials.light}
            />
            <mesh
              name="Cube644_5"
              castShadow
              receiveShadow
              geometry={nodes.Cube644_5.geometry}
              material={materials["red light"]}
            />
            <mesh
              name="Cube644_6"
              castShadow
              receiveShadow
              geometry={nodes.Cube644_6.geometry}
              material={materials["plastic-white"]}
            />
            <mesh
              name="Cube644_7"
              castShadow
              receiveShadow
              geometry={nodes.Cube644_7.geometry}
              material={materials["leather-black"]}
            />
            <mesh
              name="Cube644_8"
              castShadow
              receiveShadow
              geometry={nodes.Cube644_8.geometry}
              material={materials.glass}
            />
            <mesh
              name="Cube644_9"
              castShadow
              receiveShadow
              geometry={nodes.Cube644_9.geometry}
              material={materials["leather-brown"]}
            />
            <mesh
              name="Cube644_10"
              castShadow
              receiveShadow
              geometry={nodes.Cube644_10.geometry}
              material={materials.tire}
            />
            <mesh
              name="Cube644_11"
              castShadow
              receiveShadow
              geometry={nodes.Cube644_11.geometry}
              material={materials["Material.078"]}
            />
          </group>
          <group
            name="car020"
            position={[-28.765, -0.556, 8.932]}
            rotation={[0, -Math.PI / 2, 0]}
            scale={1.161}
          >
            <mesh
              name="Cube681"
              castShadow
              receiveShadow
              geometry={nodes.Cube681.geometry}
              material={materials["steel-blue"]}
            />
            <mesh
              name="Cube681_1"
              castShadow
              receiveShadow
              geometry={nodes.Cube681_1.geometry}
              material={materials["steel-black"]}
            />
            <mesh
              name="Cube681_2"
              castShadow
              receiveShadow
              geometry={nodes.Cube681_2.geometry}
              material={materials.mirror}
            />
            <mesh
              name="Cube681_3"
              castShadow
              receiveShadow
              geometry={nodes.Cube681_3.geometry}
              material={materials.wheel}
            />
            <mesh
              name="Cube681_4"
              castShadow
              receiveShadow
              geometry={nodes.Cube681_4.geometry}
              material={materials.light}
            />
            <mesh
              name="Cube681_5"
              castShadow
              receiveShadow
              geometry={nodes.Cube681_5.geometry}
              material={materials["red light"]}
            />
            <mesh
              name="Cube681_6"
              castShadow
              receiveShadow
              geometry={nodes.Cube681_6.geometry}
              material={materials["plastic-white"]}
            />
            <mesh
              name="Cube681_7"
              castShadow
              receiveShadow
              geometry={nodes.Cube681_7.geometry}
              material={materials["leather-black"]}
            />
            <mesh
              name="Cube681_8"
              castShadow
              receiveShadow
              geometry={nodes.Cube681_8.geometry}
              material={materials.glass}
            />
            <mesh
              name="Cube681_9"
              castShadow
              receiveShadow
              geometry={nodes.Cube681_9.geometry}
              material={materials["leather-brown"]}
            />
            <mesh
              name="Cube681_10"
              castShadow
              receiveShadow
              geometry={nodes.Cube681_10.geometry}
              material={materials.tire}
            />
          </group>
          <mesh
            name="Circle001"
            castShadow
            receiveShadow
            geometry={nodes.Circle001.geometry}
            material={materials["Material.068"]}
            position={[-21.889, -0.876, -17.702]}
            rotation={[1.991, 0, 0]}
            scale={0.056}
          />
          <mesh
            name="Circle002"
            castShadow
            receiveShadow
            geometry={nodes.Circle002.geometry}
            material={materials["Material.061"]}
            position={[-21.607, -0.717, -17.549]}
            rotation={[Math.PI / 2, 0, -Math.PI / 2]}
            scale={-0.011}
          />
          <mesh
            name="Circle003"
            castShadow
            receiveShadow
            geometry={nodes.Circle003.geometry}
            material={materials["Material.061"]}
            position={[-21.607, -0.542, -17.549]}
            rotation={[Math.PI / 2, 0, -Math.PI / 2]}
            scale={-0.014}
          />
          <mesh
            name="Circle004"
            castShadow
            receiveShadow
            geometry={nodes.Circle004.geometry}
            material={materials["Material.068"]}
            position={[-20.572, -0.876, -17.702]}
            rotation={[1.991, 0, 0]}
            scale={0.056}
          />
          <group
            name="Circle005"
            position={[-20.67, -0.946, -20.443]}
            rotation={[0, 0, -Math.PI / 2]}
            scale={0.337}
          >
            <mesh
              name="Circle028"
              castShadow
              receiveShadow
              geometry={nodes.Circle028.geometry}
              material={materials["Material.062"]}
            />
            <mesh
              name="Circle028_1"
              castShadow
              receiveShadow
              geometry={nodes.Circle028_1.geometry}
              material={materials["Material.065"]}
            />
            <mesh
              name="Circle028_2"
              castShadow
              receiveShadow
              geometry={nodes.Circle028_2.geometry}
              material={materials["Material.066"]}
            />
            <mesh
              name="Circle028_3"
              castShadow
              receiveShadow
              geometry={nodes.Circle028_3.geometry}
              material={materials["Material.067"]}
            />
          </group>
          <group
            name="Circle006"
            position={[-20.67, -0.946, -44.682]}
            rotation={[0, 0, -Math.PI / 2]}
            scale={0.337}
          >
            <mesh
              name="Circle033"
              castShadow
              receiveShadow
              geometry={nodes.Circle033.geometry}
              material={materials["Material.062"]}
            />
            <mesh
              name="Circle033_1"
              castShadow
              receiveShadow
              geometry={nodes.Circle033_1.geometry}
              material={materials["Material.065"]}
            />
            <mesh
              name="Circle033_2"
              castShadow
              receiveShadow
              geometry={nodes.Circle033_2.geometry}
              material={materials["Material.066"]}
            />
            <mesh
              name="Circle033_3"
              castShadow
              receiveShadow
              geometry={nodes.Circle033_3.geometry}
              material={materials["Material.067"]}
            />
          </group>
          <mesh
            name="Circle007"
            castShadow
            receiveShadow
            geometry={nodes.Circle007.geometry}
            material={materials["Material.061"]}
            position={[-21.607, -0.542, -41.787]}
            rotation={[Math.PI / 2, 0, -Math.PI / 2]}
            scale={-0.014}
          />
          <mesh
            name="Circle008"
            castShadow
            receiveShadow
            geometry={nodes.Circle008.geometry}
            material={materials["Material.061"]}
            position={[-21.607, -0.717, -41.787]}
            rotation={[Math.PI / 2, 0, -Math.PI / 2]}
            scale={-0.011}
          />
          <mesh
            name="Circle009"
            castShadow
            receiveShadow
            geometry={nodes.Circle009.geometry}
            material={materials["Material.068"]}
            position={[-21.889, -0.876, -41.941]}
            rotation={[1.991, 0, 0]}
            scale={0.056}
          />
          <mesh
            name="Circle010"
            castShadow
            receiveShadow
            geometry={nodes.Circle010.geometry}
            material={materials["Material.068"]}
            position={[-20.572, -0.876, -41.941]}
            rotation={[1.991, 0, 0]}
            scale={0.056}
          />
          <group
            name="Circle011"
            position={[-17.951, -0.946, -62.857]}
            rotation={[Math.PI, 0, Math.PI / 2]}
            scale={0.337}
          >
            <mesh
              name="Circle038"
              castShadow
              receiveShadow
              geometry={nodes.Circle038.geometry}
              material={materials["Material.062"]}
            />
            <mesh
              name="Circle038_1"
              castShadow
              receiveShadow
              geometry={nodes.Circle038_1.geometry}
              material={materials["Material.065"]}
            />
            <mesh
              name="Circle038_2"
              castShadow
              receiveShadow
              geometry={nodes.Circle038_2.geometry}
              material={materials["Material.066"]}
            />
            <mesh
              name="Circle038_3"
              castShadow
              receiveShadow
              geometry={nodes.Circle038_3.geometry}
              material={materials["Material.067"]}
            />
          </group>
          <mesh
            name="Circle012"
            castShadow
            receiveShadow
            geometry={nodes.Circle012.geometry}
            material={materials["Material.061"]}
            position={[-17.014, -0.542, -65.751]}
            rotation={[Math.PI / 2, 0, Math.PI / 2]}
            scale={-0.014}
          />
          <mesh
            name="Circle013"
            castShadow
            receiveShadow
            geometry={nodes.Circle013.geometry}
            material={materials["Material.061"]}
            position={[-17.014, -0.717, -65.751]}
            rotation={[Math.PI / 2, 0, Math.PI / 2]}
            scale={-0.011}
          />
          <mesh
            name="Circle014"
            castShadow
            receiveShadow
            geometry={nodes.Circle014.geometry}
            material={materials["Material.068"]}
            position={[-16.732, -0.876, -65.598]}
            rotation={[1.151, 0, Math.PI]}
            scale={0.056}
          />
          <mesh
            name="Circle015"
            castShadow
            receiveShadow
            geometry={nodes.Circle015.geometry}
            material={materials["Material.068"]}
            position={[-18.05, -0.876, -65.598]}
            rotation={[1.151, 0, Math.PI]}
            scale={0.056}
          />
          <group
            name="Circle016"
            position={[-17.368, -0.946, -9.262]}
            rotation={[0, 0, -Math.PI / 2]}
            scale={0.337}
          >
            <mesh
              name="Circle043"
              castShadow
              receiveShadow
              geometry={nodes.Circle043.geometry}
              material={materials["Material.062"]}
            />
            <mesh
              name="Circle043_1"
              castShadow
              receiveShadow
              geometry={nodes.Circle043_1.geometry}
              material={materials["Material.065"]}
            />
            <mesh
              name="Circle043_2"
              castShadow
              receiveShadow
              geometry={nodes.Circle043_2.geometry}
              material={materials["Material.066"]}
            />
            <mesh
              name="Circle043_3"
              castShadow
              receiveShadow
              geometry={nodes.Circle043_3.geometry}
              material={materials["Material.067"]}
            />
          </group>
          <mesh
            name="Circle017"
            castShadow
            receiveShadow
            geometry={nodes.Circle017.geometry}
            material={materials["Material.061"]}
            position={[-18.305, -0.542, -6.368]}
            rotation={[Math.PI / 2, 0, -Math.PI / 2]}
            scale={-0.014}
          />
          <mesh
            name="Circle018"
            castShadow
            receiveShadow
            geometry={nodes.Circle018.geometry}
            material={materials["Material.061"]}
            position={[-18.305, -0.717, -6.368]}
            rotation={[Math.PI / 2, 0, -Math.PI / 2]}
            scale={-0.011}
          />
          <mesh
            name="Circle019"
            castShadow
            receiveShadow
            geometry={nodes.Circle019.geometry}
            material={materials["Material.068"]}
            position={[-18.587, -0.876, -6.521]}
            rotation={[1.991, 0, 0]}
            scale={0.056}
          />
          <mesh
            name="Circle020"
            castShadow
            receiveShadow
            geometry={nodes.Circle020.geometry}
            material={materials["Material.068"]}
            position={[-17.269, -0.876, -6.521]}
            rotation={[1.991, 0, 0]}
            scale={0.056}
          />
          <group
            name="Circle022"
            position={[-52.735, -0.919, 104.054]}
            rotation={[Math.PI, 0, Math.PI / 2]}
            scale={0.337}
          >
            <mesh
              name="Circle067"
              castShadow
              receiveShadow
              geometry={nodes.Circle067.geometry}
              material={materials["Material.062"]}
            />
            <mesh
              name="Circle067_1"
              castShadow
              receiveShadow
              geometry={nodes.Circle067_1.geometry}
              material={materials["Material.065"]}
            />
            <mesh
              name="Circle067_2"
              castShadow
              receiveShadow
              geometry={nodes.Circle067_2.geometry}
              material={materials["Material.066"]}
            />
            <mesh
              name="Circle067_3"
              castShadow
              receiveShadow
              geometry={nodes.Circle067_3.geometry}
              material={materials["Material.067"]}
            />
          </group>
          <mesh
            name="Circle023"
            castShadow
            receiveShadow
            geometry={nodes.Circle023.geometry}
            material={materials["Material.061"]}
            position={[-51.798, -0.515, 101.16]}
            rotation={[Math.PI / 2, 0, Math.PI / 2]}
            scale={-0.014}
          />
          <mesh
            name="Circle024"
            castShadow
            receiveShadow
            geometry={nodes.Circle024.geometry}
            material={materials["Material.061"]}
            position={[-51.798, -0.691, 101.16]}
            rotation={[Math.PI / 2, 0, Math.PI / 2]}
            scale={-0.011}
          />
          <mesh
            name="Circle025"
            castShadow
            receiveShadow
            geometry={nodes.Circle025.geometry}
            material={materials["Material.068"]}
            position={[-51.516, -0.849, 101.313]}
            rotation={[1.151, 0, Math.PI]}
            scale={0.056}
          />
          <mesh
            name="Circle026"
            castShadow
            receiveShadow
            geometry={nodes.Circle026.geometry}
            material={materials["Material.068"]}
            position={[-52.833, -0.849, 101.313]}
            rotation={[1.151, 0, Math.PI]}
            scale={0.056}
          />
          <mesh
            name="Cube044"
            castShadow
            receiveShadow
            geometry={nodes.Cube044.geometry}
            material={materials["Material.062"]}
            position={[-21.259, -2.007, -19.625]}
            rotation={[Math.PI, 0, Math.PI]}
            scale={0.84}
          />
          <mesh
            name="Cube045"
            castShadow
            receiveShadow
            geometry={nodes.Cube045.geometry}
            material={materials["Material.062"]}
            position={[-21.259, -2.007, -19.64]}
            scale={0.84}
          />
          <mesh
            name="Cube046"
            castShadow
            receiveShadow
            geometry={nodes.Cube046.geometry}
            material={materials["Material.061"]}
            position={[-21.646, -0.967, -17.745]}
            rotation={[0.689, 0, 0]}
            scale={[0.039, 0.039, 0.022]}
          />
          <mesh
            name="Cube047"
            castShadow
            receiveShadow
            geometry={nodes.Cube047.geometry}
            material={materials["Material.061"]}
            position={[-20.882, -0.967, -17.745]}
            rotation={[0.689, 0, 0]}
            scale={[0.039, 0.039, 0.022]}
          />
          <group
            name="Cube048"
            position={[-21.261, -0.764, -19.444]}
            scale={0.84}
          >
            <mesh
              name="Cube079"
              castShadow
              receiveShadow
              geometry={nodes.Cube079.geometry}
              material={materials["WINDOW.001"]}
            />
            <mesh
              name="Cube079_1"
              castShadow
              receiveShadow
              geometry={nodes.Cube079_1.geometry}
              material={materials["Material.072"]}
            />
            <mesh
              name="Cube079_2"
              castShadow
              receiveShadow
              geometry={nodes.Cube079_2.geometry}
              material={materials["Material.064"]}
            />
            <mesh
              name="Cube079_3"
              castShadow
              receiveShadow
              geometry={nodes.Cube079_3.geometry}
              material={materials["Material.066"]}
            />
          </group>
          <mesh
            name="Cube049"
            castShadow
            receiveShadow
            geometry={nodes.Cube049.geometry}
            material={materials["Material.062"]}
            position={[-21.259, -2.007, -43.863]}
            rotation={[Math.PI, 0, Math.PI]}
            scale={0.84}
          />
          <mesh
            name="Cube050"
            castShadow
            receiveShadow
            geometry={nodes.Cube050.geometry}
            material={materials["Material.062"]}
            position={[-21.259, -2.007, -43.878]}
            scale={0.84}
          />
          <mesh
            name="Cube051"
            castShadow
            receiveShadow
            geometry={nodes.Cube051.geometry}
            material={materials["Material.061"]}
            position={[-21.646, -0.967, -41.983]}
            rotation={[0.689, 0, 0]}
            scale={[0.039, 0.039, 0.022]}
          />
          <mesh
            name="Cube052"
            castShadow
            receiveShadow
            geometry={nodes.Cube052.geometry}
            material={materials["Material.061"]}
            position={[-20.882, -0.967, -41.983]}
            rotation={[0.689, 0, 0]}
            scale={[0.039, 0.039, 0.022]}
          />
          <group
            name="Cube053"
            position={[-21.261, -0.764, -43.682]}
            scale={0.84}
          >
            <mesh
              name="Cube084"
              castShadow
              receiveShadow
              geometry={nodes.Cube084.geometry}
              material={materials["WINDOW.001"]}
            />
            <mesh
              name="Cube084_1"
              castShadow
              receiveShadow
              geometry={nodes.Cube084_1.geometry}
              material={materials["Material.064"]}
            />
            <mesh
              name="Cube084_2"
              castShadow
              receiveShadow
              geometry={nodes.Cube084_2.geometry}
              material={materials["Material.066"]}
            />
            <mesh
              name="Cube084_3"
              castShadow
              receiveShadow
              geometry={nodes.Cube084_3.geometry}
              material={materials["Material.071"]}
            />
          </group>
          <mesh
            name="Cube054"
            castShadow
            receiveShadow
            geometry={nodes.Cube054.geometry}
            material={materials["Material.062"]}
            position={[-17.363, -2.007, -63.676]}
            scale={0.84}
          />
          <mesh
            name="Cube055"
            castShadow
            receiveShadow
            geometry={nodes.Cube055.geometry}
            material={materials["Material.062"]}
            position={[-17.363, -2.007, -63.66]}
            rotation={[Math.PI, 0, Math.PI]}
            scale={0.84}
          />
          <mesh
            name="Cube056"
            castShadow
            receiveShadow
            geometry={nodes.Cube056.geometry}
            material={materials["Material.061"]}
            position={[-16.976, -0.967, -65.556]}
            rotation={[2.452, 0, Math.PI]}
            scale={[0.039, 0.039, 0.022]}
          />
          <mesh
            name="Cube057"
            castShadow
            receiveShadow
            geometry={nodes.Cube057.geometry}
            material={materials["Material.061"]}
            position={[-17.739, -0.967, -65.556]}
            rotation={[2.452, 0, Math.PI]}
            scale={[0.039, 0.039, 0.022]}
          />
          <group
            name="Cube058"
            position={[-17.361, -0.764, -63.857]}
            rotation={[Math.PI, 0, Math.PI]}
            scale={0.84}
          >
            <mesh
              name="Cube089"
              castShadow
              receiveShadow
              geometry={nodes.Cube089.geometry}
              material={materials["WINDOW.001"]}
            />
            <mesh
              name="Cube089_1"
              castShadow
              receiveShadow
              geometry={nodes.Cube089_1.geometry}
              material={materials["Material.062"]}
            />
            <mesh
              name="Cube089_2"
              castShadow
              receiveShadow
              geometry={nodes.Cube089_2.geometry}
              material={materials["Material.064"]}
            />
            <mesh
              name="Cube089_3"
              castShadow
              receiveShadow
              geometry={nodes.Cube089_3.geometry}
              material={materials["Material.066"]}
            />
            <mesh
              name="Cube089_4"
              castShadow
              receiveShadow
              geometry={nodes.Cube089_4.geometry}
              material={materials["Material.069"]}
            />
          </group>
          <mesh
            name="Cube059"
            castShadow
            receiveShadow
            geometry={nodes.Cube059.geometry}
            material={materials["Material.062"]}
            position={[-17.956, -2.007, -8.443]}
            rotation={[Math.PI, 0, Math.PI]}
            scale={0.84}
          />
          <mesh
            name="Cube060"
            castShadow
            receiveShadow
            geometry={nodes.Cube060.geometry}
            material={materials["Material.062"]}
            position={[-17.956, -2.007, -8.459]}
            scale={0.84}
          />
          <mesh
            name="Cube061"
            castShadow
            receiveShadow
            geometry={nodes.Cube061.geometry}
            material={materials["Material.061"]}
            position={[-18.343, -0.967, -6.563]}
            rotation={[0.689, 0, 0]}
            scale={[0.039, 0.039, 0.022]}
          />
          <mesh
            name="Cube062"
            castShadow
            receiveShadow
            geometry={nodes.Cube062.geometry}
            material={materials["Material.061"]}
            position={[-17.58, -0.967, -6.563]}
            rotation={[0.689, 0, 0]}
            scale={[0.039, 0.039, 0.022]}
          />
          <group
            name="Cube063"
            position={[-17.958, -0.764, -8.262]}
            scale={0.84}
          >
            <mesh
              name="Cube100"
              castShadow
              receiveShadow
              geometry={nodes.Cube100.geometry}
              material={materials["WINDOW.001"]}
            />
            <mesh
              name="Cube100_1"
              castShadow
              receiveShadow
              geometry={nodes.Cube100_1.geometry}
              material={materials["Material.062"]}
            />
            <mesh
              name="Cube100_2"
              castShadow
              receiveShadow
              geometry={nodes.Cube100_2.geometry}
              material={materials["Material.064"]}
            />
            <mesh
              name="Cube100_3"
              castShadow
              receiveShadow
              geometry={nodes.Cube100_3.geometry}
              material={materials["Material.066"]}
            />
            <mesh
              name="Cube100_4"
              castShadow
              receiveShadow
              geometry={nodes.Cube100_4.geometry}
              material={materials["Material.080"]}
            />
          </group>
          <mesh
            name="Cube590"
            castShadow
            receiveShadow
            geometry={nodes.Cube590.geometry}
            material={materials["Material.062"]}
            position={[-52.146, -1.98, 103.251]}
            rotation={[Math.PI, 0, Math.PI]}
            scale={0.84}
          />
          <mesh
            name="Cube591"
            castShadow
            receiveShadow
            geometry={nodes.Cube591.geometry}
            material={materials["Material.061"]}
            position={[-51.759, -0.941, 101.356]}
            rotation={[2.452, 0, Math.PI]}
            scale={[0.039, 0.039, 0.022]}
          />
          <mesh
            name="Cube592"
            castShadow
            receiveShadow
            geometry={nodes.Cube592.geometry}
            material={materials["Material.061"]}
            position={[-52.523, -0.941, 101.356]}
            rotation={[2.452, 0, Math.PI]}
            scale={[0.039, 0.039, 0.022]}
          />
          <group
            name="Cube593"
            position={[-52.144, -0.738, 103.055]}
            rotation={[Math.PI, 0, Math.PI]}
            scale={0.84}
          >
            <mesh
              name="Cube638"
              castShadow
              receiveShadow
              geometry={nodes.Cube638.geometry}
              material={materials["WINDOW.001"]}
            />
            <mesh
              name="Cube638_1"
              castShadow
              receiveShadow
              geometry={nodes.Cube638_1.geometry}
              material={materials["Material.062"]}
            />
            <mesh
              name="Cube638_2"
              castShadow
              receiveShadow
              geometry={nodes.Cube638_2.geometry}
              material={materials["Material.064"]}
            />
            <mesh
              name="Cube638_3"
              castShadow
              receiveShadow
              geometry={nodes.Cube638_3.geometry}
              material={materials["Material.066"]}
            />
            <mesh
              name="Cube638_4"
              castShadow
              receiveShadow
              geometry={nodes.Cube638_4.geometry}
              material={materials["Material.069"]}
            />
          </group>
          <mesh
            name="Plane033"
            castShadow
            receiveShadow
            geometry={nodes.Plane033.geometry}
            material={materials["Material.061"]}
            position={[-20.644, -0.5, -17.838]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={[0.209, 0.209, 0.079]}
          />
          <mesh
            name="Plane034"
            castShadow
            receiveShadow
            geometry={nodes.Plane034.geometry}
            material={materials["Material.061"]}
            position={[-21.578, -0.5, -17.838]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={[0.209, 0.209, 0.079]}
          />
          <mesh
            name="Plane035"
            castShadow
            receiveShadow
            geometry={nodes.Plane035.geometry}
            material={materials["Material.061"]}
            position={[-20.644, -0.5, -42.077]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={[0.209, 0.209, 0.079]}
          />
          <mesh
            name="Plane036"
            castShadow
            receiveShadow
            geometry={nodes.Plane036.geometry}
            material={materials["Material.061"]}
            position={[-21.578, -0.5, -42.077]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={[0.209, 0.209, 0.079]}
          />
          <mesh
            name="Plane037"
            castShadow
            receiveShadow
            geometry={nodes.Plane037.geometry}
            material={materials["Material.061"]}
            position={[-17.977, -0.5, -65.462]}
            rotation={[Math.PI / 2, 0, Math.PI]}
            scale={[0.209, 0.209, 0.079]}
          />
          <mesh
            name="Plane038"
            castShadow
            receiveShadow
            geometry={nodes.Plane038.geometry}
            material={materials["Material.061"]}
            position={[-17.044, -0.5, -65.462]}
            rotation={[Math.PI / 2, 0, Math.PI]}
            scale={[0.209, 0.209, 0.079]}
          />
          <mesh
            name="Plane039"
            castShadow
            receiveShadow
            geometry={nodes.Plane039.geometry}
            material={materials["Material.061"]}
            position={[-17.342, -0.5, -6.657]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={[0.209, 0.209, 0.079]}
          />
          <mesh
            name="Plane040"
            castShadow
            receiveShadow
            geometry={nodes.Plane040.geometry}
            material={materials["Material.061"]}
            position={[-18.275, -0.5, -6.657]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={[0.209, 0.209, 0.079]}
          />
          <mesh
            name="Plane295"
            castShadow
            receiveShadow
            geometry={nodes.Plane295.geometry}
            material={materials["Material.061"]}
            position={[-52.761, -0.474, 101.449]}
            rotation={[Math.PI / 2, 0, Math.PI]}
            scale={[0.209, 0.209, 0.079]}
          />
          <mesh
            name="Plane296"
            castShadow
            receiveShadow
            geometry={nodes.Plane296.geometry}
            material={materials["Material.061"]}
            position={[-51.827, -0.474, 101.449]}
            rotation={[Math.PI / 2, 0, Math.PI]}
            scale={[0.209, 0.209, 0.079]}
          />
          <group
            name="wheel_fr001"
            position={[-7.496, -0.95, 30.406]}
            rotation={[Math.PI, 0, Math.PI]}
            scale={0.361}
          >
            <mesh
              name="Circle048"
              castShadow
              receiveShadow
              geometry={nodes.Circle048.geometry}
              material={materials["WINDOW.001"]}
            />
            <mesh
              name="Circle048_1"
              castShadow
              receiveShadow
              geometry={nodes.Circle048_1.geometry}
              material={materials["BASIC COLOR.001"]}
            />
            <mesh
              name="Circle048_2"
              castShadow
              receiveShadow
              geometry={nodes.Circle048_2.geometry}
              material={materials["Material.060"]}
            />
          </group>
          <group
            name="wheel_fr002"
            position={[-23.727, -0.95, -2.951]}
            rotation={[0, -Math.PI / 2, 0]}
            scale={0.361}
          >
            <mesh
              name="Circle020_1"
              castShadow
              receiveShadow
              geometry={nodes.Circle020_1.geometry}
              material={materials["WINDOW.001"]}
            />
            <mesh
              name="Circle020_2"
              castShadow
              receiveShadow
              geometry={nodes.Circle020_2.geometry}
              material={materials["BASIC COLOR.001"]}
            />
          </group>
          <group
            name="wheel_fr003"
            position={[-23.727, -0.95, -25.126]}
            rotation={[0, -Math.PI / 2, 0]}
            scale={0.361}
          >
            <mesh
              name="Circle021"
              castShadow
              receiveShadow
              geometry={nodes.Circle021.geometry}
              material={materials["WINDOW.001"]}
            />
            <mesh
              name="Circle021_1"
              castShadow
              receiveShadow
              geometry={nodes.Circle021_1.geometry}
              material={materials["BASIC COLOR.001"]}
            />
          </group>
          <group
            name="wheel_fr004"
            position={[-23.727, -0.95, -53.296]}
            rotation={[0, -Math.PI / 2, 0]}
            scale={0.361}
          >
            <mesh
              name="Circle022_1"
              castShadow
              receiveShadow
              geometry={nodes.Circle022_1.geometry}
              material={materials["WINDOW.001"]}
            />
            <mesh
              name="Circle022_2"
              castShadow
              receiveShadow
              geometry={nodes.Circle022_2.geometry}
              material={materials["BASIC COLOR.001"]}
            />
          </group>
          <group
            name="wheel_fr005"
            position={[-29.671, -0.95, -64.823]}
            rotation={[0, -Math.PI / 2, 0]}
            scale={0.361}
          >
            <mesh
              name="Circle023_1"
              castShadow
              receiveShadow
              geometry={nodes.Circle023_1.geometry}
              material={materials["WINDOW.001"]}
            />
            <mesh
              name="Circle023_2"
              castShadow
              receiveShadow
              geometry={nodes.Circle023_2.geometry}
              material={materials["BASIC COLOR.001"]}
            />
          </group>
          <group
            name="wheel_fr006"
            position={[-19.875, -0.95, -64.882]}
            rotation={[0, -Math.PI / 2, 0]}
            scale={0.361}
          >
            <mesh
              name="Circle024_1"
              castShadow
              receiveShadow
              geometry={nodes.Circle024_1.geometry}
              material={materials["WINDOW.001"]}
            />
            <mesh
              name="Circle024_2"
              castShadow
              receiveShadow
              geometry={nodes.Circle024_2.geometry}
              material={materials["BASIC COLOR.001"]}
            />
            <mesh
              name="Circle024_3"
              castShadow
              receiveShadow
              geometry={nodes.Circle024_3.geometry}
              material={materials["Material.059"]}
            />
          </group>
          <group
            name="wheel_fr007"
            position={[-17.091, -0.95, -53.296]}
            rotation={[0, -Math.PI / 2, 0]}
            scale={0.361}
          >
            <mesh
              name="Circle025_1"
              castShadow
              receiveShadow
              geometry={nodes.Circle025_1.geometry}
              material={materials["WINDOW.001"]}
            />
            <mesh
              name="Circle025_2"
              castShadow
              receiveShadow
              geometry={nodes.Circle025_2.geometry}
              material={materials["BASIC COLOR.001"]}
            />
            <mesh
              name="Circle025_3"
              castShadow
              receiveShadow
              geometry={nodes.Circle025_3.geometry}
              material={materials["Material.060"]}
            />
          </group>
          <group
            name="wheel_fr008"
            position={[-18.944, -0.95, -18.318]}
            rotation={[0, Math.PI / 2, 0]}
            scale={0.361}
          >
            <mesh
              name="Circle026_1"
              castShadow
              receiveShadow
              geometry={nodes.Circle026_1.geometry}
              material={materials["WINDOW.001"]}
            />
            <mesh
              name="Circle026_2"
              castShadow
              receiveShadow
              geometry={nodes.Circle026_2.geometry}
              material={materials["BASIC COLOR.001"]}
            />
            <mesh
              name="Circle026_3"
              castShadow
              receiveShadow
              geometry={nodes.Circle026_3.geometry}
              material={materials["Material.060"]}
            />
          </group>
          <group
            name="wheel_fr009"
            position={[-20.43, -0.95, -39.318]}
            rotation={[0, -Math.PI / 2, 0]}
            scale={0.361}
          >
            <mesh
              name="Circle027"
              castShadow
              receiveShadow
              geometry={nodes.Circle027.geometry}
              material={materials["WINDOW.001"]}
            />
            <mesh
              name="Circle027_1"
              castShadow
              receiveShadow
              geometry={nodes.Circle027_1.geometry}
              material={materials["BASIC COLOR.001"]}
            />
            <mesh
              name="Circle027_2"
              castShadow
              receiveShadow
              geometry={nodes.Circle027_2.geometry}
              material={materials["Material.059"]}
            />
          </group>
          <group
            name="wheel_fr010"
            position={[-7.496, -0.95, 14.311]}
            rotation={[Math.PI, 0, Math.PI]}
            scale={0.361}
          >
            <mesh
              name="Circle049"
              castShadow
              receiveShadow
              geometry={nodes.Circle049.geometry}
              material={materials["WINDOW.001"]}
            />
            <mesh
              name="Circle049_1"
              castShadow
              receiveShadow
              geometry={nodes.Circle049_1.geometry}
              material={materials["BASIC COLOR.001"]}
            />
            <mesh
              name="Circle049_2"
              castShadow
              receiveShadow
              geometry={nodes.Circle049_2.geometry}
              material={materials["Material.165"]}
            />
          </group>
          <group
            name="wheel_fr012"
            position={[-54.659, -0.924, 102.03]}
            rotation={[0, -Math.PI / 2, 0]}
            scale={0.361}
          >
            <mesh
              name="Circle066"
              castShadow
              receiveShadow
              geometry={nodes.Circle066.geometry}
              material={materials["WINDOW.001"]}
            />
            <mesh
              name="Circle066_1"
              castShadow
              receiveShadow
              geometry={nodes.Circle066_1.geometry}
              material={materials["BASIC COLOR.001"]}
            />
            <mesh
              name="Circle066_2"
              castShadow
              receiveShadow
              geometry={nodes.Circle066_2.geometry}
              material={materials["Material.059"]}
            />
          </group>
          <group
            name="wheel_fr013"
            position={[-36.911, -0.924, 113.031]}
            rotation={[Math.PI, 0, Math.PI]}
            scale={0.361}
          >
            <mesh
              name="Circle072"
              castShadow
              receiveShadow
              geometry={nodes.Circle072.geometry}
              material={materials["WINDOW.001"]}
            />
            <mesh
              name="Circle072_1"
              castShadow
              receiveShadow
              geometry={nodes.Circle072_1.geometry}
              material={materials["BASIC COLOR.001"]}
            />
            <mesh
              name="Circle072_2"
              castShadow
              receiveShadow
              geometry={nodes.Circle072_2.geometry}
              material={materials["Material.059"]}
            />
          </group>
          <group
            name="wheel_fr015"
            position={[-20.516, -0.95, 52.73]}
            rotation={[Math.PI, 0, Math.PI]}
            scale={0.361}
          >
            <mesh
              name="Circle004_1"
              castShadow
              receiveShadow
              geometry={nodes.Circle004_1.geometry}
              material={materials["WINDOW.001"]}
            />
            <mesh
              name="Circle004_2"
              castShadow
              receiveShadow
              geometry={nodes.Circle004_2.geometry}
              material={materials["BASIC COLOR.001"]}
            />
            <mesh
              name="Circle004_3"
              castShadow
              receiveShadow
              geometry={nodes.Circle004_3.geometry}
              material={materials["Material.060"]}
            />
          </group>
          <group
            name="wheel_fr016"
            position={[-47.5, -0.95, 6.181]}
            scale={0.361}
          >
            <mesh
              name="Circle005_1"
              castShadow
              receiveShadow
              geometry={nodes.Circle005_1.geometry}
              material={materials["WINDOW.001"]}
            />
            <mesh
              name="Circle005_2"
              castShadow
              receiveShadow
              geometry={nodes.Circle005_2.geometry}
              material={materials["BASIC COLOR.001"]}
            />
          </group>
          <group
            name="wheel_fr017"
            position={[-64.709, -0.95, 46.513]}
            rotation={[0, Math.PI / 2, 0]}
            scale={0.361}
          >
            <mesh
              name="Circle006_1"
              castShadow
              receiveShadow
              geometry={nodes.Circle006_1.geometry}
              material={materials["WINDOW.001"]}
            />
            <mesh
              name="Circle006_2"
              castShadow
              receiveShadow
              geometry={nodes.Circle006_2.geometry}
              material={materials["BASIC COLOR.001"]}
            />
          </group>
          <group
            name="wheel_fr018"
            position={[-57.117, -0.95, 28.949]}
            rotation={[0, -Math.PI / 2, 0]}
            scale={0.361}
          >
            <mesh
              name="Circle073"
              castShadow
              receiveShadow
              geometry={nodes.Circle073.geometry}
              material={materials["WINDOW.001"]}
            />
            <mesh
              name="Circle073_1"
              castShadow
              receiveShadow
              geometry={nodes.Circle073_1.geometry}
              material={materials["BASIC COLOR.001"]}
            />
            <mesh
              name="Circle073_2"
              castShadow
              receiveShadow
              geometry={nodes.Circle073_2.geometry}
              material={materials["Material.060"]}
            />
          </group>
          <group
            name="wheel_fr019"
            position={[-2.498, -0.924, -62.704]}
            rotation={[0, Math.PI / 2, 0]}
            scale={0.361}
          >
            <mesh
              name="Circle053"
              castShadow
              receiveShadow
              geometry={nodes.Circle053.geometry}
              material={materials["WINDOW.001"]}
            />
            <mesh
              name="Circle053_1"
              castShadow
              receiveShadow
              geometry={nodes.Circle053_1.geometry}
              material={materials["BASIC COLOR.001"]}
            />
            <mesh
              name="Circle053_2"
              castShadow
              receiveShadow
              geometry={nodes.Circle053_2.geometry}
              material={materials["Material.060"]}
            />
          </group>
        </group>
      </group>
    </RigidBody>
  );
}

useGLTF.preload("./assets/suncity/cars.glb");
