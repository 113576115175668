import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";

export default function Landscaping(props) {
  const { nodes, materials } = useGLTF("./assets/suncity/landscaping.glb");

  return (
    <RigidBody colliders={null}>
      <group {...props} dispose={null}>
      <group position={[-10.754, -0.884, 110.334]} rotation={[0, -Math.PI / 2, 0]} scale={6.196}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane004.geometry}
          material={materials['Material.032']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane004_1.geometry}
          material={materials['Material.031']}
        />
      </group>
      <group position={[-51.028, -1.085, -30.924]} scale={[18.821, 31.885, 36.199]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane005.geometry}
          material={materials['Material.020']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane005_1.geometry}
          material={materials['Material.035']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane005_2.geometry}
          material={materials['Material.032']}
        />
      </group>
    </group>
    </RigidBody>
  );
}

useGLTF.preload("./assets/suncity/landscaping.glb");
