import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";

export default function SignBoards(props) {
  const { nodes, materials } = useGLTF("./assets/island/props/signboards.glb");
  // const ElisyaName = useGLTF('./assets/island/props/signboards-elysia.glb')
  // console.log("xvf", nodes);
  return (
    <RigidBody type="fixed" colliders="hull">
      <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sign2001.geometry}
        material={materials["wooden-material"]}
        position={[-20.087, 2.003, -7.814]}
        rotation={[Math.PI / 2, 0, 2.057]}
        scale={2.558}
      />
      <group
        position={[-13.26, 1.99, 34.921]}
        rotation={[Math.PI / 2, 0, -1.02]}
        scale={2.139}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sign4001_1.geometry}
          material={nodes.Sign4001_1.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sign4001_2.geometry}
          material={materials["wooden-stand"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sign4001_3.geometry}
          material={materials["wooden-stand"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sign4001_4.geometry}
          material={materials["wooden-stand"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sign4001_5.geometry}
          material={materials["wooden-material"]}
        />
      </group>
      <group
        position={[-55.833, 2.014, -0.351]}
        rotation={[Math.PI / 2, 0, -0.427]}
        scale={3.513}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sign7001_1.geometry}
          material={materials["wooden-material"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sign7001_2.geometry}
          material={materials["wooden-stand"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sign7001_3.geometry}
          material={materials["Material.025"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Text.geometry}
        material={materials["Material.012"]}
        position={[-13.511, 5.123, 35.573]}
        rotation={[Math.PI / 2, 0, -1.029]}
        scale={0.627}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Text001.geometry}
        material={materials["Material.012"]}
        position={[-56.445, 7.672, 0.158]}
        rotation={[Math.PI / 2, 0, -0.418]}
        scale={0.791}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Text002.geometry}
        material={materials["Material.017"]}
        position={[-19.911, 5.728, -8.419]}
        rotation={[Math.PI / 2, 0, 2.073]}
        scale={0.705}
      />
      <group
        position={[17.127, 2.176, -32.458]}
        rotation={[Math.PI / 2, 0, -0.427]}
        scale={3.513}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sign7002_1.geometry}
          material={materials["wooden-material"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sign7002_2.geometry}
          material={materials["wooden-stand"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sign7002_3.geometry}
          material={materials["Material.025"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Text003.geometry}
        material={materials["Material.017"]}
        position={[16.515, 7.834, -31.949]}
        rotation={[Math.PI / 2, 0, -0.418]}
        scale={0.791}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sign2002.geometry}
        material={materials["wooden-material"]}
        position={[66.635, 2.063, -47.257]}
        rotation={[Math.PI / 2, 0, 1.398]}
        scale={3.22}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Text004.geometry}
        material={materials["Material.012"]}
        position={[66.344, 6.751, -47.995]}
        rotation={[Math.PI / 2, 0, 1.414]}
        scale={0.888}
      />
      <group
        position={[59.025, 2.157, -13.995]}
        rotation={[Math.PI / 2, 0, 3.003]}
        scale={4.793}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sign7003_1.geometry}
          material={materials["wooden-material"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sign7003_2.geometry}
          material={materials["wooden-stand"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sign7003_3.geometry}
          material={materials["Material.025"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Text006.geometry}
        material={materials["Material.012"]}
        position={[61.759, 9.45, -14.701]}
        rotation={[Math.PI / 2, 0, 3.012]}
        scale={1.466}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Text012.geometry}
        material={materials["Material.012"]}
        position={[-33.609, 5.497, 32.621]}
        rotation={[1.593, -0.074, -2.337]}
        scale={0.418}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Text013.geometry}
        material={materials["Material.012"]}
        position={[-42.489, 5.876, 22.897]}
        rotation={[1.593, -0.074, -2.337]}
        scale={0.418}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Text014.geometry}
        material={materials["Material.012"]}
        position={[-62.388, 5.665, -8.857]}
        rotation={[1.736, -0.044, -0.659]}
        scale={0.471}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Text015.geometry}
        material={materials["Material.012"]}
        position={[-42.635, 5.673, -24.229]}
        rotation={[1.703, 0.108, 0.281]}
        scale={0.471}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Text016.geometry}
        material={materials["Material.012"]}
        position={[-6.401, 5.832, -9.836]}
        rotation={[1.672, 0.138, 0.542]}
        scale={0.471}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Text017.geometry}
        material={materials["Material.012"]}
        position={[14.906, 5.772, -10.308]}
        rotation={[1.64, -0.156, -1.556]}
        scale={0.471}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Text018.geometry}
        material={materials["Material.012"]}
        position={[18.926, 5.772, -33.65]}
        rotation={[1.64, -0.156, -1.556]}
        scale={0.471}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Text019.geometry}
        material={materials["Material.012"]}
        position={[22.348, 5.772, -59.708]}
        rotation={[1.726, 0.072, 0.03]}
        scale={0.429}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Text021.geometry}
        material={materials["Material.012"]}
        position={[55.755, 5.772, -51.362]}
        rotation={[1.653, 0.15, 0.666]}
        scale={0.429}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Text022.geometry}
        material={materials["Material.012"]}
        position={[61.254, 5.772, -33.123]}
        rotation={[1.442, 0.113, 2.022]}
        scale={0.429}
      />
      <group
        position={[40.969, 1.457, -32.062]}
        rotation={[Math.PI / 2, 0, 3.044]}
        scale={3.513}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sign7004_1.geometry}
          material={materials["wooden-material"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sign7004_2.geometry}
          material={materials["wooden-stand"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sign7004_3.geometry}
          material={materials["Material.025"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Text023.geometry}
        material={materials["Material.017"]}
        position={[41.713, 7.116, -32.346]}
        rotation={[Math.PI / 2, 0, 3.053]}
        scale={0.791}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Text024.geometry}
        material={materials["Material.017"]}
        position={[-4.942, 14.759, 49.802]}
        rotation={[Math.PI / 2, 0, -1.124]}
        scale={2.64}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sign7005.geometry}
        material={materials["wooden-material"]}
        position={[40.969, 1.457, -32.062]}
        rotation={[Math.PI / 2, 0, 3.044]}
        scale={3.513}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sign7006.geometry}
        material={materials["wooden-material"]}
        position={[-3.489, -0.997, 45.373]}
        rotation={[Math.PI / 2, 0, -1.126]}
        scale={9.802}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Text025.geometry}
        material={materials["Material.012"]}
        position={[21.213, 8.259, -5.811]}
        rotation={[Math.PI / 2, 0, 1.522]}
        scale={0.791}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Text008.geometry}
        material={materials["Material.012"]}
        position={[-55.869, 5.641, 10.601]}
        rotation={[1.593, -0.074, -2.337]}
        scale={0.418}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Text005.geometry}
        material={materials["Material.088"]}
        position={[40.636, 5.772, -60.44]}
        rotation={[1.523, 0.006, 1.622]}
        scale={0.429}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Text026.geometry}
        material={materials["Material.091"]}
        position={[-11.503, 5.312, 44.188]}
        rotation={[1.63, -0.102, -2.611]}
        scale={0.349}
      />
    </group>
    </RigidBody>
  );
}

useGLTF.preload("./assets/island/props/signboards.glb");
// useGLTF.preload("./assets/island/props/signboards-elysia.glb");
