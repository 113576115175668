import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";

export default function Signages(props) {
  const { nodes, materials } = useGLTF("./assets/suncity/signages.glb");
  return (
    <RigidBody type="fixed" colliders="trimesh">
      <group {...props} dispose={null}>
        <group position={[-7.182, -0.57, -56.704]} scale={0.714}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder_1.geometry}
            material={materials["Material.044"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder_2.geometry}
            material={materials["Material.045"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder_3.geometry}
            material={materials["Material.046"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder_4.geometry}
            material={materials["Material.047"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder_5.geometry}
            material={materials["Material.048"]}
          />
        </group>
        <group
          position={[-4.713, 2.839, 34.384]}
          rotation={[-Math.PI / 2, Math.PI / 2, 0]}
          scale={0.879}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder019.geometry}
            material={materials["Material.087"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder019_1.geometry}
            material={materials["Material.088"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder019_2.geometry}
            material={materials["Material.089"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder019_3.geometry}
            material={materials["Material.166"]}
          />
        </group>
        <group
          position={[-5.028, 2.884, -54.611]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.046, 0.973, 0.973]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube163.geometry}
            material={materials["Material.252"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube163_1.geometry}
            material={materials["Material.253"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube163_2.geometry}
            material={materials["Material.254"]}
          />
        </group>
        <group
          position={[-5.028, 2.884, 63.975]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.046, 0.973, 0.973]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube164.geometry}
            material={materials["Material.252"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube164_1.geometry}
            material={materials["Material.253"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube164_2.geometry}
            material={materials["Material.254"]}
          />
        </group>
        <group position={[21.003, 2.884, 47.807]} scale={[0.046, 0.973, 0.973]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube165.geometry}
            material={materials["Material.252"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube165_1.geometry}
            material={materials["Material.253"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube165_2.geometry}
            material={materials["Material.254"]}
          />
        </group>
        <group
          position={[55.431, 2.884, 65.91]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.046, 0.973, 0.973]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube184.geometry}
            material={materials["Material.252"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube184_1.geometry}
            material={materials["Material.253"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube184_2.geometry}
            material={materials["Material.254"]}
          />
        </group>
        <group
          position={[20.308, 2.884, 65.91]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.046, 0.973, 0.973]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube186.geometry}
            material={materials["Material.252"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube186_1.geometry}
            material={materials["Material.253"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube186_2.geometry}
            material={materials["Material.254"]}
          />
        </group>
        <group
          position={[-4.574, 2.78, -35.296]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.754}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle013.geometry}
            material={materials["Material.255"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle013_1.geometry}
            material={materials["Material.256"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle013_2.geometry}
            material={materials["Material.258"]}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text011.geometry}
          material={materials["Material.257"]}
          position={[-4.226, 2.491, -35.325]}
          rotation={[Math.PI / 2, 0, Math.PI]}
          scale={0.963}
        />
        <group
          position={[-4.574, 2.78, 44.283]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.754}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle017.geometry}
            material={materials["Material.255"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle017_1.geometry}
            material={materials["Material.256"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle017_2.geometry}
            material={materials["Material.258"]}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text022.geometry}
          material={materials["Material.257"]}
          position={[-4.226, 2.491, 44.254]}
          rotation={[Math.PI / 2, 0, Math.PI]}
          scale={0.963}
        />
        <group
          position={[-4.574, 2.78, 95.728]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.754}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle018.geometry}
            material={materials["Material.255"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle018_1.geometry}
            material={materials["Material.256"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle018_2.geometry}
            material={materials["Material.258"]}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text024.geometry}
          material={materials["Material.257"]}
          position={[-4.226, 2.491, 95.699]}
          rotation={[Math.PI / 2, 0, Math.PI]}
          scale={0.963}
        />
        <group
          position={[20.473, 2.78, 95.699]}
          rotation={[Math.PI / 2, 0, Math.PI]}
          scale={0.754}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle019.geometry}
            material={materials["Material.255"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle019_1.geometry}
            material={materials["Material.256"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle019_2.geometry}
            material={materials["Material.258"]}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text025.geometry}
          material={materials["Material.257"]}
          position={[20.124, 2.491, 95.728]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.963}
        />
        <group
          position={[20.473, 2.78, 26.73]}
          rotation={[Math.PI / 2, 0, Math.PI]}
          scale={0.754}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle051.geometry}
            material={materials["Material.255"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle051_1.geometry}
            material={materials["Material.256"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle051_2.geometry}
            material={materials["Material.258"]}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text048.geometry}
          material={materials["Material.257"]}
          position={[20.124, 2.491, 26.759]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.963}
        />
        <group
          position={[20.473, 2.78, -32.446]}
          rotation={[Math.PI / 2, 0, Math.PI]}
          scale={0.754}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle052.geometry}
            material={materials["Material.255"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle052_1.geometry}
            material={materials["Material.256"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle052_2.geometry}
            material={materials["Material.258"]}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text049.geometry}
          material={materials["Material.257"]}
          position={[20.124, 2.491, -32.417]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.963}
        />
        <group
          position={[35.987, 2.78, 64.318]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.754}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle056.geometry}
            material={materials["Material.255"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle056_1.geometry}
            material={materials["Material.256"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle056_2.geometry}
            material={materials["Material.258"]}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text050.geometry}
          material={materials["Material.257"]}
          position={[35.958, 2.491, 63.97]}
          rotation={[Math.PI / 2, 0, 1.571]}
          scale={0.963}
        />
        <group
          position={[73.973, 2.78, 64.318]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.754}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle057.geometry}
            material={materials["Material.255"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle057_1.geometry}
            material={materials["Material.256"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle057_2.geometry}
            material={materials["Material.258"]}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text051.geometry}
          material={materials["Material.257"]}
          position={[73.944, 2.491, 63.97]}
          rotation={[Math.PI / 2, 0, 1.571]}
          scale={0.963}
        />
        <group
          position={[72.212, 2.78, 47.193]}
          rotation={[Math.PI / 2, 0, 1.571]}
          scale={0.754}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle058.geometry}
            material={materials["Material.255"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle058_1.geometry}
            material={materials["Material.256"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle058_2.geometry}
            material={materials["Material.258"]}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text052.geometry}
          material={materials["Material.257"]}
          position={[72.241, 2.491, 47.542]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.963}
        />
        <group
          position={[22.848, 2.78, 47.193]}
          rotation={[Math.PI / 2, 0, 1.571]}
          scale={0.754}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle059.geometry}
            material={materials["Material.255"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle059_1.geometry}
            material={materials["Material.256"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle059_2.geometry}
            material={materials["Material.258"]}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text053.geometry}
          material={materials["Material.257"]}
          position={[22.877, 2.491, 47.542]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.963}
        />
      </group>
    </RigidBody>
  );
}

useGLTF.preload("./assets/suncity/signages.glb");
