import { useGLTF } from '@react-three/drei'

export function Spanner(props) {
  const { nodes, materials } = useGLTF('./assets/inventory/spanner.glb')
  return (
    <group {...props} dispose={null} scale={4} >
          <mesh
            name="Cube"
            castShadow
            receiveShadow
            geometry={nodes.Cube.geometry}
            material={materials.Material}
          />
    </group>
  )
}

useGLTF.preload('./assets/inventory/spanner.glb')