import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";

export default function HighRiseBuilding(props) {
  const { nodes, materials } = useGLTF(
    "./assets/suncity/HighRiseBuildings.glb"
  );
  return (
    <RigidBody type="fixed" colliders="trimesh">
      <group {...props} dispose={null}>
        <group
          position={[62.298, -0.432, -47.045]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={[0.022, 0.022, 0.027]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.AB17_OBJ_02_1.geometry}
            material={materials["WINDOW.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.AB17_OBJ_02_2.geometry}
            material={materials.wire_177148027}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.AB17_OBJ_02_3.geometry}
            material={materials.wire_228153184}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.AB17_OBJ_02_4.geometry}
            material={materials["Material.221"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.AB17_OBJ_02_5.geometry}
            material={materials.wire_006135113}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.AB17_OBJ_02_6.geometry}
            material={materials.wire_229166215}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.AB17_OBJ_02_7.geometry}
            material={materials.wire_225143087}
          />
        </group>
        <group
          position={[62.298, -0.432, 20.678]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={[0.022, 0.022, 0.027]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.AB17_OBJ_02003.geometry}
            material={materials["WINDOW.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.AB17_OBJ_02003_1.geometry}
            material={materials.wire_177148027}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.AB17_OBJ_02003_2.geometry}
            material={materials.wire_228153184}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.AB17_OBJ_02003_3.geometry}
            material={materials["Material.221"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.AB17_OBJ_02003_4.geometry}
            material={materials.wire_006135113}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.AB17_OBJ_02003_5.geometry}
            material={materials.wire_229166215}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.AB17_OBJ_02003_6.geometry}
            material={materials.wire_225143087}
          />
        </group>
        <group
          position={[62.298, -0.432, -13.603]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={[0.022, 0.022, 0.027]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.AB17_OBJ_02004.geometry}
            material={materials["WINDOW.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.AB17_OBJ_02004_1.geometry}
            material={materials.wire_177148027}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.AB17_OBJ_02004_2.geometry}
            material={materials.wire_228153184}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.AB17_OBJ_02004_3.geometry}
            material={materials["Material.221"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.AB17_OBJ_02004_4.geometry}
            material={materials.wire_006135113}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.AB17_OBJ_02004_5.geometry}
            material={materials.wire_229166215}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.AB17_OBJ_02004_6.geometry}
            material={materials.wire_225143087}
          />
        </group>
        <group
          position={[63.268, -0.729, -46.353]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={0.025}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.AB17_OBJ_07_1.geometry}
            material={materials["Material.223"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.AB17_OBJ_07_2.geometry}
            material={materials["wire_214229166.001"]}
          />
        </group>
        <group
          position={[63.268, -0.729, 20.686]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={0.025}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.AB17_OBJ_07001_1.geometry}
            material={materials["Material.223"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.AB17_OBJ_07001_2.geometry}
            material={materials["wire_214229166.001"]}
          />
        </group>
        <group
          position={[63.268, -0.729, -12.911]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={0.025}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.AB17_OBJ_07002_1.geometry}
            material={materials["Material.223"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.AB17_OBJ_07002_2.geometry}
            material={materials["wire_214229166.001"]}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.AB17_OBJ_12.geometry}
          material={materials["wire_214229166.001"]}
          position={[63.268, -0.729, -46.353]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={0.025}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.AB17_OBJ_12001.geometry}
          material={materials["wire_214229166.001"]}
          position={[63.268, -0.729, 20.686]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={0.025}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.AB17_OBJ_12002.geometry}
          material={materials["wire_214229166.001"]}
          position={[63.268, -0.729, -12.911]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={0.025}
        />
        <group
          position={[63.268, -0.729, -46.353]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={0.025}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.AB17_OBJ_13_1.geometry}
            material={materials["wire_228153184.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.AB17_OBJ_13_2.geometry}
            material={materials["Material.223"]}
          />
        </group>
        <group
          position={[63.268, -0.729, 20.657]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={0.025}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.AB17_OBJ_13001_1.geometry}
            material={materials["wire_228153184.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.AB17_OBJ_13001_2.geometry}
            material={materials["Material.223"]}
          />
        </group>
        <group
          position={[63.268, -0.729, -12.911]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={0.025}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.AB17_OBJ_13002_1.geometry}
            material={materials["wire_228153184.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.AB17_OBJ_13002_2.geometry}
            material={materials["Material.223"]}
          />
        </group>
      </group>
    </RigidBody>
  );
}

useGLTF.preload("./assets/suncity/HighRiseBuildings.glb");
