// src/useAudioStore.js
import { val } from '@theatre/core';
import { Value } from 'sass';
import { create } from 'zustand';

const useAudioStore = create(set => ({
  recording: null,
  recorderLoading: false,
  audioBlob: null,
  transcription: null,
  setRecording: (recording) => set({ recording }),
  setRecorderLoading: (val) => set({recorderLoading: val}),
  setRecordingBlob: (blob => set({audioBlob: blob})),
  setTranscription: (transcription) => set({ transcription: transcription }),
}));

export default useAudioStore;
