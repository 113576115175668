import styled from 'styled-components';
import useGame from '../stores/useGame'; // Assuming useGameStore is the file name of your Zustand store

const GameOverComponent = () => {
  const gameover = useGame((state) => state.gameover);

  if (!gameover) return null;

  return (
    <Overlay>
      <Text>GAME OVER</Text>
    </Overlay>
  );
};

export default GameOverComponent;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const Text = styled.div`
  font-size: 48px;
  color: yellow;
  text-shadow: 2px 2px 0 #ff0000, 4px 4px 0 #0000ff;
  font-family: 'Press Start 2P', cursive;
`;