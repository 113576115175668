import React, { useRef } from 'react'
import { useGLTF, Text } from '@react-three/drei'

export function StartButton(props) {
  const { nodes, materials } = useGLTF('./assets/inventory/Button.glb')
  return (
    <group {...props} dispose={null} scale={0.1}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes['Button_Circle-Mesh'].geometry}
        material={materials.F44336}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes['Button_Circle-Mesh_1'].geometry}
        material={materials['455A64']}
      />
      <Text
        // position={[0, 0.1, 0]} // Adjust the position to place the text correctly on the button
        // font="/PressStart2P-Regular.ttf" // Ensure this path is correct for your font file
        // fontSize={0.1} // Adjust the size of the text as needed
        color="#000000" // Set the color of the text
      >
        START
      </Text>
    </group>
  )
}

useGLTF.preload('./assets/inventory/Button.glb')