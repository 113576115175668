import useGame from "../../stores/useGame.jsx";
import { checkActivityCompleted } from "../../utils/CheckActivitCompleted.js";
import { coinActivity, CoinBrief } from "./positions/CoinPositions.jsx";
// import { create } from "../config/Narrations.jsx";
import useDialogue from "../../stores/useDialogue.jsx";
import useActions from "../../stores/useActions.jsx";
import useAssistant from "../../stores/useAssistant.jsx";
import { useEffect } from "react";

const IframeWindow = () => {
  const isIframeOpen = useGame((state) => state.isIframeOpen);
  const iframUrl = useGame((state) => state.iframeUrl);
  const toggleIsIframeOpen = useGame((state) => state.toggleIsIframeOpen);
  const pauseGame = useGame((state) => state.pauseGame);
  const UID = useGame((state) => state.UID);
  const topic = useGame((state) => state.topic);
  const currentActivity = useGame((state) => state.currentActivity);
  const goToNextActivity = useGame((state) => state.goToNextActivity);
  const setCompletedCoinIndex = useGame((state) => state.setCompletedCoinIndex);
  const setIsEnteredActivity = useGame((state) => state.setIsEnteredActivity);
  const setCurrentHiddenCoin = useGame((state) => state.setCurrentHiddenCoin);
  const isLoading = useGame((state) => state.isLoading);
  const loadingText = useGame((state) => state.loadingText);
  const resetLoadingText = useGame((state) => state.resetLoadingText);
  const iframePosition = useGame((state) => state.iframePosition)
  const setIframePosition = useGame((state) => state.setIframePosition);
  const narration = useDialogue((state) => state.narration);
  const { setLastAction } = useActions((state) => state);
  const { activeAssistant } = useAssistant((state) => state);

  // use dialogueS
  const setNarration = useDialogue((state) => state.setNarration);

  const onCloseButtonClicked = () => {
    if (narration) {
      if (narration.next) {
        setNarration(narration.next);
      } else {
        if (!narration.special) {
          // setNarration({
          //   trigger: "browser",
          //   action: null,
          //   key: "Naruto Mirror of Minds",
          //   special: true,
          //   timeout: 1,
          //   type: "execute",
          //   source: "system",
          //   text: "",
          //   url: `${import.meta.env.VITE_CLIENT_URI}/game/feedback`,
          //   next: null,
          // });
          setLastAction(`I am done with ${narration.key} on ${topic}`);
          setIframePosition('right');
          resetLoadingText();
        }
      }
    }
    setCompletedCoinIndex(coinActivity[currentActivity]);
    setIsEnteredActivity(false);
    toggleIsIframeOpen();
    pauseGame(false);
  };
  const styles = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: isIframeOpen ? "block" : "none",
    borderRadius: "1rem",
    boxShadow: "0px 0px 10px 8px #000000bf",
    backdropFilter: "blur(5px)",
    background: "#0000008d",
  };

  const closeButtonStyles = {
    position: "fixed",
    top: "10px",
    right: "10px",
    fontSize: "1.5rem",
    background: "rgba(0, 0, 0, 0.8)",
    color: "rgb(255, 255, 255)",
    cursor: "pointer",
    padding: "0rem 0.7rem",
    borderRadius: "50%",
    display: isIframeOpen ? "block" : "none",
    textAlign: "center",
    fontFamily: "Rajdhani",
    zIndex: "2",
    boxShadow: "0px 0px 4px 1px #fff",
  };

  useEffect(() => {
    if (!isLoading) {
      resetLoadingText();
    }
  }, [isLoading]);

  useEffect(() => {
    if (isIframeOpen) {
      let iframe = document.querySelector(".window__iframe");
      // Connect with thoughtjumper
      if (iframUrl && iframUrl.startsWith(import.meta.env.VITE_CLIENT_URI)) {
        // Send mounted to iframe
        iframe.addEventListener("load", () => {
          const object = { value: true };
          iframe.contentWindow.postMessage(
            JSON.stringify(object),
            `${import.meta.env.VITE_CLIENT_URI}`
          );
        });
      }
    }
  }, [isIframeOpen]);

  return (
    <>
      {isLoading && (
        <div className="loader__container">
          <div className="loader"></div>
          <p className="loader__text">{loadingText}</p>
        </div>
      )}
      {!isLoading && isIframeOpen && (
        <>
          <div className={ iframePosition === 'center' ? "window__container window__container--center" : "window__container window__container--right"}>
            {/* <span style={closeButtonStyles} className="close-button" onClick={onCloseButtonClicked}>x</span> */}
            <i
              className="bx bx-x window__close-button"
              onClick={onCloseButtonClicked}
            ></i>
            <iframe
              className="window__iframe"
              src={iframUrl}
              width={iframePosition === 'center' ? '1280px' : '640px'}
              height="668px"
              autoFocus={true}
              allowFullScreen
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
            ></iframe>
          </div>
          <div className="window__notification-container">
            <p className="window__notification">
              Press <span className="window__key">Esc</span> to regain cursor
              control, essential for learning interface navigation
            </p>
          </div>
        </>
      )}
    </>
  );
};

export default IframeWindow;
