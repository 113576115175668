import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

const useLoader = create(
    subscribeWithSelector((set) => {
        return {
            started: localStorage.getItem('doNotShowLoading') === 'true' ? true : false,
            setStarted: (val) => set({ started: val })
        }
    })
)

export default useLoader;