import create from 'zustand';

const useTimerStore = create((set) => ({
  time: 45 * 60 * 1000, // 60 minutes in milliseconds
  alertLimit: 10 * 60 * 1000, // 5 minutes in milliseconds
  isActive: false,
  timerStatus: false,
  setTime: (newTime) => set({ time: newTime }),
  startTimer: () => set({ isActive: true, timerStatus: true }),
  stopTimer: () => set({ isActive: false, timerStatus: false }),
  addTime: (additionalTime) => set((state) => ({ time: state.time + additionalTime })),
  setInitialTime: (initialMinutes) => set({ time: initialMinutes * 60 * 1000 }),
  setAlertLimit: (alertMinutes) => set({ alertLimit: alertMinutes * 60 * 1000 }),
}));

export default useTimerStore;