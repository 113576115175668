import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";

export default function Logos(props) {
  const { nodes, materials } = useGLTF("./assets/suncity/logos.glb");

  return (
    <RigidBody type="fixed" colliders="trimesh">
      <group {...props} dispose={null}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text002.geometry}
          material={materials["Material.022"]}
          position={[-22.077, 7.264, 29.212]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={4.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve059.geometry}
          material={materials["SVGMat.005"]}
          position={[-16.815, 10.312, 118.605]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={14.982}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve048.geometry}
          material={materials["SVGMat.005"]}
          position={[-18.531, 4.273, 118.605]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={14.982}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve055.geometry}
          material={materials["Material.015"]}
          position={[-19.247, 6.674, 120.69]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={39.435}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve064.geometry}
          material={materials["SVGMat.006"]}
          position={[-19.345, 5.388, 89.253]}
          rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
          scale={-11.733}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve050.geometry}
          material={materials["SVGMat.006"]}
          position={[-16.78, 9.806, 88.149]}
          rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
          scale={-5.723}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve060.geometry}
          material={materials["SVGMat.008"]}
          position={[-16.839, 10.667, 107.39]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={11.339}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve056.geometry}
          material={materials["SVGMat.008"]}
          position={[-18.631, 4.606, 107.39]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={11.339}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve057.geometry}
          material={materials["Material.113"]}
          position={[-19.335, 7.842, 108.093]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={18.479}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve066.geometry}
          material={materials["SVGMat.009"]}
          position={[-16.808, 10.383, 97.06]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={20.815}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve067.geometry}
          material={materials["Material.164"]}
          position={[-19.307, 7.039, 97.06]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={49.692}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve068.geometry}
          material={materials["SVGMat.009"]}
          position={[-18.522, 4.324, 97.06]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={20.815}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve052.geometry}
          material={materials["SVGMat.004"]}
          position={[-17.496, 18.974, 87.203]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={247.304}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path14.geometry}
          material={materials["SVGMat.010"]}
          position={[-16.622, 10.796, 76.496]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={19.884}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path66.geometry}
          material={materials["Material.213"]}
          position={[-16.622, 10.661, 77.736]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={19.884}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path84.geometry}
          material={materials["Material.201"]}
          position={[-16.622, 10.661, 77.06]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={19.884}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path14001.geometry}
          material={materials["SVGMat.010"]}
          position={[-19.204, 8.067, 76.731]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={51.178}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path84001.geometry}
          material={materials["Material.201"]}
          position={[-19.204, 7.722, 78.183]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={51.178}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6065.geometry}
          material={materials["Material.215"]}
          position={[-19.175, 7.555, 69.696]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6067.geometry}
          material={materials["Material.214"]}
          position={[-19.175, 7.46, 68.819]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6071001.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.955, 67.72]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6073001.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.991, 67.208]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6075001.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.938, 66.767]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6077001.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 8.067, 66.457]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6079001.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.958, 65.965]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6081001.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.902, 65.565]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6083001.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.944, 65.124]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6085001.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.938, 64.792]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6087001.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 8.037, 64.508]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6089001.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.959, 64.088]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6091001.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.939, 63.323]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6093001.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.982, 62.811]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6095001.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.336, 67.768]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6097001.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.292, 67.128]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6099001.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.27, 66.609]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6101001.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.379, 66.204]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6103001.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.295, 65.722]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6105001.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.293, 65.005]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6107001.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.207, 64.423]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6109001.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.293, 63.896]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6111001.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.292, 63.389]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6113001.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.379, 62.89]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6115001.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.207, 62.48]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6065001.geometry}
          material={materials["Material.215"]}
          position={[-16.817, 10.682, 68.061]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={9.378}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6067001.geometry}
          material={materials["Material.214"]}
          position={[-16.817, 10.631, 67.598]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={9.378}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6071002.geometry}
          material={materials["SVGMat.031"]}
          position={[-16.817, 10.893, 67.017]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={9.378}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6073002.geometry}
          material={materials["SVGMat.031"]}
          position={[-16.817, 10.912, 66.746]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={9.378}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6075002.geometry}
          material={materials["SVGMat.031"]}
          position={[-16.817, 10.884, 66.514]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={9.378}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6077002.geometry}
          material={materials["SVGMat.031"]}
          position={[-16.817, 10.953, 66.349]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={9.378}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6079002.geometry}
          material={materials["SVGMat.031"]}
          position={[-16.817, 10.895, 66.09]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={9.378}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6081002.geometry}
          material={materials["SVGMat.031"]}
          position={[-16.817, 10.865, 65.878]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={9.378}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6083002.geometry}
          material={materials["SVGMat.031"]}
          position={[-16.817, 10.887, 65.645]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={9.378}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6085002.geometry}
          material={materials["SVGMat.031"]}
          position={[-16.817, 10.884, 65.47]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={9.378}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6087002.geometry}
          material={materials["SVGMat.031"]}
          position={[-16.817, 10.936, 65.319]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={9.378}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6089002.geometry}
          material={materials["SVGMat.031"]}
          position={[-16.817, 10.895, 65.097]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={9.378}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6091002.geometry}
          material={materials["SVGMat.031"]}
          position={[-16.817, 10.884, 64.693]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={9.378}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6093002.geometry}
          material={materials["SVGMat.031"]}
          position={[-16.817, 10.908, 64.423]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={9.378}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6095002.geometry}
          material={materials["SVGMat.031"]}
          position={[-16.817, 10.566, 67.042]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={9.378}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6097002.geometry}
          material={materials["SVGMat.031"]}
          position={[-16.817, 10.543, 66.704]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={9.378}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6099002.geometry}
          material={materials["SVGMat.031"]}
          position={[-16.817, 10.531, 66.43]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={9.378}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6101002.geometry}
          material={materials["SVGMat.031"]}
          position={[-16.817, 10.588, 66.216]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={9.378}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6103002.geometry}
          material={materials["SVGMat.031"]}
          position={[-16.817, 10.544, 65.961]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={9.378}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6105002.geometry}
          material={materials["SVGMat.031"]}
          position={[-16.817, 10.543, 65.582]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={9.378}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6107002.geometry}
          material={materials["SVGMat.031"]}
          position={[-16.817, 10.498, 65.275]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={9.378}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6109002.geometry}
          material={materials["SVGMat.031"]}
          position={[-16.817, 10.543, 64.996]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={9.378}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6111002.geometry}
          material={materials["SVGMat.031"]}
          position={[-16.817, 10.543, 64.728]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={9.378}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6113002.geometry}
          material={materials["SVGMat.031"]}
          position={[-16.817, 10.588, 64.464]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={9.378}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6115002.geometry}
          material={materials["SVGMat.031"]}
          position={[-16.817, 10.498, 64.248]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={9.378}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6065002.geometry}
          material={materials["Material.215"]}
          position={[-19.175, 7.555, 69.696]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6067002.geometry}
          material={materials["Material.214"]}
          position={[-19.175, 7.46, 68.819]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6071003.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.955, 67.72]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6073003.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.991, 67.208]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6075003.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.938, 66.767]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6077003.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 8.067, 66.457]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6079003.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.958, 65.965]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6081003.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.902, 65.565]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6083003.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.944, 65.124]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6085003.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.938, 64.792]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6087003.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 8.037, 64.508]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6089003.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.959, 64.088]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6091003.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.939, 63.323]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6093003.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.982, 62.811]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6095003.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.336, 67.768]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6097003.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.292, 67.128]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6099003.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.27, 66.609]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6101003.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.379, 66.204]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6103003.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.295, 65.722]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6105003.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.293, 65.005]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6107003.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.207, 64.423]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6109003.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.293, 63.896]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6111003.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.292, 63.389]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6113003.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.379, 62.89]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.path6115003.geometry}
          material={materials["SVGMat.031"]}
          position={[-19.175, 7.207, 62.48]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={17.745}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve001.geometry}
          material={materials.SVGMat}
          position={[-36.431, 10.509, -31.151]}
          rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
          scale={-60.225}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve005.geometry}
          material={materials["SVGMat.001"]}
          position={[33.976, 10.611, -35.265]}
          rotation={[-Math.PI / 2, 0, 1.563]}
          scale={-92.964}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve006.geometry}
          material={materials.Material}
          position={[33.976, 10.611, -13.005]}
          rotation={[-Math.PI / 2, 0, 1.563]}
          scale={-92.964}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve007.geometry}
          material={materials.Material}
          position={[33.976, 10.611, 12.059]}
          rotation={[-Math.PI / 2, 0, 1.563]}
          scale={-89.591}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve008.geometry}
          material={materials.Material}
          position={[33.976, 10.611, -50.425]}
          rotation={[-Math.PI / 2, 0, 1.563]}
          scale={-77.18}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve009.geometry}
          material={materials["SVGMat.001"]}
          position={[33.976, 10.611, 26.579]}
          rotation={[-Math.PI / 2, 0, 1.563]}
          scale={-70.215}
        />
      </group>
    </RigidBody>
  );
}

useGLTF.preload("./assets/suncity/logos.glb");
