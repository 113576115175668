import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";

export default function StreetLights(props) {
  const { nodes, materials } = useGLTF("./assets/suncity/streetLights.glb");
  return (
    <RigidBody type="fixed" colliders="trimesh">
      <group {...props} dispose={null}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube037.geometry}
          material={materials["Material.049"]}
          position={[-13.495, -0.659, -40.955]}
          scale={0.074}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube038.geometry}
          material={materials["Material.049"]}
          position={[-25.601, -0.659, -40.955]}
          scale={0.074}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube039.geometry}
          material={materials["Material.049"]}
          position={[-13.495, -0.659, -16.691]}
          scale={0.074}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube040.geometry}
          material={materials["Material.049"]}
          position={[-25.601, -0.659, -16.691]}
          scale={0.074}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube041.geometry}
          material={materials["Material.049"]}
          position={[-13.495, -0.659, -54.956]}
          scale={0.074}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube042.geometry}
          material={materials["Material.049"]}
          position={[-25.601, -0.659, -54.956]}
          scale={0.074}
        />
        <group
          position={[-4.298, -0.697, -51.959]}
          rotation={[0, 0.157, 0]}
          scale={0.136}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder021.geometry}
            material={materials["Material.167"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder021_1.geometry}
            material={materials["Material.168"]}
          />
        </group>
        <group
          position={[-4.298, -0.697, -21.313]}
          rotation={[0, 0.157, 0]}
          scale={0.136}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder023.geometry}
            material={materials["Material.167"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder023_1.geometry}
            material={materials["Material.168"]}
          />
        </group>
        <group
          position={[-4.298, -0.697, 7.359]}
          rotation={[0, 0.157, 0]}
          scale={0.136}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder025_1.geometry}
            material={materials["Material.167"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder025_2.geometry}
            material={materials["Material.168"]}
          />
        </group>
        <group
          position={[-4.298, -0.697, 31.246]}
          rotation={[0, 0.157, 0]}
          scale={0.136}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder027_1.geometry}
            material={materials["Material.167"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder027_2.geometry}
            material={materials["Material.168"]}
          />
        </group>
        <group
          position={[19.803, -0.697, 16.143]}
          rotation={[Math.PI, -0.157, Math.PI]}
          scale={0.136}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder029.geometry}
            material={materials["Material.167"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder029_1.geometry}
            material={materials["Material.168"]}
          />
        </group>
        <group
          position={[19.803, -0.697, -14.164]}
          rotation={[Math.PI, -0.157, Math.PI]}
          scale={0.136}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder031_1.geometry}
            material={materials["Material.167"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder031_2.geometry}
            material={materials["Material.168"]}
          />
        </group>
        <group
          position={[19.803, -0.697, -40.889]}
          rotation={[Math.PI, -0.157, Math.PI]}
          scale={0.136}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder033_1.geometry}
            material={materials["Material.167"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder033_2.geometry}
            material={materials["Material.168"]}
          />
        </group>
        <group
          position={[19.803, -0.697, 36.629]}
          rotation={[Math.PI, -0.157, Math.PI]}
          scale={0.136}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder035_1.geometry}
            material={materials["Material.167"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder035_2.geometry}
            material={materials["Material.168"]}
          />
        </group>
        <group position={[-13.495, -0.659, 35.567]} scale={0.074}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube131.geometry}
            material={materials["Material.049"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube131_1.geometry}
            material={materials["Material.169"]}
          />
        </group>
        <group position={[-31.532, -0.659, 40.048]} scale={0.074}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube132.geometry}
            material={materials["Material.049"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube132_1.geometry}
            material={materials["Material.169"]}
          />
        </group>
        <group position={[-53.58, -0.659, 40.048]} scale={0.074}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube134.geometry}
            material={materials["Material.049"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube134_1.geometry}
            material={materials["Material.169"]}
          />
        </group>
        <group position={[-36.009, -0.659, 22.344]} scale={0.074}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube136.geometry}
            material={materials["Material.049"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube136_1.geometry}
            material={materials["Material.169"]}
          />
        </group>
        <group position={[-13.495, -0.659, 12.338]} scale={0.074}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube137.geometry}
            material={materials["Material.049"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube137_1.geometry}
            material={materials["Material.169"]}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube097.geometry}
          material={nodes.Cube097.material}
          position={[44.11, -2.152, 107.605]}
          rotation={[0, 1.571, 0]}
          scale={6.196}
        />
        <group position={[-60.401, -0.659, 17.711]} scale={0.074}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube144.geometry}
            material={materials["Material.049"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube144_1.geometry}
            material={materials["Material.169"]}
          />
        </group>
        <group
          position={[19.803, -0.697, 96.277]}
          rotation={[Math.PI, -0.157, Math.PI]}
          scale={0.136}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder038_1.geometry}
            material={materials["Material.167"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder038_2.geometry}
            material={materials["Material.168"]}
          />
        </group>
        <group
          position={[-4.057, -0.697, 96.988]}
          rotation={[0, 0.157, 0]}
          scale={0.136}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder041_1.geometry}
            material={materials["Material.167"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder041_2.geometry}
            material={materials["Material.168"]}
          />
        </group>
        <group
          position={[19.803, -0.697, 126.719]}
          rotation={[Math.PI, -0.157, Math.PI]}
          scale={0.136}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder043.geometry}
            material={materials["Material.167"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder043_1.geometry}
            material={materials["Material.168"]}
          />
        </group>
        <group
          position={[-4.057, -0.697, 127.25]}
          rotation={[0, 0.157, 0]}
          scale={0.136}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder044.geometry}
            material={materials["Material.167"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder044_1.geometry}
            material={materials["Material.168"]}
          />
        </group>
        <group
          position={[-4.057, -0.697, 69.162]}
          rotation={[0, 0.157, 0]}
          scale={0.136}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder049.geometry}
            material={materials["Material.167"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder049_1.geometry}
            material={materials["Material.168"]}
          />
        </group>
        <group
          position={[14.519, -0.95, 4.123]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.361}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle003.geometry}
            material={materials["WINDOW.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle003_1.geometry}
            material={materials["BASIC COLOR.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle003_2.geometry}
            material={materials["Material.060"]}
          />
        </group>
        <group position={[-24.715, -0.659, 49.614]} scale={0.074}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube029.geometry}
            material={materials["Material.049"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube029_1.geometry}
            material={materials["Material.169"]}
          />
        </group>
        <group position={[-61.763, -0.659, 51.75]} scale={0.074}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube120.geometry}
            material={materials["Material.049"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube120_1.geometry}
            material={materials["Material.169"]}
          />
        </group>
        <group
          position={[19.803, -0.697, -62.309]}
          rotation={[Math.PI, -0.157, Math.PI]}
          scale={0.136}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder134.geometry}
            material={materials["Material.167"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder134_1.geometry}
            material={materials["Material.168"]}
          />
        </group>
        <group position={[-10.755, -0.659, 64.192]} scale={0.074}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube012.geometry}
            material={materials["Material.049"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube012_1.geometry}
            material={materials["Material.169"]}
          />
        </group>
        <group position={[-10.755, -0.659, 83.39]} scale={0.074}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube024.geometry}
            material={materials["Material.049"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube024_1.geometry}
            material={materials["Material.169"]}
          />
        </group>
        <group position={[-10.755, -0.659, 98.462]} scale={0.074}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube026.geometry}
            material={materials["Material.049"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube026_1.geometry}
            material={materials["Material.169"]}
          />
        </group>
        <group position={[-10.755, -0.659, 116.615]} scale={0.074}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube027.geometry}
            material={materials["Material.049"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube027_1.geometry}
            material={materials["Material.169"]}
          />
        </group>
        <group
          position={[55.125, -0.697, 73.642]}
          rotation={[Math.PI, -0.157, Math.PI]}
          scale={0.136}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder197.geometry}
            material={materials["Material.167"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder197_1.geometry}
            material={materials["Material.168"]}
          />
        </group>
        <group
          position={[55.125, -0.697, 88.933]}
          rotation={[Math.PI, -0.157, Math.PI]}
          scale={0.136}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder198.geometry}
            material={materials["Material.167"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder198_1.geometry}
            material={materials["Material.168"]}
          />
        </group>
        <group
          position={[55.125, -0.697, 102.95]}
          rotation={[Math.PI, -0.157, Math.PI]}
          scale={0.136}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder199.geometry}
            material={materials["Material.167"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder199_1.geometry}
            material={materials["Material.168"]}
          />
        </group>
        <group
          position={[55.125, -0.697, 116.717]}
          rotation={[Math.PI, -0.157, Math.PI]}
          scale={0.136}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder200.geometry}
            material={materials["Material.167"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder200_1.geometry}
            material={materials["Material.168"]}
          />
        </group>
        <group
          position={[55.125, -0.697, 130.233]}
          rotation={[Math.PI, -0.157, Math.PI]}
          scale={0.136}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder201.geometry}
            material={materials["Material.167"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder201_1.geometry}
            material={materials["Material.168"]}
          />
        </group>
        <group
          position={[42.718, -0.697, 130.208]}
          rotation={[0, 0.157, 0]}
          scale={0.136}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder202.geometry}
            material={materials["Material.167"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder202_1.geometry}
            material={materials["Material.168"]}
          />
        </group>
        <group
          position={[42.718, -0.697, 114.917]}
          rotation={[0, 0.157, 0]}
          scale={0.136}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder203.geometry}
            material={materials["Material.167"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder203_1.geometry}
            material={materials["Material.168"]}
          />
        </group>
        <group
          position={[42.718, -0.697, 100.9]}
          rotation={[0, 0.157, 0]}
          scale={0.136}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder204.geometry}
            material={materials["Material.167"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder204_1.geometry}
            material={materials["Material.168"]}
          />
        </group>
        <group
          position={[42.718, -0.697, 87.133]}
          rotation={[0, 0.157, 0]}
          scale={0.136}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder205.geometry}
            material={materials["Material.167"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder205_1.geometry}
            material={materials["Material.168"]}
          />
        </group>
        <group
          position={[42.718, -0.697, 73.617]}
          rotation={[0, 0.157, 0]}
          scale={0.136}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder206.geometry}
            material={materials["Material.167"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder206_1.geometry}
            material={materials["Material.168"]}
          />
        </group>
        <group
          position={[11.734, -0.697, 48.382]}
          rotation={[Math.PI, -0.157, Math.PI]}
          scale={0.136}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder209.geometry}
            material={materials["Material.167"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder209_1.geometry}
            material={materials["Material.168"]}
          />
        </group>
        <group
          position={[32.397, -0.697, 48.382]}
          rotation={[Math.PI, -0.157, Math.PI]}
          scale={0.136}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder210.geometry}
            material={materials["Material.167"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder210_1.geometry}
            material={materials["Material.168"]}
          />
        </group>
        <group
          position={[59.217, -0.697, 48.382]}
          rotation={[Math.PI, -0.157, Math.PI]}
          scale={0.136}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder211.geometry}
            material={materials["Material.167"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder211_1.geometry}
            material={materials["Material.168"]}
          />
        </group>
        <group
          position={[97.487, -0.697, 63.62]}
          rotation={[0, 0.157, 0]}
          scale={0.136}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder212.geometry}
            material={materials["Material.167"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder212_1.geometry}
            material={materials["Material.168"]}
          />
        </group>
        <group
          position={[76.823, -0.697, 63.62]}
          rotation={[0, 0.157, 0]}
          scale={0.136}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder213.geometry}
            material={materials["Material.167"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder213_1.geometry}
            material={materials["Material.168"]}
          />
        </group>
        <group
          position={[50.004, -0.697, 63.62]}
          rotation={[0, 0.157, 0]}
          scale={0.136}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder214.geometry}
            material={materials["Material.167"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder214_1.geometry}
            material={materials["Material.168"]}
          />
        </group>
        <group position={[-53.425, -0.659, 74.678]} scale={0.074}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube631.geometry}
            material={materials["Material.049"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube631_1.geometry}
            material={materials["Material.169"]}
          />
        </group>
        <group position={[-53.425, -0.659, 88.418]} scale={0.074}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube632.geometry}
            material={materials["Material.049"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube632_1.geometry}
            material={materials["Material.169"]}
          />
        </group>
        <group position={[-53.425, -0.659, 100.341]} scale={0.074}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube633.geometry}
            material={materials["Material.049"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube633_1.geometry}
            material={materials["Material.169"]}
          />
        </group>
        <group position={[-53.425, -0.659, 112.484]} scale={0.074}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube634.geometry}
            material={materials["Material.049"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube634_1.geometry}
            material={materials["Material.169"]}
          />
        </group>
        <group
          position={[19.803, -0.697, 67.38]}
          rotation={[Math.PI, -0.157, Math.PI]}
          scale={0.136}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder036_1.geometry}
            material={materials["Material.167"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder036_2.geometry}
            material={materials["Material.168"]}
          />
        </group>
        <group
          position={[10.515, 1.283, 67.744]}
          rotation={[-Math.PI / 2, Math.PI / 2, 0]}
          scale={0.143}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle007.geometry}
            material={materials["Material.202"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle007_1.geometry}
            material={materials["Material.212"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle007_2.geometry}
            material={materials["Material.220"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle007_3.geometry}
            material={materials["Material.224"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle007_4.geometry}
            material={materials["Material.225"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle007_5.geometry}
            material={materials["Material.226"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle007_6.geometry}
            material={materials["Material.232"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle007_7.geometry}
            material={materials["Material.233"]}
          />
        </group>
        <group
          position={[21.587, 1.283, 64.279]}
          rotation={[Math.PI, 0, Math.PI / 2]}
          scale={0.143}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle010.geometry}
            material={materials["Material.202"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle010_1.geometry}
            material={materials["Material.212"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle010_2.geometry}
            material={materials["Material.220"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle010_3.geometry}
            material={materials["Material.224"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle010_4.geometry}
            material={materials["Material.225"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle010_5.geometry}
            material={materials["Material.226"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle010_6.geometry}
            material={materials["Material.232"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle010_7.geometry}
            material={materials["Material.233"]}
          />
        </group>
        <group
          position={[4.876, 1.283, 46.301]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}
          scale={0.143}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle011.geometry}
            material={materials["Material.202"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle011_1.geometry}
            material={materials["Material.212"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle011_2.geometry}
            material={materials["Material.220"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle011_3.geometry}
            material={materials["Material.224"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle011_4.geometry}
            material={materials["Material.225"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle011_5.geometry}
            material={materials["Material.226"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle011_6.geometry}
            material={materials["Material.232"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle011_7.geometry}
            material={materials["Material.233"]}
          />
        </group>
        <group
          position={[42.949, 1.283, 65.347]}
          rotation={[-Math.PI / 2, Math.PI / 2, 0]}
          scale={0.143}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle012.geometry}
            material={materials["Material.202"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle012_1.geometry}
            material={materials["Material.212"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle012_2.geometry}
            material={materials["Material.220"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle012_3.geometry}
            material={materials["Material.224"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle012_4.geometry}
            material={materials["Material.225"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle012_5.geometry}
            material={materials["Material.226"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle012_6.geometry}
            material={materials["Material.232"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle012_7.geometry}
            material={materials["Material.233"]}
          />
        </group>
      </group>
    </RigidBody>
  );
}

useGLTF.preload("./assets/suncity/streetLights.glb");
