import { val } from "@theatre/core";
import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

const useActions = create(
  subscribeWithSelector((set) => {
      return {
          lastAction: null,
          setLastAction: (val) => set({ lastAction: val }),
    };
  })
);

export default useActions;
