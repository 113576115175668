import React, { useEffect, useLayoutEffect, useRef, useMemo } from "react";
import { useGLTF } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";
// import {
//   fencePositions,
//   fenceRotations,
//   fenceScale,
// } from "./positions/FencePositions";
// import {
//   barrelsPositions,
//   barrelsRotations,
//   barrelsScales,
// } from "./positions/BarrelsPositions";
// import {
//   streetLightPositions,
//   streetLightRotations,
//   streetLightScales,
// } from "./positions/StreetLightPositions";
import * as THREE from "three";

// Instanced Barrels
// function Barrels(props) {
//   // import Single Barrel
//   const { nodes, materials } = useGLTF("./assets/island/props/barrel.glb");
//   console.log("xvf", nodes);

//   // declare variables for all the geometry in one barrel
//   const geo1 = nodes.Cylinder102.geometry.clone();
//   const geo2 = nodes.Cylinder102_1.geometry.clone();
//   const geo3 = nodes.Cylinder102_2.geometry.clone();

//   // declare variables for all the Barrels
//   const material1 = materials["Material.026"];
//   const material2 = materials["Material.028"];
//   const material3 = materials["Material.030"];

//   // Number of Barrels required
//   const InstanceBodyCount = barrelsPositions.length || 11;

//   // RigidBody reference
//   const rigidBodies = useRef();

//   // get vertex normals
//   geo1.computeVertexNormals();
//   geo2.computeVertexNormals();
//   geo3.computeVertexNormals();

//   // geometry reference
//   const geo1Ref = useRef();
//   const geo2Ref = useRef();
//   const geo3Ref = useRef();

//   const instances = useMemo(() => {
//     const instances = [];

//     for (let i = 0; i < InstanceBodyCount; i++) {
//       instances.push({
//         key: "instance_" + Math.random(),
//         position: barrelsPositions[i],
//         rotation: barrelsRotations[i],
//         scale: barrelsScales[i],
//       });
//     }

//     return instances;
//   }, []);

//   useLayoutEffect(() => {
//     const transform = new THREE.Matrix4();
//     for (let i = 0; i < InstanceBodyCount; ++i) {
//       transform.makeRotationFromQuaternion(
//         new THREE.Quaternion().setFromEuler(
//           new THREE.Euler(
//             barrelsRotations[i][0],
//             barrelsRotations[i][1],
//             barrelsRotations[i][2]
//           )
//         )
//       );
//       transform.makeScale(
//         ...[barrelsScales[i], barrelsScales[i], barrelsScales[i]]
//       );
//       transform.setPosition(...barrelsPositions[i]);
//       geo1Ref.current.setMatrixAt(i, transform);
//       geo2Ref.current.setMatrixAt(i, transform);
//       geo3Ref.current.setMatrixAt(i, transform);
//     }
//   }, []);

//   return (
//     <InstancedRigidBodies
//       ref={rigidBodies}
//       instances={instances}
//       type="fixed"
//       colliders="cuboid"
//     >
//       <instancedMesh ref={geo1Ref} args={[geo1, material1, InstanceBodyCount]}>
//         <meshStandardMaterial attach="Material.026" />
//       </instancedMesh>
//       <instancedMesh ref={geo2Ref} args={[geo2, material2, InstanceBodyCount]}>
//         <meshStandardMaterial attach="Material.028" />
//       </instancedMesh>
//       <instancedMesh ref={geo3Ref} args={[geo3, material3, InstanceBodyCount]}>
//         <meshStandardMaterial attach="Material.030" />
//       </instancedMesh>
//     </InstancedRigidBodies>
//   );
// }

function Barrels(props) {
  // import Single Barrel
  const { nodes, materials } = useGLTF("./assets/island/props/barrels.glb");
  return (
    <RigidBody type="fixed" colliders="cuboid">
      <group {...props} dispose={null}>
        <group
          position={[-14.962, 1.942, 20.225]}
          rotation={[0, -1.007, 0]}
          scale={1.29}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder100.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder100_1.geometry}
            material={materials["Material.028"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder100_2.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[-13.928, 1.942, 22.318]}
          rotation={[0, -1.007, 0]}
          scale={0.52}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder101.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder101_1.geometry}
            material={materials["Material.028"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder101_2.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[-72.15, 1.942, 20.225]}
          rotation={[0, -1.007, 0]}
          scale={1.29}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder102.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder102_1.geometry}
            material={materials["Material.028"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder102_2.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[22.742, 1.942, -9.372]}
          rotation={[0, -1.007, 0]}
          scale={1.29}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder103.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder103_1.geometry}
            material={materials["Material.028"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder103_2.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[-11.983, 1.942, 20.225]}
          rotation={[0, -1.007, 0]}
          scale={0.951}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder112.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder112_1.geometry}
            material={materials["Material.028"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder112_2.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[-77.738, 2.904, 20.225]}
          rotation={[0, -1.007, 0]}
          scale={1.582}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder113.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder113_1.geometry}
            material={materials["Material.028"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder113_2.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[-73.67, 2.111, 17.969]}
          rotation={[0, -1.007, 0]}
          scale={0.52}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder114.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder114_1.geometry}
            material={materials["Material.028"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder114_2.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[-70.56, 1.059, -24.502]}
          rotation={[0, -0.945, 0]}
          scale={1.29}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder140.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder140_1.geometry}
            material={materials["Material.028"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder140_2.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[-69.397, 1.059, -22.478]}
          rotation={[0, -0.945, 0]}
          scale={0.52}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder141.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder141_1.geometry}
            material={materials["Material.028"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder141_2.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[25.55, 1.942, -46.162]}
          rotation={[Math.PI, -1.484, Math.PI]}
          scale={1.29}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder176.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder176_1.geometry}
            material={materials["Material.028"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder176_2.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[27.921, 1.942, -44.358]}
          rotation={[Math.PI, -1.484, Math.PI]}
          scale={0.951}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder177.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder177_1.geometry}
            material={materials["Material.028"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder177_2.geometry}
            material={materials["Material.030"]}
          />
        </group>
      </group>
    </RigidBody>
  );
}

function Bonfire(props) {
  const { nodes, materials } = useGLTF("./assets/island/props/bonfire.glb");
  return (
    <RigidBody type="fixed" colliders="cuboid">
      <group {...props} dispose={null}>
        <group
          position={[-47.182, 1.995, 3.835]}
          rotation={[0.016, -0.598, -0.137]}
          scale={2.645}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.rock009.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.rock009_1.geometry}
            material={materials["Material.028"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.rock009_2.geometry}
            material={materials["Material.030"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.rock009_3.geometry}
            material={materials["Material.032"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.rock009_4.geometry}
            material={materials["Material.034"]}
          />
        </group>
        <group
          position={[21.224, 1.978, -53.053]}
          rotation={[0.017, 0.631, -0.156]}
          scale={1.418}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.rock017.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.rock017_1.geometry}
            material={materials["Material.028"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.rock017_2.geometry}
            material={materials["Material.030"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.rock017_3.geometry}
            material={materials["Material.032"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.rock017_4.geometry}
            material={materials["Material.034"]}
          />
        </group>
        <group
          position={[-1.09, 2.124, 2.526]}
          rotation={[0.014, -0.01, -0.146]}
          scale={[8.119, 8.208, 6.96]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.rock011.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.rock011_1.geometry}
            material={materials["Material.028"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.rock011_2.geometry}
            material={materials["Material.030"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.rock011_3.geometry}
            material={materials["Material.034"]}
          />
        </group>
        <group
          position={[-40.972, 2.124, 41.288]}
          rotation={[0.014, -0.01, -0.146]}
          scale={[9.964, 10.074, 8.541]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.rock012.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.rock012_1.geometry}
            material={materials["Material.028"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.rock012_2.geometry}
            material={materials["Material.030"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.rock012_3.geometry}
            material={materials["Material.034"]}
          />
        </group>
        <group
          position={[24.726, 1.978, -52.674]}
          rotation={[0.014, -0.01, -0.146]}
          scale={[3.7, 3.741, 3.172]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.rock016.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.rock016_1.geometry}
            material={materials["Material.028"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.rock016_2.geometry}
            material={materials["Material.030"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.rock016_3.geometry}
            material={materials["Material.034"]}
          />
        </group>
      </group>
    </RigidBody>
  );
}

function Tent(props) {
  const { nodes, materials } = useGLTF("./assets/island/props/tent.glb");
  return (
    <RigidBody type="fixed" colliders="hull">
      <group {...props} dispose={null}>
        <group
          position={[-81.001, 1.263, 13.675]}
          rotation={[-3.1, 0.899, -1.317]}
          scale={2}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.rock013.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.rock013_1.geometry}
            material={materials["Material.028"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.rock013_2.geometry}
            material={materials["Material.030"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.rock013_3.geometry}
            material={materials["Material.033"]}
          />
        </group>
        <group
          position={[29.074, 1.938, -49.047]}
          rotation={[-2.991, -1.399, -1.137]}
          scale={1.768}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.rock015.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.rock015_1.geometry}
            material={materials["Material.028"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.rock015_2.geometry}
            material={materials["Material.030"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.rock015_3.geometry}
            material={materials["Material.033"]}
          />
        </group>
      </group>
    </RigidBody>
  );
}

// function Fences(props) {
//   // import single fence
//   const { nodes, materials } = useGLTF("./assets/island/props/fence.glb");

//   // declare variables for all the geometry in one fence
//   const geo1 = nodes.Cube228.geometry.clone();
//   const geo2 = nodes.Cube228_1.geometry.clone();

//   // declare variables for all the fences
//   const material1 = materials["Material.026"];
//   const material2 = materials["Material.030"];

//   // Number of Fences required
//   const InstanceBodyCount = 36;

//   // RigidBody refrence
//   const rigidBodies = useRef();

//   // get vertex normals
//   geo1.computeVertexNormals();
//   geo2.computeVertexNormals();

//   // set scaling for geometry
//   geo1.scale(fenceScale[0], fenceScale[1], fenceScale[2]);
//   geo2.scale(fenceScale[0], fenceScale[1], fenceScale[2]);

//   // console.log("nodes:", nodes, "materials:", materials, "geo", geo1);

//   // Geometry refrence
//   const geo1Ref = useRef();
//   const geo2Ref = useRef();

//   // We can set the initial positions, and rotations, and scales, of
//   // the instances by providing an array of InstancedRigidBodyProps
//   // which is the same as RigidBodyProps, but with an additional "key" prop.
//   const instances = useMemo(() => {
//     const instances = [];

//     for (let i = 0; i < InstanceBodyCount; i++) {
//       instances.push({
//         key: "instance_" + Math.random(),
//         position: fencePositions[i],
//         rotation: fenceRotations[i],
//       });
//     }

//     return instances;
//   }, []);

//   useLayoutEffect(() => {
//     const transform = new THREE.Matrix4();
//     for (let i = 0; i < InstanceBodyCount; ++i) {
//       transform.makeRotationFromQuaternion(
//         new THREE.Quaternion().setFromEuler(
//           new THREE.Euler(
//             fenceRotations[i][0],
//             fenceRotations[i][1],
//             fenceRotations[i][2]
//           )
//         )
//       );

//       transform.setPosition(...fencePositions[i]);
//       geo1Ref.current.setMatrixAt(i, transform);
//       geo2Ref.current.setMatrixAt(i, transform);
//     }
//   }, []);

//   return (
//     <InstancedRigidBodies
//       ref={rigidBodies}
//       instances={instances}
//       type="fixed"
//       colliders="cuboid"
//     >
//       <instancedMesh ref={geo1Ref} args={[geo1, material1, InstanceBodyCount]}>
//         <meshStandardMaterial attach="Material.026" />
//       </instancedMesh>
//       <instancedMesh ref={geo2Ref} args={[geo2, material2, InstanceBodyCount]}>
//         <meshStandardMaterial attach="Material.030" />
//       </instancedMesh>
//     </InstancedRigidBodies>
//   );
// }

function Fences(props) {
  // import single fence
  const { nodes, materials } = useGLTF("./assets/island/props/fences.glb");

  return (
    <RigidBody type="fixed" colliders="cuboid">
      <group {...props} dispose={null}>
        <group
          position={[-78.218, 5.344, 24.23]}
          rotation={[0, -0.541, 0]}
          scale={[0.759, 4.705, 2.968]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube216.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube216_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[-88.546, 4.389, 12.845]}
          rotation={[0, -1.274, 0]}
          scale={[0.759, 4.705, 2.968]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube217.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube217_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[-68.254, 2.035, 27.925]}
          rotation={[0.034, 0.065, -0.478]}
          scale={[0.759, 4.705, 2.968]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube227.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube227_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[-90.44, 7.789, -0.35]}
          rotation={[-2.527, -1.518, -2.828]}
          scale={[0.759, 4.705, 2.968]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube228.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube228_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[-89.556, 13.006, -12.311]}
          rotation={[2.993, -1.311, 2.955]}
          scale={[0.759, 4.705, 2.968]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube229.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube229_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[-85.711, 10.562, -19.299]}
          rotation={[-2.83, -0.963, -2.436]}
          scale={[0.759, 4.705, 2.968]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube230.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube230_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[-79.999, 5.552, -26.31]}
          rotation={[-2.998, -0.551, -2.259]}
          scale={[0.759, 4.705, 2.968]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube231.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube231_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[-29.306, -0.244, 3.583]}
          rotation={[0, -0.739, 0]}
          scale={[0.61, 4.512, 2.386]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube052.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube052_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[-22.813, -0.244, 4.751]}
          rotation={[0, 0.948, 0]}
          scale={[0.61, 4.512, 2.386]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube160.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube160_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[71.976, 2.018, -59.777]}
          rotation={[0, -0.462, 0]}
          scale={[0.392, 2.912, 1.532]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube162.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube162_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[66.027, 2.018, -62.601]}
          rotation={[0, -0.462, 0]}
          scale={[0.392, 2.912, 1.532]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube232.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube232_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[77.811, 2.018, -48.719]}
          rotation={[0, -1.241, 0]}
          scale={[0.392, 2.429, 1.532]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube233.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube233_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[75.56, 2.018, -54.908]}
          rotation={[0, -1.241, 0]}
          scale={[0.392, 2.912, 1.532]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube234.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube234_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[84.153, 2.018, -42.669]}
          rotation={[0, -1.241, 0]}
          scale={[0.392, 2.912, 1.532]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube235.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube235_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[80.976, 2.018, -47.367]}
          rotation={[0, -0.522, 0]}
          scale={[0.392, 2.912, 1.532]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube236.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube236_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[84.153, 2.018, -36.948]}
          rotation={[Math.PI, -1.436, Math.PI]}
          scale={[0.392, 2.912, 1.532]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube237.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube237_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[65.222, 2.018, -16.613]}
          rotation={[0, -1.252, 0]}
          scale={[0.623, 3.865, 2.438]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube238.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube238_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[66.361, 2.018, -27.829]}
          rotation={[Math.PI, -0.702, Math.PI]}
          scale={[0.392, 2.429, 1.532]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube239.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube239_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[2.828, 2.494, -49.131]}
          rotation={[0, -0.541, 0]}
          scale={[0.759, 4.705, 2.968]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube246.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube246_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[-4.543, 1.963, -56.748]}
          rotation={[0, -1.201, 0]}
          scale={[0.759, 4.705, 2.968]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube247.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube247_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[-3.163, 1.963, -68.826]}
          rotation={[Math.PI, -1.102, Math.PI]}
          scale={[0.759, 4.705, 2.968]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube248.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube248_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[3.579, 2.08, -29.955]}
          rotation={[0, 0.314, 0]}
          scale={[0.759, 4.705, 2.968]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube249.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube249_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[-12.548, 1.901, -22.295]}
          rotation={[0, 1.092, 0]}
          scale={[0.593, 3.675, 2.318]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube250.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube250_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[13.598, 2.08, 23.447]}
          rotation={[0, 0.314, 0]}
          scale={[0.759, 4.705, 2.968]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube251.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube251_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[5.794, 1.804, 31.363]}
          rotation={[0, 1.254, 0]}
          scale={[0.759, 4.705, 2.968]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube252.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube252_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[36.569, 1.804, 15.491]}
          rotation={[0, 1.115, 0]}
          scale={[0.759, 4.705, 2.968]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube253.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube253_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[42.576, 1.804, 3.609]}
          rotation={[0, 1.115, 0]}
          scale={[0.759, 4.705, 2.968]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube254.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube254_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[31.529, 1.925, -69.632]}
          rotation={[0, -0.471, 0]}
          scale={[0.759, 4.705, 2.968]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube256.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube256_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[17.225, 1.925, -74.656]}
          rotation={[0, -0.471, 0]}
          scale={[0.759, 4.705, 2.968]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube257.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube257_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[46.134, 1.925, -65.748]}
          rotation={[0, -0.471, 0]}
          scale={[0.759, 4.705, 2.968]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube258.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube258_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[28.643, 1.925, -40.053]}
          rotation={[Math.PI, -1.104, Math.PI]}
          scale={[0.759, 4.705, 2.968]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube259.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube259_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[28.023, 1.925, -28.304]}
          rotation={[0, -1.192, 0]}
          scale={[0.759, 4.705, 2.968]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube260.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube260_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[49.19, 1.876, -55.504]}
          rotation={[Math.PI, -1.115, Math.PI]}
          scale={[0.759, 4.705, 2.968]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube261.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube261_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[50.087, 1.925, -26.415]}
          rotation={[Math.PI, -1.178, Math.PI]}
          scale={[0.759, 4.705, 2.968]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube262.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube262_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[59.045, 2.018, -60.511]}
          rotation={[0, 1.078, 0]}
          scale={[0.392, 2.912, 1.532]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube085.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube085_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[55.635, 1.824, -53.641]}
          rotation={[Math.PI, -1.115, Math.PI]}
          scale={[0.492, 3.051, 1.924]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube088.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube088_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[56.615, 2.034, -37.775]}
          rotation={[Math.PI, -1.229, Math.PI]}
          scale={[0.567, 3.515, 2.217]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube089.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube089_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
      </group>
    </RigidBody>
  );
}

function Signposts(props) {
  const { nodes, materials } = useGLTF("./assets/island/props/signposts.glb");
  return (
    <RigidBody type="fixed" colliders="cuboid">
      <group {...props} dispose={null}>
      <group
        position={[-12.987, 3.445, 43.945]}
        rotation={[0, 1.089, 0]}
        scale={4.378}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder063.geometry}
          material={materials.Holz4}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder063_1.geometry}
          material={materials.Wegweiser2}
        />
      </group>
      <group
        position={[-34.949, 3.531, 31.895]}
        rotation={[0, 0.801, 0]}
        scale={4.378}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder064.geometry}
          material={materials.Holz4}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder064_1.geometry}
          material={materials.Wegweiser2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder064_2.geometry}
          material={materials["Material.011"]}
        />
      </group>
      <group
        position={[-61.486, 3.749, -10.119]}
        rotation={[0, -0.892, 0]}
        scale={4.378}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder065.geometry}
          material={materials.Holz4}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder065_1.geometry}
          material={materials.Wegweiser2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder065_2.geometry}
          material={materials["Material.018"]}
        />
      </group>
      <group
        position={[-57.203, 3.775, 9.915]}
        rotation={[0, 0.801, 0]}
        scale={4.378}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder068.geometry}
          material={materials.Holz4}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder068_1.geometry}
          material={materials.Wegweiser2}
        />
      </group>
      <group
        position={[-43.828, 3.91, 22.172]}
        rotation={[0, 0.801, 0]}
        scale={4.378}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder055.geometry}
          material={materials.Holz4}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder055_1.geometry}
          material={materials.Wegweiser2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder055_2.geometry}
          material={materials["Material.011"]}
        />
      </group>
      <group
        position={[-40.971, 3.753, -24.254]}
        rotation={[Math.PI, -1.298, Math.PI]}
        scale={4.378}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder056.geometry}
          material={materials.Holz4}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder056_1.geometry}
          material={materials.Wegweiser2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder056_2.geometry}
          material={materials["Material.018"]}
        />
      </group>
      <group
        position={[-4.838, 3.915, -9.446]}
        rotation={[Math.PI, -1.045, Math.PI]}
        scale={4.378}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder071.geometry}
          material={materials.Holz4}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder071_1.geometry}
          material={materials.Wegweiser2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder071_2.geometry}
          material={materials["Material.018"]}
        />
      </group>
      <group
        position={[14.48, 3.856, -11.799]}
        rotation={[0, 0.007, 0]}
        scale={4.378}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder072.geometry}
          material={materials.Holz4}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder072_1.geometry}
          material={materials.Wegweiser2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder072_2.geometry}
          material={materials["Material.018"]}
        />
      </group>
      <group
        position={[18.499, 3.856, -35.142]}
        rotation={[0, 0.007, 0]}
        scale={4.378}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder022.geometry}
          material={materials.Holz4}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder022_1.geometry}
          material={materials.Wegweiser2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder022_2.geometry}
          material={materials["Material.018"]}
        />
      </group>
      <group
        position={[23.85, 3.856, -60.094]}
        rotation={[Math.PI, -1.551, Math.PI]}
        scale={4.378}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder066.geometry}
          material={materials.Holz4}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder066_1.geometry}
          material={materials.Wegweiser2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder066_2.geometry}
          material={materials["Material.018"]}
        />
      </group>
      <group
        position={[57.193, 3.856, -50.78]}
        rotation={[Math.PI, -0.915, Math.PI]}
        scale={4.378}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder069.geometry}
          material={materials.Holz4}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder069_1.geometry}
          material={materials.Wegweiser2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder069_2.geometry}
          material={materials["Material.018"]}
        />
      </group>
      <group
        position={[61.012, 3.856, -31.591]}
        rotation={[-Math.PI, 0.428, -Math.PI]}
        scale={4.378}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder070.geometry}
          material={materials.Holz4}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder070_1.geometry}
          material={materials.Wegweiser2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder070_2.geometry}
          material={materials["Material.018"]}
        />
      </group>
      <group
        position={[21.309, 7.79, -5.961]}
        rotation={[1.527, -0.006, -3.119]}
        scale={[0.011, 0.315, 0.162]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cone.geometry}
          material={materials["Material.012"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cone_1.geometry}
          material={materials["wooden-material"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cone_2.geometry}
          material={materials["wooden-stand"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cone_3.geometry}
          material={nodes.Cone_3.material}
        />
      </group>
      <group
        position={[41.049, 3.856, -59.012]}
        rotation={[-Math.PI, 0.033, -Math.PI]}
        scale={4.378}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder083.geometry}
          material={materials["Holz4.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder083_1.geometry}
          material={materials["Wegweiser2.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder083_2.geometry}
          material={materials["Material.087"]}
        />
      </group>
    </group>
    </RigidBody>
  );
}

function IslandNameAndLogo(props) {
  const { nodes, materials } = useGLTF("./assets/island/props/island-name.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Text024.geometry}
        material={materials["Material.017"]}
        position={[-4.942, 14.759, 49.802]}
        rotation={[Math.PI / 2, 0, -1.124]}
        scale={2.64}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sign7006.geometry}
        material={materials["wood-material"]}
        position={[-3.489, -0.997, 45.373]}
        rotation={[Math.PI / 2, 0, -1.126]}
        scale={9.802}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder007.geometry}
        material={materials["wood-material"]}
        position={[-4.864, 12.861, 48.654]}
        scale={12.379}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder008.geometry}
        material={materials["wood-material"]}
        position={[-2.047, 12.811, 42.63]}
        scale={13.121}
      />
      <group
        position={[5.294, 4.409, -5.436]}
        rotation={[-Math.PI / 2, 0, 2.767]}
        scale={-63.947}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve006_1.geometry}
          material={materials.me}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve006_2.geometry}
          material={materials["Material.081"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve006_3.geometry}
          material={materials["Material.082"]}
        />
      </group>
    </group>
  );
}

function createMatrix(position, rotation, scale, count) {
  const transform = new THREE.Matrix4();
  for (let i = 0; i < count; i++) {
    transform.compose(
      new THREE.Vector3(position[i][0], position[i][1], position[i][2]),
      new THREE.Quaternion().setFromEuler(
        new THREE.Euler(rotation[i][0], rotation[i][1], rotation[i][2])
      ),
      new THREE.Vector3(scale[i][0], scale[i][1], scale[i][2])
    );
  }
  return transform;
}

function createMeshMap(nodes) {
  let meshes = {};
  let count = 1;
  const keys = Object.keys(nodes);
  keys.forEach((key, index) => {
    if (nodes[key].type === "Mesh") {
      meshes[`geo${count}`] = nodes[key];
      count++;
    }
  });
  return meshes;
}

// function createMaterialMap(materials) {
//   let materials
// }

// function StreetLights(props) {
//   // import single StreetLight
//   const { nodes, materials } = useGLTF("./assets/island/props/streetLight.glb");

//   // declare variables for all the geometry in one streetLight
//   const geo1 = nodes.Cube060.geometry.clone();
//   const geo2 = nodes.Cube060_1.geometry.clone();
//   const geo3 = nodes.Cube060_2.geometry.clone();

//   // const meshes = createMeshMap(nodes);

//   // declare variables for all the material in one StreetLight
//   const material1 = materials["Box"];
//   const material2 = materials["Lantern glass"];
//   const material3 = materials["Grey"];

//   // Number of StreetLights required
//   const InstanceBodyCount = streetLightPositions.length || 3;

//   // RigidBody reference
//   const rigidBodies = useRef();

//   // get vertex normals
//   geo1.computeVertexNormals();
//   geo2.computeVertexNormals();
//   geo3.computeVertexNormals();

//   // geometry reference
//   const geo1Ref = useRef();
//   const geo2Ref = useRef();
//   const geo3Ref = useRef();

//   // define instance
//   const instances = useMemo(() => {
//     const instances = [];

//     for (let i = 0; i < InstanceBodyCount; i++) {
//       instances.push({
//         key: "instance_" + Math.random(),
//         position: streetLightPositions[i],
//         rotation: streetLightRotations[i],
//         scale: streetLightScales[i],
//       });
//     }

//     return instances;
//   }, []);

//   // LayoutChangeEffects
//   useLayoutEffect(() => {
//     const transform = new THREE.Matrix4();
//     for (let i = 0; i < InstanceBodyCount; ++i) {
//       transform.compose(
//         new THREE.Vector3(
//           streetLightPositions[i][0],
//           streetLightPositions[i][1],
//           streetLightPositions[i][2]
//         ),
//         new THREE.Quaternion().setFromEuler(
//         new THREE.Euler(
//           streetLightRotations[i][0],
//           streetLightRotations[i][1],
//           streetLightRotations[i][2]
//         )
//         ),
//         new THREE.Vector3(
//           streetLightScales[i],
//           streetLightScales[i],
//           streetLightScales[i],
//         )
//       )
//       geo1Ref.current.setMatrixAt(i, transform);
//       geo2Ref.current.setMatrixAt(i, transform);
//       geo3Ref.current.setMatrixAt(i, transform);
//     }
//   }, []);

//   // Number of Barrels required
//   return (
//     <InstancedRigidBodies
//       ref={rigidBodies}
//       instances={instances}
//       type="fixed"
//       colliders="trimesh"
//     >
//       <group>
//         <instancedMesh
//           ref={geo1Ref}
//           args={[geo1, material1, InstanceBodyCount]}
//         >
//           <meshStandardMaterial attach="Box" />
//         </instancedMesh>
//         <instancedMesh
//           ref={geo2Ref}
//           args={[geo2, material2, InstanceBodyCount]}
//         >
//           <meshStandardMaterial attach="Lantern glass" />
//         </instancedMesh>
//         <instancedMesh
//           ref={geo3Ref}
//           args={[geo3, material3, InstanceBodyCount]}
//         >
//           <meshPhysicalMaterial attach="Grey" />
//         </instancedMesh>
//       </group>
//     </InstancedRigidBodies>
//     // <group>
//     //   <instancedMesh ref={geo1Ref} args={[geo1, material1, InstanceBodyCount]}>
//     //     <meshStandardMaterial attach="Box" />
//     //   </instancedMesh>
//     //   <instancedMesh ref={geo2Ref} args={[geo2, material2, InstanceBodyCount]}>
//     //     <meshStandardMaterial attach="Lantern glass" />
//     //   </instancedMesh>
//     //   <instancedMesh ref={geo3Ref} args={[geo3, material3, InstanceBodyCount]}>
//     //     <meshPhysicalMaterial attach="Grey" />
//     //   </instancedMesh>
//     // </group>
//   );
// }

function StreetLights(props) {
  // import single StreetLight
  const { nodes, materials } = useGLTF(
    "./assets/island/props/streetLights.glb"
  );
  return (
    <group {...props} dispose={null}>
      <group
        position={[-61.47, 1.933, 7.388]}
        rotation={[0, 0.087, 0]}
        scale={32.597}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube060.geometry}
          material={materials.Box}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube060_1.geometry}
          material={materials["Lantern glass"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube060_2.geometry}
          material={materials.Grey}
        />
      </group>
      <group
        position={[12.51, 1.933, 5.479]}
        rotation={[0, 0.087, 0]}
        scale={32.597}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube245.geometry}
          material={materials.Box}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube245_1.geometry}
          material={materials["Lantern glass"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube245_2.geometry}
          material={materials.Grey}
        />
      </group>
      <group
        position={[24.857, 1.933, -35.796]}
        rotation={[-Math.PI, 0.824, -Math.PI]}
        scale={32.597}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube274.geometry}
          material={materials.Box}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube274_1.geometry}
          material={materials["Lantern glass"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube274_2.geometry}
          material={materials.Grey}
        />
      </group>
    </group>
  );
}

function WoodenLogs(props) {
  const { nodes, materials } = useGLTF("./assets/island/props/wooden-logs.glb");
  return (
    <RigidBody type="fixed" colliders="trimesh">
      <group {...props} dispose={null}>
        <group
          position={[-21.145, 1.968, 20.202]}
          rotation={[0, 0.074, 0]}
          scale={0.891}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder097.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder097_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group position={[-21.841, 1.968, 18.058]} rotation={[0, 0.074, 0]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder098.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder098_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group position={[-21.822, 1.968, 19.119]} rotation={[0, 0.074, 0]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder099.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder099_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[-34.547, 4.422, 18.793]}
          rotation={[-0.072, -0.349, 1.96]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder115.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder115_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[-33.854, 3.798, 17.606]}
          rotation={[-0.072, -0.349, 1.96]}
          scale={0.756}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder116.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder116_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[-34.39, 3.294, 17.985]}
          rotation={[-0.072, -0.349, 1.96]}
          scale={0.595}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder117.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder117_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[8.545, 1.813, -22.233]}
          rotation={[0, 0.861, 0]}
          scale={-1}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder092.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder092_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[-50.916, 1.813, 1.138]}
          rotation={[0, 0.861, 0]}
          scale={-1}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder106.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder106_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[7.696, 1.813, -24.698]}
          rotation={[Math.PI, -1.112, Math.PI]}
          scale={[-1, -0.473, -1]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder094.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder094_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[-50.653, 1.813, -4.567]}
          rotation={[Math.PI, -1.112, Math.PI]}
          scale={[-1, -0.473, -1]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder108.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder108_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[-0.071, 1.813, -25.209]}
          rotation={[0, 0.861, 0]}
          scale={[-0.666, -1.223, -0.718]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder093.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder093_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
        <group
          position={[-52.263, 1.813, -1.838]}
          rotation={[0, 0.861, 0]}
          scale={[-0.979, -1.798, -1.055]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder107.geometry}
            material={materials["Material.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder107_1.geometry}
            material={materials["Material.030"]}
          />
        </group>
      </group>
    </RigidBody>
  );
}

export function AdditionalProps(props) {
  return (
    <>
      {/* <Barrels /> */}
      <Bonfire />
      <Fences />
      <Signposts />
      <Tent />
      {/* <StreetLights /> */}
      {/* <WoodenLogs /> */}
      {/* <IslandNameAndLogo /> */}
    </>
  );
}

useGLTF.preload("./assets/island/props/barrels.glb");
useGLTF.preload("./assets/island/props/bonfire.glb");
useGLTF.preload("./assets/island/props/tent.glb");
useGLTF.preload("./assets/island/props/fences.glb");
useGLTF.preload("./assets/island/props/signposts.glb");
useGLTF.preload("./assets/island/props/streetLights.glb");
useGLTF.preload("./assets/island/props/wooden-logs.glb");
useGLTF.preload("./assets/island/props/island-name.glb");
