import React, { useEffect, useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";
import { useFrame } from "@react-three/fiber";

export default function MirrorOfMinds(props) {
  const { nodes, materials, animations } = useGLTF(
    "./assets/island/Mirror-of-Minds.glb"
  );
  // const { actions } = useAnimations(animations);
  // console.log('xvf', actions.CircleAction);

  // useFrame(() => {
  //   actions['CircleAction'].play();
  // })
  return (
    <RigidBody type="fixed" colliders="cuboid">
      <group {...props} dispose={null}>
        <group name="Scene">
          <mesh
            name="Circle"
            castShadow
            receiveShadow
            geometry={nodes.Circle.geometry}
            material={materials["Material.083"]}
            position={[-23.095, 8.877, 33.826]}
            rotation={[0, 0.28, 0]}
            scale={24.675}
          />
          <mesh
            name="Circle001"
            castShadow
            receiveShadow
            geometry={nodes.Circle001.geometry}
            material={materials.SandStone}
            position={[-22.68, 6.193, 41.522]}
            rotation={[0, 0.28, 0]}
            scale={24.675}
          />
          <mesh
            name="Circle002"
            castShadow
            receiveShadow
            geometry={nodes.Circle002.geometry}
            material={materials.SandStone}
            position={[-31.021, 6.193, 37.791]}
            rotation={[0, 0.28, 0]}
            scale={24.675}
          />
          <mesh
            name="Circle003"
            castShadow
            receiveShadow
            geometry={nodes.Circle003.geometry}
            material={materials.SandStone}
            position={[-25.838, 6.193, 25.586]}
            rotation={[0, 0.28, 0]}
            scale={24.675}
          />
          <mesh
            name="Circle004"
            castShadow
            receiveShadow
            geometry={nodes.Circle004.geometry}
            material={materials.SandStone}
            position={[-16.548, 6.193, 29.774]}
            rotation={[0, 0.28, 0]}
            scale={24.675}
          />
          <mesh
            name="Cube011"
            castShadow
            receiveShadow
            geometry={nodes.Cube011.geometry}
            material={materials.Wegweiser1}
            position={[-22.757, 2.649, 41.688]}
            rotation={[0, 0.28, 0]}
            scale={24.675}
          />
          <mesh
            name="Cube012"
            castShadow
            receiveShadow
            geometry={nodes.Cube012.geometry}
            material={nodes.Cube012.material}
            position={[-31.229, 2.649, 37.785]}
            rotation={[0, 0.28, 0]}
            scale={24.675}
          />
          <mesh
            name="Cube013"
            castShadow
            receiveShadow
            geometry={nodes.Cube013.geometry}
            material={materials.Wegweiser2}
            position={[-25.798, 2.649, 25.373]}
            rotation={[0, 0.28, 0]}
            scale={24.675}
          />
          <mesh
            name="Cube014"
            castShadow
            receiveShadow
            geometry={nodes.Cube014.geometry}
            material={materials.Wegweiser1}
            position={[-16.492, 2.649, 29.706]}
            rotation={[0, 0.28, 0]}
            scale={24.675}
          />
          <mesh
            name="Cylinder006"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder006.geometry}
            material={materials["Material.003"]}
            position={[-23.8, 2.293, 33.361]}
            rotation={[0, 0.28, 0]}
            scale={24.675}
          />
          <mesh
            name="Crystal_1_LP001"
            castShadow
            receiveShadow
            geometry={nodes.Crystal_1_LP001.geometry}
            material={materials.Magic_tower}
            position={[-16.565, 5.888, 29.753]}
            scale={24.675}
          />
          <mesh
            name="Crystal_1_LP005"
            castShadow
            receiveShadow
            geometry={nodes.Crystal_1_LP005.geometry}
            material={materials.Magic_tower}
            position={[-25.613, 6.319, 25.282]}
            scale={24.675}
          />
          <mesh
            name="Crystal_1_LP006"
            castShadow
            receiveShadow
            geometry={nodes.Crystal_1_LP006.geometry}
            material={materials.Magic_tower}
            position={[-31.266, 5.912, 37.923]}
            scale={24.675}
          />
          <mesh
            name="Crystal_1_LP007"
            castShadow
            receiveShadow
            geometry={nodes.Crystal_1_LP007.geometry}
            material={materials.Magic_tower}
            position={[-22.578, 6.088, 41.776]}
            scale={24.675}
          />
          <mesh
            name="Circle005"
            castShadow
            receiveShadow
            geometry={nodes.Circle005.geometry}
            material={materials["Material.010"]}
            position={[-23.222, 8.88, 33.714]}
            rotation={[0, 0.28, 0]}
            scale={24.675}
          />
        </group>
      </group>
    </RigidBody>
  );
}

useGLTF.preload("./assets/island/Mirror-of-Minds.glb");
