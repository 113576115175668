import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";
import { useControls } from "leva";

export default function FinalFrontier(props) {
  const { nodes, materials } = useGLTF("./assets/island/Final-Frontier.glb");
  // const directionalLight = useControls('directional light', {
  //   x: {
  //     value: 46,
  //     min: -30,
  //     max: 50,
  //     step: 1,
  //   },
  //   y: {
  //     value: 2,
  //     min: -10,
  //     max: 10,
  //     step: 1,
  //   },
  //   z:{
  //     value: -2,
  //     min: -10,
  //     max: 10,
  //     step: 1,
  //   },
  //   intensity: {
  //     value: 1,
  //     min: -5,
  //     max: 5,
  //     step: 0.5,
  //   }
  // })
  return (
    <>
      <RigidBody type="fixed" colliders="trimesh">
        <group {...props} dispose={null}>
          <mesh
            castShadow={false}
            receiveShadow
            geometry={nodes.Wizard_3.geometry}
            material={materials["default"]}
            position={[50.058, 3.722, -5.932]}
            scale={24.675}
          />
          <mesh
            castShadow={false}
            receiveShadow
            geometry={nodes.Rocks_3_LP006.geometry}
            material={materials.Magic_tower}
            position={[46.636, 3.169, -8.063]}
            scale={24.675}
          />
          <mesh
            castShadow={false}
            receiveShadow
            geometry={nodes.Rock1.geometry}
            material={materials.rock1}
            position={[45.712, 2.965, -11.654]}
            scale={24.675}
          />
          <mesh
            castShadow={false}
            receiveShadow
            geometry={nodes.Rock2.geometry}
            material={materials.rock2}
            position={[55.597, 2.893, -5.362]}
            scale={24.675}
          />
          <mesh
            castShadow={false}
            receiveShadow
            geometry={nodes.Rock3.geometry}
            material={materials.rock3}
            position={[44.7, 2.447, -7.291]}
            scale={24.675}
          />
          <mesh
            castShadow={false}
            receiveShadow
            geometry={nodes.Rock4.geometry}
            material={materials.rock4}
            position={[56.273, 3.427, -9.83]}
            scale={24.675}
          />
          <group
            position={[49.379, 2.2, -3.958]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={3.829}
          >
            <mesh
              castShadow={false}
              receiveShadow
              geometry={nodes.Portal_01_1.geometry}
              material={materials.Entrance_Doorframes}
            />
            <mesh
              castShadow={false}
              receiveShadow
              geometry={nodes.Portal_01_2.geometry}
              material={materials["Material.038"]}
            />
            <mesh
              castShadow={false}
              receiveShadow
              geometry={nodes.Portal_01_3.geometry}
              material={materials["Material.077"]}
            />
            <mesh
              castShadow={false}
              receiveShadow
              geometry={nodes.Portal_01_Hole.geometry}
              material={materials["Fantasy Portal 3D LowPoly Model_Textures"]}
              position={[0.023, 0.058, -1.221]}
            />
          </group>
        </group>
      </RigidBody>
    </>
  );
}

useGLTF.preload("./assets/island/Final-Frontier.glb");
