import prompt from "../components/prompts/prompt"
import generateGoal from "./generateGoal";
// import addInstructions from "./addInstructions";
import generateKeyphrase from "./generateKeyphrase";

export const coinContentDistribution = async (topic, goal, vectorStoreId, UID) => {
    // create a prompt
    // create a keyphrase
    // return keyphrase
    const message = prompt.gamePhraseExtraction(topic, goal);

    let keyphraseChunk = '';
    // generate Keyphrase
    await generateKeyphrase(message, vectorStoreId, UID, (decodedChunk) => {
        keyphraseChunk += decodedChunk;
    });
    console.log('xvf', 'keyphrasechunk', JSON.parse(keyphraseChunk));

    return JSON.parse(keyphraseChunk);
}

export const coinGoalDistribution = async (topic, order, prevGoal, threadId, vectorStoreId, smartGraph, UID) => {
    // create a prompt
    const message = prompt.goalPrompt(topic, order);

    let goalChunk = '';
    await generateGoal(message, `${order}`, prevGoal, threadId, vectorStoreId, smartGraph, UID, (decodedChunk) => {
        goalChunk += decodedChunk;
    });
    console.log('xvf', 'goalchunk', JSON.parse(goalChunk));
    return JSON.parse(goalChunk);
}