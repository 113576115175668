import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Grass(props) {
  const { nodes, materials } = useGLTF(
    "./assets/island/Grass-for-Realm-of-Wind.glb"
  );
  return (
    <group {...props} dispose={null}>
      <group position={[69.614, 2.914, -38.263]} scale={24.675}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube298.geometry}
          material={materials.tree2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube298_1.geometry}
          material={materials["tree 3"]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("./assets/island/Grass-for-Realm-of-Wind.glb");
