import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";

export default function Houses(props) {
  const { nodes, materials } = useGLTF("./assets/suncity/houses.glb");
  return (
    <RigidBody type="fixed" colliders="trimesh">
      <group {...props} dispose={null}>
        <group position={[32.853, 2.278, 79.79]} scale={4.39}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube583.geometry}
            material={materials["Material.037"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube583_1.geometry}
            material={materials["WINDOW.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube583_2.geometry}
            material={materials["Material.036"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube583_3.geometry}
            material={materials["Material.038"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube583_4.geometry}
            material={materials["Material.231"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube583_5.geometry}
            material={materials["Material.199"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube583_6.geometry}
            material={materials["Material.206"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube583_7.geometry}
            material={materials["Material.208"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube583_8.geometry}
            material={materials["Material.229"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube583_9.geometry}
            material={materials["Material.216"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube583_10.geometry}
            material={materials["Material.222"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube583_11.geometry}
            material={materials["Material.230"]}
          />
        </group>
        <group position={[32.853, 2.278, 106.601]} scale={4.39}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube645.geometry}
            material={materials["Material.037"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube645_1.geometry}
            material={materials["WINDOW.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube645_2.geometry}
            material={materials["Material.036"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube645_3.geometry}
            material={materials["Material.038"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube645_4.geometry}
            material={materials["Material.231"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube645_5.geometry}
            material={materials["Material.206"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube645_6.geometry}
            material={materials["Material.208"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube645_7.geometry}
            material={materials["Material.229"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube645_8.geometry}
            material={materials["Material.216"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube645_9.geometry}
            material={materials["Material.222"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube645_10.geometry}
            material={materials["Material.230"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube645_11.geometry}
            material={materials["Material.235"]}
          />
        </group>
        <group
          position={[65.211, 2.278, 106.601]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={4.39}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube646.geometry}
            material={materials["Material.037"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube646_1.geometry}
            material={materials["WINDOW.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube646_2.geometry}
            material={materials["Material.036"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube646_3.geometry}
            material={materials["Material.038"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube646_4.geometry}
            material={materials["Material.231"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube646_5.geometry}
            material={materials["Material.206"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube646_6.geometry}
            material={materials["Material.208"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube646_7.geometry}
            material={materials["Material.229"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube646_8.geometry}
            material={materials["Material.216"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube646_9.geometry}
            material={materials["Material.222"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube646_10.geometry}
            material={materials["Material.230"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube646_11.geometry}
            material={materials["Material.236"]}
          />
        </group>
        <group
          position={[65.211, 2.278, 79.443]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={4.39}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube647.geometry}
            material={materials["Material.037"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube647_1.geometry}
            material={materials["WINDOW.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube647_2.geometry}
            material={materials["Material.036"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube647_3.geometry}
            material={materials["Material.038"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube647_4.geometry}
            material={materials["Material.231"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube647_5.geometry}
            material={materials["Material.206"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube647_6.geometry}
            material={materials["Material.208"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube647_7.geometry}
            material={materials["Material.229"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube647_8.geometry}
            material={materials["Material.216"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube647_9.geometry}
            material={materials["Material.222"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube647_10.geometry}
            material={materials["Material.230"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube647_11.geometry}
            material={materials["Material.237"]}
          />
        </group>
      </group>
    </RigidBody>
  );
}

useGLTF.preload("./assets/suncity/houses.glb");
