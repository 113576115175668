import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";

export default function RealmOfFire(props) {
  const { nodes, materials } = useGLTF("./assets/island/Realm-of-Fire.glb");
  return (
    <RigidBody type="fixed" colliders="trimesh">
      <group {...props} dispose={null}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Columns_LP.geometry}
          material={materials["Platform Bricks"]}
          position={[11.641, 8.047, -61.22]}
          rotation={[0, 1.376, 0]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Floor_LP.geometry}
          material={materials["Material.003"]}
          position={[9.263, 4.573, -61.624]}
          rotation={[0, 1.376, 0]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane_LP.geometry}
          material={materials.Magic_tower}
          position={[9.263, 12.049, -61.624]}
          rotation={[0, 1.376, 0]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Platform_LP.geometry}
          material={materials.Magic_tower}
          position={[9.263, 5.018, -61.624]}
          rotation={[0, 1.376, 0]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Rocks_1_LP.geometry}
          material={materials.Magic_tower}
          position={[9.263, 4.573, -61.624]}
          rotation={[0, 1.376, 0]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Rocks_2_LP.geometry}
          material={materials.Magic_tower}
          position={[9.263, 4.573, -61.624]}
          rotation={[0, 1.376, 0]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Rocks_3_LP.geometry}
          material={materials.Magic_tower}
          position={[9.263, 4.573, -61.624]}
          rotation={[0, 1.376, 0]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Romb_1_LP.geometry}
          material={materials["Material.003"]}
          position={[10.897, 14.012, -62.789]}
          rotation={[0, 1.376, 0]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Romb_1_LP001.geometry}
          material={materials["Material.003"]}
          position={[8.098, 14.012, -63.258]}
          rotation={[0, 1.376, 0]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Romb_1_LP002.geometry}
          material={materials["Material.003"]}
          position={[7.629, 14.012, -60.459]}
          rotation={[0, 1.376, 0]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Romb_broken_LP.geometry}
          material={materials["Material.003"]}
          position={[10.428, 14.012, -59.99]}
          rotation={[0, 1.376, 0]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Stair_LP.geometry}
          material={materials["Material.003"]}
          position={[10.855, 5.385, -59.391]}
          rotation={[0, 1.376, 0]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Wall_inner_1_LP002.geometry}
          material={materials.Magic_tower}
          position={[7.819, 8.978, -60.594]}
          rotation={[0, 1.376, 0]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Wall_outer_1_LP.geometry}
          material={materials.Platform}
          position={[10.707, 8.978, -62.654]}
          rotation={[0, 1.376, 0]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Wall_outer_1_LP001.geometry}
          material={materials["Material.003"]}
          position={[8.233, 8.978, -63.068]}
          rotation={[0, 1.376, 0]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Wall_outer_1_LP002.geometry}
          material={materials.Platform}
          position={[7.819, 8.978, -60.594]}
          rotation={[0, 1.376, 0]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.cup.geometry}
          material={materials["Material.071"]}
          position={[9.126, 15.139, -61.618]}
          rotation={[0, 1.376, 0]}
          scale={24.675}
        />
      </group>
    </RigidBody>
  );
}

useGLTF.preload("./assets/island/Realm-of-Fire.glb");
