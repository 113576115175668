import axios from "axios";

export async function eventToEventStore(
  { actor, verb, value, dataSource },
  token
) {
  const actorToSend = actor;

  const toSend = {
    actor: actorToSend,
    verb,
    value,
    dataSource,
  };

  toSend.dataSource = "school-of-unlearn";

//   await axios.post(
//     `${process.env.VUE_APP_AXIOS_URI}/events/vs_store/update`,
//     toSend,
//     {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     }
//   );
  await axios.post(`${import.meta.env.VITE_ZELDA_URI}/event`, toSend);
}
