import React, { useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { RigidBody } from '@react-three/rapier';
import { useFrame } from "@react-three/fiber";

export default function Metro(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("./assets/suncity/metro.glb");
  const { actions } = useAnimations(animations, group);
  // console.log('xvf', animations, actions)
  useFrame(() => {
    actions["Cube.138Action"].play();
    actions["Cube.139Action"].play();
    actions["Cube.141Action"].play();
    actions["Cube.143Action"].play();
    actions["Cube.144Action"].play();
  })
  return (
    <RigidBody type="fixed" colliders="trimesh">
      <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group
          name="Cube140"
          position={[5.214, 5.033, 88.549]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.084, 0.067, 4.501]}>
          <mesh
            name="Cube025"
            castShadow
            receiveShadow
            geometry={nodes.Cube025.geometry}
            material={materials['Material.070']}
          />
          <mesh
            name="Cube025_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube025_1.geometry}
            material={materials['Material.074']}
          />
          <mesh
            name="Cube025_2"
            castShadow
            receiveShadow
            geometry={nodes.Cube025_2.geometry}
            material={materials['Material.083']}
          />
          <mesh
            name="Cube025_3"
            castShadow
            receiveShadow
            geometry={nodes.Cube025_3.geometry}
            material={materials['Material.173']}
          />
        </group>
        <group name="Cube139" position={[6.013, 6.95, 78.343]} scale={6.18}>
          <mesh
            name="Cube033"
            castShadow
            receiveShadow
            geometry={nodes.Cube033.geometry}
            material={materials['WINDOW.001']}
          />
          <mesh
            name="Cube033_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube033_1.geometry}
            material={materials['Material.178']}
          />
          <mesh
            name="Cube033_2"
            castShadow
            receiveShadow
            geometry={nodes.Cube033_2.geometry}
            material={materials['Material.186']}
          />
          <mesh
            name="Cube033_3"
            castShadow
            receiveShadow
            geometry={nodes.Cube033_3.geometry}
            material={materials['Material.187']}
          />
          <mesh
            name="Cube033_4"
            castShadow
            receiveShadow
            geometry={nodes.Cube033_4.geometry}
            material={materials['Material.190']}
          />
          <mesh
            name="Cube033_5"
            castShadow
            receiveShadow
            geometry={nodes.Cube033_5.geometry}
            material={materials['Material.189']}
          />
          <mesh
            name="Cube033_6"
            castShadow
            receiveShadow
            geometry={nodes.Cube033_6.geometry}
            material={materials['Material.188']}
          />
          <mesh
            name="Cube033_7"
            castShadow
            receiveShadow
            geometry={nodes.Cube033_7.geometry}
            material={materials['Material.191']}
          />
          <mesh
            name="Cube033_8"
            castShadow
            receiveShadow
            geometry={nodes.Cube033_8.geometry}
            material={materials['Material.192']}
          />
          <mesh
            name="Cube033_9"
            castShadow
            receiveShadow
            geometry={nodes.Cube033_9.geometry}
            material={materials['Material.193']}
          />
          <mesh
            name="Cube033_10"
            castShadow
            receiveShadow
            geometry={nodes.Cube033_10.geometry}
            material={materials['Material.194']}
          />
        </group>
        <group name="Cube138" position={[6.022, 6.923, 90.63]} scale={6.18}>
          <mesh
            name="Cube040"
            castShadow
            receiveShadow
            geometry={nodes.Cube040.geometry}
            material={materials['WINDOW.001']}
          />
          <mesh
            name="Cube040_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube040_1.geometry}
            material={materials['Material.178']}
          />
          <mesh
            name="Cube040_2"
            castShadow
            receiveShadow
            geometry={nodes.Cube040_2.geometry}
            material={materials['Material.186']}
          />
          <mesh
            name="Cube040_3"
            castShadow
            receiveShadow
            geometry={nodes.Cube040_3.geometry}
            material={materials['Material.187']}
          />
          <mesh
            name="Cube040_4"
            castShadow
            receiveShadow
            geometry={nodes.Cube040_4.geometry}
            material={materials['Material.190']}
          />
          <mesh
            name="Cube040_5"
            castShadow
            receiveShadow
            geometry={nodes.Cube040_5.geometry}
            material={materials['Material.188']}
          />
          <mesh
            name="Cube040_6"
            castShadow
            receiveShadow
            geometry={nodes.Cube040_6.geometry}
            material={materials['Material.191']}
          />
          <mesh
            name="Cube040_7"
            castShadow
            receiveShadow
            geometry={nodes.Cube040_7.geometry}
            material={materials['Material.192']}
          />
          <mesh
            name="Cube040_8"
            castShadow
            receiveShadow
            geometry={nodes.Cube040_8.geometry}
            material={materials['Material.194']}
          />
        </group>
        <group name="Cube141" position={[6.023, 6.919, 102.205]} scale={6.18}>
          <mesh
            name="Cube042"
            castShadow
            receiveShadow
            geometry={nodes.Cube042.geometry}
            material={materials['WINDOW.001']}
          />
          <mesh
            name="Cube042_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube042_1.geometry}
            material={materials['Material.178']}
          />
          <mesh
            name="Cube042_2"
            castShadow
            receiveShadow
            geometry={nodes.Cube042_2.geometry}
            material={materials['Material.186']}
          />
          <mesh
            name="Cube042_3"
            castShadow
            receiveShadow
            geometry={nodes.Cube042_3.geometry}
            material={materials['Material.187']}
          />
          <mesh
            name="Cube042_4"
            castShadow
            receiveShadow
            geometry={nodes.Cube042_4.geometry}
            material={materials['Material.190']}
          />
          <mesh
            name="Cube042_5"
            castShadow
            receiveShadow
            geometry={nodes.Cube042_5.geometry}
            material={materials['Material.188']}
          />
          <mesh
            name="Cube042_6"
            castShadow
            receiveShadow
            geometry={nodes.Cube042_6.geometry}
            material={materials['Material.191']}
          />
          <mesh
            name="Cube042_7"
            castShadow
            receiveShadow
            geometry={nodes.Cube042_7.geometry}
            material={materials['Material.192']}
          />
          <mesh
            name="Cube042_8"
            castShadow
            receiveShadow
            geometry={nodes.Cube042_8.geometry}
            material={materials['Material.194']}
          />
        </group>
        <group name="Cube143" position={[6.023, 6.919, 113.602]} scale={6.18}>
          <mesh
            name="Cube044"
            castShadow
            receiveShadow
            geometry={nodes.Cube044.geometry}
            material={materials['WINDOW.001']}
          />
          <mesh
            name="Cube044_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube044_1.geometry}
            material={materials['Material.178']}
          />
          <mesh
            name="Cube044_2"
            castShadow
            receiveShadow
            geometry={nodes.Cube044_2.geometry}
            material={materials['Material.186']}
          />
          <mesh
            name="Cube044_3"
            castShadow
            receiveShadow
            geometry={nodes.Cube044_3.geometry}
            material={materials['Material.187']}
          />
          <mesh
            name="Cube044_4"
            castShadow
            receiveShadow
            geometry={nodes.Cube044_4.geometry}
            material={materials['Material.190']}
          />
          <mesh
            name="Cube044_5"
            castShadow
            receiveShadow
            geometry={nodes.Cube044_5.geometry}
            material={materials['Material.188']}
          />
          <mesh
            name="Cube044_6"
            castShadow
            receiveShadow
            geometry={nodes.Cube044_6.geometry}
            material={materials['Material.191']}
          />
          <mesh
            name="Cube044_7"
            castShadow
            receiveShadow
            geometry={nodes.Cube044_7.geometry}
            material={materials['Material.192']}
          />
          <mesh
            name="Cube044_8"
            castShadow
            receiveShadow
            geometry={nodes.Cube044_8.geometry}
            material={materials['Material.194']}
          />
        </group>
        <group name="Cube144" position={[6.023, 6.919, 124.96]} scale={6.18}>
          <mesh
            name="Cube045"
            castShadow
            receiveShadow
            geometry={nodes.Cube045.geometry}
            material={materials['WINDOW.001']}
          />
          <mesh
            name="Cube045_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube045_1.geometry}
            material={materials['Material.178']}
          />
          <mesh
            name="Cube045_2"
            castShadow
            receiveShadow
            geometry={nodes.Cube045_2.geometry}
            material={materials['Material.186']}
          />
          <mesh
            name="Cube045_3"
            castShadow
            receiveShadow
            geometry={nodes.Cube045_3.geometry}
            material={materials['Material.187']}
          />
          <mesh
            name="Cube045_4"
            castShadow
            receiveShadow
            geometry={nodes.Cube045_4.geometry}
            material={materials['Material.190']}
          />
          <mesh
            name="Cube045_5"
            castShadow
            receiveShadow
            geometry={nodes.Cube045_5.geometry}
            material={materials['Material.188']}
          />
          <mesh
            name="Cube045_6"
            castShadow
            receiveShadow
            geometry={nodes.Cube045_6.geometry}
            material={materials['Material.191']}
          />
          <mesh
            name="Cube045_7"
            castShadow
            receiveShadow
            geometry={nodes.Cube045_7.geometry}
            material={materials['Material.192']}
          />
          <mesh
            name="Cube045_8"
            castShadow
            receiveShadow
            geometry={nodes.Cube045_8.geometry}
            material={materials['Material.194']}
          />
        </group>
        <mesh
          name="Plane132"
          castShadow
          receiveShadow
          geometry={nodes.Plane132.geometry}
          material={materials['Material.196']}
          position={[4.105, 5.572, -66.647]}
          scale={0.103}
        />
        <mesh
          name="Plane133"
          castShadow
          receiveShadow
          geometry={nodes.Plane133.geometry}
          material={materials['Material.197']}
          position={[11.418, 5.572, 117.309]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.103}
        />
        <mesh
          name="Plane052"
          castShadow
          receiveShadow
          geometry={nodes.Plane052.geometry}
          material={materials['Material.195']}
          position={[9.414, 4.965, 129.518]}
          scale={[1.178, 1.123, 0.115]}
        />
        <mesh
          name="Plane098"
          castShadow
          receiveShadow
          geometry={nodes.Plane098.geometry}
          material={materials['Material.195']}
          position={[6.1, 4.965, 129.518]}
          scale={[1.178, 1.123, 0.115]}
        />
      </group>
    </group>
    </RigidBody>
  );
}

useGLTF.preload("./assets/suncity/metro.glb");
