import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Sapphire(props) {
  const { nodes, materials } = useGLTF('./assets/inventory/sapphire.glb')
  return (
    <group {...props} dispose={null} scale={0.1}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.crystal_21.geometry}
        material={materials['Material.004']}
        scale={100}
      />
    </group>
  )
}

useGLTF.preload('./assets/inventory/sapphire.glb')