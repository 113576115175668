import React from "react";
import { useGLTF } from "@react-three/drei";
import MainGatewayPortal from "./MainGatewayPortal";
import { RigidBody } from "@react-three/rapier";

export default function MainGateway(props) {
  const { nodes, materials } = useGLTF("./assets/island/Main-Gateway.glb");

  return (
    <>
      <RigidBody type="fixed" colliders="trimesh">
        <group castShadow receiveShadow {...props} dispose={null}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube001.geometry}
            material={materials["Material.004"]}
            position={[-2.909, 1.652, 45.631]}
            scale={34.502}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube002.geometry}
            material={materials["Material.003"]}
            position={[-4.941, 3.496, 50.61]}
            scale={34.502}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube003.geometry}
            material={materials["Material.004"]}
            position={[-4.941, 7.603, 49.744]}
            scale={34.502}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube004.geometry}
            material={materials["Material.004"]}
            position={[-4.941, 5.559, 50.291]}
            scale={34.502}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube005.geometry}
            material={materials["Material.003"]}
            position={[-4.941, 9.702, 49.26]}
            scale={34.502}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube006.geometry}
            material={materials["Material.004"]}
            position={[-4.941, 11.861, 48.706]}
            scale={34.502}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube007.geometry}
            material={materials["Material.004"]}
            position={[-1.328, 9.866, 42.006]}
            scale={34.502}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube008.geometry}
            material={materials["Material.004"]}
            position={[-0.975, 5.537, 41.256]}
            scale={34.502}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube009.geometry}
            material={materials["Material.003"]}
            position={[-1.166, 7.592, 41.645]}
            scale={34.502}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube010.geometry}
            material={materials["Material.003"]}
            position={[-0.823, 3.496, 40.948]}
            scale={34.502}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder.geometry}
            material={nodes.Cylinder.material}
            position={[-0.134, 2.194, 54.3]}
            scale={34.502}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder001.geometry}
            material={nodes.Cylinder001.material}
            position={[4.904, 2.194, 44.142]}
            scale={34.502}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder002.geometry}
            material={nodes.Cylinder002.material}
            position={[1.068, 2.155, 37.477]}
            scale={34.502}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder003.geometry}
            material={nodes.Cylinder003.material}
            position={[-7.43, 2.155, 54.305]}
            scale={34.502}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder004.geometry}
            material={nodes.Cylinder004.material}
            position={[-3.343, 1.939, 54.43]}
            scale={34.502}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder005.geometry}
            material={nodes.Cylinder005.material}
            position={[3.206, 1.939, 40.46]}
            scale={34.502}
          />
        </group>
      </RigidBody>
      {/* <MainGatewayPortal /> */}
    </>
  );
}

useGLTF.preload("./assets/island/Main-Gateway.glb");
