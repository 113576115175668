import { useKeyboardControls } from "@react-three/drei";
import useGame from "../stores/useGame.jsx";
import { useState, useEffect } from "react";
import styled from "styled-components";
import sleep from "../utils/sleep.js";
import { getAllCompletedActivites } from "../utils/CheckActivitCompleted.js";
import useInventory from "../stores/useInventory.jsx";

export default function Interface() {
  const currentActivity = useGame((state) => state.currentActivity);
  const UID = useGame((state) => state.UID);
  const { coins, totalCoins } = useGame((state) => state);
  const { positions, collectedItems, items } = useInventory((state) => state);

  // Get true / false values for the keyboard controls
  const forward = useKeyboardControls((state) => state.forward);
  const backward = useKeyboardControls((state) => state.backward);
  const leftward = useKeyboardControls((state) => state.leftward);
  const rightward = useKeyboardControls((state) => state.rightward);
  const jump = useKeyboardControls((state) => state.jump);
  const run = useKeyboardControls((state) => state.run);
  const fly = useKeyboardControls((state) => state.fly);
  const action4 = useKeyboardControls((state) => state.action4);
  const action3 = useKeyboardControls((state) => state.action3);
  const action2 = useKeyboardControls((state) => state.action2);

  const suggestionList = [
    "Use WASD to move and Spacebar to jump for navigation",
    "Click and drag to move the camera for a full Lumina view",
    "Use camera control to navigate through Lumina's terrain.",
    "Start with slow movements then gradually increase speed for better control",
    "Use smooth single-finger swipes or gentle mouse movements for camera control",
    "Keep the camera focused on objectives while moving for better gameplay",
    "Use wrist movements for precise camera control during aiming or tasks",
    "Make sure your mouse pad area is clear for unrestricted movement",
    "Practice moving and looking simultaneously for effective multitasking",
    "Master smooth camera panning while moving to enhance your game experience",
    "Learn to adjust the camera height for the best strategic view",
    "Practice camera control in stationary settings to build skill",
    "Your mission is to find coins for insights and rewards, enhancing game depth",
    "Collect coins to unlock learning modules, adding to game progression",
    "Press Esc to regain cursor control, essential for learning interface navigation",
    "Respawn at the last visited realm if you fall into water, adding to game strategy",
    "Press T for guidance from Elysia",
    "Seek next steps from Elysia with inquiries like What is this game about?",
    "Enable microphone in browser settings for interactive gameplay with Elysia",
    "Inquire about course details from Elysia by pressing T, for immersive gameplay",
    "Explore Lumina's vast realms by mastering movement and jump controls",
    "Seek next steps from Elysia with inquiries like How do I play this game?",
    "Discover Lumina's wonders from all angles by mastering camera controls",
    "Embark on your quest by gathering coins for wisdom, adding a goal to the game",
    "Unlock learning and return to adventure by pressing X, blending learning with gameplay",
    "Hit Esc to reclaim control of a vanished cursor, ensuring smooth gameplay",
    "Dare the waters? Resurface at the last explored realm, adding challenge",
    "Initiate a chat with Elysia by pressing T for personalized guidance",
    "Enable mic in browser settings for a deeper conversation with Elysia",
    "Seek next steps from Elysia with inquiries like I have taken 3 coins what do I do now?",
    "Collect coins and press X to return to game mode",
  ];

  const [currentSuggestion, setSuggestion] = useState(0);

  useEffect(() => {
    let timer;
    timer = setInterval(() => {
      if (currentSuggestion <= 3) {
        setSuggestion((currentSuggestion) => currentSuggestion + 1);
      } else {
        setSuggestion(0);
      }
    }, 10000);
    return () => clearInterval(timer);
  }, [currentSuggestion]);

  const styles = {
    fontFamily: "monospace",
  };

  const BlurBackground = styled.div`
    backdrop-filter: blur(4px);
    background: #00000080;
    font-weight: bold;
    padding: 4px;
    border-radius: 5px;
    color: #fff;
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */
  `;

  const Item = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .25rem;
  `;

  const Score = styled.span`
    margin-right: .5rem;
    font-size: 1.4rem;
  `
  const countCollectedItems = () => {
    const counts = items.reduce((acc, item) => {
      acc[item] = collectedItems.filter((collectedItem) => collectedItem === item).length;
      return acc;
    }, {});
    return counts;
  };

  const calculateItemTotals = () => {
    let acc = {};
    items.forEach((item) => acc[item] = 0);
    positions.forEach((position) => {
      acc[items[position.type]] += 1;
    });
    return acc;
  };

  const collectedItemCounts = countCollectedItems();
  const itemTotals = calculateItemTotals();

  return (
    <>
      <div className="mobileInterface">
        <div className="flex justify-end pr-2 pt-2">
          <BlurBackground>
            {items.map((item, index) => (
              <Item key={index} className="pl-2">
                <Score>{collectedItemCounts[item] || 0} / {itemTotals[item] || 0}</Score>
                <img className="w-6" src={`./assets/images/${item}.png`} alt={item} />
              </Item>
            ))}
          </BlurBackground>
        </div>
      </div>
      <div className="interface">
        {/* Controls */}
        <div className="controls">
          <div className="raw">
            <div className={`key ${forward ? "active" : ""}`}>
              <p>
                W <i className="bx bx-up-arrow-alt"></i>
              </p>
            </div>
          </div>
          <div className="raw">
            <div className={`key ${leftward ? "active" : ""}`}>
              <p>
                <i className="bx bx-left-arrow-alt"></i>
                <span>A</span>{" "}
              </p>
            </div>
            <div className={`key ${backward ? "active" : ""} `}>
              <p>
                S <i className="bx bx-down-arrow-alt"></i>
              </p>
            </div>
            <div className={`key ${rightward ? "active" : ""} `}>
              <p>
                D <i className="bx bx-right-arrow-alt"></i>
              </p>
            </div>
          </div>
          <div className="raw">
            <div className={`key large ${jump ? "active" : ""} `}>
              <p>
                <span>Space (Jump)</span> <i className="bx bx-space-bar"></i>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="suggestion-box">
        <p className="suggestion-box__text">
          {suggestionList[currentSuggestion]}
        </p>
      </div>
    </>
  );
}
