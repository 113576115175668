import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";

export default function Bushes(props) {
  const { nodes, materials } = useGLTF("./assets/suncity/bushes.glb");
  return (
    <RigidBody type="fixed" colliders="trimesh">
      <group {...props} dispose={null}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Bush_average046.geometry}
          material={materials["Material.038"]}
          position={[28.323, -1.337, -63.275]}
          scale={0.344}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Bush_average058.geometry}
          material={materials["Material.038"]}
          position={[64.284, -1.337, 70.947]}
          scale={0.344}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Bush_average059.geometry}
          material={materials["Material.038"]}
          position={[59.699, -1.337, 84.701]}
          scale={0.344}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Bush_average060.geometry}
          material={materials["Material.038"]}
          position={[67.634, -1.337, 86.993]}
          scale={0.344}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Bush_average072.geometry}
          material={materials["Material.038"]}
          position={[33.78, -1.337, 116.615]}
          scale={0.344}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Bush_average073.geometry}
          material={materials["Material.038"]}
          position={[23.2, -1.337, 105.154]}
          scale={0.344}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Bush_average074.geometry}
          material={materials["Material.038"]}
          position={[22.847, -1.337, 89.638]}
          scale={0.344}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Bush_average075.geometry}
          material={materials["Material.038"]}
          position={[23.553, -1.337, 79.763]}
          scale={0.344}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Bush_average076.geometry}
          material={materials["Material.038"]}
          position={[30.077, -1.337, 72.358]}
          scale={0.344}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Bush_average077.geometry}
          material={materials["Material.038"]}
          position={[37.306, -1.337, 73.063]}
          scale={0.344}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Bush_average078.geometry}
          material={materials["Material.038"]}
          position={[39.422, -1.337, 77.824]}
          scale={0.344}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Bush_average079.geometry}
          material={materials["Material.038"]}
          position={[39.422, -1.337, 82.408]}
          scale={0.344}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Bush_average080.geometry}
          material={materials["Material.038"]}
          position={[59.347, -1.337, 75.708]}
          scale={0.344}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Bush_group_big028.geometry}
          material={materials["Material.038"]}
          position={[-68.017, -1.282, -30.737]}
          scale={0.344}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Bush_group_big036.geometry}
          material={materials["Material.038"]}
          position={[36.056, -1.282, 115.757]}
          scale={0.344}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Bush_group_big037.geometry}
          material={materials["Material.038"]}
          position={[35.161, -1.282, 88.235]}
          scale={0.344}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Bush_group_big038.geometry}
          material={materials["Material.038"]}
          position={[61.117, -1.282, 71.229]}
          scale={0.344}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Bush_group_big039.geometry}
          material={materials["Material.038"]}
          position={[57.761, -1.282, 81.298]}
          scale={0.344}
        />
      </group>
    </RigidBody>
  );
}

useGLTF.preload("./assets/suncity/bushes.glb");
