// src/useAudioPlayer.js
import { create } from 'zustand';

const useAudioPlayer = create((set) => ({
  isPlaying: false,
  audioSrc: '',
  playbackRate: 1.0,
  play: () => set({ isPlaying: true }),
  pause: () => set({ isPlaying: false }),
  setAudioSrc: (src) => set({ audioSrc: src }),
  increasePlaybackRate: () => set((state) => ({ playbackRate: state.playbackRate + 0.1 })),
  decreasePlaybackRate: () => set((state) => ({ playbackRate: state.playbackRate - 0.1 })),
  setVolume: (volume) => set({ volume: Math.max(0, Math.min(volume, 1)) }),
}));

export default useAudioPlayer;