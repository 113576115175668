import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";

function Bridge(props) {
  const { nodes, materials } = useGLTF("./assets/island/bridge.glb");
  const { position, scale } = props;
  return (
    <>
    {/* <RigidBody type="fixed" colliders="trimesh">
      <group {...props} dispose={null}>
      <group
        position={[-29.77, -0.421, -17.207]}
        rotation={[0, -0.44, 0]}
        scale={[37.359, 24.675, 27.986]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube059.geometry}
          material={materials.Box}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube059_1.geometry}
          material={materials.Grey}
        />
        </group>
      <group position={[10.796, -0.465, 51.74]} scale={24.675}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube065.geometry}
          material={materials["Box.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube065_1.geometry}
          material={materials["Grey.001"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube057.geometry}
        material={materials["Box.003"]}
        position={[-30.345, 2.986, -17.512]}
        rotation={[0, -0.402, 0]}
        scale={[26.871, 24.675, 35.566]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube147.geometry}
        material={materials["Box.003"]}
        position={[-37.755, 2.434, -21.07]}
        rotation={[0, -0.402, 0]}
        scale={[34.33, 24.675, 35.566]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube161.geometry}
        material={materials["Box.003"]}
        position={[-35.348, 2.486, -20.181]}
        rotation={[0, -0.402, 0]}
        scale={[34.33, 24.675, 35.566]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube162.geometry}
        material={materials["Box.003"]}
        position={[5.652, 2.325, 49.315]}
        rotation={[0.077, -0.395, 0.198]}
        scale={[34.33, 24.675, 35.566]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube163.geometry}
        material={materials["Box.003"]}
        position={[16.841, 1.785, 54.221]}
        rotation={[0, -0.402, 0]}
        scale={[34.33, 24.675, 35.566]}
      />
    </group>
      </RigidBody> */}
      {/* Bridge - 1 */}
      <RigidBody type="fixed" colliders="cuboid">
      <group
        position={[-28.77, 0.9, -17.207]}
        rotation={[0, -0.44, 0]}
        scale={[37.359, 6, 27.986]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube059.geometry}
          material={materials.Box}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube059_1.geometry}
          material={materials.Grey}
        />
        </group>
      </RigidBody>
      {/* Bridge - 2 */}
      <RigidBody type="fixed" colliders="cuboid">
      <group
        position={[9.796, 0.9, 50.74]}
        rotation={[0, -0.44, 0]}
        scale={[27.359, 6, 20.986]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube059.geometry}
          material={materials.Box}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube059_1.geometry}
          material={materials.Grey}
        />
        </group>
      </RigidBody>
      </>
  );
}

export default function Bridges(props) {
  return (
    <>
      <Bridge />
    </>
  );
}

useGLTF.preload("./assets/island/bridge.glb");
