import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";

export default function CafeSitting(props) {
  const { nodes, materials } = useGLTF("./assets/suncity/seating.glb");
  return (
    // <RigidBody>
      <group {...props} dispose={null}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube133.geometry}
          material={materials["Material.228"]}
          position={[33.941, 1.252, 27.907]}
          scale={[0.009, 0.265, 0.025]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube160.geometry}
          material={materials["Material.228"]}
          position={[33.941, 1.252, 27.982]}
          scale={[0.009, 0.265, 0.025]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube161.geometry}
          material={materials["Material.228"]}
          position={[33.941, 1.252, 11.68]}
          scale={[0.009, 0.265, 0.025]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube162.geometry}
          material={materials["Material.228"]}
          position={[33.941, 1.252, 11.754]}
          scale={[0.009, 0.265, 0.025]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube163.geometry}
          material={materials["Material.228"]}
          position={[33.941, 1.252, -4.042]}
          scale={[0.009, 0.265, 0.025]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube164.geometry}
          material={materials["Material.228"]}
          position={[33.941, 1.252, -3.967]}
          scale={[0.009, 0.265, 0.025]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube167.geometry}
          material={materials["Material.228"]}
          position={[33.941, 1.252, -35.518]}
          scale={[0.009, 0.265, 0.025]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube168.geometry}
          material={materials["Material.228"]}
          position={[33.941, 1.252, -35.443]}
          scale={[0.009, 0.265, 0.025]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube171.geometry}
          material={materials["Material.228"]}
          position={[40.145, 1.175, -59.221]}
          rotation={[0, 1.571, 0]}
          scale={[0.009, 0.265, 0.025]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube172.geometry}
          material={materials["Material.228"]}
          position={[40.22, 1.175, -59.221]}
          rotation={[0, 1.571, 0]}
          scale={[0.009, 0.265, 0.025]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube173.geometry}
          material={materials["Material.228"]}
          position={[41.261, 1.049, 35.506]}
          rotation={[0, 1.571, 0]}
          scale={[0.009, 0.265, 0.025]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube174.geometry}
          material={materials["Material.228"]}
          position={[41.336, 1.049, 35.506]}
          rotation={[0, 1.571, 0]}
          scale={[0.009, 0.265, 0.025]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane062.geometry}
          material={materials["Material.089"]}
          position={[42.83, 0.236, -60.621]}
          rotation={[0, 1.571, 0]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube175.geometry}
          material={materials["Material.089"]}
          position={[42.506, -0.554, -61.764]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube176.geometry}
          material={materials["Material.089"]}
          position={[41.75, -0.554, -60.296]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube177.geometry}
          material={materials["Material.089"]}
          position={[43.928, -0.554, -60.963]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube178.geometry}
          material={materials["Material.089"]}
          position={[44.717, 3.169, -54.532]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[11.038, 4.144, 5.163]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane141.geometry}
          material={materials["Material.089"]}
          position={[44.884, 0.236, -60.621]}
          rotation={[0, 1.571, 0]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube179.geometry}
          material={materials["Material.089"]}
          position={[44.56, -0.554, -61.764]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube192.geometry}
          material={materials["Material.089"]}
          position={[45.983, -0.554, -60.963]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane142.geometry}
          material={materials["Material.089"]}
          position={[38.276, 0.236, -60.621]}
          rotation={[0, 1.571, 0]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube193.geometry}
          material={materials["Material.089"]}
          position={[37.952, -0.554, -61.764]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube194.geometry}
          material={materials["Material.089"]}
          position={[37.196, -0.554, -60.296]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube195.geometry}
          material={materials["Material.089"]}
          position={[39.375, -0.554, -60.963]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube196.geometry}
          material={materials["Material.089"]}
          position={[40.163, 3.169, -54.532]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[11.038, 4.144, 5.163]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane144.geometry}
          material={materials["Material.089"]}
          position={[40.33, 0.236, -60.621]}
          rotation={[0, 1.571, 0]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube197.geometry}
          material={materials["Material.089"]}
          position={[40.006, -0.554, -61.764]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube198.geometry}
          material={materials["Material.089"]}
          position={[41.429, -0.554, -60.963]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane145.geometry}
          material={materials["Material.089"]}
          position={[33.794, 0.236, -60.621]}
          rotation={[0, 1.571, 0]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube199.geometry}
          material={materials["Material.089"]}
          position={[33.47, -0.554, -61.764]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube200.geometry}
          material={materials["Material.089"]}
          position={[32.713, -0.554, -60.296]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube201.geometry}
          material={materials["Material.089"]}
          position={[34.892, -0.554, -60.963]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube202.geometry}
          material={materials["Material.089"]}
          position={[35.681, 3.169, -54.532]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[11.038, 4.144, 5.163]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane154.geometry}
          material={materials["Material.089"]}
          position={[35.848, 0.236, -60.621]}
          rotation={[0, 1.571, 0]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube203.geometry}
          material={materials["Material.089"]}
          position={[35.524, -0.554, -61.764]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube204.geometry}
          material={materials["Material.089"]}
          position={[36.946, -0.554, -60.963]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube206.geometry}
          material={materials["Material.089"]}
          position={[32.232, -0.554, -56.656]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube208.geometry}
          material={materials["Material.089"]}
          position={[37.997, 3.169, -57.761]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[11.038, 4.144, 5.163]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane156.geometry}
          material={materials["Material.089"]}
          position={[31.907, 0.236, -57.928]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube209.geometry}
          material={materials["Material.089"]}
          position={[30.764, -0.554, -57.604]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube210.geometry}
          material={materials["Material.089"]}
          position={[31.566, -0.554, -59.027]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube205.geometry}
          material={materials["Material.089"]}
          position={[32.869, -0.554, -58.286]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube207.geometry}
          material={materials["Material.089"]}
          position={[32.232, -0.554, -52.985]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube211.geometry}
          material={materials["Material.089"]}
          position={[37.997, 3.169, -54.091]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[11.038, 4.144, 5.163]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane155.geometry}
          material={materials["Material.089"]}
          position={[31.907, 0.236, -54.258]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube212.geometry}
          material={materials["Material.089"]}
          position={[30.764, -0.554, -53.934]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube213.geometry}
          material={materials["Material.089"]}
          position={[31.566, -0.554, -55.356]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube214.geometry}
          material={materials["Material.089"]}
          position={[32.869, -0.554, -54.615]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube215.geometry}
          material={materials["Material.089"]}
          position={[32.232, -0.554, -46.923]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube216.geometry}
          material={materials["Material.089"]}
          position={[37.997, 3.169, -48.028]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[11.038, 4.144, 5.163]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane157.geometry}
          material={materials["Material.089"]}
          position={[31.907, 0.236, -48.195]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube217.geometry}
          material={materials["Material.089"]}
          position={[30.764, -0.554, -47.871]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube218.geometry}
          material={materials["Material.089"]}
          position={[31.566, -0.554, -49.294]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube219.geometry}
          material={materials["Material.089"]}
          position={[32.869, -0.554, -48.552]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube220.geometry}
          material={materials["Material.089"]}
          position={[29.562, -0.554, -46.923]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube221.geometry}
          material={materials["Material.089"]}
          position={[35.327, 3.169, -48.028]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[11.038, 4.144, 5.163]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane158.geometry}
          material={materials["Material.089"]}
          position={[29.237, 0.236, -48.195]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube222.geometry}
          material={materials["Material.089"]}
          position={[28.095, -0.554, -47.871]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube223.geometry}
          material={materials["Material.089"]}
          position={[28.896, -0.554, -49.294]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube224.geometry}
          material={materials["Material.089"]}
          position={[30.199, -0.554, -48.552]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube225.geometry}
          material={materials["Material.089"]}
          position={[27.617, -0.554, -51.7]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube226.geometry}
          material={materials["Material.089"]}
          position={[33.842, 3.169, -55.703]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[11.038, 4.144, 5.163]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane159.geometry}
          material={materials["Material.089"]}
          position={[27.292, 0.236, -52.972]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube227.geometry}
          material={materials["Material.089"]}
          position={[26.149, -0.554, -52.648]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube228.geometry}
          material={materials["Material.089"]}
          position={[26.951, -0.554, -54.071]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube229.geometry}
          material={materials["Material.089"]}
          position={[28.254, -0.554, -53.33]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube230.geometry}
          material={materials["Material.089"]}
          position={[27.94, -0.554, -59.248]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube231.geometry}
          material={materials["Material.089"]}
          position={[33.705, 3.169, -60.353]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[11.038, 4.144, 5.163]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane160.geometry}
          material={materials["Material.089"]}
          position={[27.615, 0.236, -60.52]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube232.geometry}
          material={materials["Material.089"]}
          position={[26.473, -0.554, -60.196]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube233.geometry}
          material={materials["Material.089"]}
          position={[27.274, -0.554, -61.619]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube234.geometry}
          material={materials["Material.089"]}
          position={[28.578, -0.554, -60.878]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube235.geometry}
          material={materials["Material.089"]}
          position={[27.555, -0.554, -54.497]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane161.geometry}
          material={materials["Material.089"]}
          position={[27.23, 0.236, -55.769]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube236.geometry}
          material={materials["Material.089"]}
          position={[26.087, -0.554, -55.445]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube237.geometry}
          material={materials["Material.089"]}
          position={[26.889, -0.554, -56.868]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube238.geometry}
          material={materials["Material.089"]}
          position={[28.192, -0.554, -56.127]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube239.geometry}
          material={materials["Material.089"]}
          position={[29.562, -0.554, -44.716]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane162.geometry}
          material={materials["Material.089"]}
          position={[29.237, 0.236, -45.988]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube240.geometry}
          material={materials["Material.089"]}
          position={[28.095, -0.554, -45.664]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube241.geometry}
          material={materials["Material.089"]}
          position={[30.199, -0.554, -46.346]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube242.geometry}
          material={materials["Material.089"]}
          position={[32.026, -0.554, -44.716]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane163.geometry}
          material={materials["Material.089"]}
          position={[31.701, 0.236, -45.988]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube243.geometry}
          material={materials["Material.089"]}
          position={[30.558, -0.554, -45.664]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube244.geometry}
          material={materials["Material.089"]}
          position={[32.663, -0.554, -46.346]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube245.geometry}
          material={materials["Material.089"]}
          position={[38.167, 3.169, -61.357]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[11.038, 4.144, 5.163]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane172.geometry}
          material={materials["Material.024"]}
          position={[32.993, 0.236, -29.932]}
          rotation={[0, Math.PI / 2, 0]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube269.geometry}
          material={materials["Material.131"]}
          position={[28.237, -0.554, -24.782]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube270.geometry}
          material={materials["Material.131"]}
          position={[34.537, 3.169, -25.888]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[11.038, 4.144, 5.163]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane177.geometry}
          material={materials["Material.131"]}
          position={[27.912, 0.236, -26.055]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube271.geometry}
          material={materials["Material.131"]}
          position={[26.769, -0.554, -25.731]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube272.geometry}
          material={materials["Material.131"]}
          position={[27.57, -0.554, -27.153]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube273.geometry}
          material={materials["Material.131"]}
          position={[28.874, -0.554, -26.412]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube274.geometry}
          material={materials["Material.131"]}
          position={[28.243, -0.554, -22.575]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane178.geometry}
          material={materials["Material.131"]}
          position={[27.918, 0.236, -23.848]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube275.geometry}
          material={materials["Material.131"]}
          position={[26.775, -0.554, -23.524]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube276.geometry}
          material={materials["Material.131"]}
          position={[28.88, -0.554, -24.205]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube277.geometry}
          material={materials["Material.131"]}
          position={[32.145, -0.554, -24.782]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube278.geometry}
          material={materials["Material.131"]}
          position={[37.91, 3.169, -25.888]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[11.038, 4.144, 5.163]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane179.geometry}
          material={materials["Material.131"]}
          position={[31.82, 0.236, -26.055]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube279.geometry}
          material={materials["Material.131"]}
          position={[30.677, -0.554, -25.731]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube280.geometry}
          material={materials["Material.131"]}
          position={[31.479, -0.554, -27.153]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube281.geometry}
          material={materials["Material.131"]}
          position={[32.782, -0.554, -26.412]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube282.geometry}
          material={materials["Material.131"]}
          position={[32.145, -0.554, -22.575]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane180.geometry}
          material={materials["Material.131"]}
          position={[31.82, 0.236, -23.848]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube283.geometry}
          material={materials["Material.131"]}
          position={[30.677, -0.554, -23.524]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube284.geometry}
          material={materials["Material.131"]}
          position={[32.782, -0.554, -24.205]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube285.geometry}
          material={materials["Material.131"]}
          position={[32.145, -0.554, -15.363]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube286.geometry}
          material={materials["Material.131"]}
          position={[37.91, 3.169, -16.469]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[11.038, 4.144, 5.163]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane181.geometry}
          material={materials["Material.131"]}
          position={[31.82, 0.236, -16.636]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube287.geometry}
          material={materials["Material.131"]}
          position={[30.677, -0.554, -16.312]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube288.geometry}
          material={materials["Material.131"]}
          position={[31.479, -0.554, -17.734]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube289.geometry}
          material={materials["Material.131"]}
          position={[32.782, -0.554, -16.993]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube290.geometry}
          material={materials["Material.131"]}
          position={[32.145, -0.554, -13.157]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane182.geometry}
          material={materials["Material.131"]}
          position={[31.82, 0.236, -14.429]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube291.geometry}
          material={materials["Material.131"]}
          position={[30.677, -0.554, -14.105]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube292.geometry}
          material={materials["Material.131"]}
          position={[32.782, -0.554, -14.786]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube293.geometry}
          material={materials["Material.131"]}
          position={[29.592, -0.554, -15.363]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube294.geometry}
          material={materials["Material.131"]}
          position={[35.357, 3.169, -16.469]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[11.038, 4.144, 5.163]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane183.geometry}
          material={materials["Material.131"]}
          position={[29.267, 0.236, -16.636]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube295.geometry}
          material={materials["Material.131"]}
          position={[28.124, -0.554, -16.312]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube296.geometry}
          material={materials["Material.131"]}
          position={[28.926, -0.554, -17.734]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube297.geometry}
          material={materials["Material.131"]}
          position={[30.229, -0.554, -16.993]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube298.geometry}
          material={materials["Material.131"]}
          position={[29.592, -0.554, -13.157]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane185.geometry}
          material={materials["Material.131"]}
          position={[29.267, 0.236, -14.429]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube299.geometry}
          material={materials["Material.131"]}
          position={[28.124, -0.554, -14.105]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube300.geometry}
          material={materials["Material.131"]}
          position={[30.229, -0.554, -14.786]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube301.geometry}
          material={materials["Material.089"]}
          position={[24.441, -0.554, -59.248]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube302.geometry}
          material={materials["Material.089"]}
          position={[30.899, 3.169, -59.978]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[11.038, 4.144, 5.163]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane187.geometry}
          material={materials["Material.089"]}
          position={[24.116, 0.236, -60.52]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube303.geometry}
          material={materials["Material.089"]}
          position={[22.974, -0.554, -60.196]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube304.geometry}
          material={materials["Material.089"]}
          position={[23.775, -0.554, -61.619]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube305.geometry}
          material={materials["Material.089"]}
          position={[25.078, -0.554, -60.878]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube306.geometry}
          material={materials["Material.089"]}
          position={[24.646, -0.554, -51.7]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube307.geometry}
          material={materials["Material.089"]}
          position={[30.872, 3.169, -55.703]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[11.038, 4.144, 5.163]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane188.geometry}
          material={materials["Material.089"]}
          position={[24.321, 0.236, -52.972]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube308.geometry}
          material={materials["Material.089"]}
          position={[23.179, -0.554, -52.648]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube309.geometry}
          material={materials["Material.089"]}
          position={[23.98, -0.554, -54.071]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube310.geometry}
          material={materials["Material.089"]}
          position={[25.283, -0.554, -53.33]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube311.geometry}
          material={materials["Material.089"]}
          position={[24.584, -0.554, -54.497]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane189.geometry}
          material={materials["Material.089"]}
          position={[24.259, 0.236, -55.769]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube312.geometry}
          material={materials["Material.089"]}
          position={[23.117, -0.554, -55.445]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube313.geometry}
          material={materials["Material.089"]}
          position={[23.918, -0.554, -56.868]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube314.geometry}
          material={materials["Material.089"]}
          position={[25.221, -0.554, -56.127]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube315.geometry}
          material={materials["Material.089"]}
          position={[24.957, -0.554, -44.2]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube316.geometry}
          material={materials["Material.089"]}
          position={[31.183, 3.169, -48.204]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[11.038, 4.144, 5.163]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane190.geometry}
          material={materials["Material.089"]}
          position={[24.632, 0.236, -45.473]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube317.geometry}
          material={materials["Material.089"]}
          position={[23.49, -0.554, -45.149]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube318.geometry}
          material={materials["Material.089"]}
          position={[24.291, -0.554, -46.571]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube319.geometry}
          material={materials["Material.089"]}
          position={[25.594, -0.554, -45.83]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube320.geometry}
          material={materials["Material.089"]}
          position={[24.895, -0.554, -46.997]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane191.geometry}
          material={materials["Material.089"]}
          position={[24.57, 0.236, -48.27]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube321.geometry}
          material={materials["Material.089"]}
          position={[23.428, -0.554, -47.946]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube322.geometry}
          material={materials["Material.089"]}
          position={[24.229, -0.554, -49.368]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube323.geometry}
          material={materials["Material.089"]}
          position={[25.532, -0.554, -48.627]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube344.geometry}
          material={materials["Material.131"]}
          position={[24.662, -0.554, -24.782]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube345.geometry}
          material={materials["Material.131"]}
          position={[30.962, 3.169, -25.888]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[11.038, 4.144, 5.163]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane202.geometry}
          material={materials["Material.131"]}
          position={[24.337, 0.236, -26.055]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube346.geometry}
          material={materials["Material.131"]}
          position={[23.195, -0.554, -25.731]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube347.geometry}
          material={materials["Material.131"]}
          position={[23.996, -0.554, -27.153]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube348.geometry}
          material={materials["Material.131"]}
          position={[25.299, -0.554, -26.412]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube349.geometry}
          material={materials["Material.131"]}
          position={[24.668, -0.554, -22.575]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane203.geometry}
          material={materials["Material.131"]}
          position={[24.343, 0.236, -23.848]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube350.geometry}
          material={materials["Material.131"]}
          position={[23.201, -0.554, -23.524]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube351.geometry}
          material={materials["Material.131"]}
          position={[25.305, -0.554, -24.205]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube352.geometry}
          material={materials["Material.131"]}
          position={[26.245, -0.554, -15.129]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube353.geometry}
          material={materials["Material.131"]}
          position={[32.545, 3.169, -16.234]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[11.038, 4.144, 5.163]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane204.geometry}
          material={materials["Material.131"]}
          position={[25.92, 0.236, -16.401]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube354.geometry}
          material={materials["Material.131"]}
          position={[24.778, -0.554, -16.077]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube355.geometry}
          material={materials["Material.131"]}
          position={[25.579, -0.554, -17.5]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube356.geometry}
          material={materials["Material.131"]}
          position={[26.882, -0.554, -16.758]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube357.geometry}
          material={materials["Material.131"]}
          position={[26.251, -0.554, -12.922]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane205.geometry}
          material={materials["Material.131"]}
          position={[25.926, 0.236, -14.194]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube358.geometry}
          material={materials["Material.131"]}
          position={[24.784, -0.554, -13.87]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube359.geometry}
          material={materials["Material.131"]}
          position={[26.888, -0.554, -14.551]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube368.geometry}
          material={materials["Material.131"]}
          position={[32.413, -0.554, -9.486]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube369.geometry}
          material={materials["Material.131"]}
          position={[38.713, 3.169, -10.592]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[11.038, 4.144, 5.163]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane208.geometry}
          material={materials["Material.131"]}
          position={[32.088, 0.236, -10.759]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube370.geometry}
          material={materials["Material.131"]}
          position={[30.946, -0.554, -10.435]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube371.geometry}
          material={materials["Material.131"]}
          position={[31.747, -0.554, -11.857]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube372.geometry}
          material={materials["Material.131"]}
          position={[33.05, -0.554, -11.116]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube373.geometry}
          material={materials["Material.131"]}
          position={[32.419, -0.554, -7.28]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane209.geometry}
          material={materials["Material.131"]}
          position={[32.095, 0.236, -8.552]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube374.geometry}
          material={materials["Material.131"]}
          position={[30.952, -0.554, -8.228]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube375.geometry}
          material={materials["Material.131"]}
          position={[33.057, -0.554, -8.909]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube376.geometry}
          material={materials["Material.131"]}
          position={[29.408, -0.554, -9.486]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube377.geometry}
          material={materials["Material.131"]}
          position={[35.708, 3.169, -10.592]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[11.038, 4.144, 5.163]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane210.geometry}
          material={materials["Material.131"]}
          position={[29.083, 0.236, -10.759]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube378.geometry}
          material={materials["Material.131"]}
          position={[27.941, -0.554, -10.435]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube379.geometry}
          material={materials["Material.131"]}
          position={[28.742, -0.554, -11.857]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube380.geometry}
          material={materials["Material.131"]}
          position={[30.046, -0.554, -11.116]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube381.geometry}
          material={materials["Material.131"]}
          position={[29.414, -0.554, -7.28]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane211.geometry}
          material={materials["Material.131"]}
          position={[29.09, 0.236, -8.552]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube382.geometry}
          material={materials["Material.131"]}
          position={[27.947, -0.554, -8.228]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube383.geometry}
          material={materials["Material.131"]}
          position={[30.052, -0.554, -8.909]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube384.geometry}
          material={materials["Material.131"]}
          position={[26.401, -0.554, -9.486]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube385.geometry}
          material={materials["Material.131"]}
          position={[32.701, 3.169, -10.592]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[11.038, 4.144, 5.163]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane212.geometry}
          material={materials["Material.131"]}
          position={[26.076, 0.236, -10.759]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube386.geometry}
          material={materials["Material.131"]}
          position={[24.933, -0.554, -10.435]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube387.geometry}
          material={materials["Material.131"]}
          position={[25.735, -0.554, -11.857]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube388.geometry}
          material={materials["Material.131"]}
          position={[27.038, -0.554, -11.116]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube389.geometry}
          material={materials["Material.131"]}
          position={[26.407, -0.554, -7.28]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane213.geometry}
          material={materials["Material.131"]}
          position={[26.082, 0.236, -8.552]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube390.geometry}
          material={materials["Material.131"]}
          position={[24.94, -0.554, -8.228]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube391.geometry}
          material={materials["Material.131"]}
          position={[27.044, -0.554, -8.909]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube400.geometry}
          material={materials["Material.131"]}
          position={[24.579, -0.554, -6.096]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube401.geometry}
          material={materials["Material.131"]}
          position={[23.474, 3.169, -12.397]}
          scale={[11.038, 4.144, 5.163]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube405.geometry}
          material={materials["Material.131"]}
          position={[26.786, -0.554, -6.103]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane217.geometry}
          material={materials["Material.131"]}
          position={[25.514, 0.236, -5.778]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube406.geometry}
          material={materials["Material.131"]}
          position={[25.838, -0.554, -4.635]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube407.geometry}
          material={materials["Material.131"]}
          position={[25.156, -0.554, -6.74]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube408.geometry}
          material={materials["Material.131"]}
          position={[29.764, -0.554, -6.096]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube409.geometry}
          material={materials["Material.131"]}
          position={[28.658, 3.169, -12.397]}
          scale={[11.038, 4.144, 5.163]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane218.geometry}
          material={materials["Material.131"]}
          position={[28.491, 0.236, -5.772]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube410.geometry}
          material={materials["Material.131"]}
          position={[28.815, -0.554, -4.629]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube411.geometry}
          material={materials["Material.131"]}
          position={[27.393, -0.554, -5.43]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube412.geometry}
          material={materials["Material.131"]}
          position={[28.134, -0.554, -6.734]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube413.geometry}
          material={materials["Material.131"]}
          position={[31.971, -0.554, -6.103]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane219.geometry}
          material={materials["Material.131"]}
          position={[30.698, 0.236, -5.778]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube414.geometry}
          material={materials["Material.131"]}
          position={[31.022, -0.554, -4.635]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube415.geometry}
          material={materials["Material.131"]}
          position={[30.341, -0.554, -6.74]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube417.geometry}
          material={materials["Material.131"]}
          position={[30.869, 3.169, -4.825]}
          scale={[11.038, 4.144, 5.163]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane220.geometry}
          material={materials["Material.131"]}
          position={[30.702, 0.236, 1.8]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube418.geometry}
          material={materials["Material.131"]}
          position={[31.026, -0.554, 2.943]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube420.geometry}
          material={materials["Material.131"]}
          position={[30.345, -0.554, 0.838]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane221.geometry}
          material={materials["Material.131"]}
          position={[32.909, 0.236, 1.794]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube422.geometry}
          material={materials["Material.131"]}
          position={[33.233, -0.554, 2.936]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube423.geometry}
          material={materials["Material.131"]}
          position={[32.552, -0.554, 0.832]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube416.geometry}
          material={materials["Material.131"]}
          position={[27.267, 3.169, -4.825]}
          scale={[11.038, 4.144, 5.163]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane222.geometry}
          material={materials["Material.131"]}
          position={[27.1, 0.236, 1.8]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube419.geometry}
          material={materials["Material.131"]}
          position={[27.424, -0.554, 2.943]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube421.geometry}
          material={materials["Material.131"]}
          position={[26.743, -0.554, 0.838]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane223.geometry}
          material={materials["Material.131"]}
          position={[29.307, 0.236, 1.794]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube424.geometry}
          material={materials["Material.131"]}
          position={[29.631, -0.554, 2.936]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube425.geometry}
          material={materials["Material.131"]}
          position={[28.95, -0.554, 0.832]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube426.geometry}
          material={materials["Material.131"]}
          position={[23.836, 3.169, -4.825]}
          scale={[11.038, 4.144, 5.163]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane224.geometry}
          material={materials["Material.131"]}
          position={[23.669, 0.236, 1.8]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube427.geometry}
          material={materials["Material.131"]}
          position={[23.993, -0.554, 2.943]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube428.geometry}
          material={materials["Material.131"]}
          position={[23.311, -0.554, 0.838]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane225.geometry}
          material={materials["Material.131"]}
          position={[25.876, 0.236, 1.794]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube429.geometry}
          material={materials["Material.131"]}
          position={[26.2, -0.554, 2.936]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube430.geometry}
          material={materials["Material.131"]}
          position={[25.518, -0.554, 0.832]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane228.geometry}
          material={materials["Material.050"]}
          position={[32.191, 0.236, 5.695]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube434.geometry}
          material={materials["Material.050"]}
          position={[32.515, -0.554, 6.838]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube435.geometry}
          material={materials["Material.050"]}
          position={[31.834, -0.554, 4.733]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane227.geometry}
          material={materials["Material.050"]}
          position={[29.818, 0.236, 5.695]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube431.geometry}
          material={materials["Material.050"]}
          position={[30.142, -0.554, 6.838]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube432.geometry}
          material={materials["Material.050"]}
          position={[29.461, -0.554, 4.733]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane229.geometry}
          material={materials["Material.050"]}
          position={[27.675, 0.236, 5.695]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube433.geometry}
          material={materials["Material.050"]}
          position={[27.999, -0.554, 6.838]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube437.geometry}
          material={materials["Material.050"]}
          position={[27.318, -0.554, 4.733]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane230.geometry}
          material={materials["Material.050"]}
          position={[25.534, 0.236, 5.695]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube438.geometry}
          material={materials["Material.050"]}
          position={[25.858, -0.554, 6.838]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube439.geometry}
          material={materials["Material.050"]}
          position={[25.176, -0.554, 4.733]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane231.geometry}
          material={materials["Material.050"]}
          position={[23.477, 0.236, 5.695]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube440.geometry}
          material={materials["Material.050"]}
          position={[23.801, -0.554, 6.838]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube441.geometry}
          material={materials["Material.050"]}
          position={[23.119, -0.554, 4.733]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane232.geometry}
          material={materials["Material.050"]}
          position={[23.477, 0.236, 8.528]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube442.geometry}
          material={materials["Material.050"]}
          position={[23.801, -0.554, 9.671]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube443.geometry}
          material={materials["Material.050"]}
          position={[23.119, -0.554, 7.566]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane233.geometry}
          material={materials["Material.050"]}
          position={[25.496, 0.236, 8.528]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube445.geometry}
          material={materials["Material.050"]}
          position={[25.82, -0.554, 9.671]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube447.geometry}
          material={materials["Material.050"]}
          position={[25.138, -0.554, 7.566]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane234.geometry}
          material={materials["Material.050"]}
          position={[27.701, 0.236, 8.528]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube448.geometry}
          material={materials["Material.050"]}
          position={[28.025, -0.554, 9.671]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube449.geometry}
          material={materials["Material.050"]}
          position={[27.344, -0.554, 7.566]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane235.geometry}
          material={materials["Material.050"]}
          position={[29.773, 0.236, 8.528]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube450.geometry}
          material={materials["Material.050"]}
          position={[30.097, -0.554, 9.671]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube451.geometry}
          material={materials["Material.050"]}
          position={[29.416, -0.554, 7.566]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane236.geometry}
          material={materials["Material.050"]}
          position={[32.297, 0.236, 8.528]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube452.geometry}
          material={materials["Material.050"]}
          position={[32.621, -0.554, 9.671]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube453.geometry}
          material={materials["Material.050"]}
          position={[31.94, -0.554, 7.566]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane237.geometry}
          material={materials["Material.050"]}
          position={[32.226, 0.236, 14.742]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube454.geometry}
          material={materials["Material.050"]}
          position={[33.369, -0.554, 14.418]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube455.geometry}
          material={materials["Material.050"]}
          position={[31.264, -0.554, 15.099]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane238.geometry}
          material={materials["Material.050"]}
          position={[32.226, 0.236, 16.563]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube456.geometry}
          material={materials["Material.050"]}
          position={[33.369, -0.554, 16.239]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube457.geometry}
          material={materials["Material.050"]}
          position={[31.264, -0.554, 16.921]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane239.geometry}
          material={materials["Material.050"]}
          position={[32.226, 0.236, 18.362]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube458.geometry}
          material={materials["Material.050"]}
          position={[33.369, -0.554, 18.038]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube459.geometry}
          material={materials["Material.050"]}
          position={[31.264, -0.554, 18.72]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane240.geometry}
          material={materials["Material.050"]}
          position={[29.716, 0.236, 17.369]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube460.geometry}
          material={materials["Material.050"]}
          position={[30.858, -0.554, 17.045]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube461.geometry}
          material={materials["Material.050"]}
          position={[28.754, -0.554, 17.726]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane241.geometry}
          material={materials["Material.050"]}
          position={[29.716, 0.236, 15.61]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube462.geometry}
          material={materials["Material.050"]}
          position={[30.858, -0.554, 15.286]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube463.geometry}
          material={materials["Material.050"]}
          position={[28.754, -0.554, 15.967]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane242.geometry}
          material={materials["Material.050"]}
          position={[27.018, 0.236, 14.566]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube464.geometry}
          material={materials["Material.050"]}
          position={[28.16, -0.554, 14.242]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube465.geometry}
          material={materials["Material.050"]}
          position={[26.056, -0.554, 14.923]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane243.geometry}
          material={materials["Material.050"]}
          position={[27.018, 0.236, 16.519]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube466.geometry}
          material={materials["Material.050"]}
          position={[28.16, -0.554, 16.195]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube467.geometry}
          material={materials["Material.050"]}
          position={[26.056, -0.554, 16.877]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane244.geometry}
          material={materials["Material.050"]}
          position={[27.018, 0.236, 18.459]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube468.geometry}
          material={materials["Material.050"]}
          position={[28.16, -0.554, 18.135]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube469.geometry}
          material={materials["Material.050"]}
          position={[26.056, -0.554, 18.816]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane245.geometry}
          material={materials["Material.050"]}
          position={[24.465, 0.236, 14.996]}
          rotation={[0, Math.PI / 2, 0]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube470.geometry}
          material={materials["Material.050"]}
          position={[24.141, -0.554, 13.853]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube471.geometry}
          material={materials["Material.050"]}
          position={[24.822, -0.554, 15.958]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane246.geometry}
          material={materials["Material.050"]}
          position={[24.465, 0.236, 18.342]}
          rotation={[0, Math.PI / 2, 0]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube472.geometry}
          material={materials["Material.050"]}
          position={[24.141, -0.554, 17.199]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube473.geometry}
          material={materials["Material.050"]}
          position={[24.822, -0.554, 19.304]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube474.geometry}
          material={materials["Material.247"]}
          position={[32.408, -0.554, 22.932]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube475.geometry}
          material={materials["Material.247"]}
          position={[38.709, 3.169, 21.826]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[11.038, 4.144, 5.163]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane247.geometry}
          material={materials["Material.247"]}
          position={[32.084, 0.236, 21.659]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube476.geometry}
          material={materials["Material.247"]}
          position={[30.941, -0.554, 21.983]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube477.geometry}
          material={materials["Material.247"]}
          position={[31.742, -0.554, 20.561]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube478.geometry}
          material={materials["Material.247"]}
          position={[33.046, -0.554, 21.302]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube479.geometry}
          material={materials["Material.247"]}
          position={[32.415, -0.554, 25.139]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane248.geometry}
          material={materials["Material.247"]}
          position={[32.09, 0.236, 23.866]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube480.geometry}
          material={materials["Material.247"]}
          position={[30.947, -0.554, 24.19]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube481.geometry}
          material={materials["Material.247"]}
          position={[33.052, -0.554, 23.509]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube482.geometry}
          material={materials["Material.247"]}
          position={[29.101, -0.554, 22.932]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube483.geometry}
          material={materials["Material.247"]}
          position={[35.401, 3.169, 21.826]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[11.038, 4.144, 5.163]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane249.geometry}
          material={materials["Material.247"]}
          position={[28.776, 0.236, 21.659]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube484.geometry}
          material={materials["Material.247"]}
          position={[27.633, -0.554, 21.983]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube485.geometry}
          material={materials["Material.247"]}
          position={[28.434, -0.554, 20.561]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube486.geometry}
          material={materials["Material.247"]}
          position={[29.738, -0.554, 21.302]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube487.geometry}
          material={materials["Material.247"]}
          position={[29.107, -0.554, 25.139]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane250.geometry}
          material={materials["Material.247"]}
          position={[28.782, 0.236, 23.866]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube488.geometry}
          material={materials["Material.247"]}
          position={[27.639, -0.554, 24.19]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube489.geometry}
          material={materials["Material.247"]}
          position={[29.744, -0.554, 23.509]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube490.geometry}
          material={materials["Material.247"]}
          position={[25.999, -0.554, 22.932]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube491.geometry}
          material={materials["Material.247"]}
          position={[32.299, 3.169, 21.826]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[11.038, 4.144, 5.163]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane251.geometry}
          material={materials["Material.247"]}
          position={[25.674, 0.236, 21.659]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube492.geometry}
          material={materials["Material.247"]}
          position={[24.531, -0.554, 21.983]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube493.geometry}
          material={materials["Material.247"]}
          position={[25.332, -0.554, 20.561]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube494.geometry}
          material={materials["Material.247"]}
          position={[26.636, -0.554, 21.302]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube495.geometry}
          material={materials["Material.247"]}
          position={[26.005, -0.554, 25.139]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane252.geometry}
          material={materials["Material.247"]}
          position={[25.68, 0.236, 23.866]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube496.geometry}
          material={materials["Material.247"]}
          position={[24.537, -0.554, 24.19]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube497.geometry}
          material={materials["Material.247"]}
          position={[26.642, -0.554, 23.509]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube498.geometry}
          material={materials["Material.247"]}
          position={[32.525, -0.554, 31.682]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube499.geometry}
          material={materials["Material.247"]}
          position={[38.825, 3.169, 30.576]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[11.038, 4.144, 5.163]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane253.geometry}
          material={materials["Material.247"]}
          position={[32.2, 0.236, 30.409]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube500.geometry}
          material={materials["Material.247"]}
          position={[31.058, -0.554, 30.733]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube501.geometry}
          material={materials["Material.247"]}
          position={[31.859, -0.554, 29.311]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube502.geometry}
          material={materials["Material.247"]}
          position={[33.162, -0.554, 30.052]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube503.geometry}
          material={materials["Material.247"]}
          position={[32.531, -0.554, 33.889]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane254.geometry}
          material={materials["Material.247"]}
          position={[32.206, 0.236, 32.616]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube504.geometry}
          material={materials["Material.247"]}
          position={[31.064, -0.554, 32.94]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube505.geometry}
          material={materials["Material.247"]}
          position={[33.168, -0.554, 32.259]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube506.geometry}
          material={materials["Material.247"]}
          position={[29.454, -0.554, 31.682]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube507.geometry}
          material={materials["Material.247"]}
          position={[35.754, 3.169, 30.576]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[11.038, 4.144, 5.163]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane255.geometry}
          material={materials["Material.247"]}
          position={[29.129, 0.236, 30.409]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube508.geometry}
          material={materials["Material.247"]}
          position={[27.986, -0.554, 30.733]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube509.geometry}
          material={materials["Material.247"]}
          position={[28.788, -0.554, 29.311]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube510.geometry}
          material={materials["Material.247"]}
          position={[30.091, -0.554, 30.052]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube511.geometry}
          material={materials["Material.247"]}
          position={[29.46, -0.554, 33.889]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane256.geometry}
          material={materials["Material.247"]}
          position={[29.135, 0.236, 32.616]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube512.geometry}
          material={materials["Material.247"]}
          position={[27.993, -0.554, 32.94]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube513.geometry}
          material={materials["Material.247"]}
          position={[30.097, -0.554, 32.259]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube514.geometry}
          material={materials["Material.247"]}
          position={[26.609, -0.554, 31.682]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube515.geometry}
          material={materials["Material.247"]}
          position={[32.909, 3.169, 30.576]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[11.038, 4.144, 5.163]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane257.geometry}
          material={materials["Material.247"]}
          position={[26.284, 0.236, 30.409]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube516.geometry}
          material={materials["Material.247"]}
          position={[25.142, -0.554, 30.733]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube517.geometry}
          material={materials["Material.247"]}
          position={[25.943, -0.554, 29.311]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube518.geometry}
          material={materials["Material.247"]}
          position={[27.246, -0.554, 30.052]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube519.geometry}
          material={materials["Material.247"]}
          position={[26.615, -0.554, 33.889]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane258.geometry}
          material={materials["Material.247"]}
          position={[26.29, 0.236, 32.616]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube520.geometry}
          material={materials["Material.247"]}
          position={[25.148, -0.554, 32.94]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube521.geometry}
          material={materials["Material.247"]}
          position={[27.252, -0.554, 32.259]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube522.geometry}
          material={materials["Material.247"]}
          position={[43.388, -0.554, 36.859]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube523.geometry}
          material={materials["Material.247"]}
          position={[42.283, 3.169, 30.559]}
          scale={[11.038, 4.144, 5.163]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane259.geometry}
          material={materials["Material.247"]}
          position={[42.116, 0.236, 37.184]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube524.geometry}
          material={materials["Material.247"]}
          position={[42.44, -0.554, 38.327]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube525.geometry}
          material={materials["Material.247"]}
          position={[41.017, -0.554, 37.525]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube526.geometry}
          material={materials["Material.247"]}
          position={[41.758, -0.554, 36.222]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube527.geometry}
          material={materials["Material.247"]}
          position={[45.595, -0.554, 36.853]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane260.geometry}
          material={materials["Material.247"]}
          position={[44.323, 0.236, 37.178]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube528.geometry}
          material={materials["Material.247"]}
          position={[44.647, -0.554, 38.32]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube529.geometry}
          material={materials["Material.247"]}
          position={[43.965, -0.554, 36.216]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube530.geometry}
          material={materials["Material.247"]}
          position={[38.432, -0.554, 36.859]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube531.geometry}
          material={materials["Material.247"]}
          position={[37.326, 3.169, 30.559]}
          scale={[11.038, 4.144, 5.163]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane261.geometry}
          material={materials["Material.247"]}
          position={[37.159, 0.236, 37.184]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube532.geometry}
          material={materials["Material.247"]}
          position={[37.483, -0.554, 38.327]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube533.geometry}
          material={materials["Material.247"]}
          position={[36.061, -0.554, 37.525]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube534.geometry}
          material={materials["Material.247"]}
          position={[36.802, -0.554, 36.222]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube535.geometry}
          material={materials["Material.247"]}
          position={[40.639, -0.554, 36.853]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane262.geometry}
          material={materials["Material.247"]}
          position={[39.366, 0.236, 37.178]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube536.geometry}
          material={materials["Material.247"]}
          position={[39.69, -0.554, 38.32]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube537.geometry}
          material={materials["Material.247"]}
          position={[39.009, -0.554, 36.216]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube538.geometry}
          material={materials["Material.247"]}
          position={[30.893, -0.554, 36.859]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube539.geometry}
          material={materials["Material.247"]}
          position={[30.158, 3.169, 30.559]}
          scale={[11.038, 4.144, 5.163]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane263.geometry}
          material={materials["Material.247"]}
          position={[29.62, 0.236, 37.184]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube540.geometry}
          material={materials["Material.247"]}
          position={[29.944, -0.554, 38.327]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube541.geometry}
          material={materials["Material.247"]}
          position={[28.522, -0.554, 37.525]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube542.geometry}
          material={materials["Material.247"]}
          position={[29.263, -0.554, 36.222]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube543.geometry}
          material={materials["Material.247"]}
          position={[33.47, -0.554, 36.853]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane264.geometry}
          material={materials["Material.247"]}
          position={[32.198, 0.236, 37.178]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube544.geometry}
          material={materials["Material.247"]}
          position={[32.522, -0.554, 38.32]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube545.geometry}
          material={materials["Material.247"]}
          position={[31.841, -0.554, 36.216]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube546.geometry}
          material={materials["Material.247"]}
          position={[26.742, -0.554, 37.367]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane265.geometry}
          material={materials["Material.247"]}
          position={[25.47, 0.236, 37.692]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube548.geometry}
          material={materials["Material.247"]}
          position={[24.371, -0.554, 38.033]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube547.geometry}
          material={materials["Material.247"]}
          position={[26.742, -0.554, 35.617]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane266.geometry}
          material={materials["Material.247"]}
          position={[25.47, 0.236, 35.942]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube549.geometry}
          material={materials["Material.247"]}
          position={[24.371, -0.554, 36.283]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube562.geometry}
          material={materials["Material.089"]}
          position={[47.678, -0.554, -45.605]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane146.geometry}
          material={materials["Material.089"]}
          position={[47.353, 0.236, -46.878]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube563.geometry}
          material={materials["Material.089"]}
          position={[47.011, -0.554, -47.976]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube564.geometry}
          material={materials["Material.089"]}
          position={[47.678, -0.554, -41.021]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane267.geometry}
          material={materials["Material.089"]}
          position={[47.353, 0.236, -42.293]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube565.geometry}
          material={materials["Material.089"]}
          position={[47.011, -0.554, -43.392]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube566.geometry}
          material={materials["Material.089"]}
          position={[47.678, -0.554, -28.466]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane268.geometry}
          material={materials["Material.089"]}
          position={[47.353, 0.236, -29.739]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube567.geometry}
          material={materials["Material.089"]}
          position={[47.011, -0.554, -30.837]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube568.geometry}
          material={materials["Material.089"]}
          position={[47.678, -0.554, -25.37]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane269.geometry}
          material={materials["Material.089"]}
          position={[47.353, 0.236, -26.643]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube569.geometry}
          material={materials["Material.089"]}
          position={[47.011, -0.554, -27.741]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube570.geometry}
          material={materials["Material.089"]}
          position={[47.678, -0.554, -13.677]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane270.geometry}
          material={materials["Material.089"]}
          position={[47.353, 0.236, -14.95]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube571.geometry}
          material={materials["Material.089"]}
          position={[47.011, -0.554, -16.048]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube572.geometry}
          material={materials["Material.089"]}
          position={[47.678, -0.554, -10.758]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane271.geometry}
          material={materials["Material.089"]}
          position={[47.353, 0.236, -12.031]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube573.geometry}
          material={materials["Material.089"]}
          position={[47.011, -0.554, -13.129]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube574.geometry}
          material={materials["Material.089"]}
          position={[47.678, -0.554, 4.785]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane272.geometry}
          material={materials["Material.089"]}
          position={[47.353, 0.236, 3.513]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube575.geometry}
          material={materials["Material.089"]}
          position={[47.011, -0.554, 2.414]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube576.geometry}
          material={materials["Material.089"]}
          position={[47.678, -0.554, 7.747]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane273.geometry}
          material={materials["Material.089"]}
          position={[47.353, 0.236, 6.474]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube577.geometry}
          material={materials["Material.089"]}
          position={[47.011, -0.554, 5.376]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube578.geometry}
          material={materials["Material.089"]}
          position={[47.678, -0.554, 19.391]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane274.geometry}
          material={materials["Material.089"]}
          position={[47.353, 0.236, 18.118]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube579.geometry}
          material={materials["Material.089"]}
          position={[47.011, -0.554, 17.02]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube580.geometry}
          material={materials["Material.089"]}
          position={[47.678, -0.554, 22.687]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane275.geometry}
          material={materials["Material.089"]}
          position={[47.353, 0.236, 21.414]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube581.geometry}
          material={materials["Material.089"]}
          position={[47.011, -0.554, 20.316]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube137.geometry}
          material={materials["Material.146"]}
          position={[29.848, -0.667, 14.417]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.18, 0.257, 0.18]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube249.geometry}
          material={materials["Material.146"]}
          position={[23.479, -0.667, -3.523]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.18, 0.257, 0.18]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane005.geometry}
          material={materials["Material.089"]}
          position={[35.533, -1.763, 2.532]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[5.039, 8.803, 11.696]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane004.geometry}
          material={materials["Material.184"]}
          position={[35.533, -1.763, 18.477]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[5.039, 8.803, 11.696]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane006.geometry}
          material={materials["Material.131"]}
          position={[35.533, -1.763, 49.913]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[5.039, 8.803, 11.696]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane007.geometry}
          material={materials["Material.050"]}
          position={[35.533, -1.763, 65.657]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[5.039, 8.803, 11.696]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane008.geometry}
          material={materials["Material.089"]}
          position={[36.973, -1.763, 0.063]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[5.039, 8.803, 11.696]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane011.geometry}
          material={materials["Material.089"]}
          position={[71.031, -1.764, -76.701]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[5.039, 8.803, 11.696]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane012.geometry}
          material={materials["Material.177"]}
          position={[36.973, -1.763, 16.016]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[5.039, 8.803, 11.696]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane017.geometry}
          material={materials["Material.131"]}
          position={[36.973, -1.763, 47.452]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[5.039, 8.803, 11.696]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube100.geometry}
          material={materials["Material.021"]}
          position={[-43.399, -0.911, 30.241]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.023, 0.287, 0.021]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube101.geometry}
          material={materials["Material.021"]}
          position={[-45.19, -0.911, 30.727]}
          scale={[0.023, 0.287, 0.021]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube102.geometry}
          material={materials["Material.021"]}
          position={[-44.537, -0.911, 29.655]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.023, 0.287, 0.021]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube103.geometry}
          material={materials["Material.021"]}
          position={[-44.02, -0.911, 31.344]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.023, 0.287, 0.021]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube104.geometry}
          material={materials["Material.021"]}
          position={[-45.19, -0.911, 28.132]}
          scale={[0.023, 0.287, 0.021]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube105.geometry}
          material={materials["Material.021"]}
          position={[-43.399, -0.911, 27.646]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.023, 0.287, 0.021]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube106.geometry}
          material={materials["Material.021"]}
          position={[-44.537, -0.911, 27.06]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.023, 0.287, 0.021]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube107.geometry}
          material={materials["Material.021"]}
          position={[-44.02, -0.911, 28.749]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.023, 0.287, 0.021]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube108.geometry}
          material={materials["Material.021"]}
          position={[-45.19, -0.911, 25.52]}
          scale={[0.023, 0.287, 0.021]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube109.geometry}
          material={materials["Material.021"]}
          position={[-43.399, -0.911, 25.035]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.023, 0.287, 0.021]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube110.geometry}
          material={materials["Material.021"]}
          position={[-44.537, -0.911, 24.448]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.023, 0.287, 0.021]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube111.geometry}
          material={materials["Material.021"]}
          position={[-44.02, -0.911, 26.138]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.023, 0.287, 0.021]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube112.geometry}
          material={materials["Material.021"]}
          position={[-45.19, -0.911, 22.883]}
          scale={[0.023, 0.287, 0.021]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube113.geometry}
          material={materials["Material.021"]}
          position={[-43.399, -0.911, 22.398]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.023, 0.287, 0.021]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube114.geometry}
          material={materials["Material.021"]}
          position={[-44.537, -0.911, 21.811]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.023, 0.287, 0.021]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube115.geometry}
          material={materials["Material.021"]}
          position={[-44.02, -0.911, 23.5]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.023, 0.287, 0.021]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube116.geometry}
          material={materials["Material.021"]}
          position={[-45.19, -0.911, 20.016]}
          scale={[0.023, 0.287, 0.021]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube117.geometry}
          material={materials["Material.021"]}
          position={[-43.399, -0.911, 19.53]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.023, 0.287, 0.021]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube118.geometry}
          material={materials["Material.021"]}
          position={[-44.537, -0.911, 18.944]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.023, 0.287, 0.021]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube119.geometry}
          material={materials["Material.021"]}
          position={[-44.02, -0.911, 20.633]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.023, 0.287, 0.021]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube120.geometry}
          material={materials["Material.021"]}
          position={[-45.19, -0.911, 17.388]}
          scale={[0.023, 0.287, 0.021]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube121.geometry}
          material={materials["Material.021"]}
          position={[-43.399, -0.911, 16.902]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.023, 0.287, 0.021]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube122.geometry}
          material={materials["Material.021"]}
          position={[-44.537, -0.911, 16.316]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.023, 0.287, 0.021]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube123.geometry}
          material={materials["Material.021"]}
          position={[-44.02, -0.911, 18.005]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.023, 0.287, 0.021]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube124.geometry}
          material={materials["Material.021"]}
          position={[-50.96, -0.911, 32.178]}
          scale={[0.023, 0.287, 0.021]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube125.geometry}
          material={materials["Material.021"]}
          position={[-49.169, -0.911, 31.693]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.023, 0.287, 0.021]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube126.geometry}
          material={materials["Material.021"]}
          position={[-50.306, -0.911, 31.106]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.023, 0.287, 0.021]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube127.geometry}
          material={materials["Material.021"]}
          position={[-49.79, -0.911, 32.796]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.023, 0.287, 0.021]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube128.geometry}
          material={materials["Material.021"]}
          position={[-53.201, -0.911, 32.178]}
          scale={[0.023, 0.287, 0.021]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube129.geometry}
          material={materials["Material.021"]}
          position={[-51.409, -0.911, 31.693]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.023, 0.287, 0.021]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube130.geometry}
          material={materials["Material.021"]}
          position={[-52.547, -0.911, 31.106]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.023, 0.287, 0.021]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube131.geometry}
          material={materials["Material.021"]}
          position={[-52.031, -0.911, 32.796]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.023, 0.287, 0.021]}
        />
        <group
          position={[-50.134, 1.99, 22.033]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[8.558, 3.213, 4.004]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube028.geometry}
            material={materials["Material.130"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube028_1.geometry}
            material={materials["Material.131"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube028_2.geometry}
            material={materials["Material.132"]}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube436.geometry}
          material={materials["Material.146"]}
          position={[-45.566, -0.966, 31.697]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.18, 0.257, 0.18]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube444.geometry}
          material={materials["Material.146"]}
          position={[-44.965, -0.966, 13.907]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.18, 0.257, 0.18]}
        />
        <group
          position={[-50.134, 1.99, 22.033]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[8.558, 3.213, 4.004]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube450_1.geometry}
            material={materials["Material.130"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube450_2.geometry}
            material={materials["Material.131"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube450_3.geometry}
            material={materials["Material.132"]}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane117.geometry}
          material={materials["Material.021"]}
          position={[-44.285, -0.299, 30.493]}
          scale={0.583}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane119.geometry}
          material={materials["Material.021"]}
          position={[-44.285, -0.299, 27.898]}
          scale={0.583}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane120.geometry}
          material={materials["Material.021"]}
          position={[-44.285, -0.299, 25.286]}
          scale={0.583}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane121.geometry}
          material={materials["Material.021"]}
          position={[-44.285, -0.299, 22.649]}
          scale={0.583}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane122.geometry}
          material={materials["Material.021"]}
          position={[-44.285, -0.299, 19.782]}
          scale={0.583}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane123.geometry}
          material={materials["Material.021"]}
          position={[-44.285, -0.299, 17.153]}
          scale={0.583}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane124.geometry}
          material={materials["Material.021"]}
          position={[-50.055, -0.299, 31.944]}
          scale={0.583}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane125.geometry}
          material={materials["Material.021"]}
          position={[-52.295, -0.299, 31.944]}
          scale={0.583}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube135.geometry}
          material={materials["Material.073"]}
          position={[-16.519, -0.673, 76.861]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube136.geometry}
          material={materials["Material.073"]}
          position={[-16.519, -0.673, 80.008]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube142.geometry}
          material={materials["Material.073"]}
          position={[-17.987, -0.673, 79.252]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube145.geometry}
          material={materials["Material.073"]}
          position={[-17.32, -0.673, 81.43]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube146.geometry}
          material={materials["Material.073"]}
          position={[-24.342, 3.05, 79.92]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[11.038, 4.144, 5.163]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube147.geometry}
          material={materials["Material.073"]}
          position={[-17.987, -0.673, 76.105]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube148.geometry}
          material={materials["Material.073"]}
          position={[-17.32, -0.673, 78.284]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube149.geometry}
          material={materials["Material.073"]}
          position={[-16.519, -0.673, 72.563]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube150.geometry}
          material={materials["Material.073"]}
          position={[-17.987, -0.673, 71.807]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube151.geometry}
          material={materials["Material.073"]}
          position={[-17.32, -0.673, 73.986]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.029, 0.37, 0.026]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube152.geometry}
          material={materials["Material.073"]}
          position={[-23.751, 3.05, 74.774]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[11.038, 4.144, 5.163]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane148.geometry}
          material={materials["Material.073"]}
          position={[-17.662, 0.116, 80.332]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane149.geometry}
          material={materials["Material.073"]}
          position={[-17.662, 0.116, 77.185]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane150.geometry}
          material={materials["Material.073"]}
          position={[-17.662, 0.116, 72.887]}
          scale={0.752}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.nike_logo_text.geometry}
          material={materials["Material.172"]}
          position={[-18.304, 4.277, 97.537]}
          rotation={[0, 1.571, 0]}
          scale={[0.484, 0.561, 0.382]}
        />
      </group>
    // </RigidBody>
  );
}

useGLTF.preload("./assets/suncity/seating.glb");
