import React, { useState, useEffect } from "react";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import useAssistant from "./stores/useAssistant";
import useGoalStore from "./stores/useGoalStore";

const NotificationsLoop = () => {
  const [index, setIndex] = useState(0);
  const { keyphrase } = useAssistant((state) => state);
  const { goals, lastestGoalId } = useGoalStore((store) => store);
  const getLatestGoal = () => {
    if (lastestGoalId) {
      return goals[lastestGoalId];
    } else {
      return null;
    }
  }
  const headlines = [
    {
      category: "Boundless",
      headline: "Boundless has partnered with Hawai’I Pacific University.",
    },
    // {
    //   category: "Boundless",
    //   headline:
    //     "Norwich University launches a Degree program on Business Analytics for Managers",
    // },
    // {
    //   category: "Boundless",
    //   headline:
    //     "New research opportunity open at Bradely University for research scholars",
    // },
    // {
    //   category: "Boundless",
    //   headline: "Sassoon launches SUMMER 2024 Collection in LONDON",
    // },
    // {
    //   category: "Boundless",
    //   headline:
    //     "Bradley is proud to support Autism Awareness with campus resources like the Moss Scholars Program",
    // },
    // {
    //   category: "ASU GSV",
    //   headline:
    //     "GSV Teams Up with Google for Education for Second Year of Innovation Fellowship",
    // },
    // {
    //   category: "ASU GSV",
    //   headline:
    //     "Van Jones to Headline ASU+GSV Summit: A Gathering of Change-Makers",
    // },
    // {
    //   category: "ASU GSV",
    //   headline:
    //     "Eric Paslay to Perform Live at AIR Show: Don't Miss the Country Sensation!",
    // },
    // {
    //   category: "ASU GSV",
    //   headline:
    //     "Paula Abdul Set for Fireside Chat at StageX with Makeda Mays Green",
    // },
    // {
    //   category: "ASU GSV",
    //   headline:
    //     "Common Returns to StageX for Special Announcement: Mark Your Calendars!",
    // },
    // {
    //   category: "ASU GSV",
    //   headline:
    //     "Download the Official ASU+GSV Summit App: Your Guide to Education Innovation",
    // },
    // {
    //   category: "ASU GSV",
    //   headline:
    //     "Emotional Intelligence Pioneer Marc Brackett to Keynote at ASU+GSV AIR Show",
    // },
    // {
    //   category: "ASU GSV",
    //   headline:
    //     "The ASU+GSV Summit was co-founded by Michael Moe and Deborah Quazzo",
    // },
    // {
    //   category: "ASU GSV",
    //   headline:
    //     'ASU+GSV Summit: 15 Years of Shaping Education from "Pre-K to Gray"',
    // },
    // {
    //   category: "ASU GSV",
    //   headline:
    //     "ASU + GSV Summit began in 2010 with a collaboration between Global Silicon Valley (GSV) and Arizona State University (ASU).",
    // },
    // {
    //   category: "Edvanta News",
    //   headline:
    //     "Edvanta Launches ThoughtJumper Beta Access: A Gateway to Innovative Learning",
    // },
    // {
    //   category: "Edvanta News",
    //   headline:
    //     "Edvanta's LEAP Bootcamps Garner Success: Third Edition Marks Milestone",
    // },
    // {
    //   category: "Edvanta News",
    //   headline:
    //     "Edvanta Introduces LEAP Bootcamp for Fresh Graduates: Empowering Tomorrow's Leaders",
    // },
    // {
    //   category: "Edvanta News",
    //   headline:
    //     "Over 200 Applications Flood In for LEAP Bootcamp's Inaugural Edition by Edvanta",
    // },
    // {
    //   category: "Edvanta News",
    //   headline:
    //     "Indian Navy Partners with Edvanta for Cutting-Edge E-Training Modules",
    // },
    // {
    //   category: "Edvanta News",
    //   headline:
    //     "Edvanta Teams Up with Transform Me Learning for Next-Gen Educational Video Game",
    // },
    // {
    //   category: "Edvanta News",
    //   headline:
    //     "Boundless Learning and Edvanta Forge Partnership to Enhance E-Learning Services",
    // },
    // {
    //   category: "Job Alert",
    //   headline:
    //     "Boundless Learning Seeks Enrollment Advisors for Orlando, FL and Chandler, AZ Locations",
    // },
    // {
    //   category: "Job Alert",
    //   headline:
    //     "Join Boundless Learning as an Enrolment Assistant in Melbourne: Apply Now!",
    // },
    // {
    //   category: "Job Alert",
    //   headline:
    //     "Chandler, AZ Calling: Boundless Learning Seeks Qualify & Transfer Agents",
    // },
    // {
    //   category: "Job Alert",
    //   headline:
    //     "Partnership & New Business Manager Needed: Boundless Learning's Opportunity in Melbourne, VIC",
    // },
    // {
    //   category: "Job Alert",
    //   headline:
    //     "Boundless Learning Expands: Sr. Director, Partnership Strategy & Engagement Role Opens in London, UK",
    // },
    // {
    //   category: "Job Alert",
    //   headline:
    //     "Calling Australian Educators: Join Oxford University's Smith School as an Online Facilitator with Boundless Learning",
    // },
    // {
    //   category: "Job Alert",
    //   headline:
    //     "Boundless Learning Seeks Product Managers in Orlando, FL, Chicago, IL, and Chandler, AZ",
    // },
    // {
    //   category: "Job Alert",
    //   headline:
    //     "Remote Opportunity: Boundless Learning Seeks Psychology Research Supervisor in Australia",
    // },
    // {
    //   category: "Job Alert",
    //   headline:
    //     "Drive Enterprise Sales Growth: Boundless Learning Seeks Senior Director in Chandler, AZ",
    // },
    // {
    //   category: "Job Alert",
    //   headline:
    //     "Expand Your Career: Boundless Learning Seeks Sr. Managers for Partner Strategy & Engagement in Orlando, FL, Chandler, AZ, and Chicago, IL",
    // },
    // {
    //   category: "Job Alert",
    //   headline:
    //     "Join Boundless Learning's Student Support Team: Opportunities Available in Chandler, AZ, Orlando, FL, and London, UK",
    // },
    // {
    //   category: "Job Alert",
    //   headline:
    //     "Kickstart Your Career: Edvanta Seeks Digital Platforms Trainee",
    // },
    // {
    //   category: "Job Alert",
    //   headline:
    //     "Calling All Marketers: Edvanta Offers Digital Marketing Internship Opportunities",
    // },
    // {
    //   category: "Job Alert",
    //   headline:
    //     "Join Edvanta's Sales Team: Outbound Sales Internship Available Now",
    // },
    // {
    //   category: "Job Alert",
    //   headline:
    //     "HR Management Opportunity: Edvanta Seeks Experienced HR Manager",
    // },
    // {
    //   category: "Job Alert",
    //   headline:
    //     "Dive into Development: Edvanta Seeks Frontend Developer Talents",
    // },
    // {
    //   category: "Job Alert",
    //   headline: "Back-End Brilliance Wanted: Edvanta Seeks Backend Developer",
    // },
    // {
    //   category: "Job Alert",
    //   headline: "Shape User Experiences: Edvanta Seeks UI UX Designer",
    // },
    // {
    //   category: "Job Alert",
    //   headline: "Unreal Engine Enthusiasts Wanted: Edvanta Seeks Game Designer",
    // },
    // {
    //   category: "Job Alert",
    //   headline: "Data Analysts Wanted: Join Edvanta's Data Team",
    // },
    {
      category: 'News! Sky City',
      headline: `${ keyphrase || 'Nothing'} found as new Keyphrase`
    }, {
      category: 'Update! Sky City',
      headline: `${getLatestGoal() || 'Nothing'} Detected as new goal`
    }
  ];
  useEffect(() => {
    const timer = setInterval(() => {
      // Changed to setInterval for continuous looping
      NotificationManager.info(
        headlines[index].headline,
        headlines[index].category + "!",
        8000
      );
      setIndex((prevIndex) => (prevIndex + 1) % headlines.length);
    }, 12000);

    return () => clearInterval(timer); // Clear interval on component unmount
  }, [index, headlines]);

  return (
    <div style={{ position: "fixed", overflow: "none" }}>
      <NotificationContainer />
      <h1>Headline Notifications</h1>
      <style>
        {`
                    .notification-container {
                        top: 0rem !important;
                        right: 50%;
                        transform: translateX(50%);
                        overflow: hidden !important;
                        width: 390px;
                        font-size: 0.8rem;
                    }
                    .notification-enter {
                        visibility: hidden;
                        transform: translate3d(0, 100%, 0);
                        }

                        .notification-enter.notification-enter-active {
                        visibility: visible;
                        transform: translate3d(0, 0, 0);
                        transition: all $react-notifications-transition-time;
                        }

                        .notification-exit {
                        visibility: visible;
                        transform: translate3d(0, 0, 0);
                        }

                        .notification-exit.notification-exit-active {
                        visibility: hidden;
                        transform: translate3d(0, 100%, 0);
                        transition: all $react-notifications-transition-time;
                        }
                `}
      </style>
    </div>
  );
};

export default NotificationsLoop;
