// /thread/add-instructions

import axios from "axios";

export default async function addInstructions(
  memoryId,
  transcript,
  token
) {
  const characterReqBody = {
    memoryId,
    prompt: transcript,
  };
  const threadResponse = await axios.post(
    `${
      import.meta.env.VITE_SERVER_URI
    }/api/v1/game-story/thread/add-instructions`,
    characterReqBody,
    {
      headers: {
        Authorization: `Bearer ${token}`, // Replace YOUR_TOKEN_HERE with your actual token
        "Content-Type": "application/json",
      },
    }
  );

    if (threadResponse.status === 200) {
        return true;
    }
}
