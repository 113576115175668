// src/observer.js
import useAudioStore from "../../stores/useAudioRecorder";
import { useEffect } from "react";
import { toast } from "react-toastify";
import speechToText from "../../services/SpeechToText";
import useGame from '../../stores/useGame';

const useRecordingObserver = () => {
  const { recording, audioBlob, setTranscription, setRecorderLoading } = useAudioStore(state => state);
  const { UID } = useGame((state) => state);

  useEffect(() => {
    if (recording) {
      setRecorderLoading(true);
      // console.log('xvf', 'I am here and it is working', recording, audioBlob);
      const formData = new FormData();
      formData.append('file', audioBlob, 'audio.wav');
      formData.append('model', 'whisper-1');
      formData.append('language', "en");

      speechToText(formData, UID).then((response) => {
        if (response && response.text) {
          setTranscription(response.text);
          setRecorderLoading(false);
        }
      }).catch((error) => {
        setTranscription(null);
        setRecorderLoading(false);
      })
    }
  }, [recording]);


};

export default useRecordingObserver;