import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";

export default function EdvantaComplex(props) {
  const { nodes, materials } = useGLTF("./assets/suncity/edvantaComplex.glb");
  return (
    <RigidBody type="fixed" colliders="trimesh">
      <group {...props} dispose={null}>
        <group position={[-48.117, -2.603, -31.23]} scale={6.196}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube162.geometry}
            material={materials["Material.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube162_1.geometry}
            material={materials["Material.008"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube162_2.geometry}
            material={materials["Material.004"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube162_3.geometry}
            material={materials["Material.012"]}
          />
        </group>
        <group
          position={[-37.388, 7.989, -45.765]}
          scale={[1.147, 4.032, 1.286]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube003_1.geometry}
            material={materials["Material.009"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube003_2.geometry}
            material={materials["Material.010"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube003_3.geometry}
            material={materials["Material.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube003_4.geometry}
            material={materials["Material.013"]}
          />
        </group>
        <group
          position={[-37.388, 7.989, -36.11]}
          scale={[1.147, 4.032, 1.286]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube011_1.geometry}
            material={materials["Material.010"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube011_2.geometry}
            material={materials["Material.013"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube011_3.geometry}
            material={materials["Material.014"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube011_4.geometry}
            material={materials["Material.016"]}
          />
        </group>
        <group
          position={[-44.232, 0.835, -41.838]}
          rotation={[0, 0, -Math.PI / 2]}
          scale={[1.919, 6.196, 1.983]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane_1.geometry}
            material={materials["Material.004"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane_2.geometry}
            material={materials["Material.003"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane_3.geometry}
            material={materials["Material.005"]}
          />
        </group>
        <group
          position={[-44.232, 0.835, -20.736]}
          rotation={[0, 0, -Math.PI / 2]}
          scale={[1.919, 6.196, 1.983]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane001_1.geometry}
            material={materials["Material.004"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane001_2.geometry}
            material={materials["Material.003"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane001_3.geometry}
            material={materials["Material.005"]}
          />
        </group>
        <group
          position={[-44.43, 3.138, -31.023]}
          rotation={[-Math.PI, 0, 0]}
          scale={[-0.133, -4.252, -5.246]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube013_1.geometry}
            material={materials["Material.004"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube013_2.geometry}
            material={materials["Material.002"]}
          />
        </group>
        <group
          position={[-45.033, 7.989, -55.335]}
          scale={[1.147, 4.032, 1.286]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube014_1.geometry}
            material={materials["Material.009"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube014_2.geometry}
            material={materials["Material.010"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube014_3.geometry}
            material={materials["Material.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube014_4.geometry}
            material={materials["Material.013"]}
          />
        </group>
        <group
          position={[-53.042, 7.989, -63.32]}
          scale={[1.147, 4.032, 1.286]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube015_1.geometry}
            material={materials["Material.009"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube015_2.geometry}
            material={materials["Material.010"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube015_3.geometry}
            material={materials["Material.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube015_4.geometry}
            material={materials["Material.013"]}
          />
        </group>
        <group
          position={[-58.606, 7.989, -63.32]}
          scale={[1.147, 4.032, 1.286]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube016_1.geometry}
            material={materials["Material.009"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube016_2.geometry}
            material={materials["Material.010"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube016_3.geometry}
            material={materials["Material.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube016_4.geometry}
            material={materials["Material.013"]}
          />
        </group>
        <group position={[-53.042, 7.989, 0.822]} scale={[1.147, 4.032, 1.286]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube017_1.geometry}
            material={materials["Material.009"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube017_2.geometry}
            material={materials["Material.010"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube017_3.geometry}
            material={materials["Material.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube017_4.geometry}
            material={materials["Material.013"]}
          />
        </group>
        <group position={[-58.606, 7.989, 0.822]} scale={[1.147, 4.032, 1.286]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube018_1.geometry}
            material={materials["Material.009"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube018_2.geometry}
            material={materials["Material.010"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube018_3.geometry}
            material={materials["Material.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube018_4.geometry}
            material={materials["Material.013"]}
          />
        </group>
        <group
          position={[-45.011, 7.989, -6.109]}
          scale={[1.147, 4.032, 1.286]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube019_1.geometry}
            material={materials["Material.009"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube019_2.geometry}
            material={materials["Material.010"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube019_3.geometry}
            material={materials["Material.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube019_4.geometry}
            material={materials["Material.013"]}
          />
        </group>
        <group
          position={[-37.218, 7.989, -16.455]}
          scale={[1.147, 4.032, 1.286]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube020_1.geometry}
            material={materials["Material.009"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube020_2.geometry}
            material={materials["Material.010"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube020_3.geometry}
            material={materials["Material.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube020_4.geometry}
            material={materials["Material.013"]}
          />
        </group>
        <group
          position={[-37.388, 7.989, -26.282]}
          scale={[1.147, 4.032, 1.286]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube021_1.geometry}
            material={materials["Material.010"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube021_2.geometry}
            material={materials["Material.013"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube021_3.geometry}
            material={materials["Material.014"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube021_4.geometry}
            material={materials["Material.016"]}
          />
        </group>
        <group
          position={[-55.625, 0.306, -56.39]}
          rotation={[-Math.PI / 2, 1.571, 0]}
          scale={[1.399, 6.196, 1.983]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane003.geometry}
            material={materials["Material.004"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane003_1.geometry}
            material={materials["Material.003"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane003_2.geometry}
            material={materials["Material.005"]}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube008.geometry}
          material={materials["Material.008"]}
          position={[-41.986, 6.155, -50.458]}
          scale={[2.087, 0.407, 3.464]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube010.geometry}
          material={materials["Material.008"]}
          position={[-41.986, 6.155, -11.381]}
          scale={[2.087, 0.407, 3.894]}
        />
        <group position={[-32.742, -0.955, -46.938]} scale={0.129}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube001_1.geometry}
            material={materials["Material.033"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube001_2.geometry}
            material={materials["Material.034"]}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube033.geometry}
          material={materials.bench_wood}
          position={[-33.405, -0.37, -59.382]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={2.199}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube015.geometry}
          material={materials.bench_wood}
          position={[-33.405, -0.37, -55.093]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={2.199}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube016.geometry}
          material={materials.bench_wood}
          position={[-33.405, -0.37, -50.512]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={2.199}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube017.geometry}
          material={materials.bench_wood}
          position={[-33.405, -0.37, -12.058]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={2.199}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube018.geometry}
          material={materials.bench_wood}
          position={[-33.405, -0.37, -7.769]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={2.199}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube019.geometry}
          material={materials.bench_wood}
          position={[-33.405, -0.37, -3.189]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={2.199}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube020.geometry}
          material={materials.bench_wood}
          position={[-35.106, -0.37, -16.475]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={2.199}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube021.geometry}
          material={materials.bench_wood}
          position={[-39.31, -0.37, -16.475]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={2.199}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube022.geometry}
          material={materials.bench_wood}
          position={[-37.256, -0.37, -14.201]}
          scale={2.199}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube023.geometry}
          material={materials.bench_wood}
          position={[-37.256, -0.37, -18.715]}
          scale={2.199}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube024.geometry}
          material={materials.bench_wood}
          position={[-35.106, -0.37, -26.286]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={2.199}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube025.geometry}
          material={materials.bench_wood}
          position={[-39.31, -0.37, -26.286]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={2.199}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube026.geometry}
          material={materials.bench_wood}
          position={[-37.256, -0.37, -24.012]}
          scale={2.199}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube027.geometry}
          material={materials.bench_wood}
          position={[-37.256, -0.37, -28.526]}
          scale={2.199}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube028.geometry}
          material={materials.bench_wood}
          position={[-35.106, -0.37, -36.117]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={2.199}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube029.geometry}
          material={materials.bench_wood}
          position={[-39.31, -0.37, -36.117]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={2.199}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube030.geometry}
          material={materials.bench_wood}
          position={[-37.256, -0.37, -33.843]}
          scale={2.199}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube031.geometry}
          material={materials.bench_wood}
          position={[-37.256, -0.37, -38.357]}
          scale={2.199}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube032.geometry}
          material={materials.bench_wood}
          position={[-35.106, -0.37, -45.799]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={2.199}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube034.geometry}
          material={materials.bench_wood}
          position={[-39.31, -0.37, -45.799]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={2.199}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube035.geometry}
          material={materials.bench_wood}
          position={[-37.256, -0.37, -43.525]}
          scale={2.199}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube036.geometry}
          material={materials.bench_wood}
          position={[-37.256, -0.37, -48.039]}
          scale={2.199}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane022.geometry}
          material={materials["Material.049"]}
          position={[-36.183, 6.328, -45.781]}
          rotation={[0, 0, -Math.PI / 2]}
          scale={0.186}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane023.geometry}
          material={materials["Material.049"]}
          position={[-36.183, 6.328, -36.129]}
          rotation={[0, 0, -Math.PI / 2]}
          scale={0.186}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane024.geometry}
          material={materials["Material.049"]}
          position={[-36.183, 6.328, -26.194]}
          rotation={[0, 0, -Math.PI / 2]}
          scale={0.186}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane025.geometry}
          material={materials["Material.049"]}
          position={[-36.183, 6.328, -16.351]}
          rotation={[0, 0, -Math.PI / 2]}
          scale={0.186}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane026.geometry}
          material={materials["Material.049"]}
          position={[-43.887, 6.328, -55.231]}
          rotation={[0, 0, -Math.PI / 2]}
          scale={0.186}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane027.geometry}
          material={materials["Material.049"]}
          position={[-43.887, 6.328, -6.023]}
          rotation={[0, 0, -Math.PI / 2]}
          scale={0.186}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane028.geometry}
          material={materials["Material.049"]}
          position={[-51.895, 6.328, 0.823]}
          rotation={[0, 0, -Math.PI / 2]}
          scale={0.186}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane029.geometry}
          material={materials["Material.049"]}
          position={[-51.895, 6.328, -63.275]}
          rotation={[0, 0, -Math.PI / 2]}
          scale={0.186}
        />
        <group position={[-48.117, -2.152, -29.444]} scale={6.196}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube074.geometry}
            material={materials["Material.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube074_1.geometry}
            material={materials["Material.004"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube074_2.geometry}
            material={materials["Material.037"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube074_3.geometry}
            material={materials["Material.058"]}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text001.geometry}
          material={materials["Material.057"]}
          position={[-38.497, 3.313, -60.059]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={[2.198, 2.198, 1.578]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Circle.geometry}
          material={materials["Material.056"]}
          position={[-38.594, 0.605, -61.204]}
          rotation={[0, 0, -Math.PI / 2]}
          scale={0.1}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.win_door_r001.geometry}
          material={materials["WINDOW.001"]}
          position={[-52.821, 6.397, -31.833]}
          scale={6.196}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.win_door_l001.geometry}
          material={materials["WINDOW.001"]}
          position={[-43.413, 6.397, -31.833]}
          scale={6.196}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.number.geometry}
          material={materials["BASIC COLOR.001"]}
          position={[-48.117, 2.763, -42.186]}
          scale={6.196}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.interior.geometry}
          material={materials["BASIC COLOR.001"]}
          position={[-42.284, 1.566, -31.691]}
          rotation={[0, 0, -Math.PI / 2]}
          scale={[2.756, 2.467, 2.467]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube064.geometry}
          material={materials.bench_wood}
          position={[-50.828, -0.37, -63.334]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={2.199}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube065.geometry}
          material={materials.bench_wood}
          position={[-55.032, -0.37, -63.334]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={2.199}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube066.geometry}
          material={materials.bench_wood}
          position={[-52.977, -0.37, -61.06]}
          scale={2.199}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube067.geometry}
          material={materials.bench_wood}
          position={[-52.977, -0.37, -65.573]}
          scale={2.199}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube068.geometry}
          material={materials.bench_wood}
          position={[-56.466, -0.37, -63.334]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={2.199}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube069.geometry}
          material={materials.bench_wood}
          position={[-60.67, -0.37, -63.334]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={2.199}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube070.geometry}
          material={materials.bench_wood}
          position={[-58.615, -0.37, -61.06]}
          scale={2.199}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube071.geometry}
          material={materials.bench_wood}
          position={[-58.615, -0.37, -65.573]}
          scale={2.199}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube072.geometry}
          material={materials.bench_wood}
          position={[-42.841, -0.37, -55.364]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={2.199}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube073.geometry}
          material={materials.bench_wood}
          position={[-45.002, -0.37, -57.637]}
          scale={2.199}
        />
        <group
          position={[-55.625, 0.306, -4.886]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}
          scale={[1.399, 6.196, 1.983]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane070.geometry}
            material={materials["Material.004"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane070_1.geometry}
            material={materials["Material.003"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane070_2.geometry}
            material={materials["Material.005"]}
          />
        </group>
      </group>
    </RigidBody>
  );
}
