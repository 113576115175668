import React from "react";
import { useGLTF } from "@react-three/drei";
import { mountainPositions } from "./positions/MountainPositions";
import { RigidBody } from "@react-three/rapier";
import * as THREE from 'three';

function Mountains(props) {
  const { nodes, materials } = useGLTF(
    "./assets/island/Mountaind-All-Layers.glb"
  );

  console.log('xvf', materials["Material.001"])
  materials["Material.001"].color.r = 0.33
  materials["Material.001"].color.g = 0.36
  materials["Material.001"].color.b = 0.34
  const mountainMaterial = materials["Material.001"];
  // const mountainMaterial = new THREE.MeshStandardMaterial({ color: 0x555C59 });
  return (
    <RigidBody type="fixed" colliders="hull">
      <group {...props} dispose={null}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.BigMountain.geometry}
          material={mountainMaterial}
          position={[-33.351, 5.919, 53.44]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.BigMountain001.geometry}
          material={mountainMaterial}
          position={[-38.923, 5.034, -2.855]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.BigMountain002.geometry}
          material={mountainMaterial}
          position={[36.294, 4.597, 7.91]}
          rotation={[0, -0.491, 0]}
          scale={17.192}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.BigMountain003.geometry}
          material={mountainMaterial}
          position={[7.818, 7.436, -64.96]}
          rotation={[0, 1.376, 0]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.BigMountain004.geometry}
          material={mountainMaterial}
          position={[-52.842, 4.897, 46.556]}
          rotation={[Math.PI, -1.444, Math.PI]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.BigMountain005.geometry}
          material={mountainMaterial}
          position={[-53.351, 6.744, 30.984]}
          rotation={[0, -0.632, 0]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.BigMountain006.geometry}
          material={mountainMaterial}
          position={[-33.842, 5.897, 9.556]}
          rotation={[-Math.PI, 1.106, -Math.PI]}
          scale={39.442}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.BigMountain007.geometry}
          material={mountainMaterial}
          position={[-45.842, 4.016, 31.556]}
          rotation={[Math.PI, -0.918, Math.PI]}
          scale={16.458}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.BigMountain008.geometry}
          material={mountainMaterial}
          position={[16.619, 5.285, 16.819]}
          rotation={[Math.PI, -0.617, Math.PI]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.BigMountain009.geometry}
          material={mountainMaterial}
          position={[36.869, 4.597, -1.512]}
          rotation={[0, -0.491, 0]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.BigMountain010.geometry}
          material={mountainMaterial}
          position={[36.869, 5.449, -12.512]}
          rotation={[0, -0.491, 0]}
          scale={[43.281, 29.662, 43.281]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.BigMountain011.geometry}
          material={mountainMaterial}
          position={[27.848, 5.17, -20.556]}
          rotation={[0, 0.959, 0]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.FatMountain.geometry}
          material={mountainMaterial}
          position={[-1.853, 5.682, 17.047]}
          scale={28.631}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.LilMountain.geometry}
          material={mountainMaterial}
          position={[2.384, 3.793, 31.748]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.LilMountain001.geometry}
          material={mountainMaterial}
          position={[10.05, 4.388, 19.75]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.LilMountain002.geometry}
          material={mountainMaterial}
          position={[-13.594, 4.92, 54.559]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.LilMountain003.geometry}
          material={mountainMaterial}
          position={[-21.206, 3.793, 56.522]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.LilMountain004.geometry}
          material={mountainMaterial}
          position={[-12.643, 4.104, 9.166]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.BigMountain012.geometry}
          material={mountainMaterial}
          position={[18.677, 5.17, -68.59]}
          rotation={[-Math.PI, 0.861, -Math.PI]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.LilMountain006.geometry}
          material={mountainMaterial}
          position={[-54.264, 2.069, -26.167]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.LilMountain007.geometry}
          material={mountainMaterial}
          position={[-63.083, 4.461, -26.742]}
          scale={[31.347, 48.613, 31.347]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.BigMountain013.geometry}
          material={mountainMaterial}
          position={[40.409, -0.907, -35.004]}
          rotation={[-Math.PI, 0.959, 0]}
          scale={18.95}
        />
      </group>
    </RigidBody>
  );
}

export default Mountains;

useGLTF.preload("./assets/island/Mountaind-All-Layers.glb");
