import React, { useRef, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";

export default function Pathways(props) {
  const { nodes, materials } = useGLTF("./assets/suncity/roads.glb");
  return (
    <RigidBody type="fixed" colliders="trimesh">
      <group {...props} dispose={null}>
        <group
          position={[-10.754, -0.884, 110.334]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={6.196}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane004.geometry}
            material={materials["Material.032"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane004_1.geometry}
            material={materials["Material.031"]}
          />
        </group>
        <group position={[8.71, -1.283, -63.653]} scale={[0.164, 4.429, 2.057]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane045.geometry}
            material={materials["Material.032"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane045_1.geometry}
            material={materials["Material.031"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane045_2.geometry}
            material={materials["Material.029"]}
          />
        </group>
      </group>
    </RigidBody>
  );
}

useGLTF.preload("./assets/suncity/roads.glb");
