import { useProgress } from "@react-three/drei";
import { useEffect, useState } from "react";
import useSound from "use-sound";
import useLoader from "../stores/useLoader";
import useGame from "../stores/useGame";
import { StoryGenerationAPI } from "../services/storyGeneration";
import addInstructions from "../services/addInstructions";
import useDialogue from "../stores/useDialogue";
import useAssistant from "../stores/useAssistant";
import prompt from "./prompts/prompt";
import useInventory from "../stores/useInventory";
import useTimerStore from "../stores/useTimerStore";
import DemoCredentials from "./CredentialContainer";

export const LoadingScreen = (props) => {
  const { started, setStarted } = useLoader((state) => state);
  const { progress } = useProgress();
  const [showStartButton, setShowStartButton] = useState(false);
  const {
    isLoggedIn,
    setIsLoggedIn,
    topic,
    setTopic,
    setIframeUrl,
    toggleIsIframeOpen,
    UID,
    setGameSessionId,
    setIframePosition,
  } = useGame((state) => state);
  const {
    setCurrentNarrationList,
    dialogueLoader,
    setDialogLoader,
    setCharacters,
  } = useDialogue((state) => state);
  const {
    threadId,
    vectorStoreId,
    smartGraph,
    setThreadId,
    setAssistants,
    setPlot,
    setActiveAssistant,
    setGameSession,
    setSmartGraph,
    setSmartGraphVectorStoreId
  } = useAssistant((state) => state);
  const {
    isInventory
  } = useInventory((state) => state);
  const {
    startTimer
  } = useTimerStore((state) => state);
  const [showLoading, setShowLoading] = useState(true);
  const thoughtjumperUrl = import.meta.env.VITE_CLIENT_URI;
  const graphLibraryUrl = `${
    import.meta.env.VITE_CLIENT_URI
  }/widget/GraphLibraryWidget?toggleGraphLibraryView=%5B%5D`;
  // const [ playStartSound ] = useSound('./assets/audio/hello.wav', { volume: 0.3, interrupt: true })
  const [playBackgroundMusic] = useSound("./assets/audio/beach.mp3", {
    volume: 0.01,
    loop: true,
  });

  const handleSetTopic = (event) => {
    // console.log(event.target);
    setTopic(event.target.value);
  };

  const handleLogin = () => {
    setIframePosition('center')
    setIframeUrl(graphLibraryUrl);
    toggleIsIframeOpen();
  };

  const handleChooseSmartGraph = () => {
    setIframePosition('center')
    setIframeUrl(graphLibraryUrl);
    toggleIsIframeOpen();
  };

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
    setDialogLoader(true);
    // console.log("is Loading is:", dialogueLoader, prompt);
    StoryGenerationAPI.generateStory(topic, UID).then((response) => {
      setThreadId(response.threadId);
      setAssistants(response.assistants);
      setActiveAssistant(response.assistants[0]);
      setGameSession(response.gameSession);
      setSmartGraphVectorStoreId(response.vectorStoreId);
      setSmartGraph(response.gameSession.graph);
      // addInstructions(
      //   response.threadId,
      //   prompt.userConversationInstruction,
      //   UID
      // );
      // setPlot(response.plot);
      
      setShowStartButton(false);
      setShowLoading(false);
      // localStorage.setItem('gameSessionId', response.gameSessionId);
      // playBackgroundMusic();
      setDialogLoader(false);
      setIframePosition('right')
      // console.log(response);
    }).catch((error) => {
      setDialogLoader(false);
    });
  };

  useEffect(() => {
    if (smartGraph) {
      setDialogLoader(true);
      setIframePosition('right');
      // console.log('xvf', `Generate Story with the mentioned data in the thread`, UID, threadId);
      StoryGenerationAPI.generateStory(
        `and data mentioned in the thread of ${topic}`,
        UID,
        { threadId, vectorStoreId, smartGraph}
      ).then((response) => {
        setThreadId(response.threadId);
        setAssistants(response.assistants);
        setActiveAssistant(response.assistants[0]);
        setGameSession(response.gameSession);
        // addInstructions(
        //   response.threadId,
        //   prompt.userConversationInstruction,
        //   UID
        // );
        setPlot(response.plot);
        setShowStartButton(false);
        setShowLoading(false);
        // playBackgroundMusic();
        setDialogLoader(false);
        // console.log(response);
      }).catch((error) => {
        setDialogLoader(false);
        setIframePosition('center');
        handleChooseSmartGraph();
      });
    }
  }, [smartGraph]);

  // getting the loading screen of
  useEffect(() => {
    if (!showLoading && isInventory) {
      console.log('I get you here');
      handleStartClick();
      // startTimer();
    }
  }, [showLoading, isInventory])

  useEffect(() => {
    // console.log('xvf', total, loaded, progress, item);
    if (progress === 100) {
      setShowStartButton(true);
    }
  }, [progress]);

  const handleStartClick = () => {
    setStarted(true);
  };

  const isDebugMode = localStorage.getItem('DEBUG') === 'TRUE';

  return (
    <div className="conquest">
      <div className="conquest__logo">
        <img src={"/assets/loadingscreen/menu/logo.png"} alt="" />
      </div>
      {
        !isDebugMode && <DemoCredentials/>
      }

      {showLoading && (
        <div
          className={`
              fixed top-0 left-0 w-full h-full z-50 transition-opacity 
              duration-1000
              flex items-center justify-center conquest__background
              ${started ? "opacity-0" : "opacity-100"}
          `}
        >
          {showStartButton && progress === 100 && (
            <form className="conquest__form-container" onSubmit={handleSubmit}>
              {isLoggedIn ? (
                !dialogueLoader && (
                  <>
                    <div className="conquest__form">
                      <label>What Do You Want to Learn?</label>
                      <input
                        type="text"
                        value={topic}
                        onChange={handleSetTopic}
                        placeholder="Search Anything..."
                        className="conquest__input"
                      />
                    </div>
                    {topic.length < 1 && (
                      <>
                        <div className="conquest__form conquest__form--sm">
                          <label>OR</label>
                        </div>
                        <div className="">
                          <button
                            className="conquest__login-btn conquest__login-btn--sm"
                            onClick={handleChooseSmartGraph}
                            type="button"
                          >
                            <span>Start with Smart Graph</span>
                            <img src={"/assets/images/tj-logo.png"} alt="tj" />
                          </button>
                        </div>
                      </>
                    )}
                  </>
                )
              ) : (
                <div className="conquest__login-container">
                  <button
                    className="conquest__login-btn"
                    onClick={handleLogin}
                    type="button"
                  >
                    <span>Login with</span>
                    <img src={"/assets/images/tj-logo.png"} alt="tj" />
                  </button>
                </div>
              )}

              <>
                {dialogueLoader ? (
                  <GeneratingStoryLoader />
                ) : (
                  <button
                    className={`text-2xl md:text-3xl text-[#f5f5f5] transition-all duration-500 ${
                      topic.length > 5 ? "block" : "hidden"
                    }`}
                    type="submit"
                    style={{
                      border: "3px solid #f5f5f5",
                      borderRadius: "15px",
                      padding: "7px 10px",
                      marginTop: "1.2rem",
                    }}
                  >
                    Start Your Journey
                  </button>
                )}
              </>
            </form>
          )}
          {progress !== 100 && (
            <div className="text-3xl md:text-5xl font-bold text-[#010148] relative">
              <div
                className="absolute left-0 top-0  overflow-hidden truncate text-clip transition-all duration-500"
                style={{
                  width: `${progress}%`,
                }}
              >
                {progress.toFixed(0)}%
              </div>
              <div className="opacity-40">{progress.toFixed(0)}%</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

function GeneratingStoryLoader() {
  const LoaderNotificationList = [
    "Getting things Ready",
    "Generating the Environment",
    "This is taking a while",
    "Creating the Surface",
    "Adjusting the Lights",
    "Almost There!",
    "Get ready to play",
    "Please hang on with us",
    "Almost there...",
    "Are you Ready for the Show",
    "Getting Started...",
    "This is taking longer than expected",
    "It seems like your internet is slow",
    "Grabing the information",
    "Downloading the Content",
  ];
  const [currentNotification, setCurrentNotification] = useState(0);

  useEffect(() => {
    // console.log(currentNotification);
    setTimeout(() => {
      setCurrentNotification(currentNotification + 1);
    }, 6000);
  }, [currentNotification]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <div role="status">
        <svg
          aria-hidden="true"
          className="w-10 h-10 text-white-200 animate-spin dark:text-white-600 fill-blue-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span className="sr-only">Loading...</span>
      </div>
      <p style={{ marginLeft: ".5rem", color: "#fff", fontSize: "2.5rem" }}>
        {LoaderNotificationList[currentNotification]}
      </p>
    </div>
  );
}
