import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Heart(props) {
  const { nodes, materials } = useGLTF('./assets/inventory/heart.glb')
  return (
    <group {...props} dispose={null} scale={0.005} >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Heart2.geometry}
        material={materials['Heart Red']}
      />
    </group>
  )
}

useGLTF.preload('./assets/inventory/heart.glb')