import axios from "axios";
import suggestContent from "./suggestContent";
import generateKeyphrase from "./generateKeyphrase";
import prompt from "../components/prompts/prompt";

export default async function createDialogue(
  assistantId,
  threadId,
  vectorStoreId,
  transcript,
  topic,
  token
) {

  let userPrompt = prompt.assistantReplyFormat(transcript);
  const characterReqBody = {
    assistantId,
    memoryId: threadId,
    prompt: userPrompt,
  };
  const threadResponse = await axios.post(
    `${
      import.meta.env.VITE_SERVER_URI
    }/api/v1/game-story/assistant/handle-thread-run`,
    characterReqBody,
    {
      headers: {
        Authorization: `Bearer ${token}`, // Replace YOUR_TOKEN_HERE with your actual token
        "Content-Type": "application/json",
      },
    }
  );

  if (threadResponse.status === 200) {

    let keyphraseChunk = '';
    await generateKeyphrase(threadResponse.data, vectorStoreId, token, (decodedChunk) => {
      keyphraseChunk += decodedChunk;
    })
    const extractedKeyphrase = JSON.parse(keyphraseChunk).keyphrase;

    let content = [];
    try {
      // content = await suggestContent({ memoryId: threadId, keyphrase: extractedKeyphrase }, token);
      return { dialogue: threadResponse.data, keyphrase: extractedKeyphrase, ...content };
    } catch (error) {
      return {
        dialogue: threadResponse.data,
        keyphrase: extractedKeyphrase,
        intents: []
      };
    }
    // return {
    //     dialogue: threadResponse.data,
    //     youtube_url: "",
    //     intent_id: "",
    //     learning_intent_url: "",
    //   };
  } else {
    return {
      dialogue: "",
      youtube_url: "",
      intent_id: "",
      learning_intent_url: "",
    };
  }
}
