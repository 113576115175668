import React from "react";
import { useGLTF } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";

export default function RealmOfWind(props) {
  const { nodes, materials } = useGLTF("./assets/island/Realm-of-Wind.glb");

  return (
    <RigidBody type="fixed" colliders="hull">
      <group {...props} dispose={null}>
        <group position={[79.573, 10.79, -40.104]} scale={45.652}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube084.geometry}
            material={materials["wood 4"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube084_1.geometry}
            material={materials["wood 1"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube084_2.geometry}
            material={materials["wood 2"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube084_3.geometry}
            material={materials["wood 3"]}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.rock.geometry}
          material={materials["rock 1"]}
          position={[77.413, 2.686, -43.437]}
          scale={24.675}
        />
      </group>
    </RigidBody>
  );
}

useGLTF.preload("./assets/island/Realm-of-Wind.glb");
