import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Fuel(props) {
  const { nodes, materials } = useGLTF('./assets/inventory/fuel.glb')
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]} scale={50}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.GasCan_1.geometry}
          material={materials.Red}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.GasCan_2.geometry}
          material={materials.DarkRed}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.GasCan_3.geometry}
          material={materials.Black}
        />
      </group>
    </group>
  )
}

useGLTF.preload('./assets/inventory/fuel.glb')