import { forwardRef, useEffect, useRef, useState } from "react";
import { useFrame } from "@react-three/fiber";
import Ecctrl, { EcctrlAnimation, useJoystickControls } from "ecctrl";
import { useAnimations, useGLTF, useKeyboardControls } from "@react-three/drei";
import * as THREE from "three";
import { useRapier } from "@react-three/rapier";
import useSound from "use-sound";
import useGame from "../stores/useGame";
import { useControls } from "leva";
import { addSpawnPoint, getSpawnPoints } from "../services/addSpawnPoint";
import useKeyObserver from "./observers/useKeyObserver";
// import { createCoinPosition } from "./world/positions/CoinPositions";
import useInventory from "../stores/useInventory";

const LIMIT = 200;

const Character = forwardRef((props, character) => {
  const { nodes, materials, animations } = useGLTF("./assets/models/Ty.glb");
  const { actions } = useAnimations(animations, character);

  return (
    <group ref={character} {...props} dispose={null} position={[0, -0.3, 0]}>
      <group name="Scene">
        <group name="Boy_01_Meshes" scale={0.01} />
        <group name="Armature" scale={0.01}>
          <skinnedMesh
            name="Boy01_Hair_Geo"
            geometry={nodes.Boy01_Hair_Geo.geometry}
            material={materials.Boy01_Hair_MAT}
            skeleton={nodes.Boy01_Hair_Geo.skeleton}
          />
          <skinnedMesh
            name="Boy01_Hands_Geo"
            geometry={nodes.Boy01_Hands_Geo.geometry}
            material={materials.Boy01_Hands_MAT}
            skeleton={nodes.Boy01_Hands_Geo.skeleton}
          />
          <skinnedMesh
            name="Boy01_Head_Geo"
            geometry={nodes.Boy01_Head_Geo.geometry}
            material={materials.Boy01_Head_MAT}
            skeleton={nodes.Boy01_Head_Geo.skeleton}
          />
          <skinnedMesh
            name="Boy01_LowerBody_Geo"
            geometry={nodes.Boy01_LowerBody_Geo.geometry}
            material={materials.Boy01_LowerBody_MAT}
            skeleton={nodes.Boy01_LowerBody_Geo.skeleton}
          />
          <skinnedMesh
            name="Boy01_Scarf_Geo"
            geometry={nodes.Boy01_Scarf_Geo.geometry}
            material={materials.Boy01_Scarf_MAT}
            skeleton={nodes.Boy01_Scarf_Geo.skeleton}
          />
          <skinnedMesh
            name="Boy01_Shoes_Geo"
            geometry={nodes.Boy01_Shoes_Geo.geometry}
            material={materials.Boy01_Shoes_MAT}
            skeleton={nodes.Boy01_Shoes_Geo.skeleton}
          />
          <skinnedMesh
            name="Boy01_UpperBody_Geo"
            geometry={nodes.Boy01_UpperBody_Geo.geometry}
            material={materials.Boy01_UpperBody_MAT}
            skeleton={nodes.Boy01_UpperBody_Geo.skeleton}
          />
          <primitive object={nodes.mixamorigHips} />
        </group>
      </group>
    </group>
  );
});

const Player = forwardRef((props, ref) => {
  const getJoystickValues = useJoystickControls(
    (state) => state.getJoystickValues
  );

  const playerPos = useRef([]);

  const playerVelocity = JSON.parse(localStorage.getItem("velocity")) || 20;
  // console.log('xvf player velocity', playerVelocity);

  const showAfterImage = useGame((state) => state.showAfterImage);

  const respawnPosition = useGame((state) => state.respawnPosition);
  const { UID } = useGame((state) => state);

  // player
  const { updatePositionMatrix } = useInventory((state) => state);

  const [subscribeKeys, getKeys] = useKeyboardControls();
  const { rapier, world } = useRapier();
  const character = useRef();
  const [playAttackSound] = useSound("./assets/audio/rpg/swoosh.wav", {
    volume: 0.9,
    interrupt: true,
  });
  const [playImpactSound] = useSound("./assets/audio/rpg/impact.wav", {
    volume: 0.6,
    interrupt: true,
  });

  const origin = useRef();
  const target = useRef();

  // spawnPoint observer
  useKeyObserver((event) => {
    // console.log("xvf", "keyup", event, playerPos.current);
    // const DEBUG = localStorage.getItem("DEBUG") || "FALSE";
    const DEBUG = "FALSE";
    if (event.code === "KeyX" && UID && DEBUG === "TRUE") {
      console.log("xvf", "keyup", event, playerPos.current);
      const val = [playerPos.current];
      addSpawnPoint(UID, val)
        .then((res) => {
          alert("Spawn point added");
        })
        .catch((error) => {
          console.log(error.message);
          // alert('can\'t add Spawn Point');
        });
    }

    if (event.code === "KeyU" && UID && DEBUG === "TRUE") {
      getSpawnPoints(UID)
        .then((res) => {
          console.log(res);
          const val = res.values;
          const newCoinsPositions = createCoinPosition(val);
          console.log(newCoinsPositions);
          updatePositionMatrix(newCoinsPositions);
          // val.forEach((pos, index) => {
          //   if (index > 0) {
          //     console.log(parseFloat(pos[0]), parseFloat(pos[2]));
          //   }
          // })
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  });

  // First attack
  const Attack1 = () => {
    // Get origin position
    const originBlock = new THREE.Vector3(0, 0, 0);
    origin.current.getWorldPosition(originBlock);

    // Player location
    const playerPosition = ref.current.translation();
    const playerVector = new THREE.Vector3(
      playerPosition.x,
      playerPosition.y,
      playerPosition.z
    );

    // Get target position
    const targetBlock = new THREE.Vector3(0, 0, 0);
    target.current.getWorldPosition(targetBlock);

    // Create rapier ray
    const ray = new rapier.Ray(originBlock, targetBlock);
    let maxToi = 50.0;
    let solid = true;

    // Cast rapier ray
    const hit = world.castRay(ray, maxToi, solid);

    playAttackSound();

    if (hit) {
      setTimeout(() => {
        playImpactSound();
        hit.collider.parent().applyImpulse(
          {
            x: -(originBlock.x - targetBlock.x) * 10,
            y: -(originBlock.y - targetBlock.y) * 10,
            z: -(originBlock.z - targetBlock.z) * 10,
          },
          true
        );
        hit.collider.parent().applyTorqueImpulse({ x: 2, y: 2, z: 2 }, true);
      }, 500);
      // hit.collider.parent().applyImpulseAtPoint(10, hit.point, true)
      // console.log(hit.collider.parent().bodyType())
      // console.log(hit.collider.parent())
    }
  };

  // Second attack, smash up
  const Attack2 = () => {
    // Attack vars
    let canAttack = true;
    let hasHit = false;
    let attackTimer = 0;

    // Get origin position
    const originBlock = new THREE.Vector3(0, 0, 0);
    origin.current.getWorldPosition(originBlock);

    // Get target position
    const targetBlock = new THREE.Vector3(0, 0, 0);
    target.current.getWorldPosition(targetBlock);

    // Create rapier ray
    const ray = new rapier.Ray(originBlock, targetBlock);
    let maxToi = 50.0;
    let solid = true;

    // Cast rapier ray
    const hit = world.castRay(ray, maxToi, solid);

    if (canAttack) {
      playAttackSound();
      // Set attack temp to false
      canAttack = false;

      if (hit && !hasHit) {
        hasHit = true;
        setTimeout(() => {
          playImpactSound();
          hit.collider.parent().applyImpulse(
            {
              x: -(originBlock.x - targetBlock.x) * 10,
              y: 10,
              z: -(originBlock.z - targetBlock.z) * 10,
            },
            true
          );
          hit.collider.parent().applyTorqueImpulse({ x: 0, y: 2, z: 0 }, true);
          canAttack = true;
          hasHit = false;
        }, 500);
      } else {
        // Timeout after attack
        setTimeout(() => {
          canAttack = true;
        }, 500);
      }
    }
  };

  useFrame(() => {
    // console.log(getJoystickValues().button1Pressed)
    // console.log(getJoystickValues().button2Pressed)
    // console.log(getJoystickValues().button3Pressed)
    // console.log(getJoystickValues().button4Pressed)

    if (ref.current) {
      const charPosition = ref.current.translation();

      // console.log("player-position", charPosition);

      const { action4, action2, action3, fly, mark } = getKeys();

      if (action4 || getJoystickValues().button2Pressed) {
        Attack1();
      }

      if (action2 || action3) {
        Attack2();
      }
      // Apply forces
      // const impulse = { x: 0, y: 0, z: 0 }
      // const torque = { x: 0, y: 0, z: 0 }

      // const impulseStrength = 1 * delta
      // const torqueStrength = 0.01 * delta

      // if(up)
      // {
      //     impulse.y += impulseStrength
      //     // torque.y += torqueStrength
      // }
      // if(down)
      // {
      //     impulse.y -= impulseStrength
      //     // torque.y -= torqueStrength
      // }
      // // Apply impulses and torque impulses to player
      // ref.current.applyImpulse(impulse)
      // ref.current.applyTorqueImpulse(torque)

      // Reset player
      // if(charPosition.x < - LIMIT || charPosition.x > LIMIT || charPosition.y < - LIMIT || charPosition.y > LIMIT || charPosition.z < - LIMIT || charPosition.z > LIMIT )
      // {
      //     // console.log(charPosition.y)
      //     ref.current.setTranslation( { x: 0, y: 10, z: 0 } )
      //     ref.current.setLinvel( { x: 0, y: 0, z: 0 } )
      //     ref.current.setAngvel( { x: 0, y: 0, z: 0 } )
      // }
      if (charPosition.y < -1) {
        // console.log(charPosition.y)
        ref.current.setTranslation({
          x: respawnPosition.x,
          y: respawnPosition.y,
          z: respawnPosition.z,
        });
        ref.current.setLinvel({ x: 0, y: 0, z: 0 });
        ref.current.setAngvel({ x: 0, y: 0, z: 0 });
      }

      if (fly) {
        const playerPosition = ref.current.translation();
        ref.current.setTranslation({
          x: playerPosition.x,
          y: playerPosition.y + 1,
          z: playerPosition.z,
        });
      }

      if (mark) {
        // console.log('xvf', 'spawn function', mark, playerPosition, UID, DEBUG);
        playerPos.current = [charPosition.x, charPosition.y, charPosition.z];
      }
    }
  });

  const characterURL = "./assets/models/wizard.glb";
  const animationSet = {
    idle: "Idle",
    walk: "Walk",
    run: "Run",
    jump: "Jump",
    jumpIdle: "Falling",
    jumpLand: "Landing",
    fall: "Falling",
    fly: "fly",
    // action1: "3",
    action2: "2",
    action3: "3",
    action4: "1",
  };

  return (
    <>
      <Ecctrl
        debug={false}
        ref={ref}
        capsuleRadius={0.3}
        capsuleHalfHeight={0.5}
        camInitDis={-20}
        camMaxDis={-30}
        camMinDis={-3}
        // camInitDis={-10}
        // camMaxDis={-30}
        // camMinDis={-0.1}
        animated={true}
        position={[67.88, 33.7, -13.74]}
        maxVelLimit={playerVelocity}
        sprintMult={2}
        jumpVel={5}
        characterInitDir={-Math.PI * 0.5}
        camInitDir={{ x: 0, y: -Math.PI * 0.65, z: 0 }}
        floatHeight={0.3}
        mode="FixedCamera"
        // mode="CameraBasedMovement"
        // mode={"PointToMove"}
        // camMoveSpeed={0.07}
        // camCollisionOffset={1}
        // camTargetPos={{ x: 0, y: 2, z: 0 }}
        autoBalance={false}
        // autoBalanceDampingOnY={autoBalanceDampingOnY}
        // slopeMaxAngle={2}
        // gravityScale={ 0 }
        friction={1}
        // turnVelMultiplier={1.5}
        // turnSpeed={4} // Turn speed
        // camCollision={ false }
        // turnSpeed={4}
        // jumpForceToGroundMult={ 20 }
        // characterInitDir={ 160 }
        // camInitDir={ Math.PI * 1 }
      >
        <EcctrlAnimation
          characterURL={characterURL}
          animationSet={animationSet}
        >
          <group>
            <Character ref={character} />
            <group position={[0, 0.5, 4]} ref={target}></group>
            <group position={[0, 0.5, 2]} ref={origin}></group>
            {/* {
              showAfterImage && <AlphaCharacter/>
            } */}
          </group>
        </EcctrlAnimation>
      </Ecctrl>
    </>
  );
});

export default Player;

useGLTF.preload("./assets/models/wizard.glb");
useGLTF.preload("./assets/models/robin.glb");
useGLTF.preload("./assets/models/Alicia.glb");
useGLTF.preload("./assets/models/Ty.glb");
