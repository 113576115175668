import React, { useEffect, useState } from "react";
import styled, { keyframes, css } from "styled-components";
import useTimerStore from "./stores/useTimerStore"; // Adjust the import path as needed
import useGame from "./stores/useGame";

// Animation for the alert state
const glow = keyframes`
  0% {
    box-shadow: 0 0 3px 3px #fe330e;
  }
  50% {
    box-shadow: 0 0 10px 10px #fe330e;
  }
  100% {
    box-shadow: 0 0 3px 3px #fe330e;
  }
`;

// Styled component for the timer container
const TimerContainer = styled.div`
  position: fixed;
  top: 1rem;
  right: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  backdrop-filter: blur(4px);
  background: #00000080;
  font-weight: bold; /* Semi-transparent black background */
  color: white;
  font-size: 1.5rem;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 14rem;
  ${(props) =>
    props.alert &&
    css`
      animation: ${glow} 1s infinite;
    `}
`;

// Timer component
const Timer = () => {
  const [localTime, setLocalTime] = useState(0);
  const time = useTimerStore((state) => state.time); // Accessing the time state from the store
  const isActive = useTimerStore((state) => state.isActive); // Accessing the isActive state from the store
  const alertLimit = useTimerStore((state) => state.alertLimit); // Accessing the alertLimit state from the store
  const setTime = useTimerStore((state) => state.setTime); // Function to set the time state
  const startTimer = useTimerStore((state) => state.startTimer); // Function to start the timer
  const { setGameOver } = useGame((state) => state);

  // Sync local time with store time
  useEffect(() => {
    setLocalTime(time);
  }, [time]);

  // Start the timer when the component mounts
  useEffect(() => {
    if (isActive) {
      startTimer();
    }
  }, [isActive]);

  // Manage the timer interval
  useEffect(() => {
    let interval;
    if (isActive) {
      // If the timer is active, set up the interval
      interval = setInterval(() => {
        setLocalTime((prevLocalTime) => {
          if (prevLocalTime > 0) {
            setTime(prevLocalTime - 100);
            return prevLocalTime - 100; // Decrease time by 25 milliseconds
          } else {
            clearInterval(interval);
            setGameOver(true);
            console.log('xvf', 'game over');
            setTime(0);
            return 0;
          }
        });
      }, 10); // Set interval to 1 millisecond
    } else if (!isActive) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, setTime]);

  // Function to format the time
  const formatTime = (time) => {
    if (time < 0 || isNaN(time)) return "00 : 00 : 00";
    const getMilliseconds = `00${Math.floor((time % 1000) / 10)}`.slice(-2);
    const seconds = Math.floor(time / 1000);
    const getSeconds = `0${seconds % 60}`.slice(-2);
    const minutes = Math.floor(seconds / 60);
    const getMinutes = `0${minutes % 60}`.slice(-2);
    return `${getMinutes} : ${getSeconds} : ${getMilliseconds}`;
  };

  const isAlert = localTime <= alertLimit;

  return (
    <TimerContainer alert={isAlert}>{formatTime(localTime)}</TimerContainer>
  );
};

export default Timer;
