import { createExecute, createNarration } from "../components/config/Narrations";
import { generateRandomNumber } from "../utils/random";

export const callTriggerWithInventoryItem = (state) => {
  const collectedItem = state.currentCollectedItem;
};

export function executeNarrationWithVideo(keyphrase) {
  // append youtube video
  const youtubeVideoExecute = createExecute(
    "watching video ",
    `${
      import.meta.env.VITE_CLIENT_URI
    }/widget/VideosWidget?setCurrentThought=[${keyphrase}]`
  );
  return youtubeVideoExecute;
}

export function executeEssayAnswering(keyphrase, goalId) {
  let essayWritingExecute;
  // append essay answering
  if (goalId) {
    essayWritingExecute = createExecute(
      'Writing Essay',
      `${import.meta.env.VITE_CLIENT_URI}/widget/EditorWidget?addNoteValue=[${keyphrase},${goalId}]`
    )
  } else {
    essayWritingExecute = createExecute(
      'Writing Essay',
      `${import.meta.env.VITE_CLIENT_URI}/widget/EditorWidget?addNoteValue=[${keyphrase}]`
    )
  }

  return essayWritingExecute;
}

export function executeNarrationWithHint() {
  let hints = [
    'The timer ensures that you start the game when you are ready',
    'Navigate around using WASD keys. ',
    'The mentor can try and answer all your questions. Just press T and ask your question',
    'Collect to access and watch a video related to your topic',
    'Collect to gain a superpower that adds more time to your game session',
    'Set and track learning goals by taking fuel cans in the game,it only appears when you have completed your current goal',
    'Take notes and answer subjective questions',
    'Collect to unlock a quiz related to the topic you chose. Each quiz carries 10 questions with a point each. You can evaluate your answers after submitting the quiz.',
  ]

  const number = generateRandomNumber(3);
  console.log('xvf', 'random number', hints[number]);

  const narration = createNarration('Hint!', `Hint!:-${hints[number]} `);
  return narration;
}

export function executeNarrationWithGoal() {
  const learningGoals = [
    "Understanding the Basics of Unemployment",
    "Overview of India's Economy and Employment Landscape",
    "Analyzing Current Unemployment Trends in India",
    "Understanding the Causes and Consequences of Unemployment in India",
    "Exploring Solutions and Policy Interventions"
  ];

  const number = generateRandomNumber(learningGoals.length);
  return learningGoals[number];
}

export function superJumpWithTimer() {
  const narration = createNarration('Timer', `Edna!:-Wow! You Got 3 more minutes `);
  return narration;
}

export function executeNarrationWithAskMeQuestion(keyphrase, goalId) {
  const askMeQuestionExecute = createExecute(
    "ask me question",
    `${
      import.meta.env.VITE_CLIENT_URI
    }/widget/AskMeQuestionWidget?setCurrentThought=[${keyphrase},668241952d18558c8762aa98,${goalId}]`
    );
    return askMeQuestionExecute;
}

export function executeFuntionWithDidYouKnow() { 
  const narration = createNarration('Coin', `Edna!:-Coming Soon `);
  return narration;
}

export function executeNarrationWithEdSkills() {
  const edSkillsExecute = createExecute(
    'EdSkill',
    `${import.meta.env.VITE_EDSKILL_URI}/mod/assign/view.php?id=48%27`
  );
  return edSkillsExecute;
}

export function executeNarrationWithReports() {
  const reportsExecute = createExecute(
    'Reports',
    `${import.meta.env.VITE_CLIENT_URI}/experiment`
  );
  return reportsExecute;
}


export function executeNarrationWithGoalCompleted() {
  const goalCompletedNarration = 'Congratulation on completing this milestone. Feel free to share your thoughts with me on this journey on what you would like to do next by pressing T. I would be more than happy to help you and guide in all manners possible. So dont hesitate to talk to me and when you are done talking to me, get yourself fueled up for the next goal.';
  const narration = createNarration('Coin', `Edna!:-${goalCompletedNarration} `);
  return narration;
}