import {
  Sky,
  Environment,
  Clouds,
  Cloud,
  ContactShadows,
  Stars,
} from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { useRef } from "react";
import * as THREE from "three";

export default function Lights(props) {
  const { char, downgradedPerformance } = props;
  const light = useRef();
  const pointLight = useRef();
  // useHelper(light, THREE.DirectionalLightHelper, "red");
  const sky = useRef();
  // useHelper(sky, THREE.BoxHelper, "red");

  useFrame(() => {
    if (char.current) {
      // Make lights follow the player
      const charPosition = char.current.translation();
      
      // console.log('xvf', pointLight.current);

        // light.current.position.z = charPosition.z + 30;
        // light.current.target.position.z = charPosition.z - 4;

        // light.current.position.x = charPosition.x + 4 - 4;
        // light.current.target.position.x = charPosition.x - 4;

        // light.current.position.y = charPosition.y + 15;
        // light.current.target.position.y = charPosition.y;

        // light.current.target.updateMatrixWorld();

      // pointRef.current.position.z = charPosition.z + 2
      // pointRef.current.position.x = charPosition.x + 2
      // pointRef.current.position.y = charPosition.y + 2

      // light.current.updateMatrixWorld()

      // console.log(pointRef.current.position)

      sky.current.position.z = charPosition.z + 1 - 4;
      sky.current.position.x = charPosition.x + 1 - 4;
      sky.current.position.y = charPosition.y + 1 - 4;
      sky.current.updateMatrixWorld();
    }
  });

  return (
    <>
      {!downgradedPerformance && (
        <>
          <Clouds position={[0, 20, 0]} material={THREE.MeshBasicMaterial}>
            <Cloud
              segments={200}
              bounds={[200, 1, 200]}
              volume={10}
              color="white"
              // fade={ 0.05 }
            />
            {/* <Cloud 
                    seed={ 20 } 
                    scale={ 2 } 
                    volume={ 10 } 
                    color="white" 
                /> */}
          </Clouds>
          <ContactShadows />
        </>
      )}
      <Environment
        // preset='sunset'
        // files="./assets/images/map.hdr"
        files="./assets/images/sunset_2.hdr"
        frames={5}
        blur={0.5}
        // background="only"
      />
      {/* <ambientLight 
            intensity={ 0.5 } 
        /> */}
      <directionalLight
        position={[-11.480, 52.383, 27.242]}
        intensity={0.1}
      />
      <directionalLight
        ref={light}
        castShadow={!downgradedPerformance}
        color={"white"}
        position={[11.480, 35.383, 15.242]}
        intensity={4}
        shadow-camera-near={0.1}
        shadow-camera-far={75}
        shadow-camera-top={75}
        shadow-camera-right={75}
        shadow-camera-bottom={-75}
        shadow-camera-left={-75}
        shadow-bias={-0.01}
        shadow-mapSize-width={2048}
        shadow-mapSize-height={2048}
        shadow-mapSize={ [ 1024, 1024 ] }
      />
      {/* day */}
      <Sky
        ref={sky}
        // color={'orange'}
        distance={80000}
        // // azimuth={0}
        sunPosition={[15, 0.3, 20]}
        mieCoefficient={0.0001}
        // inclination={0}
      />
      {/* night */}
      {/* <Sky
        ref={sky}
        // color={'orange'}
        distance={80000}
        // // azimuth={0}
        sunPosition={[1, 0, 1]}
        mieCoefficient={0.6}
        // inclination={0}
      /> */}
      <Stars
        ref={sky}
        // radius={ 400 }
        // depth={ 100 }
        // count={ 2000 }
        // speed={ 0.5 }
        // factor={ 2 }
        // fade={ !downgradedPerformance }
      />
      {/* <pointLight
        intensity={5}
        decay={2}
        position={[-70.201, 8.789, -2.853]}
        rotation={[-Math.PI / 2, 0, 0]}
      />
      <pointLight
        intensity={5}
        decay={2}
        position={[1.24, 11.585, 51.941]}
        rotation={[-Math.PI / 2, 0, 0]}
      />
      <pointLight
        intensity={5}
        decay={2}
        color="#dfe8df"
        position={[17.923, 4.179, 2.962]}
        rotation={[-Math.PI / 2, 0, 1.083]}
        scale={3.942}
        castShadow={true}
      /> */}
      {/* <directionalLight
        intensity={8.6}
        decay={2}
        position={[-18.102, 40.644, -45.965]}
        rotation={[-2.132, 0.789, -0.729]}
        castShadow={true}
        shadow-camera-near={0.1}
        shadow-camera-far={150}
        shadow-camera-top={75}
        shadow-camera-right={150}
        shadow-camera-bottom={-150}
        shadow-camera-left={-150}
        shadow-bias={-0.01}
        shadow-mapSize-width={8192}
        shadow-mapSize-height={8192}
      /> */}
      {/* <directionalLight intensity={8.6} castShadow position={[18.102, 30.644, -45.965]} shadow-bias={-0.01} shadow-mapSize={[4096, 4096]}>
        <orthographicCamera attach="shadow-camera" args={[0.1, -200, 200, 200, -200]} />
      </directionalLight> */}
    </>
  );
}

// export default function Lights(props) {
//   const { char, downgradedPerformance } = props;
//   const light = useRef();
//   const sky = useRef();

//   useFrame(() => {
//     if (char.current) {
//       // Make lights follow the player
//       const charPosition = char.current.translation();

//       light.current.position.z = charPosition.z + 4 - 4;
//       light.current.target.position.z = charPosition.z - 4;

//       light.current.position.x = charPosition.x + 4 - 4;
//       light.current.target.position.x = charPosition.x - 4;

//       light.current.position.y = charPosition.y + 30;
//       light.current.target.position.y = charPosition.y;

//       light.current.target.updateMatrixWorld();

//       // pointRef.current.position.z = charPosition.z + 2
//       // pointRef.current.position.x = charPosition.x + 2
//       // pointRef.current.position.y = charPosition.y + 2

//       // pointRef.current.updateMatrixWorld()

//       // console.log(pointRef.current.position)

//       sky.current.position.z = charPosition.z + 1 - 4;
//       sky.current.position.x = charPosition.x + 1 - 4;
//       sky.current.position.y = charPosition.y + 1 - 4;
//       sky.current.updateMatrixWorld();
//     }
//   });

//   return (
//     <>
//       {!downgradedPerformance && (
//         <>
//           <Clouds position={[0, 20, 0]} material={THREE.MeshBasicMaterial}>
//             <Cloud
//               segments={200}
//               bounds={[200, 1, 200]}
//               volume={10}
//               color="white"
//               // fade={ 0.05 }
//             />
//             {/* <Cloud
//                     seed={ 20 }
//                     scale={ 2 }
//                     volume={ 10 }
//                     color="white"
//                 /> */}
//           </Clouds>
//           <ContactShadows />
//         </>
//       )}
//       <Environment
//         // preset='sunset'
//         // files="./assets/images/map.hdr"
//         files="./assets/images/sunset_2.hdr"
//         background="only"
//       />
//       {/* <ambientLight
//             intensity={ 0.1 }
//         /> */}
//       <directionalLight
//         ref={light}
//         castShadow={!downgradedPerformance}
//         // color={ 'brown' }
//         position={[1, 1, 1]}
//         intensity={1}
//         shadow-camera-near={0.1}
//         shadow-camera-far={75}
//         shadow-camera-top={75}
//         shadow-camera-right={75}
//         shadow-camera-bottom={-75}
//         shadow-camera-left={-75}
//         shadow-bias={-0.01}
//         shadow-mapSize-width={1024}
//               shadow-mapSize-height={1024}
//         // shadow-mapSize={ [ 1024, 1024 ] }
//       />
//       {/* day */}
//       <Sky
//             ref={ sky }
//             // color={'orange'}
//             distance={80000}
//             // // azimuth={0}
//             sunPosition={[1, 0.1, 1]}
//             mieCoefficient={0.0001}
//             // inclination={0}
//       />
//       {/* night */}
//       {/* <Sky
//         ref={sky}
//         // color={'orange'}
//         distance={80000}
//         // // azimuth={0}
//         sunPosition={[1, 0, 1]}
//         mieCoefficient={0.6}
//         // inclination={0}
//       /> */}
//       <Stars
//         ref={sky}
//         // radius={ 400 }
//         // depth={ 100 }
//         // count={ 2000 }
//         // speed={ 0.5 }
//         // factor={ 2 }
//         // fade={ !downgradedPerformance }
//       />
//     </>
//   );
// }
