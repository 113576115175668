import React, { useEffect } from 'react';
import useAssistant from '../stores/useAssistant';
import styled from 'styled-components';

const AssistantsContainer = styled.div`
  position: fixed;
  top: 6rem;
  left: 1rem;
  background: rgba(200, 200, 200, 0.5);
  border-radius: 10px;
  padding: 1rem;
  backdrop-filter: blur(5px);
`;

const AssistantItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  cursor: pointer;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const AssistantIndicator = styled.div`
  width: 1rem;
  height: 1rem;
  background-color: ${({ isActive }) => (isActive ? '#a8d5ba' : '#ff6961')};
  border-radius: 50%;
  margin-right: 0.5rem;
`;

const AssistantName = styled.span`
  font-family: 'Comic Sans MS', cursive, sans-serif;
`;

const Assistants = () => {
  const { assistants, activeAssistant, setActiveAssistant } = useAssistant((state) => state);
  // console.log('xvf', assistants);

  // useEffect(() => {
  //   // If there is any need to fetch or update the assistants list, you can do it here.
  // }, [assistants.length]);

  return (
    <AssistantsContainer>
      {assistants.map((assistant, index) => (
        <AssistantItem key={index} onClick={() => setActiveAssistant(assistant)}>
          <AssistantIndicator isActive={activeAssistant.assistant_id === assistant.assistant_id} />
          <AssistantName>{assistant.name}</AssistantName>
        </AssistantItem>
      ))}
    </AssistantsContainer>
  );
};

export default Assistants;
