import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";

export default function CherryTrees(props) {
  const { nodes, materials } = useGLTF("./assets/island/Cherry-trees.glb");
  return (
    <group {...props} dispose={null}>
      <RigidBody type="fixed" colliders="trimesh">
        <group position={[-68.326, 0.987, -12.516]} scale={24.675}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane005.geometry}
            material={materials.TREE1}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane005_1.geometry}
            material={materials.tree2}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane005_2.geometry}
            material={materials["tree 3"]}
          />
        </group>
      </RigidBody>
      <RigidBody type="fixed" colliders="trimesh">
        <group position={[-8.801, 1.88, -25.366]} scale={24.675}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane049.geometry}
            material={materials.TREE1}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane049_1.geometry}
            material={materials.tree2}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane049_2.geometry}
            material={materials["tree 3"]}
          />
        </group>
      </RigidBody>
      <RigidBody type="fixed" colliders="trimesh">
        <group position={[-74.109, 0.684, -28.072]} scale={24.675}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane050.geometry}
            material={materials.TREE1}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane050_1.geometry}
            material={materials.tree2}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane050_2.geometry}
            material={materials["tree 3"]}
          />
        </group>
      </RigidBody>
      <RigidBody type="fixed" colliders="trimesh">
        <group position={[6.739, 1.909, 21.363]} scale={24.675}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane051.geometry}
            material={materials.TREE1}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane051_1.geometry}
            material={materials.tree2}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane051_2.geometry}
            material={materials["tree 3"]}
          />
        </group>
      </RigidBody>
      <RigidBody type="fixed" colliders="trimesh">
        <group position={[6.739, 1.909, 26.688]} scale={24.675}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane052.geometry}
            material={materials.TREE1}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane052_1.geometry}
            material={materials.tree2}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane052_2.geometry}
            material={materials["tree 3"]}
          />
        </group>
      </RigidBody>
      <RigidBody type="fixed" colliders="trimesh">
        <group position={[80.359, 2.159, -30.41]} scale={24.675}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane003.geometry}
            material={materials.TREE1}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane003_1.geometry}
            material={materials.tree2}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane003_2.geometry}
            material={materials["tree 3"]}
          />
        </group>
      </RigidBody>
      <RigidBody type="fixed" colliders="trimesh">
        <group position={[79.024, 2.259, -46.637]} scale={24.675}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane004.geometry}
            material={materials.TREE1}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane004_1.geometry}
            material={materials.tree2}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane004_2.geometry}
            material={materials["tree 3"]}
          />
        </group>
      </RigidBody>
      <RigidBody type="fixed" colliders="trimesh">
        <group position={[-39.357, 1.816, -9.576]} scale={24.675}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane002.geometry}
            material={materials["TREE1.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane002_1.geometry}
            material={materials["tree2.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane002_2.geometry}
            material={materials["tree 3.001"]}
          />
        </group>
      </RigidBody>
      <RigidBody type="fixed" colliders="trimesh">
        <group position={[-49.542, 0.684, -27.513]} scale={24.675}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane018.geometry}
            material={materials["TREE1.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane018_1.geometry}
            material={materials["tree2.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane018_2.geometry}
            material={materials["tree 3.002"]}
          />
        </group>
      </RigidBody>
      <RigidBody type="fixed" colliders="trimesh">
        <group position={[3.938, 1.791, 9.476]} scale={24.675}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane020.geometry}
            material={materials["TREE1.004"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane020_1.geometry}
            material={materials["tree2.004"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane020_2.geometry}
            material={materials["tree 3.004"]}
          />
        </group>
      </RigidBody>
      <RigidBody type="fixed" colliders="trimesh">
        <group position={[13.432, 1.384, -74.247]} scale={24.675}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane023.geometry}
            material={materials["TREE1.005"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane023_1.geometry}
            material={materials["tree2.005"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane023_2.geometry}
            material={materials["tree 3.005"]}
          />
        </group>
      </RigidBody>
    </group>
  );
}

useGLTF.preload("./assets/island/Cherry-trees.glb");
