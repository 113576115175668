import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";

export default function RealmOfLight(props) {
  const { nodes, materials } = useGLTF("./assets/island/Realm-of-Light.glb");
  return (
    <>
      <RigidBody type="fixed" colliders="cuboid">
        <group {...props} dispose={null}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane006.geometry}
            material={materials["Material.002"]}
            position={[-73.858, 4.15, 0.508]}
            scale={24.675}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane007.geometry}
            material={materials["Material.002"]}
            position={[-72.223, 4.224, -9.195]}
            scale={24.675}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane008.geometry}
            material={materials["Material.002"]}
            position={[-66.28, 2.791, -7.766]}
            scale={24.675}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane002.geometry}
            material={materials["Material.003"]}
            position={[-72.381, 3.636, -4.176]}
            scale={24.675}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane004.geometry}
            material={materials["Material.003"]}
            position={[-72.381, 2.294, -4.176]}
            scale={24.675}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane.geometry}
            material={materials["Material.004"]}
            position={[-72.381, 3.636, -4.176]}
            scale={24.675}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane003.geometry}
            material={materials["Material.003"]}
            position={[-72.381, 3.636, -4.176]}
            scale={24.675}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane005.geometry}
            material={materials.Material}
            position={[-72.381, 6.222, -4.176]}
            scale={24.675}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Stone_Slab.geometry}
            material={nodes.Stone_Slab.material}
            position={[-69.2, 3.715, -6.711]}
            scale={24.675}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube016.geometry}
            material={nodes.Cube016.material}
            position={[-74.068, 3.691, -1.732]}
            scale={24.675}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube017.geometry}
            material={nodes.Cube017.material}
            position={[-73.083, 3.692, -4.01]}
            scale={24.675}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube018.geometry}
            material={nodes.Cube018.material}
            position={[-72.816, 3.705, -0.22]}
            scale={24.675}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube019.geometry}
            material={nodes.Cube019.material}
            position={[-71.935, 3.677, -6.207]}
            scale={24.675}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube020.geometry}
            material={nodes.Cube020.material}
            position={[-72.071, 2.347, 1.877]}
            scale={24.675}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube021.geometry}
            material={nodes.Cube021.material}
            position={[-70.923, 2.294, -0.125]}
            scale={24.675}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube022.geometry}
            material={nodes.Cube022.material}
            position={[-67.745, 2.296, -7.024]}
            scale={24.675}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube023.geometry}
            material={nodes.Cube023.material}
            position={[-74.564, 2.39, 0.139]}
            scale={24.675}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube024.geometry}
            material={nodes.Cube024.material}
            position={[-76.297, 1.649, 2.176]}
            scale={24.675}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube025.geometry}
            material={nodes.Cube025.material}
            position={[-70.646, 2.364, -9.048]}
            scale={24.675}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube026.geometry}
            material={nodes.Cube026.material}
            position={[-69.292, 2.498, -8.839]}
            scale={24.675}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube027.geometry}
            material={nodes.Cube027.material}
            position={[-74.35, 2.294, -6.314]}
            scale={24.675}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube028.geometry}
            material={nodes.Cube028.material}
            position={[-75.726, 2.294, -4.482]}
            scale={24.675}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube029.geometry}
            material={nodes.Cube029.material}
            position={[-77.298, 3.044, -1.013]}
            scale={24.675}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube030.geometry}
            material={nodes.Cube030.material}
            position={[-71.336, 3.688, -4.518]}
            scale={24.675}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube031.geometry}
            material={nodes.Cube031.material}
            position={[-65.509, 0.954, -6.36]}
            scale={24.675}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube032.geometry}
            material={nodes.Cube032.material}
            position={[-65.38, 0.952, -8.163]}
            scale={24.675}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube033.geometry}
            material={nodes.Cube033.material}
            position={[-68.856, 0.958, 1.567]}
            scale={24.675}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube034.geometry}
            material={nodes.Cube034.material}
            position={[-69.731, 1.154, 2.632]}
            scale={24.675}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Crystal_1_LP003.geometry}
            material={materials.Magic_tower}
            position={[-72.209, 13.896, -4.201]}
            scale={24.675}
          />
        </group>
      </RigidBody>
    </>
  );
}

useGLTF.preload("./assets/island/Realm-of-Light.glb");
