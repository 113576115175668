import { useEffect } from "react";
import useGame from "../../stores/useGame";
import useDialogue from "../../stores/useDialogue";
import useSound from "use-sound";
import useAssistant from "../../stores/useAssistant";
import addInstructions from "../../services/addInstructions";

export default function useNarrationObserver(props) {
  const narration = useDialogue((state) => state.narration);
  const sceneList = useDialogue((state) => state.sceneList);
  const setNarration = useDialogue((state) => state.setNarration);
  const setIframeUrl = useGame((state) => state.setIframeUrl);
  const isIframeOpen = useGame((state) => state.isIframeOpen);
  const toggleIsIframeOpen = useGame((state) => state.toggleIsIframeOpen);
  const pauseGame = useGame((state) => state.pauseGame);
  const setDialogue = useDialogue((state) => state.setDialogue);
  const setChoices = useDialogue((state) => state.setChoices);
  const clearChoices = useDialogue((state) => state.clearChoices);
  const clearDialogue = useDialogue((state) => state.clearDialogue);
  const setIsLoading = useGame((state) => state.setIsLoading);
  const UID = useGame((state) => state.UID);
  const gameSessionId = useGame((state) => state.gameSessionId);
  const { message, threadId } = useAssistant((state) => state);

  const [playsound] = useSound("./assets/audio/rpg/game-end.mp3", {
    volume: 0.5,
    interrupt: true,
  });

  useEffect(() => {
    if(isIframeOpen) {
      if (narration && narration.type === "execute") {
        if (narration.trigger === "browser") {
          // console.log("xvf - nishcay brother", narration);
          
          if (narration.url === `${import.meta.env.VITE_CLIENT_URI}/game/feedback`) {
            let iframe = document.querySelector('.window__iframe');
            // Send a message to the iframe
            iframe.addEventListener('load', () => {
              iframe.contentWindow.postMessage(gameSessionId, `${import.meta.env.VITE_CLIENT_URI}/game/feedback`);
            })
          }
        }
        
      }
    }
  }, [isIframeOpen])

  // Add instructions to the thread
  useEffect(() => {
    if (message.length > 0) {
      addInstructions(threadId, message, UID);
    }
  }, [message]);
  
  useEffect(() => {
    if (narration) {
      // clearDialogue();
      if (narration && narration.type === "execute") {
        // Execute Browser
        if (narration.trigger === "browser") {
          setIframeUrl(narration.url);
          if (!isIframeOpen) {
            setTimeout(() => {
              setIsLoading(false);
              toggleIsIframeOpen();
            }, narration.timeout);
          }
        }
        // Execute Sound
        if (narration.trigger === "sound") {
          setTimeout(() => {
            playsound();
          }, narration.timeout);
          setNarration(narration.next);
        }
        // Execute Command
        if (narration.trigger === 'command') {
          if (narration.command === 'toggleAssistant') {
            // setActiveAssistant(assistants.find((e) => e.id !== activeAssistant.di))
          }
        }
      } else if (narration && narration.type === "dialogue") {
        if (narration.trigger === "choices") {
          clearChoices();
          clearDialogue();
          setDialogue(narration.text);
          setChoices(narration.choices);
        }
        setNarration(narration.next);
      } else if (narration && narration.type === "narration") {
        // console.log("xvf", narration);
        setIsLoading(true);
        if (narration.source === "system") {
          clearDialogue();
          setDialogue(narration.text);
          // console.log("pkk", narration.timeout);
          // setTimeout(() => {
          //   // confusionA1 : why it goes away so quickly
          //   clearDialogue();
          //   setNarration(narration.next);
          //   setIsLoading(false);
          // }, narration.timeout);
        }

        // if key is onStart
        if (narration.key === "onStart") {
          setTimeout(() => {
            pauseGame(false);
          }, 5000);
        }

      }
    }
    // if narration is null for more than 5 seconds
    if (narration === null) {
      pauseGame(false);
    }
  }, [narration]);
}
