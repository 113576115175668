import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

export default create(
  subscribeWithSelector((set) => {
    return {
      narration: null,
      sceneList: [],
      currentNarrationList: null,
      dialogueLoader: false,
      characters: {},
      showDialogue: false,
      setShowDialogue: (val) => set({ showDialogue: val }),
      setDialogLoader: (val) => set({ dialogueLoader: val}),
      setCurrentNarrationList: (val) => set({currentNarrationList: val}),
      setSceneList: (updateSceneList) => set({ sceneList: updateSceneList }),
      setNarration: (updatedNarration) => set({ narration: updatedNarration }),
      clearNarration: () => set({ narration: null }),
      setCharacters: (updateCharacters) => set({ characters: updateCharacters }),
      dialogue: [],
      choices: [],
      setChoices: (newChoices) =>
        set((state) => ({ choices: [...state.choices, ...newChoices] })),
      clearChoices: () => set({ choices: [] }),
      setDialogue: (newDialogue) =>
        set((state) => ({ dialogue: [...state.dialogue, newDialogue] })),
      clearDialogue: () => set({ dialogue: "" }),
    };
  })
);
