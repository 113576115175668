import axios from "axios";

export async function addSpawnPoint(accessToken, values) {
  const spreadsheetId = `${import.meta.env.VITE_CONTROLLER_SHEET}`;
  const sheetName = `${import.meta.env.VITE_SPAWNPOINT_SHEET}!A2:C`;

  const getEdSheet = await axios.request({
    method: 'post',
    url: `${import.meta.env.VITE_SERVER_URI}/api/v1/custom/edvanta-sheet`,
    data: {
        spreadsheetId,
        sheetName,
        values,
    },
    headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
    },
  });
  return getEdSheet.data.MySheet;
}

export async function getSpawnPoints(accessToken) {
  const spreadsheetId = `${import.meta.env.VITE_CONTROLLER_SHEET}`;
  const sheetName = `${import.meta.env.VITE_SPAWNPOINT_SHEET}`;
  
  try {
    const response = await axios.request({
      method: 'get',
      url: `${import.meta.env.VITE_SERVER_URI}/api/v1/custom/edvanta-sheet`,
      params: {
        spreadsheetId,
        sheetName,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    console.log('xvf', 'req', response)
    return response.data.MySheet;
  } catch (error) {
    console.error('Error retrieving from Google Sheet:', error);
    throw error;
  }
}