import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";

export default function RealmOfEarth(props) {
  const { nodes, materials } = useGLTF("./assets/island/Realm-of-Earth.glb");
  return (
    <RigidBody type="fixed" colliders="cuboid">
      <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Book.geometry}
        material={materials["Material.075"]}
        position={[19.948, 4.146, 3.978]}
        rotation={[0, -0.45, 0]}
        scale={24.675}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Wizard.geometry}
        material={materials["default"]}
        position={[21.485, 4.595, -0.252]}
        rotation={[0, -0.45, 0]}
        scale={24.675}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Wizard_2.geometry}
        material={materials["default.001"]}
        position={[17.86, 4.602, 7.584]}
        rotation={[0, -0.45, 0]}
        scale={24.675}
      />
    </group>
    </RigidBody>
  );
}

useGLTF.preload("./assets/island/Realm-of-Earth.glb");
