import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";
// import { totalCoinAmount } from '../components/world/Coins'
import { coinPositions } from "../components/world/positions/CoinPositions";
import { update } from "@react-spring/three";
import { Value } from "sass";
import { val } from "@theatre/core";

const totalCoins = () => {
  return coinPositions().length;
};

export default create(
  subscribeWithSelector((set) => {
    return {
      coins: 0,
      isIframeOpen: false,
      isGamePaused: false,
      iframePosition: "right",
      isLoggedIn: false,
      UID: null,
      user: null,
      currentActivity: 0,
      gameSessionId: null,
      iframeUrl: "https://edvanta.com",
      respawnPosition: { x: 67.88, y: 33.7, z: -13.74 },
      completedCoinIndex: [],
      isEnteredActivity: false,
      currentHiddenCoin: null,
      showAfterImage: true,
      isLoading: false,
      loadingText: "Generating the Dialogues",
      topic: "",
      gameover: false,
      setUser: (val) => set({ user: val}),
      setIsLoggedIn: (val) => set({ isLoggedIn: true }),
      setTopic: (val) => set({ topic: val }),
      setIsLoading: (val) => set({ isLoading: val }),
      setLoadingText: (val) => set({ loadingText: val }),
      resetLoadingText: () => set({ loadingText: "Generating the Dialogues" }),
      setGameSessionId: (val) => set({ gameSessionId: val }),
      setShowAfterImage: (val) => set({ showAfterImage: val }),
      setIsEnteredActivity: (val) => set({ isEnteredActivity: val }),
      setCurrentHiddenCoin: (val) => set({ currentHiddenCoin: val }),
      setCompletedCoinIndex: (newIndex) =>
        set((state) => ({
          completedCoinIndex: [...state.completedCoinIndex, newIndex],
        })),
      setCompletedCoinIndexAsArray: (newIndex) =>
        set((state) => ({ completedCoinIndex: [...newIndex] })),
      goToNextActivity: () =>
        set((state) => ({ currentActivity: state.currentActivity + 1 })),
      setCurrentActivity: (activity) =>
        set(() => ({ currentActivity: activity })),
      setUID: (uid) => set({ UID: uid }),
      setRespawnPosition: (updatedPosition) =>
        set({ respawnPosition: updatedPosition }),
      increaseCoins: () => set((state) => ({ coins: state.coins + 1 })),
      removeAllCoins: () => set({ coins: 0 }),
      setIframeUrl: (updatedUrl) => set({ iframeUrl: updatedUrl }),
      setIframePosition: (val) => set({ iframePosition: val }),
      toggleIsIframeOpen: () =>
        set((state) => ({ isIframeOpen: !state.isIframeOpen })),
      // totalCoins: totalCoinAmount(),
      totalCoins: totalCoins(),
      pauseGame: (val) => set({ isGamePaused: val }),
      setGameOver: (val) => set({ gameover: val}),
    };
  })
);
