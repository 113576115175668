import axios from "axios";
import { browserActions } from "../utils/listsOfActions";
import formatString from "../utils/formatString";
import suggestContent from "./suggestContent";

const testData = {
  scene: {
    story: {
      characters: [
        {
          id: "1",
          name: "Flawosphies",
          role: "Leader of edTech companies",
          situation: "Teaching Nischay about leadership in Digital Marketing",
        },
        {
          id: "2",
          name: "Nischay",
          role: "Student",
          situation: "Learning leadership skills from Flawosphies",
        },
        {
          id: "3",
          name: "Rohit",
          role: "Project Manager",
          situation: "Assisting in the teaching process",
        },
      ],
      situation: [
        {
          id: "s1",
          character: "Flawosphies",
          dialogue:
            "Leadership in Digital Marketing isn't just about understanding the market; it's also about inspiring your team to innovate.",
          choices: [
            {
              action: {
                type: "watch_video",
                url: `${
                  import.meta.env.VITE_CLIENT_URI
                }/game/intent/65547fa9f3fc648fa5731a4f/EffectiveLeadershipTips`,
              },
            },
            {
              action: {
                type: "read_article",
                url: `${
                  import.meta.env.VITE_CLIENT_URI
                }/game/intent/65547fa9f3fc648fa5731a4f/EffectiveLeadershipTips`,
              },
            },
          ],
          nextId: "s2",
        },
        {
          id: "s2",
          character: "Nischay",
          dialogue: "How can I ensure I'm leading my team effectively?",
          choices: [
            {
              action: {
                type: "read_article",
                url: `${
                  import.meta.env.VITE_CLIENT_URI
                }/game/intent/65547fa9f3fc648fa5731a4f/EffectiveLeadershipTips`,
              },
            },
          ],
          nextId: "s3",
        },
        {
          id: "s3",
          character: "Rohit",
          dialogue:
            "Remember to continuously engage with both your team and the latest digital marketing trends.",
          choices: [
            {
              action: {
                type: "take_quiz",
                url: `${
                  import.meta.env.VITE_CLIENT_URI
                }/game/intent/65391e77b54291a916be40e7/DigitalMarketingTrends`,
              },
            },
          ],
          nextId: "end",
        },
      ],
      follow_up_situation:
        "Nischay learns about the importance of innovation and engagement from Flawosphies and Rohit to become a more effective leader in Digital Marketing.",
    },
  },
  narrative: [
    {
      parent: null,
      trigger: "start",
      id: "s1",
      action: null,
      key: "tminus",
      timeout: 1000,
      type: "narration",
      source: "thoughtjumper",
      text: "Leadership in Digital Marketing isn't just about understanding the market; it's also about inspiring your team to innovate.",
      character: {
        title: "Leader of edTech companies",
        charId: "1",
        animations: [],
        expression: {},
      },
      choices: [
        {
          action: {
            type: "watch_video",
            url: `${
              import.meta.env.VITE_CLIENT_URI
            }/embed/VideosWidget/InnovativeLeadership`,
          },
        },
      ],
    },
    {
      parent: "s1",
      trigger: "parent",
      id: "s2",
      action: null,
      key: "tminus",
      timeout: 1000,
      type: "dialogue",
      source: "thoughtjumper",
      text: "How can I ensure I'm leading my team effectively?",
      character: {
        title: "Student",
        charId: "2",
        animations: [],
        expression: {},
      },
      choices: [
        {
          action: {
            type: "read_article",
            url: `${
              import.meta.env.VITE_CLIENT_URI
            }/game/intent/65547fa9f3fc648fa5731a4f/EffectiveLeadershipTips`,
          },
        },
      ],
    },
    {
      parent: "s2",
      trigger: "parent",
      id: "s3",
      action: null,
      key: "tminus",
      timeout: 1000,
      type: "dialogue",
      source: "thoughtjumper",
      text: "Remember to continuously engage with both your team and the latest digital marketing trends.",
      character: {
        title: "Project Manager",
        charId: "3",
        animations: [],
        expression: {},
      },
      choices: [
        {
          action: {
            type: "take_quiz",
            url: `${
              import.meta.env.VITE_CLIENT_URI
            }/game/intent/65391e77b54291a916be40e7/DigitalMarketingTrends`,
          },
        },
      ],
    },
  ],
};

export const StoryGenerationAPI = {
  async sceneGeneration(test = false, gameSessionId, topicName, token) {
    if (test) {
      return testData.scene.story.situation;
    }
    let reqBody = {};

    if (gameSessionId) {
      reqBody = {
        topicName: topicName,
        narrativeMapping: true,
        sessionId: gameSessionId,
      };
    } else {
      reqBody = {
        // followUpSituations: `Flawosphies who is a leader of edTech companies teaching Nischay a valuable lesson related to ${topicName}, Rohit is Math teacher he also helps him`,
        topicName: topicName,
        narrativeMapping: true,
      };
    }
    // console.log("nischay_token", token);

    const { data } = await axios.post(
      `${import.meta.env.VITE_SERVER_URI}/api/v1/game-story/scene-generation`,
      reqBody,
      {
        headers: {
          Authorization: `Bearer ${token}`, // Replace YOUR_TOKEN_HERE with your actual token
          "Content-Type": "application/json",
        },
      }
    );

    const { situation, characters } = data.scene.story;
    const { session } = data;

    let head;
    let tail;

    const currentNarrationList = situation.reduce((acc, curr) => {
      // Mapping of choices
      let mappedChoices = [];
      if (curr.choices.length > 0) {
        mappedChoices = curr.choices.map((c, i) => {
          const choiceObj = {
            label: formatString(`${c.action.type}[${i + 1}]`),
            keyCode: `${i + 1}`,
            next: {
              trigger: "browser",
              action: null,
              key: "Naruto Mirror of Minds",
              timeout: 1,
              type: "execute",
              source: "system",
              text: "",
              url: c.action.url,
              next: null,
            },
          };
          return choiceObj;
        });
      }

      const currentElement = {
        trigger: "choices",
        action: null,
        key: "Mirror Of Minds",
        timeout: 1,
        type: "dialogue",
        source: "system",
        text: `"${curr.character}: ${curr.dialogue}"`,
        choices: [
          {
            // Second Dialogue
            label: "Next",
            keyCode: "n",
            next: null,
          },
          /**
           *
           * Here, basically we're defining all action and inside that we map our list of actions that are available
           */
          ...mappedChoices,
        ],
      };

      // recursive call to append nextInteraction at last of next
      function setChoice(choice, nextEl) {
        // if inside choices there are another choice then we have to go even deeper in it
        if (!choice.next) {
          choice.next = nextEl;
        } else {
          setChoice(choice.next, nextEl);
        }
      }

      if (Object.keys(acc).length === 0) {
        head = currentElement;
        tail = currentElement;
      } else {
        // tail.choices[0].next = currentElement;
        tail.choices.forEach((choice) => {
          setChoice(choice, currentElement);
        });

        // console.log("tail", tail);

        const finalChoice = {
          label: "Show All Actions",
          keyCode: "9",
          next: {
            trigger: "choices",
            action: null,
            timeout: 1,
            type: "dialogue",
            source: "system",
            text: `"Choose One Action"`,
            specialChoice: true,
            choices: [...browserActions(currentElement, topicName)],
          },
        };

        tail.choices.push(finalChoice);

        tail = currentElement;
      }
      acc = head;
      return acc;
    }, {});

    tail.choices.forEach((choice) => {
      if (!choice.next) {
        choice.next = {
          trigger: "browser",
          action: null,
          key: "Naruto Mirror of Minds",
          timeout: 1,
          type: "execute",
          source: "system",
          text: "",
          url: `${import.meta.env.VITE_CLIENT_URI}/game/feedback`,
          special: true,
          next: null,
        };
      }
    });

    // console.log("pkk - currentNarrationList", currentNarrationList);
    return { currentNarrationList, gameSessionId: session, characters };
  },
  async generateStory(topicName, token, graphMemory = null) {
    let threadId;
    try {
      if (!graphMemory) {
        const threadResponse = await axios.get(
          `${import.meta.env.VITE_SERVER_URI}/api/v1/game-story/thread/create`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Replace YOUR_TOKEN_HERE with your actual token
              "Content-Type": "application/json",
            },
          }
        );
        if (threadResponse.status === 200) {
          threadId = threadResponse.data;
        } else {
          throw new Error("Thread creation failed");
        }
      }

      const characterReqBody = {
        threadId,
        topic: topicName,
        ...graphMemory,
      };
      const charactersResponse = await axios.post(
        `${
          import.meta.env.VITE_SERVER_URI
        }/api/v1/game-story/assistant/create-characters`,
        characterReqBody,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Replace YOUR_TOKEN_HERE with your actual token
            "Content-Type": "application/json",
          },
        }
      );

      if (charactersResponse.status === 200) {
        // console.log('xvf', 'characterResponse', charactersResponse.data.assistants, charactersResponse.data.gameSession.assistant.thread_id, charactersResponse.data.plot, charactersResponse.data.gameSession);
        const characterResponseObject = {
          assistants: charactersResponse.data.assistants,
          threadId: charactersResponse.data.gameSession.assistant.thread_id,
          plot: charactersResponse.data.plot,
          gameSession: charactersResponse.data.gameSession
        }
        if (charactersResponse.isGameStarted) {
          characterResponseObject.vectorStoreId = charactersResponse.vectorStoreId
        }
        return characterResponseObject;
      } else {
        throw new Error("Character creation failed");
      }
    } catch (error) {
      console.error(error);
      alert("Cannot generate story");
    }
  },
  // async generateStory(topicName, token) {
  //   try {
  //     // console.log("xvf", topicName, token);
  //     // create a thread
  //     const threadResponse = await axios.get(
  //       `${import.meta.env.VITE_SERVER_URI}/api/v1/game-story/thread/create`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`, // Replace YOUR_TOKEN_HERE with your actual token
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     if (threadResponse.status === 200) {
  //       const threadId = threadResponse.data;
  //       // return {
  //       //     // assistants: charactersResponse.data.assistants,
  //       //     threadId: threadId,
  //       //     // plot: charactersResponse.data.plot,
  //       //   };
  //       console.log('xvf', threadResponse);
  //       const characterReqBody = {
  //         threadId,
  //         topic: topicName,
  //       };
  //       const charactersResponse = await axios.post(
  //         `${
  //           import.meta.env.VITE_SERVER_URI
  //         }/api/v1/game-story/assistant/create-characters`,
  //         characterReqBody,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`, // Replace YOUR_TOKEN_HERE with your actual token
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );

  //       if (charactersResponse.status === 200) {
  //         return {
  //           assistants: charactersResponse.data.assistants,
  //           threadId: threadId,
  //           plot: charactersResponse.data.plot,
  //         };
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     alert("cannot generate story");
  //   }
  // },
};
