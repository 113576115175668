import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Exclaimation(props) {
  const { nodes, materials } = useGLTF('./assets/inventory/exclamation.glb')
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]} scale={20}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube_Exclamation_1.geometry}
          material={materials.BrightGreen}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube_Exclamation_2.geometry}
          material={materials.BrightGreen2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube_Exclamation_3.geometry}
          material={materials.White}
        />
      </group>
    </group>
  )
}

useGLTF.preload('./assets/inventory/exclamation.glb')
