import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";

export default function Dustbins(props) {
  const { nodes, materials } = useGLTF("./assets/suncity/dustbins.glb");
  return (
    <RigidBody>
      <group {...props} dispose={null}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube246.geometry}
          material={materials["Material.146"]}
          position={[22.844, -0.667, 37.227]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.18, 0.257, 0.18]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube247.geometry}
          material={materials["Material.146"]}
          position={[23.033, -0.667, 21.583]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.18, 0.257, 0.18]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube248.geometry}
          material={materials["Material.146"]}
          position={[22.331, -0.667, 9.43]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.18, 0.257, 0.18]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube250.geometry}
          material={materials["Material.146"]}
          position={[22.331, -0.667, -6.836]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.18, 0.257, 0.18]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube251.geometry}
          material={materials["Material.146"]}
          position={[22.331, -0.667, -33.078]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.18, 0.257, 0.18]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube252.geometry}
          material={materials["Material.146"]}
          position={[22.331, -0.667, -51.259]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.18, 0.257, 0.18]}
        />
      </group>
    </RigidBody>
  );
}

useGLTF.preload("./assets/suncity/dustbins.glb");
