import React, { useRef } from "react";
import { useGLTF, Html } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";

function DriveThrough1(props) {
  const { nodes, materials } = useGLTF("./assets/suncity/driveThrough.glb");
  return (
    <RigidBody type="fixed" colliders="trimesh">
      <group {...props} dispose={null}>
        <group
          position={[-50.134, 1.99, 22.033]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[8.558, 3.213, 4.004]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube282.geometry}
            material={materials["WINDOW.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube282_1.geometry}
            material={materials["Material.115"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube282_2.geometry}
            material={materials["Material.116"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube282_3.geometry}
            material={materials["Material.118"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube282_4.geometry}
            material={materials["Material.119"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube282_5.geometry}
            material={materials["Material.120"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube282_6.geometry}
            material={materials["Material.121"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube282_7.geometry}
            material={materials["Material.122"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube282_8.geometry}
            material={materials["Material.123"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube282_9.geometry}
            material={materials["Material.127"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube282_10.geometry}
            material={materials["Material.128"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube282_11.geometry}
            material={materials["Material.130"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube282_12.geometry}
            material={materials["Material.131"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube282_13.geometry}
            material={materials["Material.132"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube282_14.geometry}
            material={materials["Material.145"]}
          />
        </group>
        <group
          position={[-50.412, -0.949, 16.298]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.608}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane294.geometry}
            material={materials["Material.147"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane294_1.geometry}
            material={materials["Material.148"]}
          />
        </group>
        <group
          position={[-50.412, -0.949, 19.429]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.608}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane292.geometry}
            material={materials["Material.147"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane292_1.geometry}
            material={materials["Material.148"]}
          />
        </group>
        <group
          position={[-50.412, -0.949, 22.898]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.608}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane284.geometry}
            material={materials["Material.147"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane284_1.geometry}
            material={materials["Material.148"]}
          />
        </group>
        <group
          position={[-50.412, -0.949, 25.606]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.608}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane283.geometry}
            material={materials["Material.147"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane283_1.geometry}
            material={materials["Material.148"]}
          />
        </group>
        <group
          position={[-50.412, -0.949, 28.278]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.608}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane278.geometry}
            material={materials["Material.147"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane278_1.geometry}
            material={materials["Material.148"]}
          />
        </group>
        <group
          position={[-50.358, -2.455, 22.368]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[3.559, 3.559, 4.128]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube126.geometry}
            material={materials["Material.089"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube126_1.geometry}
            material={materials["Material.110"]}
          />
        </group>
        {/* <Html position={[-50.358, -2.455, 22.368]} transform>
                <iframe
                    src="https://www.youtube.com/embed/fHuyvkcWB1Q"
                    title="Inline Frame Example"
                    width="640"
            height="480"
            allowFullScreen
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    // style={{ border: 'none' }}
                />
        </Html> */}
      </group>
    </RigidBody>
  );
}

function DriveThrough2(props) {
  const { nodes, materials } = useGLTF("./assets/suncity/edDriveThrough.glb");
  return (
    <RigidBody type="fixed" colliders="trimesh">
      <group {...props} dispose={null}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text005.geometry}
          material={nodes.Text005.material}
          position={[-20.647, 1.638, 36.096]}
          rotation={[Math.PI / 2, 0, -1.133]}
          scale={0.423}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane045.geometry}
          material={materials["Material.020"]}
          position={[-24.631, -1.103, 22.158]}
          scale={[5.415, 14.443, 9.882]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube074.geometry}
          material={materials["Material.090"]}
          position={[-23.538, -2.362, 22.236]}
          scale={3.559}
        />
        <group position={[-23.538, -2.362, 22.158]} scale={3.559}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube328.geometry}
            material={materials["Material.090"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube328_1.geometry}
            material={materials["Material.091"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube328_2.geometry}
            material={materials["Material.092"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube328_3.geometry}
            material={materials["Material.094"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube328_4.geometry}
            material={materials["Material.095"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube328_5.geometry}
            material={materials["Material.096"]}
          />
        </group>
        <group
          position={[-20.743, -0.641, 36.049]}
          rotation={[0, -0.438, 0]}
          scale={4.491}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube114.geometry}
            material={materials["Material.098"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube114_1.geometry}
            material={materials["Material.099"]}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube078.geometry}
          material={materials["Material.106"]}
          position={[-23.538, -1.815, 22.158]}
          scale={[3.559, 3.559, 4.128]}
        />
        <group
          position={[-23.538, -1.815, 22.158]}
          scale={[3.559, 3.559, 4.128]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube117.geometry}
            material={materials["Material.089"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube117_1.geometry}
            material={materials["Material.104"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube117_2.geometry}
            material={materials["Material.108"]}
          />
        </group>
        <group
          position={[-23.538, -1.905, 22.158]}
          scale={[3.559, 3.559, 4.128]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube118.geometry}
            material={materials["Material.089"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube118_1.geometry}
            material={materials["Material.110"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube118_2.geometry}
            material={materials["Material.111"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube118_3.geometry}
            material={materials.RammedEarth006_2K}
          />
        </group>
        <group
          position={[-24.72, -2.362, 21.847]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={3.559}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube330.geometry}
            material={materials["Material.090"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube330_1.geometry}
            material={materials["Material.091"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube330_2.geometry}
            material={materials["Material.092"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube330_3.geometry}
            material={materials["Material.094"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube330_4.geometry}
            material={materials["Material.095"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube330_5.geometry}
            material={materials["Material.096"]}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube084.geometry}
          material={materials["Material.146"]}
          position={[-19.735, -0.966, 13.907]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.18, 0.257, 0.18]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube085.geometry}
          material={materials["Material.146"]}
          position={[-19.735, -0.966, 31.218]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.18, 0.257, 0.18]}
        />
      </group>
    </RigidBody>
  );
}

function DriveThrough3(props) {
  const { nodes, materials } = useGLTF("./assets/suncity/driveThroughJob.glb");
  return (
    <RigidBody type="fixed" colliders="trimesh">
      <group {...props} dispose={null}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane143.geometry}
          material={nodes.Plane143.material}
          position={[-56.282, -1.263, 57.666]}
          scale={[0.131, 0.393, 1.607]}
        />
        <group
          position={[-49.12, 2.146, 50.189]}
          rotation={[0, 1.571, 0]}
          scale={6.196}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube005.geometry}
            material={materials["WINDOW.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube005_1.geometry}
            material={materials["Material.028"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube005_2.geometry}
            material={materials["Material.051"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube005_3.geometry}
            material={materials["Material.183"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube005_4.geometry}
            material={materials["Material.198"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube005_5.geometry}
            material={materials["Material.200"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube005_6.geometry}
            material={materials["Material.203"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube005_7.geometry}
            material={materials["Material.204"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube005_8.geometry}
            material={materials["Material.205"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube005_9.geometry}
            material={materials["Material.211"]}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube154.geometry}
          material={materials["Material.207"]}
          position={[-41.773, 1.167, 47.304]}
          rotation={[0, 1.571, 0]}
          scale={[6.196, 0.194, 6.196]}
        />
        <group
          position={[-45.672, 3.323, 46.587]}
          rotation={[0, 1.571, 0]}
          scale={6.196}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube007.geometry}
            material={materials["Material.204"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube007_1.geometry}
            material={materials["Material.209"]}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane153.geometry}
          material={nodes.Plane153.material}
          position={[-46.745, -0.733, 51.884]}
          rotation={[0, 1.571, 0]}
          scale={[6.533, 6.275, 11.446]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text029.geometry}
          material={nodes.Text029.material}
          position={[-55.519, 4.599, 51.735]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={[0.917, 0.725, 0.998]}
        />
        <group
          position={[-48.843, 2.717, 62.019]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[3.559, 3.559, 4.128]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube008.geometry}
            material={materials["Material.089"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube008_1.geometry}
            material={materials["Material.110"]}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text031.geometry}
          material={materials["Material.023"]}
          position={[-46.456, 5.08, 60.395]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.963}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text013.geometry}
          material={materials["Material.023"]}
          position={[-41.17, 6.088, 52.869]}
          rotation={[Math.PI / 2, 0, Math.PI]}
          scale={[1.441, 1.139, 1.568]}
        />
        <group
          position={[-52.323, 1.664, 47.952]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.608}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane181.geometry}
            material={materials["Material.147"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane181_1.geometry}
            material={materials["Material.148"]}
          />
        </group>
        <group
          position={[-52.323, 1.664, 50.974]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.608}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane182.geometry}
            material={materials["Material.147"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane182_1.geometry}
            material={materials["Material.148"]}
          />
        </group>
        <group position={[-53.085, 1.664, 54.497]} scale={0.608}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane183.geometry}
            material={materials["Material.147"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane183_1.geometry}
            material={materials["Material.148"]}
          />
        </group>
      </group>
    </RigidBody>
  );
}

export default function DriveThroughs(props) {
  return (
    <>
      <DriveThrough1 />
      <DriveThrough2 />
      <DriveThrough3 />
    </>
  );
}

useGLTF.preload("./assets/suncity/driveThrough.glb");
useGLTF.preload("./assets/suncity/edDriveThrough.glb");
useGLTF.preload("./assets/suncity/driveThroughJob.glb");
