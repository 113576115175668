import useDialogue from "../../stores/useDialogue";
import useGame from "../../stores/useGame";
import { useEffect, useState, useRef } from "react";
import textToSpeech from "../../utils/textTospeech";
import ReactMarkdown from "react-markdown";
import useVoice from "../../stores/useVoice";
import axios from "axios";
import useAssistant from "../../stores/useAssistant.jsx";
// import { StoryGenerationAPI } from '../services/storyGeneration.js';
import createDialogue from "../../services/createDialogue.js";
import Markdown from "marked-react";
import AudioRecorder from "../Recorder.jsx";
import useAudioStore from "../../stores/useAudioRecorder.jsx";
import useAudioPlayer from "../../stores/useAudioPlayer.jsx";
import AudioPlayer from "../AudioPlayer.jsx";
import addInstructions from "../../services/addInstructions.js";
import useTTS from "../hooks/useTTS.jsx";

import {
  createNarration,
  createChoice,
  createDialogueFormat,
  createExecute,
  createCommand,
} from "../config/Narrations.jsx";
import useActions from "../../stores/useActions.jsx";

export default function DialogueUI(props) {
  // Component State
  const [revertFacePosition, setReverseFacePosition] = useState(false);
  const [showInformation, setShowInformation] = useState(false);
  const [isCloseDialogueRequested, setCloseDialogueRequested] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [dialogueAudioNotification, setDialogueAudioNotification] = useState(
    "..getting the audio"
  );
  const [isDialogueAudioPlaying, setDialogueAudioPlaying] = useState(false);
  const [volume, setVolume] = useState(1);

  // Component Refs
  // const audio = useRef();

  // Dialogue store
  const {
    dialogue,
    choices,
    narration,
    setNarration,
    clearChoices,
    characters,
    clearDialogue,
    setShowDialogue,
  } = useDialogue((state) => state);

  // Voice store
  const voiceStore = useVoice((state) => state);

  // Assistant store
  const { activeAssistant, threadId, graphName, smartGraph, vectorStoreId } =
    useAssistant((state) => state);

  // Action store
  const { lastAction } = useActions((state) => state);

  // Audio Recorder Store
  const { transcription } = useAudioStore((state) => state);

  // Game store
  const { setIsLoading, setLoadingText, UID, topic, setTopic } = useGame(
    (state) => state
  );

  // import TTS
  const { audioRef, streamTTS } = useTTS(UID);


  const handleVolumeChange = (event) => {
    const newVolume = event.target.value;
    setVolume(newVolume);
    if (audioRef.current) {
      audioRef.current.volume = newVolume; // Update volume
    }
  };

  const handleVolumeButtonClick = (newVolume) => {
    setVolume(newVolume);
    if (audioRef.current) {
      audioRef.current.volume = newVolume;
    }
  };


  useEffect(() => {
    // console.log(
    //   "xvf",
    //   "we are here at 8:51",
    //   activeAssistant.assistant_id,
    //   threadId,
    //   lastAction
    // );
    if ((activeAssistant.assistant_id, threadId, lastAction)) {
      // setIsLoading(true);
      addInstructions(threadId, lastAction, UID);
      // createDialogue(activeAssistant.assistant_id, threadId, lastAction, topic, UID).then(
      //   (dialogueObject) => {
      //     setIsLoading(false);

      //     // update Topic
      //     setTopic(dialogueObject.keyphrase);

      //     const youtubeVideoExecute = createExecute(
      //       "watching video ",
      //       `${
      //         import.meta.env.VITE_CLIENT_URI
      //       }/widget/VideosWidget?setCurrentThought=[${topic}]`
      //     );
      //     const videoChoice = createChoice(
      //       `Watch Video over ${topic}`,
      //       "v",
      //       youtubeVideoExecute
      //     );

      //     // deep dive
      //     const deepDiveExecute = createExecute(
      //       "deep dive on",
      //       `${
      //         import.meta.env.VITE_CLIENT_URI
      //       }/widget/SmartGraphWidget?setCurrentThought=[${smartGraph},${threadId},${topic}]`
      //     );
      //     const deepDiveChoice = createChoice(
      //       `Deep dive on ${topic}`,
      //       "d",
      //       deepDiveExecute
      //     );

      //     let intents = [];
      //     dialogueObject.intents.forEach((e, index) => {
      //       const intentExecute = createExecute(
      //         "reading article",
      //         `${import.meta.env.VITE_CLIENT_URI}${e.learning_intent_url}`
      //       );
      //       const intentChoice = createChoice(
      //         `See ${e.label}`,
      //         String.fromCharCode(75 + index),
      //         intentExecute
      //       );
      //       intents.push(intentChoice);
      //     });
      //     const done = createNarration(
      //       "done",
      //       ` ${activeAssistant.name}:-Understand, You can talk to me again while Pressing [T] `
      //     );
      //     const doneChoice = createChoice("End Conversation", "x", done);
      //     console.log("end conversation", [
      //       videoChoice,
      //       ...intents,
      //       deepDiveChoice,
      //       doneChoice,
      //     ]);
      //     const newDialogue = createDialogueFormat(
      //       ` ${activeAssistant.name}:-${dialogueObject.dialogue}`,
      //       [videoChoice, ...intents, doneChoice]
      //     );
      //     setNarration(newDialogue);
      //   }
      // );
    }
  }, [lastAction]);

  const handleMouseEnter = () => {
    setShowInformation(true);
  };

  const handleMouseLeave = () => {
    setShowInformation(false);
  };

  // handle Key press for choice
  function handleKeyPress(event) {
    const getKeyPressed = event.key;
    if (choices.length > 0) {
      let availableKeys = [];
      choices.forEach((each) => availableKeys.push(each.keyCode));
      // console.log("horizontal check", choices, availableKeys);
      let nextNarration = choices.find(
        (each) => each.keyCode.toLowerCase() === getKeyPressed
      );
      // console.log("get Next conversation", nextNarration);
      if (nextNarration && nextNarration.next) {
        setNarration(nextNarration.next);
        setReverseFacePosition(!revertFacePosition);
      }
      if (availableKeys.includes(getKeyPressed)) {
        // clearChoices();
        // clearDialogue();
      }
    }
    // press q to see information about character
    if (getKeyPressed === "q") {
      setShowInformation(true);
      setTimeout(() => {
        setShowInformation(false);
      }, 4000);
    }
  }

  useEffect(() => {
    // console.log("xvf", dialogue);
    if (dialogue && dialogue.length > 0) {
      const transcript = getDialogueLine(dialogue);
      const payload = {
        transcript,
        voice: voiceStore.voice,
        filename: voiceStore.generateFilename(),
        encoding: voiceStore.encoding,
      };
      // console.log(payload.transcript);
      setDialogueAudioNotification("getting voices");
      streamTTS(payload.transcript, "alloy", () => {
        if (narration && narration.type === "narration") {
          setIsLoading(false);
          clearDialogue();
          setShowDialogue(false);
          setNarration(narration.next);
        }
      });
      // textToSpeech(payload.transcript)
      //   .then((response) => {
      //     const a = response;
      //     // console.log(response, audio, audio.current, a);
      //     // setAudioSrc(a);
      //     // play();
      //     audio.current.src = a;
      //     audio.current.play();
      //     audio.current.onended = () => {
      //       // console.log('xvf', 'dialogue is ended');
      //       if (narration && narration.type === "narration") {
      //         setIsLoading(false);
      //         clearDialogue();
      //         setShowDialogue(false);
      //         setNarration(narration.next);
      //       }
      //       // window.URL.revokeObjectURL(a);
      //     };
      //   })
      //   .catch((error) => {
      //     console.log('xvf', 'eror', error);
      //     const done = createNarration(
      //       "done",
      //       ` ${activeAssistant.name}:-unable to answer right now, meanwhile you can talk to your fellow learner `
      //     );
      //     setNarration(done);
      //   });
    }
  }, [dialogue]);

  const handleAudioPlayback = (event) => {};

  // playback rate
  // useEffect(() => {
  //   window.addEventListener("keyup", handleAudioPlayback);
  //   return () => {
  //     window.removeEventListener("keyup", handleAudioPlayback);
  //   };
  // }, []);

  // handle auto scroll feature
  useEffect(() => {
    const handleTimeUpdate = () => {
      if (audioRef.current) {
        const scrollContainer = document.querySelector(".dialogueUI__question");
        if (scrollContainer) {
          const duration = audioRef.current.duration;
          const currentTime = audioRef.current.currentTime;
          const progress = currentTime / duration;
          const maxScrollTop =
            scrollContainer.scrollHeight - scrollContainer.clientHeight;
          scrollContainer.scrollTop = progress * maxScrollTop;
        }
      }
    };

    if (audioRef.current) {
      audioRef.current.addEventListener("timeupdate", handleTimeUpdate);
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener("timeupdate", handleTimeUpdate);
      }
    };
  }, [audioRef.current]);

  useEffect(() => {
    window.addEventListener("keyup", handleKeyPress);
    return () => {
      window.removeEventListener("keyup", handleKeyPress);
    };
  }, [choices]);

  // handleControl
  // function handleControl(control) {
  //   if (control == "forward") {
  //     setPlaybackRate(playbackRate * 2);
  //   } else if (control === "rewind") {
  //     setPlaybackRate(playbackRate / 2);
  //   } else {
  //     setDialogueAudioPlaying(!isDialogueAudioPlaying);
  //   }
  // }

  // useEffect(() => {
  //   if (audio.current) {
  //     if (isDialogueAudioPlaying) {
  //       audio.current.pause();
  //     } else {
  //       audio.current.play();
  //     }
  //   }
  // }, [isDialogueAudioPlaying]);

  // if we get transcription;
  useEffect(() => {
    if (transcription) {
      console.log(
        "xvf",
        "transcription",
        transcription,
        activeAssistant.assistant_id,
        threadId,
        vectorStoreId,
        topic
      );
      if (activeAssistant.assistant_id && threadId && topic && UID) {
        setLoadingText(`${activeAssistant.name} is thinking...`);
        setIsLoading(true);
        createDialogue(
          activeAssistant.assistant_id,
          threadId,
          vectorStoreId,
          transcription,
          topic,
          UID
        )
          .then((dialogueObject) => {
            setIsLoading(false);

            // update Topic
            setTopic(dialogueObject.keyphrase);

            // append youtube video
            const youtubeVideoExecute = createExecute(
              "watching video ",
              `${
                import.meta.env.VITE_CLIENT_URI
              }/widget/VideosWidget?setCurrentThought=[${
                dialogueObject.keyphrase
              }]`
            );
            const videoChoice = createChoice(
              `Watch Video over ${dialogueObject.keyphrase}`,
              "v",
              youtubeVideoExecute
            );

            // deep dive
            let deepDivePanel = [];
            if (smartGraph) {
              const deepDiveExecute = createExecute(
                "deep dive on",
                `${
                  import.meta.env.VITE_CLIENT_URI
                }/widget/SmartGraphWidget?setCurrentThought=[${smartGraph},${threadId},${
                  dialogueObject.keyphrase
                },${vectorStoreId}]`
              );
              const deepDiveChoice = createChoice(
                `Deep dive on ${dialogueObject.keyphrase}`,
                "u",
                deepDiveExecute
              );
              deepDivePanel.push(deepDiveChoice);
            }

            // Ask Me Question Panel
            const askMeQuestionPanel = [];
            const askMeQuestionExecute = createExecute(
              "ask me question",
              `${
                import.meta.env.VITE_CLIENT_URI
              }/widget/AskMeQuestionWidget?setCurrentThought=[${
                dialogueObject.keyphrase
              },668241952d18558c8762aa98]`
            );
            const askMeQuestionChoice = createChoice(
              `Ask Me Questions on ${dialogueObject.keyphrase}`,
              "c",
              askMeQuestionExecute
            );
            askMeQuestionPanel.push(askMeQuestionChoice);

            // All Prompts Panel
            const allPromptPanel = [];
            const allPromptExecute = createExecute(
              "all prompt",
              `${
                import.meta.env.VITE_CLIENT_URI
              }/widget/KaaroWidget?toggleKaaroWidgetView=[]&setCurrentThought=[${
                dialogueObject.keyphrase
              },${vectorStoreId}]`
            );
            const allPromptChoice = createChoice(
              `Boost My Awareness `,
              "b",
              allPromptExecute
            );
            allPromptPanel.push(allPromptChoice);

            let intents = [];
            dialogueObject.intents &&
              dialogueObject.intents.length > 0 &&
              dialogueObject.intents.forEach((intent, index) => {
                const intentExecute = createExecute(
                  "reading article",
                  `${
                    import.meta.env.VITE_CLIENT_URI
                  }/widget/IntentLoaderWidget?setCurrentThought=[${
                    dialogueObject.keyphrase
                  },${intent.id}]`
                );
                const intentChoice = createChoice(
                  `See ${intent.label}`,
                  String.fromCharCode(75 + index),
                  intentExecute
                );
                intents.push(intentChoice);
              });
            const done = createNarration(
              "done",
              ` ${activeAssistant.name}:-Understand, You can talk to me again while Pressing [T] `
            );
            const doneChoice = createChoice("End Conversation", "x", done);
            const newDialogue = createDialogueFormat(
              ` ${activeAssistant.name}:-${dialogueObject.dialogue} `,
              [
                videoChoice,
                ...intents,
                ...deepDivePanel,
                ...askMeQuestionPanel,
                ...allPromptPanel,
                doneChoice,
              ]
            );
            setNarration(newDialogue);
          })
          .catch((error) => {
            console.log(error);
            const done = createNarration(
              "done",
              ` ${activeAssistant.name}:-I am not feeling well right now, can you come back later `
            );
            setNarration(done);
          });
      }
    }
  }, [transcription]);

  // if we want character name
  const getCharacterName = (dialogue) => {
    if (dialogue && dialogue.length > 0) {
      const fetchDialogueFromArray = dialogue[0].slice(1, -1);
      if (fetchDialogueFromArray.includes(":-")) {
        const splitNameFromDialogue = fetchDialogueFromArray.split(":-");
        if (splitNameFromDialogue) {
          return splitNameFromDialogue[0];
        } else {
          return "Anonymous";
        }
      } else {
        return "Edna";
      }
    }
  };

  // if we want dialogue line
  const getDialogueLine = (dialogue) => {
    // console.log("shift dialogue", dialogue);
    if (dialogue && dialogue.length > 0) {
      const fetchDialogueFromArray = dialogue[0].slice(1, -1);
      const splitNameFromDialogue = fetchDialogueFromArray.split(":-");
      if (splitNameFromDialogue.length > 1) {
        return splitNameFromDialogue[1];
      } else {
        return dialogue;
      }
    }
  };

  // fetch about isn't working right now need a fix
  const fetchAbout = () => {
    if (Boolean(characters.length)) {
      const foundCharacter = characters.find((char) => {
        return char.name === getCharacterName(dialogue);
      });

      if (!foundCharacter) return "";
      if (!foundCharacter.about) return "";
      return foundCharacter.about;
    }
    return "";
  };

  return (
    <>
      {dialogue.length > 0 && (
        <div className="dialogueUI">
          <header
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={
              revertFacePosition
                ? "dialogueUI__header dialogueUI__header--revert"
                : "dialogueUI__header"
            }
          >
            <h3 className="dialogueUI__title">
              {activeAssistant && activeAssistant.name}
            </h3>
          </header>
          <main className="dialogueUI__main">
            {/* <div className="dialogueUI__audio-container">
              <div className="dialogueUI__audio-notification-container">
                <p className="dialogueUI__audio-notification">
                  <i class="bx bxs-cog recorder__icon--animate"></i>{" "}
                  <span>{dialogueAudioNotification}</span>{" "}
                </p>
              </div>
              <div className="dialogueUI__audio-controllers">
                <i
                  class="box-icon bx bx-rewind"
                ></i>
                <i
                  class="box-icon bx bx-pause"
                ></i>
                <i
                  class="box-icon bx bx-fast-forward"
                ></i>
              </div>
            </div> */}
            <img
              src={
                getCharacterName(dialogue) === "Edna"
                  ? "./assets/dialogues/dialogue_face_medusa.png"
                  : "./assets/dialogues/dialogue_face_smile.png"
              }
              alt=""
              className={
                revertFacePosition
                  ? "dialogueUI__img"
                  : "dialogueUI__img dialogueUI__img--revert"
              }
            />
            <div
              className={
                isDialogueAudioPlaying
                  ? "dialogueUI__question dialogueUI__question--auto"
                  : "dialogueUI__question dialogueUI__question--scroll"
              }
              dangerouslySetInnerHTML={{ __html: getDialogueLine(dialogue) }}
            >
              {/* <Markdown>{getDialogueLine(dialogue)}</Markdown> */}
            </div>
            {choices.length > 0 && (
              <ul className="dialogueUI__options">
                {choices.map((choice) => (
                  <li key={choice.label} className="dialogueUI__option">
                    {choice.label} ({choice.keyCode})
                  </li>
                ))}
              </ul>
            )}
          </main>
          <footer className="dialogueUI__footer">
            <div className="dialogueUI__notification">
              <p>
                Press [T] to Talk to {activeAssistant && activeAssistant.name}
              </p>
              <audio src="" ref={audioRef}></audio>
            </div>
          </footer>
          <div className="dialogueUI__volume-control">
            <button onClick={() => handleVolumeButtonClick(0)} className="dialogueUI__volume-control-button">
             <i class='bx bxs-volume-mute'></i>
            </button>
              <input
                type="range"
                id="volume-slider"
                min="0"
                max="1"
                step="0.01"
                value={volume}
                onChange={handleVolumeChange}
                className="dialogueUI__volume-control-slider"
              />
            <button onClick={() => handleVolumeButtonClick(1)} className="dialogueUI__volume-control-button">
            <i class='bx bx-volume-full'></i>
            </button>
            </div>
        </div>
      )}
      <AudioRecorder />
      {/* <AudioPlayer/> */}
    </>
  );
}

// {Boolean(fetchAbout().trim()) && (
//               <div
//                 className={
//                   showInformation
//                     ? revertFacePosition
//                       ? "dialogueUI__information dialogueUI__information--show"
//                       : "dialogueUI__information dialogueUI__information--show dialogueUI__information--revert"
//                     : "dialogueUI__information"
//                 }
//               >
//                 <Markdown>{fetchAbout()}</Markdown>
//               </div>
//             )}
