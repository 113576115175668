// useKeyObserver.js
import { useEffect } from 'react';

const useKeyObserver = (callback) => {
  useEffect(() => {
    const handleKeyUp = (event) => {
      callback(event);
    };

    window.addEventListener('keyup', handleKeyUp);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [callback]);
};

export default useKeyObserver;
