
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function CoinModel(props) {
  const { nodes, materials } = useGLTF('./assets/inventory/coin.glb')
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]} scale={100}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Coin_1.geometry}
          material={materials.Yellow}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Coin_2.geometry}
          material={materials.DarkYellow}
        />
      </group>
    </group>
  )
}

useGLTF.preload('./assets/inventory/coin.glb')