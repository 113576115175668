import axios from "axios";

export async function fetchGoal(smartGraph, UID) {
  try {
    const response = await axios.get(
      `${import.meta.env.VITE_SERVER_URI}/api/v1/game-story/assistant/fetch-goals/${smartGraph}`,
      {
        headers: {
          Authorization: `Bearer ${UID}`,
        },
      }
      );
      return response.data.goals;
  } catch (error) {
    console.error(error);
    return [];
  }
}
