import { Canvas } from "@react-three/fiber";
import Experience from "./components/Experience.jsx";
import { Suspense, useEffect, useState } from "react";
import { Physics } from "@react-three/rapier";
import {
  KeyboardControls,
  PointerLockControls,
  OrbitControls,
  Preload,
  PerformanceMonitor,
} from "@react-three/drei";
import { isMobile, isDesktop } from "react-device-detect";
import Interface from "./components/Interface.jsx";
import { LoadingScreen } from "./components/LoadingScreen.jsx";
import MobileInterface from "./components/MobileInterface.jsx";
import Joystick from "./components/Joystick.jsx";
import IframeWindow from "./components/world/Window.jsx";
import DialogueUI from "./components/narrative/dialogueUI.jsx";
import { useControls } from "leva";
import useGame from "./stores/useGame.jsx";
import { Perf } from "r3f-perf";
import { getAllCompletedActivites } from "./utils/CheckActivitCompleted.js";
import { coinActivity, createSpawnPosition } from "./components/world/positions/CoinPositions.jsx";
import useLoader from "./stores/useLoader.jsx";
import NotificationsLoop from "./NotificationsLoop.jsx";
import Assistants from "./components/AssistantWindow.jsx";
import useAssistant from "./stores/useAssistant.jsx";
import useRecordingObserver from "./components/observers/RecorderObserver.jsx";
import useNarrationObserver from "./components/observers/NarrationObserver.jsx";
import { getSpawnPoints } from "./services/addSpawnPoint.js";
import useInventory from "./stores/useInventory.jsx";
import GoalsWidget from "./components/GoalsWidget.jsx";
import Timer from "./Timer.jsx";
import useTimeObserver from "./components/observers/useTimeObserver.jsx";
import useInventoryObserver from "./components/observers/useInventoryObserver.jsx";
// import useTimerStore from "./stores/useTimerStore.jsx";
import GameOverComponent from "./components/GameOver.jsx";
import subscribeToInventoryChanges from "./services/zustandSubscribers.jsx";
import { callTriggerWithInventoryItem, executeNarrationWithGoalCompleted } from "./services/callTriggerWithInventoryItems.jsx";
import useEventObserver from "./components/observers/EventObserver.jsx";
import useGoalStore from "./stores/useGoalStore.jsx";
import useEventStore from "./stores/useEventStore.jsx";
import useDialogue from "./stores/useDialogue.jsx";

export default function App() {
  const [downgradedPerformance, setDowngradedPerformance] = useState(false);
  const { started, setStarted } = useLoader((state) => state);
  const isGamePaused = useGame((state) => state.isGamePaused);
  const { gameover, setTopic, UID, setUID, setUser, setIsLoggedIn, setIframeUrl, toggleIsIframeOpen } =
    useGame((state) => state);
  const { setEvent } = useEventStore((state) => state);
  const { updatePositionMatrix, setInventory, items } = useInventory((state) => state);
  const { setThreadId, setSmartGraph, setMessage, setSmartGraphVectorStoreId } = useAssistant((state) => state);
  const { updateGoalWithLatestGoalId, setLatestGoalId, goals } = useGoalStore((state) => state);
  const { setNarration } = useDialogue((state) => state);

  // system control
  // const { xDebugPhysics } = useControls('Physics', {
  //   xDebugPhysics: false,
  // })
  const xDebugPhysics = false;

  const isDebugMode = localStorage.getItem('DEBUG') === 'TRUE';

  // Observers
  useRecordingObserver();
  useNarrationObserver();
  // useTimeObserver();
  useInventoryObserver();
  useEventObserver();

  useEffect(() => {
    const unsubscribe = subscribeToInventoryChanges(callTriggerWithInventoryItem);

    // Cleanup the subscription on component unmount
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    // call for inventory
    if (UID) {
      getSpawnPoints(UID).then((spawnPoints) => {
        const spawnPositions = createSpawnPosition(spawnPoints.values, items);
        // console.log('xvf', 'spawn points', spawnPositions);
        updatePositionMatrix(spawnPositions);
        setInventory(true);
      });
    }
  }, [UID]);

  useEffect(() => {
    if (gameover) {
      // create a event for creating a new Goal
      const user = JSON.parse(localStorage.getItem('user'));
        const event = {
          actor: {
            username: user.username,
          },
          verb: 'GAME_ENDED',
          value: '60 min over',
          dataSource: "school-of-unlearn",
        };
        setEvent(event);
        //  ----- Event End -----
      setTimeout(() => {
        console.log('xvf', 'game is over');
        // setInventory(false);
        // setStarted(false);
        location.reload();
      }, 10000);
    }
  }, [gameover])

  useEffect(() => {
    window.onmessage = (event) => {
      if (event.origin === import.meta.env.VITE_CLIENT_URI) {
        console.log("event", event.data);
        const response = JSON.parse(event.data);
        // setUID(UID.user.sid);
        if (response.data.status === 200) {
          if (response.data.type === "auth") {
            console.log('xvf', "Message from parent:", response);
            setIsLoggedIn(true);
            localStorage.setItem("userToken", response.data.userToken);
            localStorage.setItem('user', JSON.stringify(response.data.user));
            setUID(response.data.userToken);
            setUser(response.data.user);  
            // if (!isDebugMode) {
            //   setIframeUrl('');
            //   toggleIsIframeOpen();
            // }
          } else if (response.data.type === "authenticated") {
            setIsLoggedIn(true);
            setUID(localStorage.getItem("userToken"));
          } else if (response.data.type === "threadId") {
            // console.log("xvf", "threadId", response);
            setThreadId(response.data.threadId);
            setSmartGraph(response.data.graphId); // Add this line
            setSmartGraphVectorStoreId(response.data.vectorStoreId);
            setTopic(response.data.graphName);
            setIframeUrl("");
            toggleIsIframeOpen();
          } else if (response.data.type === 'video') {
            // console.log("xvf", "video", response);
            let message = '';
            message += 'Title: ' + response.data.video.title;
            message += 'Transcript: ' + response.data.video.transcript;
            setMessage(message);
          } else if (response.data.type === 'goal') {
            const user = JSON.stringify(localStorage.getItem('user'));
            // console.log('xvf', 'goal: updated', response, user);
            
            // select another goal
            updateGoalWithLatestGoalId(response.data.goal);
            const findIncompleteGoal = goals.find(goal => goal.status === false);
            if (findIncompleteGoal) {
              setLatestGoalId(findIncompleteGoal.id);
            }
            // create a event for creating a new Goal
            const temp = executeNarrationWithGoalCompleted();
            setNarration(temp);
              const event = {
                actor: {
                  username: user.username,
                },
                verb: 'CLOSED_GOAL',
                value: {
                  title: response.data.goal.goal,
                },
                dataSource: "school-of-unlearn",
              };
              setEvent(event);
              //  ----- Event End -----
          }
        }
        // // restore previous completed activities
        // getAllCompletedActivites(UID.user.sid).then((activites) => {
        //   // console.log('completed activities', activites);
        //   setCompletedCoinIndexAsArray(activites);
        //   setCurrentActivity(coinActivity.indexOf(activites[activites.length - 1]) + 1);
        // })
      }
    };
  }, []);

  const keyboardMap = [
    { name: "forward", keys: ["ArrowUp", "KeyW"] },
    { name: "backward", keys: ["ArrowDown", "KeyS"] },
    { name: "leftward", keys: ["ArrowLeft", "KeyA"] },
    { name: "rightward", keys: ["ArrowRight", "KeyD"] },
    { name: "jump", keys: ["Space"] },
    { name: "run", keys: ["Shift"] },
    { name: "fly", keys: ["KeyZ"] },
    { name: "action4", keys: ["1"] },
    { name: "action2", keys: ["2"] },
    { name: "action3", keys: ["3"] },
    { name: 'mark', keys: ['KeyX']},
  ];

  return (
    <>
      {!started && <LoadingScreen started={started} setStarted={setStarted} />}
      {isMobile && started && <Joystick />}
      <KeyboardControls map={keyboardMap}>
        <Canvas
          shadows={!downgradedPerformance}
          camera={{ far: 480, near: 1 }}
          flat
          // gl={{ antialias: true }}
          dpr={[1, 1.5]}
          // frameloop="demand"
          // onClick={() => setPaused(false)}
          // style={{ background: '#000000' }}
        >
          {/* <fog attach="fog" args={["#64838c", 10, 65]} /> */}
          <Physics
            // timeStep="vary"
            // paused={isGamePaused}
            // paused={true}
            debug={xDebugPhysics}
            gravity={[0, -9.8, 0]}
          >
            <PerformanceMonitor />
            <Suspense fallback={null}>
              {started && (
                <>
                  <Experience downgradedPerformance={downgradedPerformance} />
                  <Preload all />
                </>
              )}
            </Suspense>
          </Physics>
          {isDesktop && started && (
            <>
              {/* <PointerLockControls /> */}
              {/* <Perf position="top-left" /> */}
              {/* <OrbitControls /> */}
            </>
          )}
        </Canvas>
        {isDesktop && started && <Interface />}
        {isMobile && started && <MobileInterface />}
      </KeyboardControls>
      <IframeWindow />
      <Assistants />
      <DialogueUI />
      <Timer initialMinutes={5} alertLimit={4} />
      <NotificationsLoop />
      <GoalsWidget/>
      <GameOverComponent/>
    </>
  );
}
