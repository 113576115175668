import React, { useRef, useMemo, useContext, createContext } from "react";
import { useGLTF, Merged } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";
import * as THREE from "three";

const isCastShadow = false;
const isReceiveShadow = false;

const SuncityBase = (props) => {
  const { nodes, materials } = useGLTF("./assets/suncity/base-site.glb");
  return (
    <RigidBody type="fixed" colliders={"trimesh"}>
      <group {...props} dispose={null}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube097.geometry}
          material={materials.pathways}
          position={[44.11, -2.152, 107.605]}
          rotation={[0, 1.571, 0]}
          scale={6.196}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube081.geometry}
          material={materials.pathways}
          position={[-48.117, -2.152, -31.23]}
          scale={6.196}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube086.geometry}
          material={materials.pathways}
          position={[-22.617, -2.152, -31.23]}
          scale={6.196}
        />
        <group position={[-48.117, -2.152, -31.23]} scale={6.196}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube002.geometry}
            material={materials["Material.032"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube002_1.geometry}
            material={materials["Material.029"]}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube095.geometry}
          material={materials.pathways}
          position={[44.11, -2.152, 90.5]}
          rotation={[0, 1.571, 0]}
          scale={6.196}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube098.geometry}
          material={materials.pathways}
          position={[-48.117, -2.152, 90.826]}
          scale={6.196}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube099.geometry}
          material={materials.pathways}
          position={[-22.617, -2.152, 90.826]}
          scale={6.196}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube165.geometry}
          material={materials["Material.228"]}
          position={[33.941, 1.252, -19.772]}
          scale={[0.009, 0.265, 0.025]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube166.geometry}
          material={materials["Material.228"]}
          position={[33.941, 1.252, -19.697]}
          scale={[0.009, 0.265, 0.025]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube169.geometry}
          material={materials["Material.228"]}
          position={[33.941, 1.252, -51.26]}
          scale={[0.009, 0.265, 0.025]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube170.geometry}
          material={materials["Material.228"]}
          position={[33.941, 1.252, -51.185]}
          scale={[0.009, 0.265, 0.025]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube550.geometry}
          material={materials["Material.228"]}
          position={[46.184, 1.252, -51.743]}
          scale={[0.009, 0.265, 0.025]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube551.geometry}
          material={materials["Material.228"]}
          position={[46.184, 1.252, -51.668]}
          scale={[0.009, 0.265, 0.025]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube552.geometry}
          material={materials["Material.228"]}
          position={[46.184, 1.252, -36.247]}
          scale={[0.009, 0.265, 0.025]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube553.geometry}
          material={materials["Material.228"]}
          position={[46.184, 1.252, -36.172]}
          scale={[0.009, 0.265, 0.025]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube554.geometry}
          material={materials["Material.228"]}
          position={[46.184, 1.252, -20.663]}
          scale={[0.009, 0.265, 0.025]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube555.geometry}
          material={materials["Material.228"]}
          position={[46.184, 1.252, -20.588]}
          scale={[0.009, 0.265, 0.025]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube556.geometry}
          material={materials["Material.228"]}
          position={[46.184, 1.252, -5.167]}
          scale={[0.009, 0.265, 0.025]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube557.geometry}
          material={materials["Material.228"]}
          position={[46.184, 1.252, -5.092]}
          scale={[0.009, 0.265, 0.025]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube558.geometry}
          material={materials["Material.228"]}
          position={[46.184, 1.252, 10.796]}
          scale={[0.009, 0.265, 0.025]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube559.geometry}
          material={materials["Material.228"]}
          position={[46.184, 1.252, 10.871]}
          scale={[0.009, 0.265, 0.025]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube560.geometry}
          material={materials["Material.228"]}
          position={[46.184, 1.252, 26.055]}
          scale={[0.009, 0.265, 0.025]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube561.geometry}
          material={materials["Material.228"]}
          position={[46.184, 1.252, 26.13]}
          scale={[0.009, 0.265, 0.025]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane054.geometry}
          material={nodes.Plane054.material}
          position={[-49.233, -0.733, 23.327]}
          scale={[6.533, 6.275, 11.446]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane134.geometry}
          material={nodes.Plane134.material}
          position={[1.608, -1.283, -63.653]}
          scale={[0.164, 4.429, 2.057]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane140.geometry}
          material={nodes.Plane140.material}
          position={[39.922, -0.924, -13.553]}
          scale={[8.434, 6.196, 52.13]}
        />
        <group
          position={[-51.028, -1.085, -30.924]}
          scale={[18.821, 31.885, 36.199]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane005.geometry}
            material={materials["Material.020"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane005_1.geometry}
            material={materials["Material.035"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane005_2.geometry}
            material={materials["Material.032"]}
          />
        </group>
      </group>
      {/* <SuncityBasePlatform/> */}
    </RigidBody>
  );
};

function SuncityBasePlatform(props) {
  const { nodes, materials } = useGLTF(
    "./assets/suncity/base-site_platforms.glb"
  );
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow={isCastShadow}
        receiveShadow={isReceiveShadow}
        geometry={nodes.Cube081.geometry}
        material={nodes.Cube081.material}
        position={[-239.194, 5.029, -154.771]}
        scale={26.801}
      />
      <mesh
        castShadow={isCastShadow}
        receiveShadow={isReceiveShadow}
        geometry={nodes.Cube086.geometry}
        material={nodes.Cube086.material}
        position={[-128.896, 5.029, -154.771]}
        scale={26.801}
      />
      <mesh
        castShadow={isCastShadow}
        receiveShadow={isReceiveShadow}
        geometry={nodes.Cube095.geometry}
        material={nodes.Cube095.material}
        position={[159.72, 5.029, 371.75]}
        rotation={[0, 1.571, 0]}
        scale={26.801}
      />
      <mesh
        castShadow={isCastShadow}
        receiveShadow={isReceiveShadow}
        geometry={nodes.Cube098.geometry}
        material={nodes.Cube098.material}
        position={[-239.194, 5.029, 373.164]}
        scale={26.801}
      />
      <mesh
        castShadow={isCastShadow}
        receiveShadow={isReceiveShadow}
        geometry={nodes.Cube099.geometry}
        material={nodes.Cube099.material}
        position={[-128.896, 5.029, 373.164]}
        scale={26.801}
      />
    </group>
  );
}

/**
 * Island Base
 */
const IslandBase = (props) => {
  const { nodes, materials } = useGLTF("./assets/island/Base-Site.glb");
  // console.log("xvf", nodes, materials);

  // Create a green material
  const greenMaterial = new THREE.MeshStandardMaterial({ color: 0x81c2a4 });

  return (
    <RigidBody type="fixed" colliders={"trimesh"} {...props}>
      <group {...props} dispose={null}>
        <group position={[7.173, -3, -2.669]} scale={34.715}>
          <mesh
            castShadow={isCastShadow}
            receiveShadow={isReceiveShadow}
            geometry={nodes.Plane056.geometry}
            material={greenMaterial}
          />
          <mesh
            castShadow={isCastShadow}
            receiveShadow={isReceiveShadow}
            geometry={nodes.Plane056_1.geometry}
            material={materials["rock small"]}
          />
        </group>
      </group>
    </RigidBody>
  );
};

// const IslandBase = (props) => {
//   const { nodes, materials } = useGLTF("./assets/island/Base-Site_alpha.glb");

//   return (
//     <RigidBody type="fixed" colliders={"trimesh"} {...props}>
//       <group {...props} dispose={null}>
//         <group position={[7.173, -3.15, -2.669]} scale={34.715}>
//           <mesh
//             castShadow={isCastShadow}
//             receiveShadow={isReceiveShadow}
//             geometry={nodes.Plane056.geometry}
//             material={materials["grass-big plan"]}
//           />
//           <mesh
//             castShadow={isCastShadow}
//             receiveShadow={isReceiveShadow}
//             geometry={nodes.Plane056_1.geometry}
//             material={materials["rock small"]}
//           />
//         </group>
//       </group>
//     </RigidBody>
//   );
// };

export default function Platforms() {
  return <SuncityBase />;
}

useGLTF.preload("./assets/island/Base-Site.glb");
useGLTF.preload("./assets/island/Base-Site_alpha.glb");
useGLTF.preload("./assets/suncity/base-site.glb");
useGLTF.preload("./assets/suncity/base-site_platforms.glb");
