import axios from 'axios';

async function streamHandler(url, payload, token, callback) {
  console.log('Payload:', payload);
  console.log('URL:', url);

  try {
    const response = await axios.post(url, payload, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob', // Use blob to handle the stream
    });

    const reader = response.data.stream().getReader();
    const decoder = new TextDecoder('utf-8');

    let result = '';
    while (true) {
      // eslint-disable-next-line no-await-in-loop
      const { done, value } = await reader.read();
      if (done) break;
      const chunk = decoder.decode(value, { stream: true });
      result += chunk;
      callback(chunk);
    }

    console.log(result);
    console.log('Stream ended.');
    return result;
  } catch (error) {
    console.error('Axios error:', error);
    throw error;
  }
}

export default streamHandler;
