import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";

export default function CentralPassage(props) {
  const { nodes, materials } = useGLTF(
    "./assets/island/Gateway-to-realm-of-earth.glb"
  );
  return (
    <group {...props} dispose={null}>
        <RigidBody type="fixed" colliders="trimesh">
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube035.geometry}
          material={materials.SandStone}
          position={[-15.885, 2.983, -9.333]}
          rotation={[0, -0.436, 0]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube036.geometry}
          material={materials.SandStone}
          position={[-18.941, 2.501, -10.498]}
          rotation={[0, -0.436, 0]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube040.geometry}
          material={materials.SandStone}
          position={[-9.782, 2.414, -4.712]}
          rotation={[0, -0.436, 0]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube041.geometry}
          material={materials.SandStone}
          position={[-10.021, 2.867, -9.035]}
          rotation={[0, -0.436, 0]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube042.geometry}
          material={materials.Platform}
          position={[-12.879, 2.095, -9.612]}
          rotation={[0, -0.436, 0]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube046.geometry}
          material={materials.SandStone}
          position={[-10.621, 3.273, -11.302]}
          rotation={[0, -0.436, 0]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube047.geometry}
          material={materials.Platform}
          position={[-12.88, 2.9, -9.612]}
          rotation={[0, -0.436, 0]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Fireplace001.geometry}
          material={materials.SandStone}
          position={[-13.686, 3.013, -16.214]}
          rotation={[0, -0.436, 0]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Fireplace002.geometry}
          material={materials.SandStone}
          position={[-13.551, 2.969, -16.505]}
          rotation={[0, -0.436, 0]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube036.geometry}
          material={materials.SandStone}
          position={[-8.794, 2.597, -5.055]}
          rotation={[-0.001, -0.457, -1.615]}
          scale={24.675}
        />
        </RigidBody>
        <RigidBody type="fixed" colliders={'trimesh'}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder019.geometry}
          material={materials.SandStone}
          position={[-13.863, 4.24, -7.761]}
          rotation={[0, -0.436, 0]}
          scale={24.675}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Fireplace.geometry}
          material={materials.SandStone}
          position={[-18.899, 2.964, -6.397]}
          rotation={[0, -0.436, 0]}
          scale={24.675}
          />
          </RigidBody>
      </group>
  );
}

useGLTF.preload("./assets/island/Gateway-to-realm-of-earth.glb");
