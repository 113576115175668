import { useState, useRef, useCallback } from 'react';

const useTTS = (UID) => {
  const [isLoading, setIsLoading] = useState(false);
  const audioRef = useRef(null);

  const streamTTS = useCallback(async (text, voice = 'alloy', onEnd = () => {}) => {
    setIsLoading(true);
    try {
      const serverUri = import.meta.env.VITE_SERVER_URI;
      const url = `${serverUri}/api/v1/game-story/tts-stream`;

      console.log('Fetching TTS stream from:', url);

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${UID}`,  // Pass UID as an authorization token in the headers
        },
        body: JSON.stringify({ input: text, voice }),
      });

      console.log('Response received:', response);

      if (!response.body) {
        throw new Error('ReadableStream not supported by the response');
      }

      const mediaSource = new MediaSource();
      const audio = audioRef.current;
      if (audio) {
        audio.src = URL.createObjectURL(mediaSource);

        mediaSource.addEventListener('sourceopen', () => {
          console.log('MediaSource opened');
          const sourceBuffer = mediaSource.addSourceBuffer('audio/mpeg');
          const reader = response.body.getReader();

          const appendBuffer = (value) => {
            return new Promise((resolve, reject) => {
              sourceBuffer.addEventListener('updateend', () => {
                resolve();
              }, { once: true });
              sourceBuffer.addEventListener('error', () => reject(new Error('SourceBuffer error')), { once: true });
              sourceBuffer.appendBuffer(value);
            });
          };

          const pump = async () => {
            try {
              while (true) {
                const { done, value } = await reader.read();
                if (done) {
                  mediaSource.endOfStream();
                  break;
                }
                await appendBuffer(value);
              }
            } catch (error) {
              mediaSource.endOfStream();
            }
          };

          pump().then(() => {
            if (audio.paused) {
              audio.play().catch((error) => {
                console.error('Error playing audio:', error);
              });
            }
          });
        });

        audio.onended = () => {
          console.log('Audio playback ended');
          onEnd();
        };
      }
    } catch (error) {
      console.error('Error streaming TTS:', error);
    } finally {
      setIsLoading(false);
    }
  }, [UID]);

  return { audioRef, isLoading, streamTTS };
};

export default useTTS;
