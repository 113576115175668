// speechToText.js
import axios from 'axios';

const speechToText = async (formData, apiKey) => {
  try {
    const serverUri = import.meta.env.VITE_SERVER_URI;
    const url = `${serverUri}/api/v1/game-story/speech-to-text`;
    const response = await axios.post(
      url,
      formData,
      {
        headers: {
          Authorization: `Bearer ${apiKey}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error transcribing audio:', error);
    throw error;
  }
};

export default speechToText;
