// trigger

export const createNarration = (key, text, next=null) => {
  return {
    trigger: "onStart",
    action: null,
    key: key,
    timeout: 200000,
    type: "narration",
    source: "system",
    text: text,
    next: next,
  };
};

export const createDialogueFormat = (dialogue, choices = []) => {
  return {
    trigger: "choices",
    action: null,
    key: "Mirror Of Minds",
    timeout: 1,
    type: "dialogue",
    source: "system",
    text: dialogue,
    choices: choices,
  };
};

export const createChoice = (label, keyCode, dialogue) => {
  return {
    label, 
    keyCode,
    next: dialogue
  }
}

export const createExecute = (key, url) => {
  return {
    trigger: "browser",
    action: null,
    key: key,
    timeout: 1,
    type: "execute",
    source: "system",
    text: "",
    url: url,
    next: null,
  };
};

export const createCommand = (key, command) => {
  return {
    trigger: "command",
    action: null,
    key: key,
    timeout: 1,
    type: "execute",
    source: "system",
    text: "",
    command: command,
    next: null,
  };
}

export const Narrations = [
  // // on Start
  {
    // After the Game is Started
    trigger: "start",
    action: null,
    key: "onStart",
    timeout: 1000,
    type: "narration",
    source: "system",
    text: "Welcome to Lumina.",
    // after Game introduction is finished
    next: {
      trigger: "choices",
      action: null,
      key: "Mirror Of Minds",
      timeout: 1,
      type: "dialogue",
      source: "system",
      text: "As you approach, know this coin marks the start of your journey. It leads to an assessment revealing your learning style - visual, auditory, or kinesthetic.",
      choices: [
        {
          // Second Dialogue
          label: "Okay[Y]",
          keyCode: "y",
          next: {
            trigger: "choices",
            action: null,
            key: "Mirror Of Minds",
            timeout: 1,
            type: "dialogue",
            source: "system",
            text: "Collect it at the Mirror of Minds, log in, and discover your path to leadership.",
            choices: [
              {
                label: "Okay[n]",
                keyCode: "n",
                next: {
                  trigger: "end",
                  action: null,
                  key: "onEnd",
                  timeout: 500,
                  type: "dialogue",
                  source: "convai",
                  text: ' say "I got it, what you said"',
                  next: null,
                },
              },
            ],
          },
        },
      ],
    },
  },
  // on Start
  // locked coin Interaction
  {
    trigger: "coin",
    action: "collision",
    key: "Locked Coin",
    timeout: 1500,
    type: "narration",
    source: "system",
    text: "Finish earlier module to access this coin.",
    next: null,
  },
  // locked coin Interaction
  {
    trigger: "coin",
    action: "collision",
    key: "Incomplete Activity",
    timeout: 2000,
    type: "narration",
    source: "system",
    text: "It seems you've exited the module early. The coin will reappear for you to complete the learning activity. Collect it again to finish the module and advance your leadership journey.",
    next: null,
  },
  // Coin Interactions
  // tutorial coin
  // Mirror of Minds
  {
    trigger: "coin",
    action: "collision",
    key: "Naruto Mirror of Minds",
    timeout: 1,
    type: "narration",
    source: "convai",
    text: "I Have Reached the Mirror of Minds",
    next: {
      trigger: "browser",
      action: null,
      key: "Naruto Mirror of Minds",
      timeout: 1,
      type: "execute",
      source: "system",
      text: "",
      url: "https://edvantagame.edvantalabs.com/mod/page/view.php?id=58",
      next: null,
    },
  },
  // Realm of Light
  {
    trigger: "coin",
    action: "collision",
    key: "Naruto Realm of Light",
    timeout: 1,
    type: "narration",
    source: "convai",
    text: "I Have Reached the Realm of Light",
    next: {
      trigger: "browser",
      action: null,
      key: "Naruto Mirror of Minds", // confusionA1
      timeout: 1,
      type: "execute",
      source: "system",
      text: "",
      url: "https://edvantagame.edvantalabs.com/mod/page/view.php?id=60",
      next: null,
    },
  },
  // Realm of Earth
  {
    trigger: "coin",
    action: "collision",
    key: "Naruto Realm of Earth",
    timeout: 1,
    type: "narration",
    source: "convai",
    text: "I Have Reached the Realm of Earth",
    next: {
      trigger: "browser",
      action: null,
      key: "Naruto Mirror of Minds", //? Why this?
      timeout: 1,
      type: "execute",
      source: "system",
      text: "",
      url: "https://edvantagame.edvantalabs.com/mod/page/view.php?id=61",
      next: null,
    },
  },
  // Way to Realm of Fire
  {
    trigger: "coin",
    action: "collision",
    key: "Naruto Way to Realm of Fire",
    timeout: 1,
    type: "narration",
    source: "convai",
    text: "I Have Reached on the path to Realm of Fire from the Realm of Earth",
    next: {
      trigger: "browser",
      action: "collision",
      key: "Naruto Way to Realm of Fire",
      timeout: 1000,
      type: "execute",
      source: "system",
      text: "",
      url: "https://edvantagame.edvantalabs.com/mod/page/view.php?id=63",
      next: null,
    },
  },
  // Realm of Fire
  {
    trigger: "coin",
    action: "collision",
    key: "Naruto Realm of Fire",
    timeout: 1,
    type: "narration",
    source: "convai",
    text: "I Have Reached the Realm of Fire",
    next: {
      trigger: "browser",
      action: null,
      key: "Naruto Realm of Fire",
      timeout: 1,
      type: "execute",
      source: "system",
      text: "",
      url: "https://edvantagame.edvantalabs.com/mod/page/view.php?id=64",
      next: null,
    },
  },
  // Realm of Water
  {
    trigger: "coin",
    action: "collision",
    key: "Naruto Realm of Water",
    timeout: 1,
    type: "narration",
    source: "convai",
    text: "I Have Reached the Realm of Water",
    next: {
      trigger: "browser",
      action: "collision",
      key: "Naruto Way to Realm of Wind",
      timeout: 1000,
      type: "execute",
      source: "system",
      text: "",
      url: "https://edvantagame.edvantalabs.com/mod/page/view.php?id=65",
      next: null,
    },
  },
  // Realm of Wind
  {
    trigger: "coin",
    action: "collision",
    key: "Naruto Realm of Wind",
    timeout: 1,
    type: "narration",
    source: "convai",
    text: "I Have Reached the Realm of Wind ",
    next: {
      trigger: "browser",
      action: null,
      key: "Naruto Realm of Wind",
      timeout: 1,
      type: "execute",
      source: "system",
      text: "",
      url: "https://edvantagame.edvantalabs.com/mod/page/view.php?id=66",
      next: null,
    },
  },
  // Final Assessment
  {
    trigger: "coin",
    action: "collision",
    key: "Naruto Elemental Crown",
    timeout: 1,
    type: "narration",
    source: "convai",
    text: "I Have Reached the Elemental Crown",
    next: {
      trigger: "browser",
      action: "collision",
      key: "Naruto Mirror of Minds",
      timeout: 1,
      type: "execute",
      source: "system",
      text: "",
      url: "https://edvantagame.edvantalabs.com/mod/page/view.php?id=67",
      next: null,
    },
  },
  // Survey
  {
    trigger: "coin",
    action: "collision",
    key: "Naruto Survey",
    timeout: 1,
    type: "narration",
    source: "convai",
    text: "I Have Reached the Survey",
    next: {
      trigger: "browser",
      action: "collision",
      key: "Naruto Survey",
      timeout: 1,
      type: "execute",
      source: "system",
      text: "",
      url: "https://edvantagame.edvantalabs.com/mod/page/view.php?id=103",
      next: null,
    },
  },
  // Certificate
  {
    trigger: "coin",
    action: "collision",
    key: "Naruto Certificate",
    timeout: 1,
    type: "narration",
    source: "convai",
    text: "I Have Reached the Certificate",
    next: {
      trigger: "browser",
      action: "collision",
      key: "Naruto Certificate",
      timeout: 1,
      type: "execute",
      source: "system",
      text: "",
      url: "https://edvantagame.edvantalabs.com/mod/customcert/view.php?id=76",
      next: null,
    },
  },
  // coin close Interactions
  // Coin Interactions
  // Mirror of Minds
  {
    trigger: "coin-close",
    action: "click",
    key: "Mirror of Minds",
    timeout: 5000,
    type: "narration",
    source: "system",
    text: "Now aware of your learning style, proceed to the Realm of Light for enlightenment on leadership qualities. Follow the arrows to discover what defines a true leader.",
    next: null,
  },
  // Realm of Light
  {
    trigger: "coin-close",
    action: "click",
    key: "Realm of Light",
    timeout: 5000,
    type: "narration",
    source: "system",
    text: "Module complete! Head to the Realm of Earth to unlock decision-making wisdom next.",
    next: null,
  },
  // Realm of Earth
  {
    trigger: "coin-close",
    action: "click",
    key: "Realm of Earth",
    timeout: 5000,
    type: "narration",
    source: "system",
    text: "Well done! Your journey continues to the path between Earth and Fire for deeper leadership insights.",
    next: null,
  },
  // Way to Realm of Fire
  {
    trigger: "coin-close",
    action: "click",
    key: "Way to Realm of Fire",
    timeout: 5000,
    type: "narration",
    source: "system",
    text: "Insights gained! Proceed to the Realm of Fire to discover your leadership style through self-assessment.",
    next: null,
  },
  // Realm of Fire
  {
    trigger: "coin-close",
    action: "click",
    key: "Realm of Fire",
    timeout: 5000,
    type: "narration",
    source: "system",
    text: "Assessment complete! Was that an eye-opener for you? Next, venture into the Realm of Water to dive into Adaptive Leadership.",
    next: null,
  },
  // Realm of Water
  {
    trigger: "coin-close",
    action: "click",
    key: "Realm of Water",
    timeout: 5000,
    type: "narration",
    source: "system",
    text: "Armed with the knowledge of Adaptive Leadership, let’s move to the final leg - where you will be handling various situations! Ready?",
    next: null,
  },
  // Realm of Wind
  {
    trigger: "coin-close",
    action: "click",
    key: "Realm of Wind",
    timeout: 5000,
    type: "narration",
    source: "system",
    text: "Simulation mastered! Move towards the Triumph Temple for the final assessment of your leadership journey.",
    next: null,
  },
  // Final Assessment
  {
    trigger: "coin-close",
    action: "click",
    key: "Elemental Crown",
    timeout: 5000,
    type: "narration",
    source: "system",
    text: "Congratulations on completing the final assessment! Collect your well-deserved certificate near the Elemental Crown.",
    next: null,
  },
  // Survey
  {
    trigger: "coin-close",
    action: "click",
    key: "Survey",
    timeout: 5000,
    type: "narration",
    source: "system",
    text: "Outstanding achievement! Reflect on your journey and share your feedback with the survey coin.",
    next: null,
  },
  // Certificate
  {
    trigger: "coin-close",
    action: "click",
    key: "Certificate",
    timeout: 5000,
    type: "narration",
    source: "system",
    text: "Thank you for your feedback! You've truly mastered the realms and your leadership path.",
    next: {
      trigger: "sound",
      action: "play",
      key: "Certificate",
      timeout: 1000,
      type: "execute",
      source: "system",
      text: "",
      url: "",
      next: null,
    },
  },
];

// getNarration("coin", "collision", CoinBrief[index])
export const getNarration = (trigger, action, key) =>
  Narrations.find((each) => each.action === action && each.key === key);
