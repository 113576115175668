import formatString from "./formatString"

const listOfActions = [
    { "type": "watch video", "name": "Watch Video", "url": `${import.meta.env.VITE_CLIENT_URI}/embed/VideosWidget/<phrase_or_name_of_topic>` },
    { "type": "read article", "name": "Read Article", "url": `${import.meta.env.VITE_CLIENT_URI}/game/intent/65547fa9f3fc648fa5731a4f/<phrase_or_name_of_topic>` },
    { "type": "take quiz", "name": "Take Quiz", "url": `${import.meta.env.VITE_CLIENT_URI}/game/intent/65fd5735bb94173b984a7c17/<phrase_or_name_of_topic>` },
    { "type": "take interview", "name": "Take Interview", "url": `${import.meta.env.VITE_CLIENT_URI}/game/intent/64e507455ac651a5beeabc47/<phrase_or_name_of_topic>` },
    { "type": "open url", "name": "Open URL", "url": `${import.meta.env.VITE_CLIENT_URI}/game/intent/654b1bbf7a0bff2f0832240d/<phrase_or_name_of_topic>` },
    { "type": "ask question to eddie", "name": "Ask Question to Eddie", "url": `${import.meta.env.VITE_CLIENT_URI}/game/intent/64b681e8671a5a71e86e9df2/<phrase_or_name_of_topic>` },
    { "type": "ask trigger question to tj", "name": "Ask Trigger Question to TJ", "url": `${import.meta.env.VITE_CLIENT_URI}/game/intent/64b681e8671a5a71e86e9df2/<phrase_or_name_of_topic>` },
    { "type": "see day summary", "name": "See Day Summary", "url": `${import.meta.env.VITE_CLIENT_URI}/game/intent/64b681e8671a5a71e86e9df2/<phrase_or_name_of_topic>` },
        // {
        //   type: 'Explain in Hindi',
        //   name: 'Explain in Hindi',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/64b51308916fea7090b6efdf/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Give me examples',
        //   name: 'Give me examples',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/64b51576a6745f6db0452771/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Socratic Questioning',
        //   name: 'Socratic Questioning',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/64b68066671a5a71e86e9da4/<phrase_or_name_of_topic>`
        // },
        {
          type: 'Story based learning',
          name: 'Story based learning',
          url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/64b68116671a5a71e86e9de9/<phrase_or_name_of_topic>`
        },
        {
          type: 'Case study based learning',
          name: 'Case study based learning',
          url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/64b6814c671a5a71e86e9dec/<phrase_or_name_of_topic>`
        },
        {
          type: 'Learn as an Expert',
          name: 'Learn as an Expert',
          url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/64b681ca671a5a71e86e9def/<phrase_or_name_of_topic>`
        },
        // {
        //   type: 'Learn as a Newbie.',
        //   name: 'Learn as a Newbie.',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/64b681e8671a5a71e86e9df2/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Ask me questions',
        //   name: 'Ask me questions',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/64c0dd026dac17ae702e3fef/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Fill in the Blanks',
        //   name: 'Fill in the Blanks',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/64c101b3448c058d5099917c/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Code Devil ultra',
        //   name: 'Code Devil ultra',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/64c248ca66f2ad42249eba50/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Step-wise Learning',
        //   name: 'Step-wise Learning',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/64c249502d83966ab828c2e2/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'True and False Statements',
        //   name: 'True and False Statements',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/64c25ee9e1e94413904600df/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Use for Language Learning',
        //   name: 'Use for Language Learning',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/64c566b2c0d3f138c3debc83/<phrase_or_name_of_topic>`
        // },
        {
          type: 'Role play based learning',
          name: 'Role play based learning',
          url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/64c569d2c0d3f138c3debd48/<phrase_or_name_of_topic>`
        },
        // {
        //   type: 'Roleplay for Language learning',
        //   name: 'Roleplay for Language learning',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/64c572cac0d3f138c3debf4c/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Review this',
        //   name: 'Review this',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/64c6cbb0c0d3f138c3dec3bb/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Verify this. Your opinions please.',
        //   name: 'Verify this. Your opinions please.',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/64c7a9b12befbcd2e2a15e60/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Key Information Components',
        //   name: 'Key Information Components',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/64d540decaa9697053704a36/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Understand as a Researcher',
        //   name: 'Understand as a Researcher',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/64d547c5522b708015cfd83e/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Explain Technical Concepts',
        //   name: 'Explain Technical Concepts',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/64d5cd366f9144f6e5709580/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Related Words',
        //   name: 'Related Words',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/64d6153a5a8de64ebe08ae3f/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'In Kannda',
        //   name: 'In Kannda',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/64d7c9485c251cff47f4ea4f/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Key Events',
        //   name: 'Key Events',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/64d7ee755c251cff47f4eb08/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Key People & their contribution',
        //   name: 'Key People & their contribution',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/64d7f3a55c251cff47f4eb76/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Understanding as Framework & Policies',
        //   name: 'Understanding as Framework & Policies',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/64d7f5e35c251cff47f4ebbe/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'As an Entity',
        //   name: 'As an Entity',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/64d7f7fb5c251cff47f4ebe5/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'As a Mathematician',
        //   name: 'As a Mathematician',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/64dbaec78520c47fb075f040/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'As an Expert',
        //   name: 'As an Expert',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/64dc785e6ea50165746cd6ae/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Skills & Capabilities Required',
        //   name: 'Skills & Capabilities Required',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/64ddacac4e3facb6833fda20/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Diagram it',
        //   name: 'Diagram it',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/64ddb8609e7bc8cb81d0007b/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Ascii Art',
        //   name: 'Ascii Art',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/64ddc3fc577fcdd9ae6cc16d/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'XRAY this Concept',
        //   name: 'XRAY this Concept',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/64e4ce845ac651a5beeab536/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Interview me',
        //   name: 'Interview me',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/64e507455ac651a5beeabc47/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Understand by Connections',
        //   name: 'Understand by Connections',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/64e592da5ac651a5beeabfc8/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Review my Understanding',
        //   name: 'Review my Understanding',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/64e7204ad8cdd6f8cfc62534/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'As an Imaginative Creative Thinker',
        //   name: 'As an Imaginative Creative Thinker',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/64f0fb68d8cdd6f8cfc6584a/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Understand at Chosen Depth(Level 1 - 6)',
        //   name: 'Understand at Chosen Depth(Level 1 - 6)',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/64f103f4d8cdd6f8cfc65927/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'As a friend',
        //   name: 'As a friend',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/64fec600747eded14b65ff5c/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Level 1 Depth - Structure & Symptoms',
        //   name: 'Level 1 Depth - Structure & Symptoms',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/6502b062e6cbf660798e804e/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Level 2 Depth - Summary of Processes',
        //   name: 'Level 2 Depth - Summary of Processes',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/6502b0bae6cbf660798e8064/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Level 3 Depth - Common Questions ',
        //   name: 'Level 3 Depth - Common Questions ',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/6502b107e6cbf660798e8078/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Level 4 Depth - Advanced Deconstruction',
        //   name: 'Level 4 Depth - Advanced Deconstruction',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/6502b186e6cbf660798e8097/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Level 5 Depth - Benefits, Gaps & Opportunities',
        //   name: 'Level 5 Depth - Benefits, Gaps & Opportunities',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/6502b208e6cbf660798e80ae/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Level 6 Depth - How to do a Project',
        //   name: 'Level 6 Depth - How to do a Project',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/6502b259e6cbf660798e80bd/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Master smart query v1',
        //   name: 'Master smart query v1',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/6508aef88a0281943083ea60/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Create a Smart Query',
        //   name: 'Create a Smart Query',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/6508af06516b87a2b9b11a12/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Give me a Gist',
        //   name: 'Give me a Gist',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/650944d08a0281943083f12d/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Properties & Attributes',
        //   name: 'Properties & Attributes',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/65098c0f8a0281943083f553/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Property Graph',
        //   name: 'Property Graph',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/6509978d8a0281943083f703/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Raga Description',
        //   name: 'Raga Description',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/652265202f8281200817acaf/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'How-to',
        //   name: 'How-to',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/6538ec4b52e92d611f4ceb8a/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Single Question Export',
        //   name: 'Single Question Export',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/65391ae1b54291a916be4040/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Question Set Export',
        //   name: 'Question Set Export',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/65391e77b54291a916be40e7/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Explain in Kannada',
        //   name: 'Explain in Kannada',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/653f7dbba5e9aeec13522845/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Identify the Relationship ',
        //   name: 'Identify the Relationship ',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/653fd35ca39e147c86a98ac3/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Question I should ask?',
        //   name: 'Question I should ask?',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/6548cb987a0bff2f08321feb/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Quick Summary',
        //   name: 'Quick Summary',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/654b1bbf7a0bff2f0832240d/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Annotated Summary',
        //   name: 'Annotated Summary',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/65547ea9172820cbb8af492e/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Learn as Expert with Central Ideas ',
        //   name: 'Learn as Expert with Central Ideas ',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/65547fa9f3fc648fa5731a4f/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Tell me the Mathematical Formula',
        //   name: 'Tell me the Mathematical Formula',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/6555edc5172820cbb8af4a73/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Convert to csv',
        //   name: 'Convert to csv',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/657c828bc4d9f79f07ebeb1c/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Code Devil Ultra 2',
        //   name: 'Code Devil Ultra 2',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/658099d5c4d9f79f07ec2909/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: "TJ's Guide to Galaxy",
        //   name: "TJ's Guide to Galaxy",
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/65b0e31eb413942d9fea0e74/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Roleplaying in Galaxy',
        //   name: 'Roleplaying in Galaxy',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/65b2062c29c1e7e33ca7359c/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Roleplay choice based learning',
        //   name: 'Roleplay choice based learning',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/65d5de2483ad6e3c2ac5f18a/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Prompt based learning',
        //   name: 'Prompt based learning',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/65d655d183ad6e3c2ac5f509/<phrase_or_name_of_topic>`
        // },
        // {
        //   type: 'Extract the key phrases',
        //   name: 'Extract the key phrases',
        //   url: `${import.meta.env.VITE_CLIENT_URI}/game/intent/65e4976183ad6e3c2ac5fe97/<phrase_or_name_of_topic>`
        // }
]

export const browserActions = (nextEl, topicName) => {
    return listOfActions.map((action, i) => {
        return {
            label: formatString(`${action.name}`),
            keyCode: `${i + 1}`,
            next: {
                trigger: "browser",
                action: null,
                key: "Naruto Mirror of Minds",
                timeout: 1,
                type: "execute",
                source: "system",
                text: "",
                url: action.url.replace('<phrase_or_name_of_topic>', topicName),
                next: nextEl,
            
            }
        }
    })
}