import { val } from "@theatre/core";
import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

const useAssistant = create(
  subscribeWithSelector((set) => {
    return {
      threadId: null,
      setThreadId: (val) => set({ threadId: val }),
      smartGraph: false,
      setSmartGraph: (val) => set({ smartGraph: val }),
      vectorStoreId: null,
      setSmartGraphVectorStoreId: (val) => set({ vectorStoreId: val}),
      activeAssistant: {},
      setActiveAssistant: (val) => set({ activeAssistant: val }),
      assistants: [],
      setAssistants: (val) => set({ assistants: val }),
      plot: "",
      setPlot: (val) => set({ plot: val }),
      gameSession: {},
      setGameSession: (val) => set({ gameSession: val}),
      message: "",
      setMessage: (val) => set({ message: val }),
      keyphrase: '',
      updateKeyphrase: (val) => set({ keyphrase: val}),
    };
  })
);

export default useAssistant;
