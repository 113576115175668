import { val } from '@theatre/core';
import { Value } from 'sass';
import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

export default create(
    subscribeWithSelector((set) => {
        return {
            blob: null,
            isLoading: false,
            voice: 'WUKFemale 3',
            filename: '',
            encoding: 'mp3', //
            setBlob: (val) => set({ blob: val }),
            setIsLoading: (val) => set({ isLoading: val }),
            setVoice: (val) => set({ voice: val }),
            setFilename: (Val) => set({ filename: val }),
            setEncoding: (val) => set({ encoding: val }),
            generateFilename: () => set({filename: `audio-${Math.ceil(Math.random()*99999999999)}`})
        }
    })
)