import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import CherryTrees from "./trees/CherryTrees";
import { RigidBody } from "@react-three/rapier";

// christmas trees
export function ChristmasTrees(props) {
  const { nodes, materials } = useGLTF(
    "./assets/island/landscapes/christmas-trees.glb"
  );
  return (
    <group {...props} dispose={null}>
      <group
        position={[28.778, 2.084, -7.431]}
        rotation={[0, 0.005, 0]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder011.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder011_1.geometry}
          material={materials["Conifer leaves"]}
        />
      </group>
      <group position={[-47.982, 1.999, 52.946]} scale={33.796}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder075.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder075_1.geometry}
          material={materials["Conifer leaves"]}
        />
      </group>
      <group position={[-44.982, 1.988, 36.946]} scale={24.675}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder076.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder076_1.geometry}
          material={materials["Conifer leaves"]}
        />
      </group>
      <group
        position={[24.733, 1.983, -8.456]}
        rotation={[0, -0.191, 0]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder010.geometry}
          material={materials["Conifer tree.008"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder010_1.geometry}
          material={materials["Conifer leaves.001"]}
        />
      </group>
      <group
        position={[25.917, 1.924, -2.216]}
        rotation={[0, -0.191, 0]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder012.geometry}
          material={materials["Conifer tree.008"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder012_1.geometry}
          material={materials["Conifer leaves.001"]}
        />
      </group>
      <group
        position={[23.962, 1.931, -11.264]}
        rotation={[0, -0.191, 0]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder013.geometry}
          material={materials["Conifer tree.008"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder013_1.geometry}
          material={materials["Conifer leaves.001"]}
        />
      </group>
      <group
        position={[25.356, 2.084, -5.906]}
        rotation={[0, 0.264, 0]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder014.geometry}
          material={materials["Conifer tree.008"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder014_1.geometry}
          material={materials["Conifer leaves.001"]}
        />
      </group>
      <group
        position={[26.938, 1.931, -11.264]}
        rotation={[0, -0.191, 0]}
        scale={19.442}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder015.geometry}
          material={materials["Conifer tree.009"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder015_1.geometry}
          material={materials["Conifer leaves.002"]}
        />
      </group>
      <group
        position={[20.622, 1.972, 11.105]}
        rotation={[0, -0.45, 0]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder016.geometry}
          material={materials["Conifer tree.010"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder016_1.geometry}
          material={materials["Conifer leaves.003"]}
        />
      </group>
      <group
        position={[24.649, 1.612, 4.7]}
        rotation={[0, -0.45, 0]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder017.geometry}
          material={materials["Conifer tree.010"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder017_1.geometry}
          material={materials["Conifer leaves.003"]}
        />
      </group>
      <group
        position={[24.725, 1.933, 2.786]}
        rotation={[0, -0.45, 0]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder018.geometry}
          material={materials["Conifer tree.010"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder018_1.geometry}
          material={materials["Conifer leaves.003"]}
        />
      </group>
      <group
        position={[22.709, 1.905, 9.92]}
        rotation={[0, -0.45, 0]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder019.geometry}
          material={materials["Conifer tree.010"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder019_1.geometry}
          material={materials["Conifer leaves.003"]}
        />
      </group>
      <group
        position={[23.013, 1.933, 6.445]}
        rotation={[0, -0.45, 0]}
        scale={38.322}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder020.geometry}
          material={materials["Conifer tree.010"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder020_1.geometry}
          material={materials["Conifer leaves.003"]}
        />
      </group>
      <group
        position={[34.112, 2.757, 3.136]}
        rotation={[0, -0.45, 0]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder003.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder003_1.geometry}
          material={materials["Conifer leaves"]}
        />
      </group>
      <group
        position={[28.804, 1.931, -12.967]}
        rotation={[0, -0.45, 0]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder009.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder009_1.geometry}
          material={materials["Conifer leaves"]}
        />
      </group>
      <group
        position={[27.678, 1.904, 15.273]}
        rotation={[0, -0.45, 0]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder024.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder024_1.geometry}
          material={materials["Conifer leaves"]}
        />
      </group>
      <group
        position={[31.753, 1.948, -4.504]}
        rotation={[0, -0.45, 0]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder025.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder025_1.geometry}
          material={materials["Conifer leaves"]}
        />
      </group>
      <group
        position={[28.375, 1.924, -3.72]}
        rotation={[0, -0.45, 0]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder026.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder026_1.geometry}
          material={materials["Conifer leaves"]}
        />
      </group>
      <group
        position={[30.59, 1.953, 11.608]}
        rotation={[0, -0.45, 0]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder027.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder027_1.geometry}
          material={materials["Conifer leaves"]}
        />
      </group>
      <group
        position={[32.71, 1.981, 9.471]}
        rotation={[0, 0.041, 0]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder028.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder028_1.geometry}
          material={materials["Conifer leaves"]}
        />
      </group>
      <group
        position={[23.891, 1.97, 14.689]}
        rotation={[0, -0.45, 0]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder029.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder029_1.geometry}
          material={materials["Conifer leaves"]}
        />
      </group>
      <group
        position={[20.822, 1.945, 13.702]}
        rotation={[0, -0.45, 0]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder030.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder030_1.geometry}
          material={materials["Conifer leaves"]}
        />
      </group>
      <group
        position={[29.19, 1.702, 6.129]}
        rotation={[0, -0.45, 0]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder031.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder031_1.geometry}
          material={materials["Conifer leaves"]}
        />
      </group>
      <group
        position={[26.883, 3.651, 10.574]}
        rotation={[0, -0.45, 0]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder032.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder032_1.geometry}
          material={materials["Conifer leaves"]}
        />
      </group>
      <group
        position={[31.022, 1.783, 7.088]}
        rotation={[0, -0.45, 0]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder033.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder033_1.geometry}
          material={materials["Conifer leaves"]}
        />
      </group>
      <group
        position={[32.408, 1.877, 5.381]}
        rotation={[0, -0.45, 0]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder034.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder034_1.geometry}
          material={materials["Conifer leaves"]}
        />
      </group>
      <group
        position={[28.83, 1.983, -10.055]}
        rotation={[0, -0.45, 0]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder035.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder035_1.geometry}
          material={materials["Conifer leaves"]}
        />
      </group>
      <group
        position={[29.933, 3.463, 14.453]}
        rotation={[0, -0.45, 0]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder036.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder036_1.geometry}
          material={materials["Conifer leaves"]}
        />
      </group>
      <group
        position={[32.626, 2.152, -1.793]}
        rotation={[0, -0.45, 0]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder037.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder037_1.geometry}
          material={materials["Conifer leaves"]}
        />
      </group>
      <group position={[-7.469, 1.915, -15.03]} scale={24.675}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder039.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder039_1.geometry}
          material={materials["Conifer leaves"]}
        />
      </group>
      <group
        position={[31.421, 1.833, 0.855]}
        rotation={[0, -0.45, 0]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder040.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder040_1.geometry}
          material={materials["Conifer leaves"]}
        />
      </group>
      <group
        position={[31.18, 1.884, 3.338]}
        rotation={[0, -0.45, 0]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder041.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder041_1.geometry}
          material={materials["Conifer leaves"]}
        />
      </group>
      <group
        position={[24.073, 2.147, 11.405]}
        rotation={[0, -0.45, 0]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder042.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder042_1.geometry}
          material={materials["Conifer leaves"]}
        />
      </group>
      <group
        position={[29.749, 1.965, -1.832]}
        rotation={[0, -0.45, 0]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder043.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder043_1.geometry}
          material={materials["Conifer leaves"]}
        />
      </group>
      <group
        position={[29.365, 2.012, 8.826]}
        rotation={[0, -0.45, 0]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder044.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder044_1.geometry}
          material={materials["Conifer leaves"]}
        />
      </group>
      <group
        position={[28.642, 1.945, 11.468]}
        rotation={[0, -0.45, 0]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder045.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder045_1.geometry}
          material={materials["Conifer leaves"]}
        />
      </group>
      <group
        position={[26.27, 1.926, 12.879]}
        rotation={[0, -0.45, 0]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder046.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder046_1.geometry}
          material={materials["Conifer leaves"]}
        />
      </group>
      <group
        position={[27.593, 1.917, 15.265]}
        rotation={[0, -0.45, 0]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder047.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder047_1.geometry}
          material={materials["Conifer leaves"]}
        />
      </group>
      <group
        position={[27.327, 1.933, 4.237]}
        rotation={[0, -0.45, 0]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder048.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder048_1.geometry}
          material={materials["Conifer leaves"]}
        />
      </group>
      <group
        position={[27.023, 1.905, 7.712]}
        rotation={[0, -0.45, 0]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder049.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder049_1.geometry}
          material={materials["Conifer leaves"]}
        />
      </group>
      <group
        position={[29.04, 1.933, 0.578]}
        rotation={[0, -0.45, 0]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder050.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder050_1.geometry}
          material={materials["Conifer leaves"]}
        />
      </group>
      <group
        position={[28.963, 1.612, 2.492]}
        rotation={[0, -0.45, 0]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder051.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder051_1.geometry}
          material={materials["Conifer leaves"]}
        />
      </group>
      <group
        position={[24.936, 1.972, 8.897]}
        rotation={[0, -0.45, 0]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder052.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder052_1.geometry}
          material={materials["Conifer leaves"]}
        />
      </group>
      <group position={[-23.165, 1.932, 51.576]} scale={24.675}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube097.geometry}
          material={materials["Tree 1"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube097_1.geometry}
          material={materials["Leaves 1.1"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube097_2.geometry}
          material={materials["Leaves 1"]}
        />
      </group>
      <group
        position={[9.186, 1.959, 14.229]}
        rotation={[0, -0.45, 0]}
        scale={31.77}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder057.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder057_1.geometry}
          material={materials["Conifer leaves"]}
        />
      </group>
      <group position={[-49.303, 1.999, 41.629]} scale={24.675}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder058.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder058_1.geometry}
          material={materials["Conifer leaves"]}
        />
      </group>
      <group position={[-25.324, 1.999, 59.237]} scale={24.675}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder059.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder059_1.geometry}
          material={materials["Conifer leaves"]}
        />
      </group>
      <group position={[-47.702, 1.999, 45.195]} scale={24.675}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder060.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder060_1.geometry}
          material={materials["Conifer leaves"]}
        />
      </group>
      <group position={[-19.2, 2.054, 3.341]} scale={24.675}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder061.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder061_1.geometry}
          material={materials["Conifer leaves"]}
        />
      </group>
      <group position={[-9.565, 2.054, 5.339]} scale={24.675}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder062.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder062_1.geometry}
          material={materials["Conifer leaves"]}
        />
      </group>
    </group>
  );
}

function FlowerPots(props) {
  const { nodes, materials } = useGLTF(
    "./assets/island/landscapes/flower-pots.glb"
  );
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2001.geometry}
        material={materials["Material.025"]}
        position={[-12.232, 1.931, 37.28]}
        rotation={[Math.PI / 2, 0, -1.892]}
        scale={[1.858, 1.108, 1.108]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f1001.geometry}
        material={materials["Material.025"]}
        position={[-15.205, 1.931, 36.038]}
        rotation={[Math.PI / 2, 0, -1.142]}
        scale={[1.858, 1.108, 1.108]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2002.geometry}
        material={materials["Material.025"]}
        position={[-12.232, 1.931, 37.28]}
        rotation={[Math.PI / 2, 0, -1.892]}
        scale={[1.858, 1.108, 1.108]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f1002.geometry}
        material={materials["Material.025"]}
        position={[-15.205, 1.931, 36.038]}
        rotation={[Math.PI / 2, 0, -1.142]}
        scale={[1.858, 1.108, 1.108]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2003.geometry}
        material={materials["Material.025"]}
        position={[-53.661, 1.79, 13.357]}
        rotation={[1.555, -0.001, -1.657]}
        scale={[2.387, 1.423, 1.423]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f1003.geometry}
        material={materials["Material.025"]}
        position={[-56.069, 1.854, 11.562]}
        rotation={[1.555, -0.001, -0.908]}
        scale={[2.387, 1.423, 1.423]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f1004.geometry}
        material={materials["Material.025"]}
        position={[-38.377, 1.931, 15.298]}
        rotation={[Math.PI / 2, 0, -1.142]}
        scale={[2.345, 1.398, 1.398]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f1005.geometry}
        material={materials["Material.025"]}
        position={[-36.919, 1.931, 16.535]}
        rotation={[Math.PI / 2, 0, -1.142]}
        scale={[2.345, 1.398, 1.398]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f1006.geometry}
        material={materials["Material.025"]}
        position={[-37.578, 1.931, 15.982]}
        rotation={[Math.PI / 2, 0, -1.142]}
        scale={[2.345, 1.398, 1.398]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f1007.geometry}
        material={materials["Material.025"]}
        position={[-40.721, 1.931, 13.174]}
        rotation={[Math.PI / 2, 0, -1.142]}
        scale={[2.345, 1.398, 1.398]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f1008.geometry}
        material={materials["Material.025"]}
        position={[-40.184, 1.931, 15.904]}
        rotation={[Math.PI / 2, 0, -1.142]}
        scale={[2.345, 1.398, 1.398]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f1009.geometry}
        material={materials["Material.025"]}
        position={[-39.922, 1.931, 13.858]}
        rotation={[Math.PI / 2, 0, -1.142]}
        scale={[2.345, 1.398, 1.398]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f1010.geometry}
        material={materials["Material.025"]}
        position={[-42.712, 1.931, 13.025]}
        rotation={[Math.PI / 2, 0, -1.142]}
        scale={[2.345, 1.398, 1.398]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f1011.geometry}
        material={materials["Material.025"]}
        position={[-41.339, 1.931, 11.066]}
        rotation={[Math.PI / 2, 0, -1.142]}
        scale={[2.345, 1.398, 1.398]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f1012.geometry}
        material={materials["Material.025"]}
        position={[-41.696, 1.931, 9.628]}
        rotation={[Math.PI / 2, 0, -1.142]}
        scale={[2.345, 1.398, 1.398]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f1013.geometry}
        material={materials["Material.025"]}
        position={[-41.696, 1.931, 8.07]}
        rotation={[Math.PI / 2, 0, -1.142]}
        scale={[2.345, 1.398, 1.398]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2004.geometry}
        material={materials["Material.025"]}
        position={[-10.943, 1.863, 21.749]}
        rotation={[Math.PI / 2, 0, -0.821]}
        scale={[2.653, 1.581, 1.581]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f1014.geometry}
        material={materials["Material.025"]}
        position={[-12.55, 2.158, 15.515]}
        rotation={[Math.PI / 2, 0, -0.072]}
        scale={[1.605, 0.957, 0.957]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2005.geometry}
        material={materials["Material.025"]}
        position={[-8.591, 1.863, 25.508]}
        rotation={[Math.PI / 2, 0, -0.821]}
        scale={[1.854, 1.105, 1.105]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2006.geometry}
        material={materials["Material.025"]}
        position={[-11.652, 2.158, 23.065]}
        rotation={[Math.PI / 2, 0, -0.821]}
        scale={[1.854, 1.105, 1.105]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2007.geometry}
        material={materials["Material.025"]}
        position={[-7.539, 1.863, 26.057]}
        rotation={[Math.PI / 2, 0, -1.664]}
        scale={[2.653, 1.581, 1.581]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2008.geometry}
        material={materials["Material.025"]}
        position={[-3.168, 1.894, 26.804]}
        rotation={[Math.PI / 2, 0, -1.664]}
        scale={[1.854, 1.105, 1.105]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2009.geometry}
        material={materials["Material.025"]}
        position={[0.049, 1.894, 27.718]}
        rotation={[Math.PI / 2, 0, -0.833]}
        scale={[1.854, 1.105, 1.105]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2010.geometry}
        material={materials["Material.025"]}
        position={[0.843, 1.696, 25.463]}
        rotation={[1.592, 0.217, -2.204]}
        scale={[1.542, 0.919, 0.919]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2011.geometry}
        material={materials["Material.025"]}
        position={[-66.453, 0.68, -23.233]}
        rotation={[Math.PI / 2, 0, -0.884]}
        scale={[2.653, 1.581, 1.581]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2012.geometry}
        material={materials["Material.025"]}
        position={[-63.87, 0.68, -19.629]}
        rotation={[Math.PI / 2, 0, -0.884]}
        scale={[1.854, 1.105, 1.105]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2013.geometry}
        material={materials["Material.025"]}
        position={[-67.078, 0.975, -21.876]}
        rotation={[Math.PI / 2, 0, -0.884]}
        scale={[1.854, 1.105, 1.105]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2014.geometry}
        material={materials["Material.025"]}
        position={[-62.786, 0.725, -19.147]}
        rotation={[Math.PI / 2, 0, -1.726]}
        scale={[2.653, 1.581, 1.581]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2015.geometry}
        material={materials["Material.025"]}
        position={[-58.377, 1.833, -18.675]}
        rotation={[Math.PI / 2, 0, -1.726]}
        scale={[1.854, 1.105, 1.105]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f1015.geometry}
        material={materials["Material.025"]}
        position={[-61.074, 1.931, 19.797]}
        rotation={[Math.PI / 2, 0, -2.496]}
        scale={[2.345, 1.398, 1.398]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2016.geometry}
        material={materials["Material.025"]}
        position={[-7.126, 1.894, 1.021]}
        rotation={[Math.PI / 2, 0, -1.263]}
        scale={[1.854, 1.105, 1.105]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2017.geometry}
        material={materials["Material.025"]}
        position={[5.462, 1.894, -22.9]}
        rotation={[Math.PI / 2, 0, -1.263]}
        scale={[1.854, 1.105, 1.105]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2018.geometry}
        material={materials["Material.025"]}
        position={[10.942, 1.894, -20.48]}
        rotation={[Math.PI / 2, 0, -1.263]}
        scale={[1.854, 1.105, 1.105]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2019.geometry}
        material={materials["Material.025"]}
        position={[4.442, 1.894, 6.866]}
        rotation={[Math.PI / 2, 0, -1.263]}
        scale={[1.854, 1.105, 1.105]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2020.geometry}
        material={materials["Material.025"]}
        position={[-44.104, 1.894, 49.166]}
        rotation={[Math.PI / 2, 0, -1.263]}
        scale={[1.854, 1.105, 1.105]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2021.geometry}
        material={materials["Material.025"]}
        position={[21.518, 1.999, -4.654]}
        rotation={[Math.PI / 2, 0, -1.726]}
        scale={[1.854, 1.105, 1.105]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2022.geometry}
        material={materials["Material.025"]}
        position={[21.75, 1.863, -62.915]}
        rotation={[Math.PI / 2, 0, -1.11]}
        scale={[1.854, 1.105, 1.105]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2023.geometry}
        material={materials["Material.025"]}
        position={[22.915, 1.863, -62.688]}
        rotation={[Math.PI / 2, 0, -1.952]}
        scale={[2.653, 1.581, 1.581]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2024.geometry}
        material={materials["Material.025"]}
        position={[27.318, 1.894, -63.215]}
        rotation={[Math.PI / 2, 0, -1.952]}
        scale={[1.854, 1.105, 1.105]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2025.geometry}
        material={materials["Material.025"]}
        position={[12.468, 1.805, -47.987]}
        rotation={[Math.PI / 2, 0, 2.71]}
        scale={[1.854, 1.105, 1.105]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2026.geometry}
        material={materials["Material.025"]}
        position={[11.704, 1.805, -48.895]}
        rotation={[Math.PI / 2, 0, 1.868]}
        scale={[2.653, 1.581, 1.581]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2027.geometry}
        material={materials["Material.025"]}
        position={[-7.126, 1.894, 1.021]}
        rotation={[Math.PI / 2, 0, -1.263]}
        scale={[1.854, 1.105, 1.105]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2028.geometry}
        material={materials["Material.025"]}
        position={[25.236, 1.894, -38.734]}
        rotation={[Math.PI / 2, 0, 0.635]}
        scale={[1.854, 1.105, 1.105]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2029.geometry}
        material={materials["Material.025"]}
        position={[25.236, 1.894, -38.734]}
        rotation={[Math.PI / 2, 0, 0.635]}
        scale={[1.854, 1.105, 1.105]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2030.geometry}
        material={materials["Material.025"]}
        position={[23.43, 1.894, -43.356]}
        rotation={[Math.PI / 2, 0, 0.635]}
        scale={[1.854, 1.105, 1.105]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2031.geometry}
        material={materials["Material.025"]}
        position={[46.725, 1.805, -53.227]}
        rotation={[Math.PI / 2, 0, 1.83]}
        scale={[1.854, 1.105, 1.105]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2032.geometry}
        material={materials["Material.025"]}
        position={[45.538, 1.805, -53.216]}
        rotation={[Math.PI / 2, 0, 0.988]}
        scale={[2.653, 1.581, 1.581]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2033.geometry}
        material={materials["Material.025"]}
        position={[46.598, 1.805, -59.554]}
        rotation={[Math.PI / 2, 0, -0.321]}
        scale={[1.854, 1.105, 1.105]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2034.geometry}
        material={materials["Material.025"]}
        position={[47.257, 1.805, -58.567]}
        rotation={[Math.PI / 2, 0, -1.163]}
        scale={[2.653, 1.581, 1.581]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2035.geometry}
        material={materials["Material.025"]}
        position={[47.998, 1.805, -59.783]}
        rotation={[Math.PI / 2, 0, -2.769]}
        scale={[1.854, 1.105, 1.105]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2036.geometry}
        material={materials["Material.025"]}
        position={[48.122, 1.805, -60.964]}
        rotation={[Math.PI / 2, 0, 2.672]}
        scale={[2.653, 1.581, 1.581]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2037.geometry}
        material={materials["Material.025"]}
        position={[-49.957, 0.725, -21.207]}
        rotation={[Math.PI / 2, 0, 1.003]}
        scale={[2.653, 1.581, 1.581]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2038.geometry}
        material={materials["Material.025"]}
        position={[-54.186, 1.833, -19.875]}
        rotation={[Math.PI / 2, 0, 1.003]}
        scale={[1.854, 1.105, 1.105]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f1016.geometry}
        material={materials["Material.025"]}
        position={[-44.605, 1.931, 9.163]}
        rotation={[Math.PI / 2, 0, -1.142]}
        scale={[2.345, 1.398, 1.398]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f1017.geometry}
        material={materials["Material.025"]}
        position={[-44.068, 1.931, 11.893]}
        rotation={[Math.PI / 2, 0, -1.142]}
        scale={[2.345, 1.398, 1.398]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f1018.geometry}
        material={materials["Material.025"]}
        position={[-43.806, 1.931, 9.847]}
        rotation={[Math.PI / 2, 0, -1.142]}
        scale={[2.345, 1.398, 1.398]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2039.geometry}
        material={materials["Material.025"]}
        position={[44.166, 1.805, -62.484]}
        rotation={[Math.PI / 2, 0, -0.321]}
        scale={[1.854, 1.105, 1.105]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2040.geometry}
        material={materials["Material.025"]}
        position={[44.825, 1.805, -61.497]}
        rotation={[Math.PI / 2, 0, -1.163]}
        scale={[2.653, 1.581, 1.581]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2041.geometry}
        material={materials["Material.025"]}
        position={[44.166, 1.805, -62.484]}
        rotation={[Math.PI / 2, 0, -0.321]}
        scale={[1.854, 1.105, 1.105]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.f2042.geometry}
        material={materials["Material.025"]}
        position={[44.825, 1.805, -61.497]}
        rotation={[Math.PI / 2, 0, -1.163]}
        scale={[2.653, 1.581, 1.581]}
      />
    </group>
  );
}

function Mushrooms(props) {
  const { nodes, materials } = useGLTF(
    "./assets/island/landscapes/mushrooms.glb"
  );
  return (
    <group {...props} dispose={null}>
      <group
        position={[-46.254, 1.882, -5.086]}
        scale={[-0.102, -0.165, -0.102]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder109.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder109_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group position={[-44.992, 1.982, -6.737]} scale={-0.15}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder110.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder110_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group position={[8.892, 1.794, 5.786]} scale={[-0.222, -0.231, -0.238]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder111.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder111_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[-50.793, 1.882, 21.966]}
        scale={[-0.102, -0.165, -0.102]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder118.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder118_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[-48.257, 1.882, 23.776]}
        scale={[-0.102, -0.165, -0.102]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder119.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder119_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[-42.343, 1.882, 10.742]}
        scale={[-0.102, -0.165, -0.102]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder120.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder120_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group position={[-44.992, 1.982, -6.737]} scale={-0.15}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder121.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder121_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group position={[-14.989, 1.982, 47.354]} scale={-0.15}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder122.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder122_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group position={[-23.849, 1.982, 49.437]} scale={-0.15}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder123.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder123_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[-32.475, 1.982, 45.832]}
        rotation={[0, -0.225, 0]}
        scale={-0.15}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder124.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder124_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[-13.863, 2.151, 24.245]}
        scale={[-0.067, -0.108, -0.067]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder133.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder133_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group position={[-2.373, 1.915, 29.202]} scale={-0.097}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder134.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder134_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[-11.063, 1.981, 24.34]}
        scale={[-0.117, -0.122, -0.126]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder135.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder135_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[-5.685, 1.968, 27.871]}
        rotation={[0, 0.842, 0]}
        scale={[-0.117, -0.122, -0.126]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder136.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder136_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[-2.068, 1.968, 26.865]}
        rotation={[0, 0.842, 0]}
        scale={[-0.117, -0.122, -0.126]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder137.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder137_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[1.621, 1.94, 25.041]}
        rotation={[-0.825, 1.284, 0.866]}
        scale={[-0.098, -0.102, -0.105]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder138.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder138_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group position={[-9.016, 1.915, 28.796]} scale={-0.097}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder139.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder139_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[-69.211, 1.268, -20.559]}
        rotation={[0, 0.063, 0]}
        scale={[-0.067, -0.108, -0.067]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder142.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder142_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[-57.433, 1.817, -16.332]}
        rotation={[0, 0.063, 0]}
        scale={-0.097}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder143.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder143_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[-66.41, 0.731, -20.64]}
        rotation={[0, 0.063, 0]}
        scale={[-0.117, -0.122, -0.126]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder144.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder144_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[-60.822, 0.805, -17.452]}
        rotation={[0, 0.905, 0]}
        scale={[-0.117, -0.122, -0.126]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder145.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder145_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[-57.275, 2.364, -18.684]}
        rotation={[0, 0.905, 0]}
        scale={[-0.117, -0.122, -0.126]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder146.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder146_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[-64.088, 0.731, -16.321]}
        rotation={[0, 0.063, 0]}
        scale={-0.097}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder147.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder147_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[-61.607, 1.882, 20.707]}
        rotation={[0, 1.354, 0]}
        scale={[-0.102, -0.165, -0.102]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder148.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder148_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[-7.328, 1.915, 3.538]}
        rotation={[0, -0.401, 0]}
        scale={-0.097}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder149.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder149_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[-9.859, 1.968, 1.022]}
        rotation={[0, 0.442, 0]}
        scale={[-0.117, -0.122, -0.126]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder150.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder150_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[-6.136, 1.968, 1.505]}
        rotation={[0, 0.442, 0]}
        scale={[-0.117, -0.122, -0.126]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder151.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder151_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[5.26, 1.915, -20.382]}
        rotation={[0, -0.401, 0]}
        scale={-0.097}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder152.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder152_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[2.729, 1.968, -22.898]}
        rotation={[0, 0.442, 0]}
        scale={[-0.117, -0.122, -0.126]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder153.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder153_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[6.451, 1.968, -22.415]}
        rotation={[0, 0.442, 0]}
        scale={[-0.117, -0.122, -0.126]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder154.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder154_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[10.739, 1.915, -17.962]}
        rotation={[0, -0.401, 0]}
        scale={-0.097}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder155.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder155_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[8.208, 1.968, -20.479]}
        rotation={[0, 0.442, 0]}
        scale={[-0.117, -0.122, -0.126]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder156.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder156_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[4.24, 1.915, 9.384]}
        rotation={[0, -0.401, 0]}
        scale={-0.097}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder157.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder157_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[1.709, 1.968, 6.868]}
        rotation={[0, 0.442, 0]}
        scale={[-0.117, -0.122, -0.126]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder158.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder158_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[5.431, 1.968, 7.351]}
        rotation={[0, 0.442, 0]}
        scale={[-0.117, -0.122, -0.126]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder159.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder159_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[-44.306, 1.915, 51.683]}
        rotation={[0, -0.401, 0]}
        scale={-0.097}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder160.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder160_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[-46.837, 1.968, 49.167]}
        rotation={[0, 0.442, 0]}
        scale={[-0.117, -0.122, -0.126]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder161.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder161_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[-43.114, 1.968, 49.65]}
        rotation={[0, 0.442, 0]}
        scale={[-0.117, -0.122, -0.126]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder162.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder162_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[22.462, 1.983, -2.311]}
        rotation={[0, 0.063, 0]}
        scale={-0.097}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder163.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder163_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[22.62, 1.911, -4.662]}
        rotation={[0, 0.905, 0]}
        scale={[-0.117, -0.122, -0.126]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder164.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder164_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[28.762, 1.915, -61.143]}
        rotation={[0, 0.288, 0]}
        scale={-0.097}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder165.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder165_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[19.049, 1.981, -63.332]}
        rotation={[0, 0.288, 0]}
        scale={[-0.117, -0.122, -0.126]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder166.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder166_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[25.209, 1.968, -61.476]}
        rotation={[0, 1.131, 0]}
        scale={[-0.117, -0.122, -0.126]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder167.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder167_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[28.39, 1.968, -63.47]}
        rotation={[0, 1.131, 0]}
        scale={[-0.117, -0.122, -0.126]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder168.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder168_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[22.279, 1.915, -59.642]}
        rotation={[0, 0.288, 0]}
        scale={-0.097}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder169.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder169_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[8.12, 1.858, -53.767]}
        rotation={[-Math.PI, 0.39, -Math.PI]}
        scale={-0.097}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder170.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder170_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[14.311, 1.924, -45.968]}
        rotation={[-Math.PI, 0.39, -Math.PI]}
        scale={[-0.117, -0.122, -0.126]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder171.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder171_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[10.678, 1.91, -51.278]}
        rotation={[Math.PI, -0.453, Math.PI]}
        scale={[-0.117, -0.122, -0.126]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder172.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder172_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[6.95, 1.91, -51.721]}
        rotation={[Math.PI, -0.453, Math.PI]}
        scale={[-0.117, -0.122, -0.126]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder173.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder173_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[14.111, 1.858, -50.868]}
        rotation={[-Math.PI, 0.39, -Math.PI]}
        scale={-0.097}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder174.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder174_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[-9.859, 1.968, 1.022]}
        rotation={[0, 0.442, 0]}
        scale={[-0.117, -0.122, -0.126]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder178.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder178_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[26.114, 1.968, -41.322]}
        rotation={[0, -1.457, 0]}
        scale={[-0.117, -0.122, -0.126]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder179.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder179_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[26.114, 1.968, -41.322]}
        rotation={[0, -1.457, 0]}
        scale={[-0.117, -0.122, -0.126]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder180.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder180_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[24.308, 1.968, -45.945]}
        rotation={[0, -1.457, 0]}
        scale={[-0.117, -0.122, -0.126]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder181.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder181_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[35.032, 1.91, -53.385]}
        rotation={[Math.PI, -0.453, Math.PI]}
        scale={[-0.117, -0.122, -0.126]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder182.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder182_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[39.845, 1.91, -50.923]}
        rotation={[0, -0.539, 0]}
        scale={[-0.117, -0.122, -0.126]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder183.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder183_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[46.159, 1.91, -54.694]}
        rotation={[-Math.PI, 1.232, -Math.PI]}
        scale={[-0.117, -0.122, -0.126]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder184.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder184_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[-55.989, 1.817, -21.644]}
        rotation={[Math.PI, -0.474, Math.PI]}
        scale={-0.097}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder021.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder021_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[-52.435, 0.805, -21.974]}
        rotation={[Math.PI, -1.317, Math.PI]}
        scale={[-0.117, -0.122, -0.126]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder038.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder038_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[-55.985, 2.487, -19.426]}
        rotation={[Math.PI, -1.317, Math.PI]}
        scale={[-0.117, -0.122, -0.126]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder053.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder053_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group
        position={[-42.908, 1.882, 8.532]}
        scale={[-0.102, -0.165, -0.102]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder054.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder054_1.geometry}
          material={materials["Material.036"]}
        />
      </group>
    </group>
  );
}

function Rocks(props) {
  const { nodes, materials } = useGLTF("./assets/island/landscapes/rocks.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.rock002.geometry}
        material={materials["rock 1"]}
        position={[11.726, 2.979, -33.394]}
        rotation={[-Math.PI, 0.269, -Math.PI]}
        scale={24.675}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.rock003.geometry}
        material={materials["rock 1"]}
        position={[15.008, 3.016, -37.305]}
        rotation={[-Math.PI, 0.269, -Math.PI]}
        scale={24.675}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.rock004.geometry}
        material={materials["rock 1"]}
        position={[14.511, 2.881, -33.817]}
        rotation={[-Math.PI, 0.269, -Math.PI]}
        scale={24.675}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.rock005.geometry}
        material={materials["rock 1"]}
        position={[12.106, 3.39, -44.8]}
        rotation={[-Math.PI, 0.269, -Math.PI]}
        scale={24.675}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.rock006.geometry}
        material={materials["rock 1"]}
        position={[16.891, 3.427, -40.995]}
        rotation={[-Math.PI, 0.269, -Math.PI]}
        scale={24.675}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.rock007.geometry}
        material={materials["rock 1"]}
        position={[14.277, 3.292, -42.976]}
        rotation={[-Math.PI, 0.269, -Math.PI]}
        scale={24.675}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.rock008.geometry}
        material={materials["rock 1"]}
        position={[1.817, 3.329, -51.127]}
        scale={24.675}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.rock009.geometry}
        material={materials["rock 1"]}
        position={[-5.688, 4.271, -16.167]}
        scale={24.675}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.rock010.geometry}
        material={materials["rock 1"]}
        position={[4.59, 3.231, -51.58]}
        scale={24.675}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.rock011.geometry}
        material={materials["rock 1"]}
        position={[9.505, 3.329, -46.401]}
        scale={24.675}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.rock012.geometry}
        material={materials["rock 1"]}
        position={[-11.595, 2.67, -21.422]}
        scale={24.675}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.rock013.geometry}
        material={materials["rock 1"]}
        position={[-8.864, 3.329, -19.516]}
        scale={24.675}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.rock014.geometry}
        material={materials["rock 1"]}
        position={[-11.472, 2.976, -19.516]}
        scale={24.675}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.rock015.geometry}
        material={materials["rock 1"]}
        position={[-14.235, 2.976, -16.55]}
        scale={24.675}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.rock016.geometry}
        material={materials["rock 1"]}
        position={[57.042, 3.11, -0.085]}
        scale={24.675}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.rock017.geometry}
        material={materials["rock 1"]}
        position={[59.086, 3.147, -2.99]}
        scale={24.675}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.rock018.geometry}
        material={materials["rock 1"]}
        position={[65.477, 2.82, -11.91]}
        scale={24.675}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.rock019.geometry}
        material={materials["rock 1"]}
        position={[62.336, 3.558, -6.522]}
        scale={24.675}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.rock020.geometry}
        material={materials["rock 1"]}
        position={[64.483, 3.423, -9.354]}
        scale={24.675}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.rock021.geometry}
        material={materials["rock 1"]}
        position={[26.276, 3.11, 21.424]}
        scale={24.675}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.rock023.geometry}
        material={materials["rock 1"]}
        position={[35.602, 2.385, 12.865]}
        scale={24.675}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.rock024.geometry}
        material={materials["rock 1"]}
        position={[33.358, 3.558, 15.747]}
        scale={24.675}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.rock025.geometry}
        material={materials["rock 1"]}
        position={[33.233, 3.423, 13.384]}
        scale={24.675}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.rock026.geometry}
        material={materials["rock 1"]}
        position={[-17.672, 2.653, 4.571]}
        scale={24.675}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.rock027.geometry}
        material={materials["rock 1"]}
        position={[-16.43, 2.83, 6.956]}
        scale={24.675}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.rock001.geometry}
        material={materials["rock 1"]}
        position={[-11.984, 2.976, -16.55]}
        scale={24.675}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.rock022.geometry}
        material={materials["rock 1"]}
        position={[30.452, 3.147, 18.572]}
        scale={24.675}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.rock032.geometry}
        material={materials["rock 1.005"]}
        position={[26.592, 3.427, -68.778]}
        rotation={[Math.PI, -1.046, Math.PI]}
        scale={24.675}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.rock033.geometry}
        material={materials["rock 1.005"]}
        position={[29.683, 3.016, -66.02]}
        rotation={[Math.PI, -1.046, Math.PI]}
        scale={24.675}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.rock028.geometry}
        material={materials["rock 1.001"]}
        position={[-48.209, 1.258, -25.503]}
        scale={24.675}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.rock029.geometry}
        material={materials["rock 1.002"]}
        position={[-44.719, 1.258, -25.744]}
        rotation={[Math.PI, -0.248, Math.PI]}
        scale={24.675}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.rock030.geometry}
        material={materials["rock 1.003"]}
        position={[-41.513, 2.273, -26.266]}
        rotation={[Math.PI, -0.248, Math.PI]}
        scale={16.736}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.rock031.geometry}
        material={materials["rock 1.004"]}
        position={[-39.123, 2.242, -25.693]}
        rotation={[Math.PI, -0.248, Math.PI]}
        scale={21.687}
      />
    </group>
  );
}

function SmallTrees(props) {
  const { nodes, materials } = useGLTF(
    "./assets/island/landscapes/small-trees.glb"
  );
  return (
    <group {...props} dispose={null}>
      <group
        position={[10.127, 1.892, 10.278]}
        rotation={[0, -0.45, 0]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube081.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube081_1.geometry}
          material={materials.Bush}
        />
      </group>
      <group
        position={[7.733, 1.956, -30.648]}
        rotation={[-Math.PI, 0.843, -Math.PI]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube082.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube082_1.geometry}
          material={materials.Bush}
        />
      </group>
      <group
        position={[9.027, 1.921, -27.9]}
        rotation={[-Math.PI, 0.843, -Math.PI]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube083.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube083_1.geometry}
          material={materials.Bush}
        />
      </group>
      <group position={[54.397, 1.893, 1.689]} scale={24.675}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube092.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube092_1.geometry}
          material={materials.Bush}
        />
      </group>
      <group position={[-13.149, 1.884, 15.825]} scale={24.675}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube118.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube118_1.geometry}
          material={materials.Bush}
        />
      </group>
      <group
        position={[4.064, 1.939, -27.377]}
        rotation={[-Math.PI, 0.843, -Math.PI]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube127.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube127_1.geometry}
          material={materials.Bush}
        />
      </group>
      <group position={[0.506, 1.884, 15.015]} scale={24.675}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube187.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube187_1.geometry}
          material={materials.Bush}
        />
      </group>
      <group position={[-63.506, 1.536, -37.129]} scale={24.675}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube189.geometry}
          material={materials["Conifer tree"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube189_1.geometry}
          material={materials.Bush}
        />
      </group>
      <group position={[-63.775, 2.055, 23.245]} scale={24.675}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube053.geometry}
          material={materials["Conifer tree.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube053_1.geometry}
          material={materials["Bush.001"]}
        />
      </group>
      <group position={[13.306, 1.884, -15.45]} scale={24.675}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube062.geometry}
          material={materials["Conifer tree.002"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube062_1.geometry}
          material={materials["Bush.002"]}
        />
      </group>
      <group position={[13.812, 1.884, -22.973]} scale={24.675}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube063.geometry}
          material={materials["Conifer tree.003"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube063_1.geometry}
          material={materials["Bush.003"]}
        />
      </group>
      <group position={[15.08, 1.884, -29.664]} scale={24.675}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube064.geometry}
          material={materials["Conifer tree.004"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube064_1.geometry}
          material={materials["Bush.004"]}
        />
      </group>
      <group
        position={[21.047, 1.884, -15.677]}
        rotation={[Math.PI, -0.065, Math.PI]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube074.geometry}
          material={materials["Conifer tree.005"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube074_1.geometry}
          material={materials["Bush.005"]}
        />
      </group>
      <group
        position={[21.527, 1.884, -22.436]}
        rotation={[Math.PI, -0.065, Math.PI]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube075.geometry}
          material={materials["Conifer tree.006"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube075_1.geometry}
          material={materials["Bush.006"]}
        />
      </group>
      <group
        position={[22.656, 1.884, -28.466]}
        rotation={[Math.PI, -0.065, Math.PI]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube076.geometry}
          material={materials["Conifer tree.007"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube076_1.geometry}
          material={materials["Bush.007"]}
        />
      </group>
      <group
        position={[45.209, 1.884, -65.061]}
        rotation={[-Math.PI, 1.498, -Math.PI]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube139.geometry}
          material={materials["Conifer tree.011"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube139_1.geometry}
          material={materials["Bush.008"]}
        />
      </group>
      <group
        position={[33.831, 1.921, -65.115]}
        rotation={[-Math.PI, 0.843, -Math.PI]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube140.geometry}
          material={materials["Conifer tree.012"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube140_1.geometry}
          material={materials["Bush.009"]}
        />
      </group>
      <group
        position={[39.392, 1.956, -65.687]}
        rotation={[-Math.PI, 0.843, -Math.PI]}
        scale={24.675}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube141.geometry}
          material={materials["Conifer tree.012"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube141_1.geometry}
          material={materials["Bush.009"]}
        />
      </group>
    </group>
  );
}

function SteppingStones(props) {
  const { nodes, materials } = useGLTF(
    "./assets/island/landscapes/stepping-stones.glb"
  );
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Stepping_stone003.geometry}
        material={materials.Stone}
        position={[39.923, 1.821, -45.582]}
        rotation={[0, 0.284, 0]}
        scale={24.675}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Stepping_stone001.geometry}
        material={materials.Stone}
        position={[47.936, 1.521, -38.467]}
        rotation={[0, -0.471, 0]}
        scale={25.675}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Stepping_stone002.geometry}
        material={materials.Stone}
        position={[52.494, 1.821, -43.737]}
        rotation={[0, -0.371, 0]}
        scale={40.675}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Stepping_stone004.geometry}
        material={materials.Stone}
        position={[41.649, 1.821, -47.938]}
        rotation={[0, 0.284, 0]}
        scale={24.675}
      />
    </group>
  );
}

function TealTrees(props) {
  const { nodes, materials } = useGLTF(
    "./assets/island/landscapes/teal-trees.glb"
  );
  return (
    <group {...props} dispose={null}>
      <group position={[-68.326, 0.987, -12.516]} scale={24.675}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane005.geometry}
          material={materials.TREE1}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane005_1.geometry}
          material={materials.tree2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane005_2.geometry}
          material={materials["tree 3"]}
        />
      </group>
      <group position={[-15.814, 1.88, -0.151]} scale={24.675}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane049.geometry}
          material={materials.TREE1}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane049_1.geometry}
          material={materials.tree2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane049_2.geometry}
          material={materials["tree 3"]}
        />
      </group>
      <group position={[-74.109, 0.684, -28.072]} scale={24.675}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane050.geometry}
          material={materials.TREE1}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane050_1.geometry}
          material={materials.tree2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane050_2.geometry}
          material={materials["tree 3"]}
        />
      </group>
      <group position={[6.739, 1.909, 21.363]} scale={24.675}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane051.geometry}
          material={materials.TREE1}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane051_1.geometry}
          material={materials.tree2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane051_2.geometry}
          material={materials["tree 3"]}
        />
      </group>
      <group position={[6.739, 1.909, 26.688]} scale={24.675}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane052.geometry}
          material={materials.TREE1}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane052_1.geometry}
          material={materials.tree2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane052_2.geometry}
          material={materials["tree 3"]}
        />
      </group>
      <group position={[-80.12, 0.987, 1.101]} scale={24.675}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane065.geometry}
          material={materials.TREE1}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane065_1.geometry}
          material={materials.tree2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane065_2.geometry}
          material={materials["tree 3"]}
        />
      </group>
      <group position={[-44.062, 1.639, 55.005]} scale={24.675}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube096.geometry}
          material={materials["Tree 1"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube096_1.geometry}
          material={materials["Leaves 1.1"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube096_2.geometry}
          material={materials["Leaves 1"]}
        />
      </group>
      <group
        position={[76.631, 1.976, -27.249]}
        rotation={[-Math.PI, 1.563, -Math.PI]}
        scale={[24.675, 22.813, 24.675]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane003.geometry}
          material={materials.TREE1}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane003_1.geometry}
          material={materials.tree2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane003_2.geometry}
          material={materials["tree 3"]}
        />
      </group>
      <group position={[74.388, 2.259, -48.167]} scale={24.675}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane004.geometry}
          material={materials.TREE1}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane004_1.geometry}
          material={materials.tree2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane004_2.geometry}
          material={materials["tree 3"]}
        />
      </group>
      <group position={[-85.791, 4.384, 18.202]} scale={[0.756, 1.584, 0.832]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder095.geometry}
          material={materials["Material.025"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder095_1.geometry}
          material={materials["Material.026"]}
        />
      </group>
      <group position={[-72.363, 4.384, 27.156]} scale={[0.756, 1.584, 0.832]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder096.geometry}
          material={materials["Material.025"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder096_1.geometry}
          material={materials["Material.026"]}
        />
      </group>
      <group position={[-92.592, 12.046, -6.369]} scale={[0.756, 1.584, 0.832]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder104.geometry}
          material={materials["Material.025"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder104_1.geometry}
          material={materials["Material.026"]}
        />
      </group>
      <group position={[-37.313, 1.816, -9.576]} scale={24.675}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane002.geometry}
          material={materials["TREE1.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane002_1.geometry}
          material={materials["tree2.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane002_2.geometry}
          material={materials["tree 3.001"]}
        />
      </group>
      <group position={[-49.542, 0.684, -27.513]} scale={24.675}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane018.geometry}
          material={materials["TREE1.002"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane018_1.geometry}
          material={materials["tree2.002"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane018_2.geometry}
          material={materials["tree 3.002"]}
        />
      </group>
      <group position={[2.254, 0.072, -52.549]} scale={24.675}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane019.geometry}
          material={materials["TREE1.003"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane019_1.geometry}
          material={materials["tree2.003"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane019_2.geometry}
          material={materials["tree 3.003"]}
        />
      </group>
      <group position={[3.938, 1.791, 9.476]} scale={24.675}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane020.geometry}
          material={materials["TREE1.004"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane020_1.geometry}
          material={materials["tree2.004"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane020_2.geometry}
          material={materials["tree 3.004"]}
        />
      </group>
      <group position={[13.432, 1.384, -74.247]} scale={24.675}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane023.geometry}
          material={materials["TREE1.005"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane023_1.geometry}
          material={materials["tree2.005"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane023_2.geometry}
          material={materials["tree 3.005"]}
        />
      </group>
      <group position={[37.497, 1.899, -64.784]} scale={24.675}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane169.geometry}
          material={materials["TREE1.005"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane169_1.geometry}
          material={materials["tree2.005"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane169_2.geometry}
          material={materials["tree 3.005"]}
        />
      </group>
      <group position={[45.665, 1.899, -20.232]} scale={24.675}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane317.geometry}
          material={materials["TREE1.005"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane317_1.geometry}
          material={materials["tree2.005"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane317_2.geometry}
          material={materials["tree 3.005"]}
        />
      </group>
      <group position={[24.173, 2.022, -70.76]} scale={24.675}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane168.geometry}
          material={materials["TREE1.005"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane168_1.geometry}
          material={materials["tree2.005"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane168_2.geometry}
          material={materials["tree 3.005"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Tree_average_bare.geometry}
        material={materials["wood 2"]}
        position={[-20.849, 1.869, 11.721]}
        scale={24.675}
      />
      <group position={[61.933, 1.918, -62.804]} scale={24.675}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane321.geometry}
          material={materials.TREE1}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane321_1.geometry}
          material={materials.tree2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane321_2.geometry}
          material={materials["tree 3"]}
        />
      </group>
    </group>
  );
}

function Forest(props) {
  return (
    <>
      {/* <FlowerPots /> */}
      {/* <Mushrooms /> */}
      {/* <FlowerPots /> */}
      <RigidBody type="fixed" colliders="hull">
        <SmallTrees />
      </RigidBody>
      <RigidBody type="fixed" colliders="hull">
        <SteppingStones />
      </RigidBody>
      <RigidBody type="fixed" colliders="cuboid">
        <ChristmasTrees />
      </RigidBody>
      <RigidBody type="fixed" colliders="hull">
        <Rocks />
        <TealTrees />
      </RigidBody>
    </>
  );
}

useGLTF.preload("./assets/island/landscapes/christmas-trees.glb");
useGLTF.preload("./assets/island/landscapes/flower-pots.glb");
useGLTF.preload("./assets/island/landscapes/mushrooms.glb");
useGLTF.preload("./assets/island/landscapes/rocks.glb");
useGLTF.preload("./assets/island/landscapes/small-trees.glb");
useGLTF.preload("./assets/island/landscapes/stepping-stones.glb");
useGLTF.preload("./assets/island/landscapes/teal-trees.glb");

export default Forest;
