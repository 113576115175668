import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";

function AliciaTree(props) {
  const { nodes, materials } = useGLTF("./assets/island/floating-island-tree.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.tree.geometry}
        material={materials["Material.073"]}
        position={[-29.391, 11.225, -41.713]}
        rotation={[Math.PI / 2, 0, 2.23]}
        scale={0.01}
      />
    </group>
  );
}

export function FlyingIsland(props) {
  const { nodes, materials } = useGLTF("./assets/island/floating-island.glb");
  return (
    <>
      <group {...props} dispose={null}>
        <RigidBody type="fixed" colliders="trimesh">
          <AliciaTree/>
        </RigidBody>
        <RigidBody type="fixed" colliders="hull">
          <group {...props} dispose={null}>
            <group
              position={[-24.894, -4.794, -45.997]}
              rotation={[0, 0.801, 0]}
              scale={22.256}
            >
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.mesh1926919455.geometry}
                material={materials.mat20}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.mesh1926919455_1.geometry}
                material={materials.mat9}
              />
            </group>
          </group>
        </RigidBody>
      </group>
    </>
  );
}

useGLTF.preload("./assets/island/floating-island-tree.glb");
useGLTF.preload("./assets/island/floating-island.glb");
