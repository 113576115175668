import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";

export default function Cafes(props) {
  const { nodes, materials } = useGLTF("./assets/suncity/cafes.glb");
  return (
    <RigidBody type="fixed" colliders="trimesh">
      <group {...props} dispose={null}>
        <group position={[-44.647, -1.993, -31.23]} scale={6.196}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube133.geometry}
            material={materials["WINDOW.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube133_1.geometry}
            material={materials["Material.050"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube133_2.geometry}
            material={materials.wire_177148027}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube133_3.geometry}
            material={materials["Material.227"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube133_4.geometry}
            material={materials["Material.234"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube133_5.geometry}
            material={materials["Material.241"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube133_6.geometry}
            material={materials["Material.242"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube133_7.geometry}
            material={materials["Material.247"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube133_8.geometry}
            material={materials["Material.218"]}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane139.geometry}
          material={materials["Material.184"]}
          position={[50.053, 7.463, -13.237]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={[5.039, 8.803, 11.696]}
        />
        <group position={[-48.117, -2.152, -31.23]} scale={6.196}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube135.geometry}
            material={materials["Material.089"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube135_1.geometry}
            material={materials["Material.050"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube135_2.geometry}
            material={materials.wire_177148027}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube135_3.geometry}
            material={materials["Material.240"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube135_4.geometry}
            material={materials["Material.245"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube135_5.geometry}
            material={materials["Material.246"]}
          />
        </group>
      </group>
    </RigidBody>
  );
}

useGLTF.preload("./assets/suncity/cafes.glb");
