import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";

export default function LockedDoorwaysOfRealmOfWinds(props) {
  const { nodes, materials } = useGLTF("./assets/island/LockedDoorways.glb");
  return (
    <group {...props} dispose={null}>
      {/* first door */}
      <group
        position={[-37.312, 2.897, 22.631]}
        rotation={[Math.PI / 2, 0, -1]}
        scale={[0.005, 0.004, 0.004]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Arch.geometry}
          material={materials.Entrance_Pillar_Arches}
          position={[-345.4, -230.549, 196.07]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Arch_Step.geometry}
          material={materials.Side_Stairwell}
          position={[-345.4, -230.549, 196.07]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Doorframe.geometry}
          material={materials.Entrance_Doorframes}
          position={[-345.4, -230.549, 196.07]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Gem1.geometry}
          material={materials.Gem}
          position={[-345.4, -230.549, 196.07]}
        />
      </group>
      {/* first door-arch inside */}
      {/* <group
        position={[-37.312, 2.897, 23.031]}
        rotation={[Math.PI / 2, 0, -1.075]}
        scale={[0.005, 0.004, 0.004]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Arch_Step001.geometry}
          material={materials.Side_Stairwell}
          position={[-345.4, -230.549, 196.07]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Doorframe001.geometry}
          material={materials.Entrance_Doorframes}
          position={[-345.4, -230.549, 196.07]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Gem1001.geometry}
          material={materials.Gem}
          position={[-345.4, -230.549, 196.07]}
        />
      </group> */}
      <group
        position={[-34.475, 2.897, 17.668]}
        rotation={[Math.PI / 2, 0, -1.075]}
        scale={0.004}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Arch002.geometry}
          material={materials.Entrance_Pillar_Arches}
          position={[-345.921, -230.624, 196.498]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Arch_Step002.geometry}
          material={materials.Side_Stairwell}
          position={[-345.921, -230.624, 196.498]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Doorframe002.geometry}
          material={materials.Entrance_Doorframes}
          position={[-345.921, -230.624, 196.498]}
        />
      </group>
      <group
        position={[-34.475, 2.897, 17.668]}
        rotation={[Math.PI / 2, 0, -1.075]}
        scale={0.004}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Arch_Step003.geometry}
          material={materials.Side_Stairwell}
          position={[-345.922, -230.622, 196.498]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Gem1003.geometry}
          material={materials.Gem}
          position={[-345.922, -230.622, 196.498]}
        />
      </group>
      {/* last door */}
      <group
        position={[-44.209, 2.897, 25.169]}
        rotation={[Math.PI / 2, 0, -1.075]}
        scale={0.004}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Arch004.geometry}
          material={materials.Entrance_Pillar_Arches}
          position={[-544.644, 863.185, 194.821]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Arch_Step004.geometry}
          material={materials.Side_Stairwell}
          position={[-544.644, 863.185, 194.821]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Doorframe004.geometry}
          material={materials.Entrance_Doorframes}
          position={[-544.644, 863.185, 194.821]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Gem1004.geometry}
          material={materials.Gem}
          position={[-544.644, 863.185, 194.821]}
        />
      </group>
      {/* Locked doorway 1 */}
      <RigidBody type="fixed" colliders="cuboid">
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane009.geometry}
          material={nodes.Plane009.material}
          position={[-42.225, 4.91, 28.803]}
          rotation={[0, -Math.PI / 6, -Math.PI / 2]}
          scale={[3.637, 2.512, 2.213]}
        />
      </RigidBody>
      {/* Locked doorway 2 */}
      <RigidBody type="fixed" colliders="cuboid">
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane010.geometry}
          material={nodes.Plane010.material}
          position={[-36.078, 4.91, 18.447]}
          rotation={[0, -Math.PI / 6, -Math.PI / 2]}
          scale={[3.637, 2.512, 2.213]}
        />
      </RigidBody>
    </group>
  );
}

useGLTF.preload("./assets/island/LockedDoorways.glb");
