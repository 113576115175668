import streamHandler from "../utils/streamHandler";

async function generateKeyphrase(dialogue, vectorStoreId, token, callback) {
  const payload = { dialogue, vectorStoreId };
  const serverUri = import.meta.env.VITE_SERVER_URI;
  const url = `${serverUri}/api/v1/game-story/assistant/keyphrase-extraction`;

  return await streamHandler(url, payload, token, callback);
}

export default generateKeyphrase;
