import { useEffect } from "react";
import useInventory from "../../stores/useInventory";
import useTimerStore from "../../stores/useTimerStore";
import useGame from "../../stores/useGame";

const useInventoryObserver = () => {
    const { currentCollectedItem, collectedItems } = useInventory((state) => state);

  // add timer
//   const { addTime } = useTimerStore((state) => state);

  // increase Coins
  const { increaseCoins } = useGame((state) => state);

    useEffect(() => {
        // console.log('xvf', collectedItems);
      if (currentCollectedItem) {
          if (currentCollectedItem === 'coin') {
              increaseCoins();
          }
      switch (currentCollectedItem) {
        case "heart":
          // Perform action for heart item
          console.log("Heart item collected!");
          break;
        case "clock":
          // Perform action for clock item
          console.log("Clock item collected!");
        //   addTime(30000);
          break;
        case "question":
          // Perform action for question item
          console.log("Question item collected!");
          break;
        case "fuel":
          // Perform action for fuel item
          console.log("Fuel item collected!");
          break;
        case "coin":
          // Perform action for coin item
          console.log("Coin item collected!");
        //   increaseCoins();
          break;
        case "exclaimation":
          // Perform action for exclaimation item
          console.log("Exclaimation item collected!");
          break;
        case "diamond":
          // Perform action for diamond item
          console.log("Diamond item collected!");
          break;
        default:
          console.log("Unknown item collected!");
      }

      // Reset current collected item after action is performed
    //   setCurrentCollectedItem(null);
    }
  }, [currentCollectedItem]);
};

export default useInventoryObserver;
