import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";

export default function ShoppingMall(props) {
  const { nodes, materials } = useGLTF("./assets/suncity/shoppingMall.glb");
  return (
    <RigidBody type="fixed" colliders="trimesh">
      <group {...props} dispose={null}>
        <group
          position={[-56.122, -2.293, -71.163]}
          scale={[6.395, 8.803, 8.088]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane057.geometry}
            material={materials["Material.020"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane057_1.geometry}
            material={materials["WINDOW.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane057_2.geometry}
            material={materials["Material.040"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane057_3.geometry}
            material={materials["Material.041"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane057_4.geometry}
            material={materials["Material.050"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane057_5.geometry}
            material={materials["Material.063"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane057_6.geometry}
            material={materials["Material.073"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane057_7.geometry}
            material={materials["Material.174"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane057_8.geometry}
            material={materials["Material.179"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane057_9.geometry}
            material={materials["Material.180"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane057_10.geometry}
            material={materials["Material.181"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane057_11.geometry}
            material={materials["Material.052"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane057_12.geometry}
            material={materials["Material.175"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane057_13.geometry}
            material={materials["Material.176"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane057_14.geometry}
            material={materials["Material.177"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane057_15.geometry}
            material={materials["Material.184"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane057_16.geometry}
            material={materials["Material.025"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane057_17.geometry}
            material={materials["Material.026"]}
          />
        </group>
        <group
          position={[-56.122, -2.293, -71.163]}
          scale={[6.395, 8.803, 8.088]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane002.geometry}
            material={materials["Material.020"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane002_1.geometry}
            material={materials["WINDOW.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane002_2.geometry}
            material={materials["Material.040"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane002_3.geometry}
            material={materials["Material.041"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane002_4.geometry}
            material={materials["Material.050"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane002_5.geometry}
            material={materials["Material.063"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane002_6.geometry}
            material={materials["Material.073"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane002_7.geometry}
            material={materials["Material.174"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane002_8.geometry}
            material={materials["Material.179"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane002_9.geometry}
            material={materials["Material.180"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane002_10.geometry}
            material={materials["Material.181"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane002_11.geometry}
            material={materials["Material.052"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane002_12.geometry}
            material={materials["Material.175"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane002_13.geometry}
            material={materials["Material.176"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane002_14.geometry}
            material={materials["Material.177"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane002_15.geometry}
            material={materials["Material.184"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane002_16.geometry}
            material={materials["Material.025"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane002_17.geometry}
            material={materials["Material.026"]}
          />
        </group>
      </group>
    </RigidBody>
  );
}

useGLTF.preload("./assets/suncity/shoppingMall.glb");
