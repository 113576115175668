import streamHandler from "../utils/streamHandler";

async function generateGoal(dialogue, order, prevGoal, threadId, vectorStoreId, smartGraph, token, callback) {
  const payload = { dialogue, order, prevGoal, threadId, vectorStoreId, smartGraph };
  const serverUri = import.meta.env.VITE_SERVER_URI;
  const url = `${serverUri}/api/v1/game-story/assistant/goal-extraction`;

  return await streamHandler(url, payload, token, callback);
}

export default generateGoal;