import { useGLTF, Html } from "@react-three/drei";
import useGame from "../../stores/useGame.jsx";
import { useRef, useEffect, useState } from "react";
import { useFrame } from "@react-three/fiber";
import useSound from "use-sound";
import { Vector3 } from "three";
import useDialogue from "../../stores/useDialogue.jsx";
import useInventory from "../../stores/useInventory.jsx";
import useTimerStore from "../../stores/useTimerStore.jsx";
import useAssistant from "../../stores/useAssistant.jsx";
import useGoalStore from "../../stores/useGoalStore.jsx";
import useEventStore from "../../stores/useEventStore.jsx";
import {
  coinContentDistribution,
  coinGoalDistribution,
} from "../../services/coinContentDistribution.js";
import {
  callTriggerWithInventoryItem,
  executeEssayAnswering,
  executeFuntionWithDidYouKnow,
  executeNarrationWithAskMeQuestion,
  executeNarrationWithEdSkills,
  executeNarrationWithGoal,
  executeNarrationWithHint,
  executeNarrationWithReports,
  executeNarrationWithVideo,
  superJumpWithTimer,
} from "../../services/callTriggerWithInventoryItems.jsx";

// import inventory items
import { Clock } from "./inventory/Clock.jsx";
import { Diamond } from "./inventory/Diamond.jsx";
import { Exclaimation } from "./inventory/ExclaimationMark.jsx";
import { Fuel } from "./inventory/Fuel.jsx";
import { Heart } from "./inventory/Heart.jsx";
import { Question } from "./inventory/QuestionMark.jsx";
import { CoinModel } from "./inventory/Coin.jsx";
import { Sapphire } from "./inventory/Sapphire.jsx";
import { StartButton } from "./inventory/StartButton.jsx";
import { fetchGoal } from "../../services/fetchGoals.js";
import { Spanner } from "./inventory/Spanner.jsx";
import { Glasses } from "./inventory/Glass.jsx";

const InventoryItem = ({ item, isStartButton }) => {
  if (isStartButton) {
    return <StartButton />;
  } else {
    if (item === "diamond") {
      return <Diamond />;
    } else if (item === "clock") {
      return <Clock />;
    } else if (item === "heart") {
      return <Heart />;
    } else if (item === "fuel") {
      return <Fuel />;
    } else if (item === "question") {
      return <Question />;
    } else if (item === "exclaimation") {
      return <Exclaimation />;
    } else if (item === "coin") {
      return <CoinModel />;
    } else if (item === "sapphire") {
      return <Sapphire />;
    } else if (item === 'spanner') {
      return <Spanner/>
    } else if (item === 'glasses') {
      return <Glasses/>
    }
  }
};

const Coin = (props) => {
  const { char } = props;
  const { topic, UID, user, setIsLoading, setLoadingText, resetLoadingText } =
    useGame((state) => state);
  const { positions, items, setCurrentCollectedItem } = useInventory(
    (state) => state
  );
  const { updateKeyphrase, threadId, vectorStoreId, keyphrase, smartGraph } =
    useAssistant((state) => state);
  const { addTime, startTimer, timerStatus } = useTimerStore((state) => state);
  const { setNarration } = useDialogue((state) => state);

  const { goals, addGoal, latestGoalId, setGoals, setLatestGoalId } = useGoalStore(
    (state) => state
  );
  const { setEvent } = useEventStore((state) => state);

  const coinPositionsCount = positions.length;
  const [playCoinSound] = useSound("./assets/audio/rpg/coin.wav", {
    volume: 0.2,
    interrupt: true,
  });
  const [playsound] = useSound("./assets/audio/rpg/game-end.wav", {
    volume: 0.5,
    interrupt: true,
  });
  const coinRef = useRef([]);
  const { nodes, materials } = useGLTF("./assets/models/coin.glb");

  const [showCoins, setShowCoins] = useState(false);

  useEffect(() => {
    setShowCoins(timerStatus);
  }, [timerStatus]);

  const areAllGoalsCompleted =
    goals.length === 0 || goals.every((goal) => goal.status);

  const coin = [...Array(coinPositionsCount)].map((value, index) => {
    const currentItem = items[positions[index].type];
    const isFuelCan = currentItem === "fuel";
    const shouldShowFuelCan = isFuelCan && areAllGoalsCompleted;
    const shouldShowOtherItems = !isFuelCan && goals.length > 0;

    if (positions[index].type === items.length) {
      // Render start button
      return (
        <group
          ref={(element) => (coinRef.current[index] = element)}
          key={index}
          position={positions[index].position}
          scale={2}
          col={false}
        >
          <InventoryItem item={currentItem} isStartButton={true} />
        </group>
      );
    }

    if ((shouldShowFuelCan || shouldShowOtherItems) && showCoins) {
      return (
        <group
          ref={(element) => (coinRef.current[index] = element)}
          key={index}
          position={positions[index].position}
          scale={2}
          col={false}
        >
          <InventoryItem item={currentItem} isStartButton={false} />
        </group>
      );
    }
    return null;
  });

  const updateKeyPhrase = () => {
    let goal = '';
    if (goals && goals.length > 0) {
      goal = goals.find((goal) => goal.id === latestGoalId);
    }
    coinContentDistribution(topic, goal.goal, vectorStoreId, UID).then((response) => {
      updateKeyphrase(response.keyphrase);
      console.log("xvf", "I got the keyphrase here", response.keyphrase);
    });
  };

  const createGoalForTheTopic = () => {
    setLoadingText("Setting up your goal");
    setIsLoading(true);
    const lastGoal = goals.length > 0 ? goals[goals.length - 1] : null;
    coinGoalDistribution(
      topic,
      goals.length,
      lastGoal,
      threadId,
      vectorStoreId,
      smartGraph,
      UID
    )
      .then((response) => {
        addGoal(response);
        setIsLoading(false);
        updateKeyPhrase();
        resetLoadingText();
        // create a event for creating a new Goal
        const event = {
          actor: {
            username: user.username,
          },
          verb: 'CREATED_GOAL',
          value: {
            title: response.goal,
          },
          dataSource: "school-of-unlearn",
        };
        setEvent(event);
        //  ----- Event End -----
        console.log("xvf", "I got a goal here", response.goal);
      })
      .catch((error) => {
        setLoadingText("Not Feeling well");
        setTimeout(() => {
          setIsLoading(false);
          resetLoadingText();
        }, 1000);
      });
  };

  useFrame((state, delta) => {
    coinRef.current.forEach((ref, index) => {
      if (!ref) return;
      let coinType = positions[index].type;
      let currentItem = items[coinType];
      ref.rotation.y += delta * 1.5;
      if (char.current) {
        const coinPosition = ref.position;
        const playerPosition = char.current.translation();
        const position = new Vector3(
          playerPosition.x,
          playerPosition.y,
          playerPosition.z
        );
        const distance = coinPosition.distanceTo(position);

        if (!ref.col && distance < 3) {
          playCoinSound();
          ref.visible = false;
          ref.col = true;
          let temp;
          console.log("xvf", currentItem);
          if (currentItem === "clock" && timerStatus) {
            let temp = superJumpWithTimer();
            setNarration(temp);
            addTime(180000); // 5 minute;
          }
          if (currentItem === "diamond" && timerStatus) {
            let temp = executeNarrationWithVideo(keyphrase);
            updateKeyPhrase();
            setNarration(temp);
          }
          if (currentItem === "question" && timerStatus) {
            let temp = executeNarrationWithAskMeQuestion(
              keyphrase,
              latestGoalId
            );
            updateKeyPhrase();
            setNarration(temp);
          }
          if (currentItem === "sapphire" && timerStatus) {
            let temp = executeEssayAnswering(keyphrase, latestGoalId);
            updateKeyPhrase();
            setNarration(temp);
          }
          if (currentItem === "fuel" && timerStatus) {
            createGoalForTheTopic();
          }
          if (currentItem === "exclaimation" && timerStatus) {
            let temp = executeNarrationWithHint();
            console.log("xvf", "hint narration", temp);
            setNarration(temp);
          }
          if (currentItem === 'coin' && timerStatus) {
            let temp = executeFuntionWithDidYouKnow();
            setNarration(temp);
          }
          if (currentItem === 'spanner' && timerStatus) {
            let temp = executeNarrationWithEdSkills();
            setNarration(temp);
          }
          if (currentItem === 'glasses' && timerStatus) {
            let temp = executeNarrationWithReports();
            setNarration(temp);
          }
          if (coinType === items.length) {
            const event = {
              actor: {
                username: user.username,
              },
              verb: "GAME_STARTED",
              value: "60 min Remaining",
              dataSource: "school-of-unlearn",
            };
            setEvent(event);
            fetchGoal(smartGraph, UID).then((goals) => {
              console.log(goals);
              const lastIncompleteGoal = goals.find(each => each.status === false);
              if (lastIncompleteGoal) {
                setLatestGoalId(lastIncompleteGoal.id);
              }
              setGoals(goals);
              updateKeyPhrase();
            });
            startTimer();
          }
          setCurrentCollectedItem(currentItem);
        }
      }
    });
  });

  return <>{coin}</>;
};

export default function Coins(props) {
  const { char } = props;

  return (
    <>
      <Coin char={char} />
    </>
  );
}

useGLTF.preload("./assets/models/coin.glb");
