import { val } from "@theatre/core";
import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";
const heightofCoin = 1;

const useInventory = create(
  subscribeWithSelector((set) => {
    return {
      isInventory: false,
      currentCollectedItem: null,
      collectedItems: [],
      items: [ 'coin', 'heart', 'clock', 'question', 'fuel', 'sapphire', 'exclaimation', 'diamond', 'spanner', 'glasses'],
      positions: [
        [55.13, heightofCoin, 69.7],
        [43.04, heightofCoin, 107.52],
        [36.97, heightofCoin, 78.47],
        [34.93, heightofCoin, 37.95],
        [26.82, heightofCoin, 11.53],
        [7.63, heightofCoin, -30.26],
        [-10.35, heightofCoin, -48.6],
        [-62.88, heightofCoin, -62.27],
      [-58.54, heightofCoin, 15.33],
        [-25.09, heightofCoin, 33.29],
        [-25.09, heightofCoin, 33.29],
        [-57.76, heightofCoin, 115.01],
        [7.9, heightofCoin, 105.95],
      ],
      setCurrentCollectedItem: (val) => set((state) => {
        const newCollectedItems = [...state.collectedItems, val];
        return { currentCollectedItem: val, collectedItems: newCollectedItems };
      }),
      setInventory: (val) => set({ isInventory: val }),
      updatePositionMatrix: (val) => set({ positions: val }),
    };
  })
);

export default useInventory;
