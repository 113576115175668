import { generateRandomNumber } from "../../../utils/random";

const heightOfCoin = 1;
let coinPositionsArray = [
  [55.13, heightOfCoin, 69.7],
  [43.04, heightOfCoin, 107.52],
  [36.97, heightOfCoin, 78.47],
  [34.93, heightOfCoin, 37.95],
  [26.82, heightOfCoin, 11.53],
  [7.63, heightOfCoin, -30.26],
  [-10.35, heightOfCoin, -48.6],
  [-62.88, heightOfCoin, -62.27],
  [-58.54, heightOfCoin, 15.33],
  [-25.09, heightOfCoin, 33.29],
  [-25.09, heightOfCoin, 33.29],
  [-57.76, heightOfCoin, 115.01],
  [7.9, heightOfCoin, 105.95]
];

export const coinPositions = () => {
  return coinPositionsArray;
}

const positionForButton = [62.54859924316406, 30.712514877319336, -13.417963981628418];

// export create Coin Positions
export const createSpawnPosition = (coinArray, inventory) => {
  const inventoryItemsLength = inventory.length;
  let newCoinPositions = [];
  if (coinArray.length > 1) {
    coinArray.forEach((pos, index) => {
      if (index > 0) {
        newCoinPositions.push([parseFloat(pos[0]), parseFloat(pos[1]), parseFloat(pos[2])]);
      }
    });
  }
  const coinConfigArray = [];
  newCoinPositions.forEach((each) => {
    let tempObj = {
      position: each,
      type: generateRandomNumber(inventoryItemsLength)
    }
    coinConfigArray.push(tempObj)
  });

  // adding position for start button
  const startButtonObj = {
    position: positionForButton,
    type: inventoryItemsLength
  }
  coinConfigArray.push(startButtonObj);
  return coinConfigArray;
}

export const coinLinksv1 = [
  "https://edvantagame.edvantalabs.com/mod/prequiz/view.php?id=47",
  "https://edvantagame.edvantalabs.com/mod/collabwordcloud/view.php?id=21&g=0",
  "https://edvantagame.edvantalabs.com/mod/lesson/view.php?id=19",
  "https://edvantagame.edvantalabs.com/mod/lesson/view.php?id=22",
  "https://edvantagame.edvantalabs.com/mod/quiz/view.php?id=34",
];

export const coinLinksv2 = [
  "https://edvantagame.edvantalabs.com/mod/prequiz/view.php?id=47",
  "https://edvantagame.edvantalabs.com/mod/lesson/view.php?id=51",
  "https://edvantagame.edvantalabs.com/mod/lesson/view.php?id=52",
  "https://edvantagame.edvantalabs.com/mod/collabwordcloud/view.php?id=38&g=0",
  "https://edvantagame.edvantalabs.com/mod/questionnaire/view.php?id=53",
];

export const coinLinks = [
  "https://edvantagame.edvantalabs.com/mod/page/view.php?id=58", // Mirror of Minds
  "https://edvantagame.edvantalabs.com/mod/page/view.php?id=60", // Realm of Lights
  "https://edvantagame.edvantalabs.com/mod/page/view.php?id=61", // Realm of Earth
  "https://edvantagame.edvantalabs.com/mod/page/view.php?id=63", // way to Realm of Fire
  "https://edvantagame.edvantalabs.com/mod/page/view.php?id=64", // Realm of Fire
  "https://edvantagame.edvantalabs.com/mod/page/view.php?id=65", // way to Realm of Wind
  "https://edvantagame.edvantalabs.com/mod/page/view.php?id=66", // Realm of Wind
  "https://edvantagame.edvantalabs.com/mod/page/view.php?id=67", // Way to Final Frontier
  "https://edvantagame.edvantalabs.com/mod/page/view.php?id=103", // Survey
  "https://edvantagame.edvantalabs.com/mod/customcert/view.php?id=76", // Certificate
];

export const CoinBrief = [
  "Naruto Mirror of Minds",
  "Naruto Realm of Light",
  "Naruto Realm of Earth",
  "Naruto Way to Realm of Fire",
  "Naruto Realm of Fire",
  "Naruto Realm of Water",
  "Naruto Realm of Wind",
  "Naruto Elemental Crown",
  "Naruto Survey",
  "Naruto Certificate",
];

export const coinActivity = [47, 100, 105, 97, 53, 54, 107, 102, 99, 76];

// export const coinLinks = [
//     "https://app.thoughtjumper.com",
//     "https://edvanta.com",
//     "https://youtu.be/Vb6Y40Fin18",
//     "https://youtu.be/XMwa9q6wWkw",
//     "https://threejs.org/",
// ]
