// useGoalStore.js
import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

const useGoalStore = create(
  subscribeWithSelector((set, get) => ({
    goals: [],
    latestGoalId: null,
    toggleGoalCompletion: (id) => {
      set((state) => ({
        goals: state.goals.map((goal) =>
          goal.id === id ? { ...goal, status: !goal.completed } : goal
        ),
      }));
    },
    addGoal: (goal) => {
      const newGoal = { ...goal };
      set((state) => ({
        goals: [...state.goals, newGoal],
        latestGoalId: newGoal.id,
      }));
    },
    updateGoalWithLatestGoalId: (updates) => {
      const latestGoalId = get().latestGoalId;
      if (!latestGoalId) return;

      set((state) => ({
        goals: state.goals.map((goal) =>
          goal.id === latestGoalId ? { ...goal, ...updates } : goal
        ),
      }));
    },
    setLatestGoalId: (id) => {
      set({ latestGoalId: id });
    },
    setGoals: (newGoals) => set({ goals: newGoals }),
    // other actions...
  }))
);

export default useGoalStore;
