// src/components/AudioRecorder.js
import React, { useState, useRef, useEffect } from "react";
import useAudioStore from "../stores/useAudioRecorder";
import useSound from "use-sound";

const AudioRecorder = () => {
  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const { setRecording, setRecordingBlob, recorderLoading } = useAudioStore((state) => state);
  const [soundForRecordingStart] = useSound(
    "./assets/audio/recoder_start.mp3",
    {
      volume: 1,
    }
  );

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      // console.log("Audio stream:", stream);
      mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current.ondataavailable = (event) => {
        // console.log("Data available:", event.data);
        audioChunksRef.current.push(event.data);
      };
      mediaRecorderRef.current.onstop = () => {
        // console.log("Recording stopped");
        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/wav",
        });
        audioChunksRef.current = [];
        setRecording(URL.createObjectURL(audioBlob));
        setRecordingBlob(audioBlob);
      };
      mediaRecorderRef.current.start();
      setIsRecording(true);
      // console.log("Recording started");
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      // console.log("Recording stopped manually");
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.key === "T" || event.key === "t") && !isRecording) {
        soundForRecordingStart();
        startRecording();
      }
    };

    const handleKeyUp = (event) => {
      if ((event.key === "T" || event.key === "t") && isRecording) {
        stopRecording();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [isRecording]);

  return (
    <div
      className={
        isRecording
          ? "recorder recorder__recording"
          : "recorder recorder__stopped"
      }
    >
      {
        recorderLoading ? (
          <i class='recorder__icon recorder__icon--animate bx bx-loader-circle'></i>
        ) : (
          <i className="recorder__icon bx bxs-microphone" />
        )
      }

    </div>
  );
};

export default AudioRecorder;
