import Platforms from "./world/Platforms";
import { Elon } from "./world/models/Alicia";
// Suncity Imports
import Cars from "./world/suncity/Cars";
import Metro from "./world/suncity/Metro";
import Cafes from "./world/suncity/Cafes";
import DriveThroughs from "./world/suncity/DriveThroughs";
import HighRiseBuilding from "./world/suncity/HighRiseBuildings";
import Forest from "./world/Forest";
import Coins from "./world/Coins";
import Rocks from "./world/Rocks";
import Mountains from "./world/Mountains";
import CentralPassage from "./world/CentralPassage";
import RealmOfWind from "./world/RealmOfWind";
import LockedDoorwaysOfRealmOfWinds from "./world/LockedDoorwaysOfRealmOfWind";
import MainGateway from "./world/MainGateway";
import BareTree from "./world/BareTree";
import Pathways from "./world/Pathways";
import Grass from "./world/Grass";
import MirrorOfMinds from "./world/MirrorOfMinds";
import Bridges from "./world/Bridge";
import RealmOfFire from "./RealmOfFire";
import FinalFrontier from "./world/FinalFrontier";
import RealmOfEarth from "./world/RealmOfEarth";
import RealmOfLight from "./world/RealmOfLight";
import { FlyingIsland } from "./world/FlyingIsland";
import Ocean from "./world/Ocean";
// import Fire from "./world/Fire";
import { AdditionalProps } from "./world/Props";
import SignBoards from './world/SignBoards';
import { Instances } from "@react-three/drei";
import CafeSitting from "./world/suncity/CafeSeating";
import EdvantaComplex from "./world/suncity/EdvantaComplex";
import Houses from "./world/suncity/Houses";
import StreetLights from "./world/suncity/StreetLights";
import ShoppingMall from "./world/suncity/ShoppingMall";
import Signages from "./world/suncity/Signages";
import Landscaping from "./world/suncity/Landscaping";
import Bushes from "./world/suncity/Bushes";
import Dustbins from "./world/suncity/Dustbins";
import Walls from "./world/suncity/Walls";
import Logos from "./world/suncity/Logos";
import VisitLinks from "./world/suncity/VisitLinks";

export default function World(props) {
  const { char, downgradedPerformance } = props;

  return (
    <>
      <group>
        <Platforms />
        {/* <Forest /> */}
        {/* <Mountains /> */}
        {/* <CentralPassage /> */}
        {/* <RealmOfWind /> */}
        {/* <LockedDoorwaysOfRealmOfWinds /> */}
        {/* <MainGateway /> */}
        <Pathways char={char} />
        <Cars />
        <Metro />
        <Cafes />
        <DriveThroughs />
        <HighRiseBuilding />
        <CafeSitting />
        <EdvantaComplex />
        <Houses />
        <StreetLights />
        <ShoppingMall />
        <Signages />
        {/* <Landscaping /> */}
        <Bushes />
        <Dustbins />
        <Walls />
        <Logos />
        <VisitLinks char={char} />
        {/* <Grass /> */}
        {/* <MirrorOfMinds /> */}
        {/* <Bridges /> */}
        {/* <RealmOfFire /> */}
        {/* <FinalFrontier /> */}
        {/* <RealmOfEarth /> */}
        {/* <RealmOfLight/> */}
        <Coins char={char} />

        {/* <Elon
          char={char}
        /> */}
        {/* <FlyingIsland/> */}
        {/* <AdditionalProps /> */}
        {/* <SignBoards/> */}
        {/* <Ocean /> */}
      </group>
    </>
  );
}
