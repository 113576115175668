import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";
import { useFrame } from "@react-three/fiber";
import { Vector3 } from "three";
import useSound from "use-sound";
import useGame from "../../../stores/useGame";

const educationalResources = {
  bradley: 'https://www.youtube.com/embed/EkfIMUu24hU',
  dakota: 'https://und.edu/',
  edvanta: 'https://www.edvanta.com/',
  gomad: 'https://www.youtube.com/embed/fHuyvkcWB1Q',
  leap: 'https://leap.thoughtjumper.com/',
  maryville: 'https://maryville.edu/',
  norwich: 'https://nuari.org/',
  sassoon: 'https://www.youtube.com/embed/xJk2Quxj7lY',
  shiseido: 'https://www.youtube.com/embed/7tL3PB2o664',
  thoughtjumper: 'https://app.thoughtjumper.com/',
  jobs: 'https://job-search-three-psi.vercel.app/'
};

function Bradley(props) {
  const url = educationalResources.bradley;
  const { char } = props;
  const { isIframeOpen, setIframeUrl, toggleIsIframeOpen } = useGame(
    (state) => state
  );
  const ref = useRef();
  const { nodes, materials } = useGLTF("./assets/suncity/url/bradley.glb");
  const [playCoinSound] = useSound("./assets/audio/rpg/coin.wav", {
    volume: 0.2,
    interrupt: true,
  });
  useFrame((state, delta) => {
    //   console.log(char)
    if (char && char.current) {
      const logoPosition = ref.current.position;
      const playerPosition = char.current.translation();
      //   console.log(playerPosition, logoPosition);
      const position = new Vector3(
        playerPosition.x,
        playerPosition.y,
        playerPosition.z
      );
      const distance = logoPosition.distanceTo(position);
      if (distance < 2 && !isIframeOpen) {
        if (ref.current.visible) {
          setIframeUrl(url);
          toggleIsIframeOpen();
          playCoinSound();
          ref.current.visible = false;
          ref.current.col = false;
        }
        setTimeout(() => {
          ref.current.visible = true;
          ref.current.col = true;
        }, 10000);
      }
    }
  });
  return (
    <group {...props} dispose={null}>
      <mesh
        ref={ref}
        castShadow
        receiveShadow
        geometry={nodes.Curve063.geometry}
        material={materials["SVGMat.008"]}
        position={[-16.539, 0.381, 107.39]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={8.12}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve065.geometry}
        material={materials["Material.112"]}
        position={[-16.539, 0.385, 107.39]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={8.12}
      />
    </group>
  );
}

// Dakota
function Dakota(props) {
  const url = educationalResources.dakota; // Update with the actual URL
  const { char } = props;
  const { isIframeOpen, setIframeUrl, toggleIsIframeOpen } = useGame(
    (state) => state
  );
  const ref = useRef();
  const { nodes, materials } = useGLTF("./assets/suncity/url/dakota.glb");
  const [playCoinSound] = useSound("./assets/audio/rpg/coin.wav", {
    volume: 0.2,
    interrupt: true,
  });

  useFrame(() => {
    if (char && char.current && ref.current) {
      const logoPosition = ref.current.position;
      const playerPosition = char.current.translation();
      const position = new Vector3(
        playerPosition.x,
        playerPosition.y,
        playerPosition.z
      );
      const distance = logoPosition.distanceTo(position);
      if (distance < 2 && !isIframeOpen) {
        if (ref.current.visible) {
          setIframeUrl(url);
          toggleIsIframeOpen();
          playCoinSound();
          ref.current.visible = false;
          ref.current.col = false;
        }
        setTimeout(() => {
          ref.current.visible = true;
          ref.current.col = true;
        }, 10000);
      }
    }
  });

  return (
    <group {...props} dispose={null}>
      <mesh
        ref={ref}
        castShadow
        receiveShadow
        geometry={nodes.path6065003.geometry}
        material={materials["Material.215"]}
        position={[-15.93, 0.643, 67.406]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={5.861}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.path6067003.geometry}
        material={materials["Material.214"]}
        position={[-15.93, 0.611, 67.117]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={5.861}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.path6071004.geometry}
        material={materials["SVGMat.031"]}
        position={[-15.93, 0.775, 66.754]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={5.861}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.path6073004.geometry}
        material={materials["SVGMat.031"]}
        position={[-15.93, 0.787, 66.585]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={5.861}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.path6075004.geometry}
        material={materials["SVGMat.031"]}
        position={[-15.93, 0.769, 66.439]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={5.861}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.path6077004.geometry}
        material={materials["SVGMat.031"]}
        position={[-15.93, 0.812, 66.336]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={5.861}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.path6079004.geometry}
        material={materials["SVGMat.031"]}
        position={[-15.93, 0.776, 66.174]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={5.861}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.path6081004.geometry}
        material={materials["SVGMat.031"]}
        position={[-15.93, 0.757, 66.042]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={5.861}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.path6083004.geometry}
        material={materials["SVGMat.031"]}
        position={[-15.93, 0.771, 65.896]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={5.861}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.path6085004.geometry}
        material={materials["SVGMat.031"]}
        position={[-15.93, 0.769, 65.787]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={5.861}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.path6087004.geometry}
        material={materials["SVGMat.031"]}
        position={[-15.93, 0.802, 65.693]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={5.861}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.path6089004.geometry}
        material={materials["SVGMat.031"]}
        position={[-15.93, 0.776, 65.554]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={5.861}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.path6091004.geometry}
        material={materials["SVGMat.031"]}
        position={[-15.93, 0.769, 65.301]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={5.861}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.path6093004.geometry}
        material={materials["SVGMat.031"]}
        position={[-15.93, 0.784, 65.132]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={5.861}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.path6095004.geometry}
        material={materials["SVGMat.031"]}
        position={[-15.93, 0.57, 66.77]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={5.861}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.path6097004.geometry}
        material={materials["SVGMat.031"]}
        position={[-15.93, 0.556, 66.558]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={5.861}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.path6099004.geometry}
        material={materials["SVGMat.031"]}
        position={[-15.93, 0.549, 66.387]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={5.861}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.path6101004.geometry}
        material={materials["SVGMat.031"]}
        position={[-15.93, 0.584, 66.253]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={5.861}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.path6103004.geometry}
        material={materials["SVGMat.031"]}
        position={[-15.93, 0.557, 66.094]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={5.861}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.path6105004.geometry}
        material={materials["SVGMat.031"]}
        position={[-15.93, 0.556, 65.857]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={5.861}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.path6107004.geometry}
        material={materials["SVGMat.031"]}
        position={[-15.93, 0.528, 65.665]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={5.861}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.path6109004.geometry}
        material={materials["SVGMat.031"]}
        position={[-15.93, 0.556, 65.491]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={5.861}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.path6111004.geometry}
        material={materials["SVGMat.031"]}
        position={[-15.93, 0.556, 65.323]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={5.861}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.path6113004.geometry}
        material={materials["SVGMat.031"]}
        position={[-15.93, 0.584, 65.158]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={5.861}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.path6115004.geometry}
        material={materials["SVGMat.031"]}
        position={[-15.93, 0.528, 65.023]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={5.861}
      />
    </group>
  );
}

// Edvanta
function Edvanta(props) {
  const url = educationalResources.edvanta; // Update with actual URL
  const { char } = props;
  const { isIframeOpen, setIframeUrl, toggleIsIframeOpen } = useGame(
    (state) => state
  );
  const ref = useRef();
  const { nodes, materials } = useGLTF("./assets/suncity/url/edvanta.glb");
  const [playCoinSound] = useSound("./assets/audio/rpg/coin.wav", {
    volume: 0.2,
    interrupt: true,
  });

  useFrame(() => {
    if (char && char.current && ref.current) {
      const logoPosition = ref.current.position;
      const playerPosition = char.current.translation();
      const position = new Vector3(
        playerPosition.x,
        playerPosition.y,
        playerPosition.z
      );
      const distance = logoPosition.distanceTo(position);
      if (distance < 2 && !isIframeOpen) {
        if (ref.current.visible) {
          setIframeUrl(url);
          toggleIsIframeOpen();
          playCoinSound();
          ref.current.visible = false;
          ref.current.col = false;
        }
        setTimeout(() => {
          ref.current.visible = true;
          ref.current.col = true;
        }, 10000);
      }
    }
  });

  return (
    <group {...props} dispose={null}>
      <mesh
        ref={ref}
        castShadow
        receiveShadow
        geometry={nodes.Curve004.geometry}
        material={materials.SVGMat}
        position={[-35.326, -0.306, -30.829]}
        rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
        scale={-12.828}
      />
    </group>
  );
}

// Gomad
function Gomad(props) {
  const url = educationalResources.gomad; // Update with actual URL
  const { char } = props;
  const { isIframeOpen, setIframeUrl, toggleIsIframeOpen } = useGame((state) => state);
  const ref = useRef();
  const { nodes, materials } = useGLTF("./assets/suncity/url/gomad.glb");
  const [playCoinSound] = useSound("./assets/audio/rpg/coin.wav", { volume: 0.2, interrupt: true });

  useFrame(() => {
    if (char && char.current && ref.current) {
      const logoPosition = ref.current.position;
      const playerPosition = char.current.translation();
      const position = new Vector3(playerPosition.x, playerPosition.y, playerPosition.z);
      const distance = logoPosition.distanceTo(position);
      if (distance < 2 && !isIframeOpen) {
        if (ref.current.visible) {
          setIframeUrl(url);
          toggleIsIframeOpen();
          playCoinSound();
          ref.current.visible = false;
          ref.current.col = false;
        }
        setTimeout(() => {
          ref.current.visible = true;
          ref.current.col = true;
        }, 10000);
      }
    }
  });

  return (
    <group {...props} dispose={null}>
      <mesh ref={ref}
            castShadow
            receiveShadow
            geometry={nodes.Text003.geometry}
            material={materials["Material.022"]}
            position={[-15.73, -0.318, 22.983]}
            rotation={[Math.PI / 2, 0, -Math.PI / 2]}
            scale={0.716}/>
    </group>
  );
}


// Leap
function Leap(props) {
  const url = educationalResources.leap; // Update with actual URL
  const { char } = props;
  const { isIframeOpen, setIframeUrl, toggleIsIframeOpen } = useGame((state) => state);
  const ref = useRef();
  const { nodes, materials } = useGLTF("./assets/suncity/url/leap.glb");
  const [playCoinSound] = useSound("./assets/audio/rpg/coin.wav", { volume: 0.2, interrupt: true });

  useFrame(() => {
    if (char && char.current && ref.current) {
      const logoPosition = ref.current.position;
      const playerPosition = char.current.translation();
      const position = new Vector3(playerPosition.x, playerPosition.y, playerPosition.z);
      const distance = logoPosition.distanceTo(position);
      if (distance < 2 && !isIframeOpen) {
        if (ref.current.visible) {
          setIframeUrl(url);
          toggleIsIframeOpen();
          playCoinSound();
          ref.current.visible = false;
          ref.current.col = false;
        }
        setTimeout(() => {
          ref.current.visible = true;
          ref.current.col = true;
        }, 10000);
      }
    }
  });

  return (
    <group {...props} dispose={null}>
      <mesh ref={ref}
            castShadow
            receiveShadow
            geometry={nodes.Curve073.geometry}
            material={materials["SVGMat.011"]}
            position={[22.052, -0.771, -4.397]}
            rotation={[Math.PI / 2, 0, Math.PI / 2]}
            scale={17.268}/>
    </group>
  );
}


// Maryville
function Maryville(props) {
  const url = educationalResources.maryville; // Update with the actual URL
  const { char } = props;
  const { isIframeOpen, setIframeUrl, toggleIsIframeOpen } = useGame((state) => state);
  const ref = useRef();
  const { nodes, materials } = useGLTF("./assets/suncity/url/maryville.glb");
  const [playCoinSound] = useSound("./assets/audio/rpg/coin.wav", { volume: 0.2, interrupt: true });

  useFrame(() => {
    if (char && char.current && ref.current) {
      const logoPosition = ref.current.position;
      const playerPosition = char.current.translation();
      const position = new Vector3(playerPosition.x, playerPosition.y, playerPosition.z);
      const distance = logoPosition.distanceTo(position);
      if (distance < 2 && !isIframeOpen) {
        if (ref.current.visible) {
          setIframeUrl(url);
          toggleIsIframeOpen();
          playCoinSound();
          ref.current.visible = false;
          ref.current.col = false;
        }
        setTimeout(() => {
          ref.current.visible = true;
          ref.current.col = true;
        }, 10000);
      }
    }
  });

  return (
    <group {...props} dispose={null}>
      <mesh ref={ref}
            castShadow
            receiveShadow
            geometry={nodes.path14002.geometry}
            material={materials["SVGMat.010"]}
            position={[-15.61, 0.107, 77.92]}
            rotation={[Math.PI / 2, 0, -Math.PI / 2]}
            scale={11.873}/>
      {/* Add more meshes if needed */}
    </group>
  );
}


// Norwich
function Norwich(props) {
  const url = educationalResources.norwich; // Update with the actual URL
  const { char } = props;
  const { isIframeOpen, setIframeUrl, toggleIsIframeOpen } = useGame((state) => state);
  const ref = useRef();
  const { nodes, materials } = useGLTF("./assets/suncity/url/norwich.glb");
  const [playCoinSound] = useSound("./assets/audio/rpg/coin.wav", { volume: 0.2, interrupt: true });

  useFrame(() => {
    if (char && char.current && ref.current) {
      const logoPosition = ref.current.position;
      const playerPosition = char.current.translation();
      const position = new Vector3(playerPosition.x, playerPosition.y, playerPosition.z);
      const distance = logoPosition.distanceTo(position);
      if (distance < 2 && !isIframeOpen) {
        if (ref.current.visible) {
          setIframeUrl(url);
          toggleIsIframeOpen();
          playCoinSound();
          ref.current.visible = false;
          ref.current.col = false;
        }
        setTimeout(() => {
          ref.current.visible = true;
          ref.current.col = true;
        }, 10000);
      }
    }
  });

  return (
    <group {...props} dispose={null}>
      <mesh ref={ref}
            castShadow
            receiveShadow
            geometry={nodes.Curve054.geometry}
            material={materials["Material.027"]}
            position={[-16.771, 0.068, 88.542]}
            rotation={[1.556, 0, -Math.PI / 2]}
            scale={2.916}/>
      {/* Add more meshes if needed */}
    </group>
  );
}


// Sassoon
function Sassoon(props) {
  const url = educationalResources.sassoon; // Update with the actual URL
  const { char } = props;
  const { isIframeOpen, setIframeUrl, toggleIsIframeOpen } = useGame((state) => state);
  const ref = useRef();
  const { nodes, materials } = useGLTF("./assets/suncity/url/sassoon.glb");
  const [playCoinSound] = useSound("./assets/audio/rpg/coin.wav", { volume: 0.2, interrupt: true });

  useFrame(() => {
    if (char && char.current && ref.current) {
      const logoPosition = ref.current.position;
      const playerPosition = char.current.translation();
      const position = new Vector3(playerPosition.x, playerPosition.y, playerPosition.z);
      const distance = logoPosition.distanceTo(position);
      if (distance < 2 && !isIframeOpen) {
        if (ref.current.visible) {
          setIframeUrl(url);
          toggleIsIframeOpen();
          playCoinSound();
          ref.current.visible = false;
          ref.current.col = false;
        }
        setTimeout(() => {
          ref.current.visible = true;
          ref.current.col = true;
        }, 10000);
      }
    }
  });

  return (
    <group {...props} dispose={null}>
      <mesh ref={ref}
            castShadow
            receiveShadow
            geometry={nodes.Curve069.geometry}
            material={materials["Material.164"]}
            position={[-16.724, -0.059, 97.282]}
            rotation={[Math.PI / 2, 0, -Math.PI / 2]}
            scale={15.697}/>
    </group>
  );
}


// Shiseido
function Shiseido(props) {
  const url = educationalResources.shiseido; // Update with the actual URL
  const { char } = props;
  const { isIframeOpen, setIframeUrl, toggleIsIframeOpen } = useGame((state) => state);
  const ref = useRef();
  const { nodes, materials } = useGLTF("./assets/suncity/url/shiseido.glb");
  const [playCoinSound] = useSound("./assets/audio/rpg/coin.wav", { volume: 0.2, interrupt: true });

  useFrame(() => {
    if (char && char.current && ref.current) {
      const logoPosition = ref.current.position;
      const playerPosition = char.current.translation();
      const position = new Vector3(playerPosition.x, playerPosition.y, playerPosition.z);
      const distance = logoPosition.distanceTo(position);
      if (distance < 2 && !isIframeOpen) {
        if (ref.current.visible) {
          setIframeUrl(url);
          toggleIsIframeOpen();
          playCoinSound();
          ref.current.visible = false;
          ref.current.col = false;
        }
        setTimeout(() => {
          ref.current.visible = true;
          ref.current.col = true;
        }, 10000);
      }
    }
  });

  return (
    <group {...props} dispose={null}>
      <mesh ref={ref}
            castShadow
            receiveShadow
            geometry={nodes.Curve049.geometry}
            material={materials["Material.015"]}
            position={[-16.553, 0.016, 118.605]}
            rotation={[Math.PI / 2, 0, -Math.PI / 2]}
            scale={14.982}/>
    </group>
  );
}


// ThoughtJumper
function ThoughtJumper(props) {
  const url = educationalResources.thoughtjumper; // Update with the actual URL or concept
  const { char } = props;
  const { isIframeOpen, setIframeUrl, toggleIsIframeOpen } = useGame((state) => state);
  const ref = useRef();
  const { nodes, materials } = useGLTF("./assets/suncity/url/thoughtjumper.glb");
  const [playCoinSound] = useSound("./assets/audio/rpg/coin.wav", { volume: 0.2, interrupt: true });

  useFrame(() => {
    if (char && char.current && ref.current) {
      const logoPosition = ref.current.position;
      const playerPosition = char.current.translation();
      const position = new Vector3(playerPosition.x, playerPosition.y, playerPosition.z);
      const distance = logoPosition.distanceTo(position);
      if (distance < 2 && !isIframeOpen) {
        if (ref.current.visible) {
          setIframeUrl(url);
          toggleIsIframeOpen();
          playCoinSound();
          ref.current.visible = false;
          ref.current.col = false;
        }
        setTimeout(() => {
          ref.current.visible = true;
          ref.current.col = true;
        }, 10000);
      }
    }
  });

  return (
    <group {...props} dispose={null}>
      <mesh ref={ref}
            castShadow
            receiveShadow
            geometry={nodes.Curve011.geometry}
            material={materials["SVGMat.001"]}
            position={[23.315, -0.031, -35.193]}
            rotation={[-Math.PI / 2, 0, 1.563]}
            scale={-40.113}/>
    </group>
  );
}

// Jobs
function Jobs(props) {
  const url = educationalResources.jobs;
  const { char } = props;
  const { isIframeOpen, setIframeUrl, toggleIsIframeOpen } = useGame((state) => state);
  const ref = useRef();
  const { nodes, materials } = useGLTF('./assets/suncity/url/jobs.glb')
  const [playCoinSound] = useSound("./assets/audio/rpg/coin.wav", { volume: 0.2, interrupt: true });

  useFrame(() => {
    if (char && char.current && ref.current) {
      const logoPosition = ref.current.position;
      const playerPosition = char.current.translation();
      const position = new Vector3(playerPosition.x, playerPosition.y, playerPosition.z);
      const distance = logoPosition.distanceTo(position);
      // console.log('xvf', distance)
      if (distance < 2 && !isIframeOpen) {
        if (ref.current.visible) {
          setIframeUrl(url);
          toggleIsIframeOpen();
          playCoinSound();
          ref.current.visible = false;
          ref.current.col = false;
        }
        setTimeout(() => {
          ref.current.visible = true;
          ref.current.col = true;
        }, 10000);
      }
    }
  });
  return (
    <group {...props} dispose={null}>
      <mesh
        ref={ref}
        castShadow
        receiveShadow
        geometry={nodes.Text004.geometry}
        material={materials['Material.217']}
        position={[-44.321, 0.366, 62.31]}
        rotation={[1.563, 0.016, -1.117]}
        scale={0.24}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Text006.geometry}
        material={materials['Material.217']}
        position={[-53.917, 8.01, 56.665]}
        rotation={[1.566, -0.007, 0.025]}
        scale={1.724}
      />
    </group>
  )

}


function VisitLinks(props) {
  return (
    <>
      <Bradley char={props.char} />
      <Dakota char={props.char} />
      <Edvanta char={props.char} />
      <Gomad char={props.char} />
      <Leap char={props.char} />
      <Maryville char={props.char} />
      <Norwich char={props.char} />
      <Sassoon char={props.char} />
      <Shiseido char={props.char} />
      <ThoughtJumper char={props.char} />
      <Jobs char={props.char}/>
    </>
  );
}

export default VisitLinks;

useGLTF.preload("./assets/suncity/url/bradley.glb");
useGLTF.preload("./assets/suncity/url/dakota.glb");
useGLTF.preload("./assets/suncity/url/edvanta.glb");
useGLTF.preload("./assets/suncity/url/gomad.glb");
useGLTF.preload("./assets/suncity/url/leap.glb");
useGLTF.preload("./assets/suncity/url/maryville.glb");
useGLTF.preload("./assets/suncity/url/norwich.glb");
useGLTF.preload("./assets/suncity/url/sassoon.glb");
useGLTF.preload("./assets/suncity/url/shiseido.glb");
useGLTF.preload("./assets/suncity/url/thoughtjumper.glb");
useGLTF.preload("./assets/suncity/url/jobs.glb");
