import React, { useRef, useState, useEffect } from "react";
import { useGLTF, useAnimations, PositionalAudio } from "@react-three/drei";
import { RigidBody, CuboidCollider } from "@react-three/rapier";
import { ConvaiClient } from "convai-web-sdk";
import { useFrame, useLoader } from "@react-three/fiber";
import { Vector3 } from "three";
import useSound from "use-sound";
import * as THREE from "three";
import useGame from "../../../stores/useGame";
import useDialogue from "../../../stores/useDialogue";

const convaiClient = new ConvaiClient({
  apiKey: "6b0b554cf92e51ebda8d112be737a7f6",
  characterId: "0b9e8e6c-f8d6-11ee-9420-42010a7be00e",
  enableAudio: true, // use false for text only.
  //   sessionId: "4d94cc001ebafdf3c8e542aded273b86",
  //   enableFacialData: true,
  //   faceModel: 3, // OVR lipsync
});

export function Elon(props) {
  const { char } = props;
  const group = useRef();
  const elon = useRef();
  const { nodes, materials, animations } = useGLTF(
    "./assets/models/Alicia.glb"
  );
  // const [ playElonSound ] = useSound('./assets/audio/musk.wav', { volume: 1, interrupt: true })
  const { actions } = useAnimations(animations, group);

  let play = true;
  const audio = useRef();
  // const loader = new THREE.AudioLoader()
  // const audioFile = useLoader(loader, './assets/audio/musk.wav')
  // const audioFile = useLoader(THREE.AudioLoader, './assets/audio/musk.wav')

  // Convai client
  //Declare this part(React hooks) of the code outside the use effect
  const [userText, setUserText] = useState("");
  const [npcText, setNpcText] = useState("");
  const [keyPressed, setKeyPressed] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [isTalking, setIsTalking] = useState(false);
  const finalizedUserText = useRef();
  const npcTextRef = useRef();
  // console.log("xvf", convaiClient);

  // useGameState
  const setShowAfterImage = useGame((state) => state.setShowAfterImage);
  // useDialogueState
  const narration = useDialogue((state) => state.narration);
  const setNarration = useDialogue((state) => state.setNarration);
  const setDialogue = useDialogue((state) => state.setDialogue);
  const clearDialogue = useDialogue((state) => state.clearDialogue);
  const setIsLoading = useGame((state) => state.setIsLoading);
  convaiClient.setResponseCallback((response) => {
    // console.log(response);
    if (response.hasUserQuery()) {
      // console.log("xvf", response);
      var transcript = response.getUserQuery();
      var isFinal = transcript.getIsFinal();
      // console.log("xvf", "transcript", transcript.getTextData());
      if (isFinal) {
        finalizedUserText.current += " " + transcript.getTextData();
        transcript = "";
      }
      if (transcript) {
        setUserText(finalizedUserText.current + transcript.getTextData());
      } else {
        setUserText(finalizedUserText.current);
      }
    }
    if (response.hasAudioResponse()) {
      // clearDialogue();
      let audioResponse = response?.getAudioResponse();
      // if (audioResponse?.getVisemesData()?.array[0]) {
      // //Viseme data
      //     let faceData = audioResponse?.getVisemesData().array[0];
      //     //faceData[0] implies sil value. Which is -2 if new chunk of audio is recieve.
      //     if (faceData[0] !== -2) {
      //         facialRef.current.push(faceData);
      //         setFacialData(facialRef.current);
      //     }
      // }
      // var audioResponse = response?.getAudioResponse();
      // console.log(audioResponse);
      npcTextRef.current += " " + audioResponse.getTextData();
      if (audioResponse.getTextData().length > 0) {
        setDialogue(audioResponse.getTextData());
      }
      setNpcText(npcTextRef.current);
    }
  });

  convaiClient.onAudioPlay((e) => {
    // console.log(e);
    setIsTalking(true);
  });

  convaiClient.onAudioStop(() => {
    setIsTalking(false);
    clearDialogue();
    setIsLoading(false);
    if (narration && narration.next) {
      setNarration(narration.next);
    }
  });

  function handleSpacebarPress(event) {
    // console.log('pressed and start recording', event);
    if (event.keyCode === 84 && !keyPressed) {
      setKeyPressed(true);
      finalizedUserText.current = "";
      npcTextRef.current = "";
      setUserText("");
      setNpcText("");
      // console.log("xvf", convaiClient);
      convaiClient.startAudioChunk();
    }
  }

  function handleSpacebarRelease(event) {
    if (event.keyCode === 84 && keyPressed) {
      setKeyPressed(false);
      convaiClient.endAudioChunk();
      setIsLoading(true);
      // console.log(convaiClient);
    }
  }

  useEffect(() => {
    if (narration && narration.source === "convai") {
      setDialogue("...");
      setTimeout(() => {
        convaiClient.sendTextChunk(narration.text);
      }, narration.timeout);
    }
  }, [narration]);

  useEffect(() => {
    window.addEventListener("keydown", handleSpacebarPress);
    window.addEventListener("keyup", handleSpacebarRelease);
    return () => {
      window.removeEventListener("keydown", handleSpacebarPress);
      window.removeEventListener("keyup", handleSpacebarRelease);
    };
  }, [keyPressed]);

  useFrame(() => {
    // actions["Crystal_3_LP.002Action"].play();
    // Object.keys(actions).forEach((each) => actions[each].play());

    if (char.current) {
      const elonPosition = elon.current.translation();
      const elonVector = new Vector3(
        elonPosition.x,
        elonPosition.y,
        elonPosition.z
      );
      const playerPosition = char.current.translation();
      const position = new Vector3(
        playerPosition.x,
        playerPosition.y,
        playerPosition.z
      );
      const distance = elonVector.distanceTo(position);

      if (distance < 20) {
        setIsListening(true);
        // setShowAfterImage(false);
      } else if (distance > 20) {
        setIsListening(false);
        // setShowAfterImage(true);
      }

      if (elonPosition.y < -0.5) {
        // console.log(charPosition.y)
        elon.current.setTranslation({ x: -25.766, y: 14.264, z: -47.438 });
        // ref.current.applyImpulse(impulse)
        // ref.current.applyTorqueImpulse(torque)
        elon.current.setLinvel({ x: 0, y: 0, z: 0 });
        elon.current.setAngvel({ x: 0, y: 0, z: 0 });
        elon.current.rotation.y = Math.PI * 1;
      }

      // if(elonPosition !== elonLocation)
      // {
      //     // console.log("flying!")
      //     // console.log(elonPosition.y)
      //     // console.log(elonLocation)

      // }
    }
  });

  return (
    <RigidBody ref={elon} colliders={false} canSleep={false} {...props}>
      <group {...props} dispose={null}>
        <group name="Scene">
          <group
            name="Crystal_3_LP002"
            position={[-27.954, 20.789, -47.713]}
            rotation={[Math.PI, -1.283, Math.PI]}
            // scale={14.115}
            scale={0.001}
          >
            <mesh
              name="Circle013"
              castShadow
              receiveShadow
              geometry={nodes.Circle013.geometry}
              material={materials["Material.072"]}
            />
            <mesh
              name="Circle013_1"
              castShadow
              receiveShadow
              geometry={nodes.Circle013_1.geometry}
              material={materials["Material.069"]}
            />
            <mesh
              name="Circle013_2"
              castShadow
              receiveShadow
              geometry={nodes.Circle013_2.geometry}
              material={materials["Material.070"]}
            />
            <mesh
              name="Circle013_3"
              castShadow
              receiveShadow
              geometry={nodes.Circle013_3.geometry}
              material={materials["Material.071"]}
            />
            <mesh
              name="Circle013_4"
              castShadow
              receiveShadow
              geometry={nodes.Circle013_4.geometry}
              material={materials.Magic_tower}
            />
          </group>
          <mesh
            name="Body141"
            castShadow
            receiveShadow
            geometry={nodes.Body141.geometry}
            material={materials["Material.063"]}
            position={[-25.766, 14.283, -47.438]}
            rotation={[0, 0.868, 0]}
            // scale={3.525}
            scale={0.001}
          />
          <group
            name="Body180"
            position={[-25.766, 14.283, -47.438]}
            rotation={[0, 0.868, 0]}
            // scale={3.525}
            scale={0.001}
          >
            <mesh
              name="body180"
              castShadow
              receiveShadow
              geometry={nodes.body180.geometry}
              material={materials["Material.007"]}
            />
            <mesh
              name="body180_1"
              castShadow
              receiveShadow
              geometry={nodes.body180_1.geometry}
              material={materials["Material.021"]}
            />
          </group>
          <mesh
            name="Body245"
            castShadow
            receiveShadow
            geometry={nodes.Body245.geometry}
            material={materials["Material.065"]}
            position={[-25.766, 14.283, -47.438]}
            rotation={[0, 0.868, 0]}
            // scale={3.525}
            scale={0.001}
          />
          <mesh
            name="Body281"
            castShadow
            receiveShadow
            geometry={nodes.Body281.geometry}
            material={materials["Material.064"]}
            position={[-25.766, 14.283, -47.438]}
            rotation={[0, 0.868, 0]}
            // scale={3.525}
            scale={0.001}
          />
          <mesh
            name="Body350"
            castShadow
            receiveShadow
            geometry={nodes.Body350.geometry}
            material={materials["Material.068"]}
            position={[-25.766, 14.283, -47.438]}
            rotation={[0, 0.868, 0]}
            // scale={3.525}
            scale={0.001}
          />
          <mesh
            name="Body705"
            castShadow
            receiveShadow
            geometry={nodes.Body705.geometry}
            material={materials["Material #39.002"]}
            position={[-25.766, 14.283, -47.438]}
            rotation={[0, 0.868, 0]}
            // scale={3.525}
            scale={0.001}
          />
          <mesh
            name="Cube123"
            castShadow
            receiveShadow
            geometry={nodes.Cube123.geometry}
            material={materials["Material.064"]}
            position={[-25.745, 19.028, -47.455]}
            rotation={[0, 0.931, 0]}
            // scale={0.691}
            scale={0.001}
          />
        </group>
      </group>
    </RigidBody>
  );
}

useGLTF.preload("./assets/models/world/Alicia.glb");
