import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

const useEventStore = create(
    subscribeWithSelector((set) => {
        return {
            event: null,
            setEvent: (val) => set({event: val}),
        }
    })
)

export default useEventStore;