import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { rocksPositions } from "./positions/RockPositions";
import { RigidBody } from "@react-three/rapier";

function Rocks(props) {
  const { nodes, materials } = useGLTF("./assets/island/Rocks-all-Layers.glb");

  return (
    <group dispose={null}>
      {rocksPositions.map((position, index) => (
          <RigidBody key={index} type="fixed" colliders="trimesh">
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rock001.geometry}
                material={materials["rock 1.001"]}
                position={position}
                scale={24.675}
                />
        </RigidBody>
      ))}
    </group>
  );
}

export default Rocks;

useGLTF.preload("./assets/island/Rocks-all-Layers.glb");